import { QuotationSalesmanEditableDetails } from "../interfaces/QuotationSalesmanEditableDetails";
import {
	quotationBaseEditableDetailsModel,
	quotationBaseEditableDetailsModelBuilder,
} from "./QuotationBaseEditableDetailsModel";

export const quotationSalesmanEditableDetailsModel: QuotationSalesmanEditableDetails =
	{
		...quotationBaseEditableDetailsModel,
		financialCustomerCode: "",
	};

export function quotationSalesmanEditableDetailsModelBuilder(
	data: QuotationSalesmanEditableDetails
) {
	if (!data) return quotationSalesmanEditableDetailsModel;
	var QuotationBaseEditableDetails =
		quotationBaseEditableDetailsModelBuilder(data);
	return {
		...QuotationBaseEditableDetails,
	} as QuotationSalesmanEditableDetails;
}
