import { cloneDeep } from "lodash";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import { DistrictEditProps } from "../interfaces/DistrictEditProps";
import { DistrictGroupProps } from "../interfaces/DistrictGroupProps";

class GroupDistrictStore {
	@observable loading: boolean = false;
	@observable districtGroupInitial: DistrictGroupProps[] = [];
	@observable districtGroupEdit: DistrictGroupProps[] = [];

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action cleanData = () => {
		this.districtGroupInitial = [];
		this.districtGroupEdit = [];
	};

	@action getListDistrictGroups = async () => {
		this.loading = true;

		this.api.districtService
			.getListDistrictGroups()
			.then((rs) => {
				this.districtGroupInitial = rs.data ?? [];
				this.districtGroupEdit = rs.data ?? [];
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action updateDistrictGroupDistricts = async () => {
		this.loading = true;

		return await this.api.districtService
			.changeAssociationDistrictToDistrictGroup(this.districtGroupEdit)
			.then(async () => {
				await this.getListDistrictGroups();
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action resetDistrictGroupEdit = () => {
		this.districtGroupEdit = cloneDeep(this.districtGroupInitial);
	};

	@action setDistrictIntoDistrictGroup = (
		district: DistrictEditProps | undefined,
		districtGroup: DistrictGroupProps
	) => {
		let group = this.districtGroupEdit.filter((x) => x.id === districtGroup.id);

		if (group.length === 0) return;

		let districts = group.map((x) => x.districts)[0];

		if (districts && districts.length === 0) return;

		let districtInsideGroup = districts?.filter(
			(x) => x?.id === district?.id
		)[0];

		if (!districtInsideGroup) return;

		districtInsideGroup!.isAssociated = !districtInsideGroup!.isAssociated;
	};
}

const GroupDistrictStoreContext = React.createContext(new GroupDistrictStore());

export const useGroupDistrictStore = () =>
	React.useContext(GroupDistrictStoreContext);
