import React from "react";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { FilterProps } from "../interfaces/FilterProps";
import { filterModel } from "../models/FilterModel";
import { QuotaProposalProps } from "../interfaces/QuotaProposalProps";
import {
	LINE_F_PROPOSAL_TYPES,
	LINE_VM_PROPOSAL_TYPES,
	ProposalTypesConsiderALL,
	ProposalTypesConsiderBR,
} from "../constants/proposalTypes";
import { QuotaProposalItemProps } from "../interfaces/QuotaProposalItemProps";
import moment from "moment";
import { downloadCsv } from "../../../../utils/downloadCsv";
import { DateType, LineType } from "../../../../utils/GenericTypes";

configure({ enforceActions: "always" });

/* Store start */
class VolvoReadStore {
	@observable filter: FilterProps = filterModel;
	@observable filterIsValid: boolean = true;
	@observable lastUpdateDate: Date = new Date();
	@observable proposalLineTypes: Array<string> = [];
	@observable brMarketTotal: Array<QuotaProposalProps> = [];
	@observable allMarketTotal: Array<QuotaProposalProps> = [];
	@observable line: LineType = filterModel.line;
	@observable date: Date = filterModel.date;
	@observable timePeriod: number = filterModel.timePeriod;
	@observable dateType: DateType = filterModel.dateType;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.proposalLineTypes = [];
		this.brMarketTotal = [];
		this.allMarketTotal = [];
	};

	@action setFilterByKeyOnChange = <K extends keyof FilterProps>(
		fieldKey: K,
		newValue: FilterProps[K],
		isValid: boolean
	) => {
		const _filter: FilterProps = Object.assign({}, this.filter);
		_filter[fieldKey] = newValue;
		this.filter = _filter;
		this.filterIsValid = isValid;
	};

	@action setFilter = () => {
		this.line = this.filter.line;
		this.date = this.filter.date;
		this.timePeriod = this.filter.timePeriod;
		this.dateType = this.filter.dateType;
		this.setProposalLineTypes();
		this.allMarketTotal = [];
		this.brMarketTotal = [];
	};

	@action setProposalLineTypes = () => {
		this.proposalLineTypes = [];
		if (this.line === "F") this.proposalLineTypes = LINE_F_PROPOSAL_TYPES;
		else if (this.line === "VM")
			this.proposalLineTypes = LINE_VM_PROPOSAL_TYPES;
	};

	@action getQuotaProposalByType = async (proposalType: string) => {
		this.lastUpdateDate = new Date();
		const response = await this.api.quotaService.getReadOnlyList(
			this.line,
			this.date.toDateString(),
			this.timePeriod,
			proposalType,
			this.dateType
		);
		this.setBrMarketTotal(response.data, proposalType);
		this.setAllMarketTotal(response.data, proposalType);
		return response.data;
	};

	@action sumItem = ({ ...a }, b: any, region: string) => ({
		...Object.keys(a).reduce(
			(r, k) => ({ ...r, [k]: k === "region" ? region : a[k] + b[k] }),
			{}
		),
	});

	@action getProposalMarketSum = (
		marketProposal: Array<QuotaProposalProps>,
		proposals: Array<QuotaProposalProps>,
		sumType: string,
		region: string
	) => {
		return marketProposal.map(
			(m, idx) =>
				({
					...m,
					sumType: [...(m.sumType as Array<string>), sumType],
					data: [
						m.data
							.concat([proposals[idx].total as QuotaProposalItemProps])
							.reduce(
								(m, o) => this.sumItem(o, m, region) as QuotaProposalItemProps
							),
					],
				}) as QuotaProposalProps
		);
	};

	@action getProposalMarket = (
		proposals: Array<QuotaProposalProps>,
		type: string
	) => {
		return proposals.map(
			(m) =>
				({
					data: [m.total as QuotaProposalItemProps],
					type: type,
					period: m.period,
					sumType: [m.type],
				}) as QuotaProposalProps
		);
	};

	@action setBrMarketTotal = (
		proposals: Array<QuotaProposalProps>,
		type: string
	) => {
		if (!ProposalTypesConsiderBR.includes(type)) return;
		if (this.brMarketTotal.length === 0) {
			this.brMarketTotal = this.getProposalMarket(proposals, "BR");
			return;
		}
		this.brMarketTotal = this.getProposalMarketSum(
			this.brMarketTotal,
			proposals,
			type,
			"BR+"
		);
	};

	@action setAllMarketTotal = (
		proposals: Array<QuotaProposalProps>,
		type: string
	) => {
		if (!ProposalTypesConsiderALL.includes(type)) return;

		if (this.allMarketTotal.length === 0) {
			this.allMarketTotal = this.getProposalMarket(proposals, "ALL");
			return;
		}
		this.allMarketTotal = this.getProposalMarketSum(
			this.allMarketTotal,
			proposals,
			type,
			"ALL+"
		);
	};

	@action getQuotaProposalOrdersCsvVolvo = async (
		region: string,
		period: string,
		deliveryProposaltype: string,
		t: any
	) => {
		var date = new Date(period);
		let fileName = `${moment(date).format("YYYYMM")}${t(
			"quota"
		)}${moment().format("YYYYMMDD")}.csv`;
		await this.api.quotaService
			.getCsvDeliveryProposalVolvoVDB(
				this.filter.line,
				region,
				period,
				deliveryProposaltype,
				this.filter.dateType
			)
			.then((content: any) => downloadCsv(content, fileName));
	};
}

/* Store end */

/* Store helpers */
const VolvoReadStoreContext = React.createContext(new VolvoReadStore());

/* Hook to use store in any functional component */
export const useVolvoReadStore = () => React.useContext(VolvoReadStoreContext);
