import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import moment from "moment";
import DateForm from "../FormInputs/DateForm";
import { requiredDateValidator } from "../../utils/FormValidators";

interface MonthFilterProps {
	maxDate?: Date;
	minDate?: Date;
	value: Date;
	set: (value: any) => void;
}

const MonthFilter: React.FC<MonthFilterProps> = ({
	maxDate = moment().startOf("month").add(6, "M").toDate(),
	minDate = moment().startOf("month").subtract(24, "M").toDate(),
	set,
	value = moment().startOf("month").toDate(),
}) => {
	const { t } = useTranslation("general");
	const [date, setDate] = useState<Date>(value);

	const handleChange = (dataItem: any) => {
		const newDateMoment = moment(dataItem);
		if (!Boolean(validator(dataItem))) {
			set({ value: newDateMoment.startOf("month").toDate(), isValid: true });
		} else {
			set({ value: undefined, isValid: false });
		}
		setDate(dataItem);
	};

	const validator = (value: Date | null) => {
		if (!requiredDateValidator(value, minDate, maxDate)) return "required";
		return "";
	};

	return (
		<DateForm
			editable
			label={t("month")}
			inputFormat="MM/yyyy"
			value={date}
			onChange={handleChange}
			minDate={minDate}
			maxDate={maxDate}
			validator={validator}
		/>
	);
};

export default observer(MonthFilter);
