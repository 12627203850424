import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { CustomerSectionProps } from "../../interfaces/Sections/CustomerSection";
import SwitchForm from "../../../../../components/FormInputs/SwitchForm";
import AutoCompleteForm from "../../../../../components/FormInputs/AutoCompleteForm";

const CustomerSection: React.FC<CustomerSectionProps> = ({
	readOnlyFields,
	editable,
	canEditCustomerDetailsFinancialInstitution,
	isQuotationSalesmanUser,
	baseEditableFields,
	loadFinancialCustomer,
	financialCustomerOptions,
	setQuotationBaseEditableField,
	isFinancialCustomer,
	setIsFinancialCustomer,
}) => {
	const { t } = useTranslation(["quotation", "general"]);
	const [loading, setLoading] = React.useState(true);
	const isFinancialCustomerOnChange = (isFinancialCustomer: boolean) => {
		setIsFinancialCustomer(isFinancialCustomer);
		if (!isFinancialCustomer) {
			setQuotationBaseEditableField(
				"financialCustomerCode",
				"",
				isQuotationSalesmanUser
			);
		}
	};

	const fetchFinancialCustomer = React.useCallback(async () => {
		await loadFinancialCustomer(
			canEditCustomerDetailsFinancialInstitution
		).finally(() => setLoading(false));
	}, [loadFinancialCustomer, canEditCustomerDetailsFinancialInstitution]);

	React.useEffect(() => {
		fetchFinancialCustomer();
	}, [fetchFinancialCustomer]);

	const formValidators = (value: any) => {
		if (isFinancialCustomer && value === "") {
			return t("validator.fieldRequired", { ns: "general" });
		}
		return "";
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.customer.title")}
			</Grid>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.customer.name")}
							value={readOnlyFields.customerName}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.customer.rut")}
							editable={editable}
							value={baseEditableFields.rut}
							onChange={(e) =>
								setQuotationBaseEditableField(
									"rut",
									e.target.value,
									isQuotationSalesmanUser
								)
							}
							inputProps={{ maxLength: 10 }}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.customer.code")}
							value={readOnlyFields.customerCode}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.customer.business")}
							value={readOnlyFields.business}
						/>
					</Grid>
					<Grid item xs={4}>
						<SwitchForm
							label={t("details.sections.customer.isFinancialCustomer")}
							editable={canEditCustomerDetailsFinancialInstitution}
							checked={isFinancialCustomer}
							onChange={(e) => isFinancialCustomerOnChange(e.target.checked)}
						/>
					</Grid>
					<Grid item xs={4}>
						<AutoCompleteForm
							label={t("details.sections.customer.financialCustomer")}
							labelId={"details.sections.customer.financialCustomer"}
							value={baseEditableFields.financialCustomerCode}
							editable={canEditCustomerDetailsFinancialInstitution}
							options={financialCustomerOptions}
							hasEditIcon
							hasEmptyDefaultItem
							disabled={!isFinancialCustomer}
							onChange={(value) =>
								setQuotationBaseEditableField(
									"financialCustomerCode",
									value,
									isQuotationSalesmanUser
								)
							}
							isCustomSearch
							editTooltip={t("tooltips.financialCustomerSearch")}
							validator={(value) => formValidators(value)}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(CustomerSection);
