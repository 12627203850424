import { Search } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import LineFilter from "../../../../components/Filters/LineFilter";
import MarketFilter from "../../../../components/Filters/MarketFilter";
import YearFilter from "../../../../components/Filters/YearFilter";
import { useEditQuotaStore } from "../stores/EditQuotaStore";

const EditFilters: React.FC = () => {
	const { setFilterByKeyOnChange, filter, filterIsValid, loadData } =
		useEditQuotaStore();

	return (
		<Box sx={{ display: "flex" }}>
			<Grid container sx={{ display: "flex", height: "90px" }} spacing={3}>
				<Grid item>
					<MarketFilter
						set={(e) => setFilterByKeyOnChange("market", e.value, e.isValid)}
						value={filter.market}
					/>
				</Grid>
				<Grid item>
					<YearFilter
						set={(e) => setFilterByKeyOnChange("year", e.value, e.isValid)}
						value={filter.year}
					/>
				</Grid>
				<Grid item>
					<LineFilter
						set={(e) => setFilterByKeyOnChange("line", e.value, e.isValid)}
						value={filter.line}
					/>
				</Grid>
				<Grid item sx={{ alignSelf: "center" }}>
					<Button
						variant="contained"
						onClick={loadData}
						size="small"
						disabled={!filterIsValid}
					>
						<Search fontSize="small" />
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default observer(EditFilters);
