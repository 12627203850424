import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { useTranslation } from "react-i18next";
import DealerPreFilter from "../../../../../components/PreFilter/containers/DealerPreFilter";
import DestinationPreFilter from "../../../../../components/PreFilter/containers/DestinationPreFilter";
import PaymentFormPreFilter from "../../../../../components/PreFilter/containers/PaymentFormPreFilter";
import ShippingDatePreFilter from "../../../../../components/PreFilter/containers/ShippingDatePreFilter";
import VehicleLocationPreFilter from "../../../../../components/PreFilter/containers/VehicleLocationPreFilter";
import CertificateDatePreFilter from "../../../../../components/PreFilter/containers/CertificateDatePreFilter";
import ChassiAOPreFilter from "../../../../../components/PreFilter/containers/ChassiAOPreFilter";
import CustomerSASPreFilter from "../../../../../components/PreFilter/containers/CustomerSASPreFilter";
import GokDatePreFilter from "../../../../../components/PreFilter/containers/GokDatePreFilter";
import AsWeekDatePreFilter from "../../../../../components/PreFilter/containers/AsWeekDatePreFilter";
import BusinessControlVehiclePreFilter from "../../../../../components/PreFilter/containers/BusinessControlVehiclePreFilter";
import CurrencyPreFilter from "../../../../../components/PreFilter/containers/CurrencyPreFilter";
import { getCurrencyEnumOptions } from "../../../../../utils/SelectOptions";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { ARG_DEALERS } from "../../../../../constants/AuthGroups/authGroups";
import PreFilter from "../../../../../components/PreFilter/PreFilter";
import PrevisionBillingMonthPreFilter from "../../../../../components/PreFilter/containers/PrevisionBillingMonthPreFilter";
import { CLOSED_PERIOD_MENU_ARG_TRUCK } from "../../../../../constants/Menus/ClosedPeriodMenuUtils";
import { ChassiType } from "../../../../../constants/chassiAoOmFoConst";

const PreFilters: React.FC = () => {
	const { t, i18n } = useTranslation(["order", "general"]);
	const language = i18n.language ? i18n.language : "en-US";

	const {
		cleanAllFilters,
		hasPreFiltersApplied,
		hasFormatError,
		filters,
		removeFilter,
		applyFilters,
		cleanSpecificPreFilters,
		onUndo,
	} = useStores().filterStore;

	const {
		preFilterPrevisionBillingMonthStore,
		preFilterAsWeekDateStore,
		preFilterCertificateDateStore,
		preFilterGokDateStore,
		preFilterShippingDateStore,
		preFilterDealerStore,
		preFilterPaymentFormStore,
		preFilterVehicleLocationStore,
		preFilterCurrencyStore,
		preFilterChassiAOStore,
		preFilterCustomerStoreSAS,
		preFilterDestinationStore,
		preFilterBusinessControlVehicleStore,
	} = useStores();

	const { getOptions: getOptionsDealer } = preFilterDealerStore;
	const { getOptions: getOptionsPaymentForm } = preFilterPaymentFormStore;
	const { getOptions: getOptionsVehicleLocation } =
		preFilterVehicleLocationStore;
	const { setOptions: setOptionsCurrency } = preFilterCurrencyStore;

	const currencyEnumOptions = getCurrencyEnumOptions(t);
	const { isAuthorizedGroup } = useAuthStore();
	const shouldShowDealerPreFilter = !isAuthorizedGroup(ARG_DEALERS);
	const { menu } = useStores().listStore;

	const fetchOptions = React.useCallback(() => {
		cleanAllFilters();
		getOptionsPaymentForm(language);
		getOptionsVehicleLocation();
		setOptionsCurrency(currencyEnumOptions);
		if (shouldShowDealerPreFilter) {
			getOptionsDealer();
		}
	}, [
		cleanAllFilters,
		getOptionsPaymentForm,
		language,
		getOptionsVehicleLocation,
		setOptionsCurrency,
		currencyEnumOptions,
		shouldShowDealerPreFilter,
		getOptionsDealer,
	]);

	React.useEffect(() => {
		fetchOptions();

		return () => {
			cleanSpecificPreFilters();
		};
	}, []);

	const isTruck = menu === CLOSED_PERIOD_MENU_ARG_TRUCK;
	const vehicleType = isTruck ? "Truck" : "Bus";
	const chassiFilter = isTruck ? ChassiType.ChassiAO : ChassiType.ChassiFO;

	return (
		<PreFilter
			id="pre-filter-edition"
			title={t("preFilters.quickFilter", { ns: "order" })}
			onUndo={onUndo}
			onSubmit={applyFilters}
			hasPreFiltersApplied={hasPreFiltersApplied}
			hasFormatError={hasFormatError}
			filters={filters}
			chassiType={ChassiType.ChassiAO}
			removeFilter={removeFilter}
		>
			<ChassiAOPreFilter
				vehicleType={vehicleType}
				type={chassiFilter}
				preFilterStore={preFilterChassiAOStore}
			/>
			<PrevisionBillingMonthPreFilter
				preFilterStore={preFilterPrevisionBillingMonthStore}
			/>
			<BusinessControlVehiclePreFilter
				preFilterStore={preFilterBusinessControlVehicleStore}
				md={3}
				lg={3}
			/>
			<CustomerSASPreFilter
				preFilterStore={preFilterCustomerStoreSAS}
				sm={5}
				md={4}
				lg={4}
				xl={3}
			/>
			<DestinationPreFilter preFilterStore={preFilterDestinationStore} md={3} />
			<CurrencyPreFilter preFilterStore={preFilterCurrencyStore} />
			<PaymentFormPreFilter preFilterStore={preFilterPaymentFormStore} />
			<VehicleLocationPreFilter
				preFilterStore={preFilterVehicleLocationStore}
				lg={4}
			/>
			<GokDatePreFilter preFilterStore={preFilterGokDateStore} />
			<AsWeekDatePreFilter preFilterStore={preFilterAsWeekDateStore} />
			<ShippingDatePreFilter preFilterStore={preFilterShippingDateStore} />
			<CertificateDatePreFilter
				preFilterStore={preFilterCertificateDateStore}
			/>
			{shouldShowDealerPreFilter && (
				<DealerPreFilter preFilterStore={preFilterDealerStore} />
			)}
		</PreFilter>
	);
};

export default observer(PreFilters);
