import { GUID_EMPTY } from "../../../../app/constants/config";
import { VariantBasePutProps } from "../interfaces/VariantBasePutProps";

export const variantBasePutPropsModel: VariantBasePutProps = {
	id: GUID_EMPTY,
	descriptionEsEs: "",
	forFollowUpChl: false,
};

export function variantBasePutPropsModelBuilder(data: any) {
	if (!data) {
		return variantBasePutPropsModel;
	}
	const id = data.id ?? GUID_EMPTY;
	return {
		id: id,
		descriptionEsEs: data.descriptionEsEs ?? "",
		forFollowUpChl: data.forFollowUpChl ?? false,
	} as VariantBasePutProps;
}
