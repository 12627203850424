import React from "react";
import { InvoicingDeliveryMarketCompanySectionProps } from "../Interfaces/Europe/InvoicingDeliveryMarketCompanySection";
import BaseInvoicingDeliveryMarketCompanySection from "../BaseInvoicingDeliveryMarketCompanySection";
import { observer } from "mobx-react";

const InvoicingDeliveryMarketCompanySection: React.FC<
	InvoicingDeliveryMarketCompanySectionProps
> = ({ readOnlyFields }) => {
	return (
		<BaseInvoicingDeliveryMarketCompanySection readOnlyFields={readOnlyFields} />
	);
};

export default observer(InvoicingDeliveryMarketCompanySection);
