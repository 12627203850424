import { action, makeObservable, observable } from "mobx";
import React from "react";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import { cloneDeep } from "lodash";
import { DistrictEditProps } from "../interfaces/DistrictEditProps";

class EditDistrictStore {
	@observable districtInitial: Array<DistrictEditProps> = [];
	@observable districtEdit: Array<DistrictEditProps> = [];
	@observable idsToToggle: Array<string> = [];
	@observable loading: boolean = false;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action fetchData = async () => {
		this.loading = true;

		await this.api.districtService
			.getDistrictList()
			.then((rs) => {
				this.districtInitial = rs.data ?? [];
				this.districtEdit = rs.data ?? [];
				this.idsToToggle = [];
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action updateDistricts = async () => {
		this.loading = true;

		await this.api.districtService
			.toggleActiveDistrict(this.idsToToggle)
			.finally(() => {
				this.loading = false;
			});
	};

	@action cleanData = () => {
		this.districtInitial = [];
		this.districtEdit = [];
	};

	@action resetDistrictEdit = () => {
		this.districtEdit = cloneDeep(this.districtInitial);
		this.idsToToggle = [];
	};

	@action changeDistrictActive = (district: DistrictEditProps) => {
		let index = this.districtEdit.findIndex((x) => x.id === district.id);

		this.districtEdit[index].isActive = !this.districtEdit[index].isActive;

		if (this.idsToToggle.includes(district.id))
			this.idsToToggle = this.idsToToggle.filter((x) => x !== district.id);
		else this.idsToToggle.push(district.id);
	};
}

const EditDistrictStoreContext = React.createContext(new EditDistrictStore());

export const useEditDistrictStore = () =>
	React.useContext(EditDistrictStoreContext);
