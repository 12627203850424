import { action, configure, makeObservable } from "mobx";
import CtadmvenApi from "../../../apis/CtadmvenApi";
import BasePreFilterStringArrayStore from "./BasePreFilterStringArrayStore";

configure({ enforceActions: "always" });

class BasePreFilterVehicleLocationStore extends BasePreFilterStringArrayStore {
	constructor(
		rootStore: any,
		private api: CtadmvenApi
	) {
		super(rootStore, "managementDetail.vehicleLocationId");
		makeObservable(this);
	}

	@action private setVehicleLocationOptions = (data: any) => {
		this.preFilterOptions = [];

		if (!data) {
			return;
		}

		const arrayOfKeyValues = Object.entries(data);
		this.preFilterOptions = arrayOfKeyValues.map((m) => ({
			value: m[0],
			label: m[1] as string,
		}));
	};

	@action getOptions = async () => 
		this.baseGetOptions(this.setVehicleLocationOptions, this.api.vehicleLocationService.getDictionaryIdNameByCountry);
}

export default BasePreFilterVehicleLocationStore;
