import { PaymentTermsProps } from "../pages/Quotations/Details/interfaces/PaymentTermsProps";

export const getLabel = (paymentTerm: PaymentTermsProps, language: string) => {
	switch (language) {
		case "pt-BR":
			return paymentTerm.descriptionPtBr === ""
				? paymentTerm.descriptionEnUs
				: paymentTerm.descriptionPtBr;
		case "es-ES":
			return paymentTerm.descriptionEsEs === ""
				? paymentTerm.descriptionEnUs
				: paymentTerm.descriptionEsEs;
		default:
			return paymentTerm.descriptionEnUs;
	}
};

export const getValueGrid = (language: string) => {
	switch (language) {
		case "pt-BR":
			return "paymentTerms.descriptionPtBr";
		case "es-ES":
			return "paymentTerms.descriptionEsEs";
		default:
			return "paymentTerms.descriptionEnUs";
	}
};
