import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { GeneralCommentsSectionProps } from "../../interfaces/Sections/GeneralCommentsSection";

const GeneralCommentsSection: React.FC<GeneralCommentsSectionProps> = ({
	generalComment,
}) => {
	const { t } = useTranslation("order");
	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.generalComments.title")}
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.generalComments.title")}
					value={generalComment}
					multiline
					maxRows={4}
				/>
			</Grid>
		</>
	);
};

export default observer(GeneralCommentsSection);
