import { Save, Undo } from "@mui/icons-material";
import {
	Button,
	Checkbox,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { GridToolbar } from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useEditDistrictStore } from "../stores/EditDistrictStore";
import _ from "lodash";
import { toast } from "react-toastify";

const EditGrid: React.FC = () => {
	const { t } = useTranslation("general");
	const {
		resetDistrictEdit,
		districtEdit,
		districtInitial,
		updateDistricts,
		changeDistrictActive,
		fetchData,
	} = useEditDistrictStore();

	const handleSubmit = async () => {
		await updateDistricts()
			.then(() => toast.success(t(`updatedRecordWithSuccess`).toString()))
			.catch((err) => toast.error(err.response.data))
			.finally(async () => await fetchData());
	};

	interface Column {
		id: "countryName" | "value" | "isActive";
		label: string;
		align: "left" | "center";
	}

	const columns: readonly Column[] = [
		{ id: "countryName", label: t("country"), align: "left" },
		{ id: "value", label: t("district"), align: "left" },
		{ id: "isActive", label: t("available"), align: "center" },
	];

	return (
		<>
			<Paper sx={{ width: "100%", overflow: "hidden" }}>
				<GridToolbar>
					<div className="right-buttons-header">
						<Button
							color="secondary"
							onClick={() => resetDistrictEdit()}
							disabled={_.isEqual(districtInitial, districtEdit)}
						>
							<Undo fontSize="small" />
							{t("clear")}
						</Button>
						<Button
							color="secondary"
							type={"submit"}
							onClick={() => handleSubmit()}
							className="ms-2 btn-sm"
							disabled={_.isEqual(districtInitial, districtEdit)}
						>
							<Save fontSize="small" />
							{t("save")}
						</Button>
					</div>
				</GridToolbar>
				<TableContainer sx={{ maxHeight: "50%" }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ width: "50%", fontWeight: "bold" }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{districtEdit.map((district) => {
								return (
									<TableRow
										hover
										role="checkbox"
										tabIndex={-1}
										key={district.id}
									>
										{columns.map((column, i) => {
											const value = district[column.id];

											return (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ padding: "16 10" }}
												>
													{column.id === "isActive" ? (
														<Checkbox
															checked={!!value}
															color={"secondary"}
															onChange={() => changeDistrictActive(district)}
															key={i}
														/>
													) : (
														value
													)}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
};

export default observer(EditGrid);
