import { PaymentTermsProps } from "../interfaces/PaymentTermsProps";

export const getPaymentTermsModel = (
	paymentTermOption: PaymentTermsProps
): PaymentTermsProps => {
	return {
		id: paymentTermOption.id,
		quotationPaymentTermsId: crypto.randomUUID(),
		value: 0,
		descriptionEnUs: "",
		descriptionEsEs: "",
		descriptionPtBr: "",
		hasErrors: "",
	};
};
