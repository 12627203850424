import RootStore from "../../../stores/RootStore";
import { configure, makeObservable } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import BasePreFilterVehicleLocationStore from "../../../../../../components/PreFilter/Stores/BasePreFilterVehicleLocationStore";

configure({ enforceActions: "always" });

class PreFilterVehicleLocationStore extends BasePreFilterVehicleLocationStore {
	constructor(
		rootStore: RootStore,
		api: CtadmvenApi
	) {
		super(rootStore, api);
		makeObservable(this);
	}
}

export default PreFilterVehicleLocationStore;
