import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import { useProductionWeekStore } from "../../stores/ProductionWeekStore";
import Button from "@mui/material/Button";
import { Save, Undo } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { getColumns } from "../../utils/GridUtils";
import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useAuthStore } from "../../../../../auth/store/AuthStore";

const TableGrid: React.FC = () => {
	const {
		initialGridDataTable,
		gridDataTable,
		updateProductionCalendar,
		resetDataAndGridData,
		setAvailableDay,
	} = useProductionWeekStore();

	const { graphData } = useAuthStore();

	const userEmail = graphData?.mail ?? "";

	const { t } = useTranslation("general");

	const handleSubmit = async () => {
		await updateProductionCalendar(userEmail)
			.then(() => {
				toast.success(t("editRecordWithSuccess").toString());
			})
			.catch((err: any) => toast.error(err.response.data));
	};

	return (
		<>
			<Grid data={gridDataTable} scrollable="none" editField="inEdit">
				<GridToolbar>
					<div className="right-buttons-header">
						<Button
							color="secondary"
							onClick={() => resetDataAndGridData()}
							disabled={_.isEqual(initialGridDataTable, gridDataTable)}
						>
							<Undo fontSize="small" />
							{t("clear")}
						</Button>
						<Button
							color="secondary"
							type={"submit"}
							onClick={handleSubmit}
							className="ms-2 btn-sm"
							disabled={_.isEqual(initialGridDataTable, gridDataTable)}
						>
							<Save fontSize="small" />
							{t("save")}
						</Button>
					</div>
				</GridToolbar>
				{getColumns(t, setAvailableDay).map((col: any, i: number) => {
					return <GridColumn key={i} {...col} />;
				})}
			</Grid>
		</>
	);
};

export default observer(TableGrid);
