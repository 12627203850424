import { ReservationExportProps } from "../interfaces/ReservationExportDetails";

export const reservationExportModel: ReservationExportProps = {
	exportationStatus: "Production",
	available: false,
	quotationNumber: "",
	availableDate: null,
	reserved: false,
	reservationCreationDate: null,
};

export function reservationExportModelBuilder(data: ReservationExportProps) {
	if (!data) return reservationExportModel;
	return {
		...reservationExportModel,
		...data,
		reservationCreationDate: data.reservationCreationDate || null,
		quotationNumber: data.quotationNumber || "",
	} as ReservationExportProps;
}
