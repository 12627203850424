import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MultiSelectPreFilterProps } from "../Interfaces/MultiSelectPreFilterProps";
import MultiSelectPreFilter from "../components/MultiSelectPreFilter";

export const DEALER_PRE_FILTER_XS = 12;
export const DEALER_PRE_FILTER_SM = 6;
export const DEALER_PRE_FILTER_MD_LG = 4;
export const DEALER_PRE_FILTER_XL = 2;

const DealerPreFilter: React.FC<MultiSelectPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "dealerPreFilter",
		label = "preFilters.dealer",
		xs = DEALER_PRE_FILTER_XS,
		sm = DEALER_PRE_FILTER_SM,
		md = DEALER_PRE_FILTER_MD_LG,
		lg = DEALER_PRE_FILTER_MD_LG,
		xl = DEALER_PRE_FILTER_XL,
	} = props;
	return (
		<MultiSelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(DealerPreFilter);
