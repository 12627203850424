import React from "react";
import { observer } from "mobx-react";
import InconsistenciesGrid from "./InconsistenciesGrid";
import { Grid } from "@mui/material";
import { TitleBox } from "../../../components/TitleBox/TitleBox";
import { useTranslation } from "react-i18next";

const Total: React.FC = () => {
	const { t } = useTranslation("menu");

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("inconsistencies", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<>
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs} />
			</Grid>
			<Grid item lg={12}>
				<InconsistenciesGrid />
			</Grid>
		</>
	);
};

export default observer(Total);
