import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextPreFilter from "../components/TextPreFilter";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import { GRID_MAX_SIZE } from "../../../constants/gridConst";

const CUSTOMERSAS_PRE_FILTER_XS_SM = GRID_MAX_SIZE;
export const CUSTOMERSAS_PRE_FILTER_MD_LG_XL = 6;

const CustomerSASPreFilter: React.FC<TextPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "customerPreFilter",
		xs = CUSTOMERSAS_PRE_FILTER_XS_SM,
		sm = CUSTOMERSAS_PRE_FILTER_XS_SM,
		md = CUSTOMERSAS_PRE_FILTER_MD_LG_XL,
		lg = CUSTOMERSAS_PRE_FILTER_MD_LG_XL,
		xl = CUSTOMERSAS_PRE_FILTER_MD_LG_XL,
		maxLength = 100,
		label = "preFilters.customerSAS",
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			maxLength={maxLength}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(CustomerSASPreFilter);
