import React from "react";
import {
	Autocomplete,
	autocompleteClasses,
	createFilterOptions,
	ListSubheader,
	Popper,
	styled,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Edit, Info } from "@mui/icons-material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { VariableSizeList, ListChildComponentProps } from "react-window";

type MenuItemProps = {
	label: string;
	value: any;
	searchValue?: any;
};

type AutoCompleteFormProps = {
	id?: string;
	options: Array<MenuItemProps>;
	validator?: (value: string, name?: string) => string;
	onChange?: (value: any) => void;
	name?: string;
	label: string | React.ReactNode;
	labelId: string;
	value: any;
	editable?: boolean;
	hasEmptyDefaultItem?: boolean;
	hasEditIcon?: boolean;
	isCustomSearch?: boolean;
	editTooltip?: string;
	style?: CSSProperties | undefined;
	listboxStyle?: CSSProperties | undefined;
	disabled?: boolean;
	loading?: boolean;
};

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number) + LISTBOX_PADDING,
	};

	if (dataSet.hasOwnProperty("group")) {
		return (
			<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
				{dataSet.group}
			</ListSubheader>
		);
	}

	const { key, ...optionProps } = dataSet[0];

	return (
		<Typography
			key={key}
			component="li"
			{...optionProps}
			noWrap
			style={inlineStyle}
		>
			{dataSet[1].label}
		</Typography>
	);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData: React.ReactElement<any>[] = [];
	(children as React.ReactElement<any>[]).forEach(
		(
			item: React.ReactElement<any> & { children?: React.ReactElement<any>[] }
		) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	);

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child: React.ReactElement<any>) => {
		if (child.hasOwnProperty("group")) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index: number) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: "border-box",
		"& ul": {
			padding: 0,
			margin: 0,
		},
	},
});

function getErrorMessage(
	validator: ((value: string, name?: string) => string) | undefined,
	value: any,
	name: string | undefined
) {
	if (!validator) {
		return "";
	}
	return validator(value, name);
}

function getFormattedOptions(
	options: Array<MenuItemProps>,
	editable: boolean = false,
	hasEmptyDefaultItem: boolean = false
) {
	if (editable && hasEmptyDefaultItem) {
		return [{ label: "", value: "", searchValue: "" }, ...options];
	}
	return options;
}

function getHasEditIcon(
	editable: boolean = false,
	hasEditIcon: boolean = false
) {
	return editable && hasEditIcon;
}

function getFormattedLabel(
	label: React.ReactNode,
	editable: boolean = false,
	editTooltip: string = ""
) {
	if (editable && editTooltip !== "") {
		return (
			<>
				{label}{" "}
				<Tooltip title={editTooltip}>
					<Info sx={{ paddingTop: 1 }} />
				</Tooltip>{" "}
			</>
		);
	}
	return label;
}

const AutoCompleteForm: React.FC<AutoCompleteFormProps> = ({
	options,
	onChange,
	validator,
	name,
	label,
	labelId,
	hasEditIcon,
	hasEmptyDefaultItem,
	editable,
	isCustomSearch,
	editTooltip,
	style,
	listboxStyle,
	...props
}) => {
	const filtered = React.useMemo(
		() => options.filter((f) => f.value === props.value),
		[options, props.value]
	);

	const errorMessage = getErrorMessage(validator, props.value, name);

	const onChangeHandler = (e: any, value: any) => {
		if (!onChange) {
			return undefined;
		}

		const newValue = value?.value ?? value ?? "";

		return onChange(newValue);
	};

	options = getFormattedOptions(options, editable, hasEmptyDefaultItem);
	hasEditIcon = getHasEditIcon(editable, hasEditIcon);

	const filterOptions = createFilterOptions({
		matchFrom: "any",
		stringify: (option: MenuItemProps) => option.searchValue,
	});

	label = getFormattedLabel(label, editable, editTooltip);

	return (
		<Autocomplete
			{...props}
			disableListWrap
			style={style}
			readOnly={!editable}
			id={labelId}
			disablePortal
			options={options}
			popupIcon={hasEditIcon ? <Edit /> : undefined}
			sx={{
				"& .MuiAutocomplete-popupIndicator": {
					transform: hasEditIcon ? "none" : "",
				},
			}}
			fullWidth
			getOptionLabel={(option: MenuItemProps) => option.label}
			value={filtered && filtered.length > 0 ? filtered[0] : null}
			onChange={onChangeHandler}
			renderInput={(params) => (
				<TextField
					{...params}
					slotProps={{ inputLabel: { shrink: true } }}
					variant="standard"
					error={Boolean(errorMessage)}
					helperText={errorMessage}
					fullWidth
					label={label}
				/>
			)}
			filterOptions={isCustomSearch ? filterOptions : undefined}
			ListboxProps={{ style: listboxStyle }}
			renderOption={(p, option, state) =>
				[p, option, state.index] as React.ReactNode
			}
			slots={{
				popper: StyledPopper,
				listbox: ListboxComponent,
			}}
		/>
	);
};

export default AutoCompleteForm;
