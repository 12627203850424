import React from "react";
import { observer } from "mobx-react";
import { Button } from "@mui/material";

type CancelButtonProps = {
	id: string;
	title: string;
	onCancel: (() => void) | ((e: any) => void);
};

const CancelButton: React.FC<CancelButtonProps> = ({ id, title, onCancel }) => {
	return (
		<Button
			id={`cancel-btn-${id}`}
			variant="outlined"
			color="secondary"
			onClick={onCancel}
		>
			{title}
		</Button>
	);
};

export default observer(CancelButton);
