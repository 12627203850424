import { Traffic } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import CancelButton from "../../../../../components/Dialog/Buttons/CancelButton";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import {
	CTADMVEN_FOLLOWUP_ARG_BUS,
	CTADMVEN_FOLLOWUP_ARG_TRUCK,
} from "../../../../../constants/routes";
import { useClosedPeriodArgStore } from "../../stores/ClosedPeriodArgStore";
import { toast, ToastItem } from "react-toastify";

const OpenOrderButtonAndModal: React.FC = () => {
	const { t } = useTranslation(["general", "order"]);

	const navigate = useNavigate();

	const [open, setOpen] = React.useState(false);

	const [shouldChangePage, setshouldChangePage] = React.useState(false);

	const { openClosedPeriod, vehicleType } = useClosedPeriodArgStore();

	const setModalOpen = () => {
		setOpen(true);
	};

	const handleSubmit = async () => {
		await openClosedPeriod()
			.then(() =>
				toast.success(t(`updatedRecordWithSuccess`).toString(), {
					autoClose: 1800,
				})
			)
			.catch((err) => toast.error(err.response.data))
			.finally(() => {
				setModalClosed();
				setshouldChangePage(true);
			});
	};

	const setModalClosed = () => {
		setOpen(false);
	};

	toast.onChange((payload: ToastItem) => {
		switch (payload.status) {
			case "removed":
				if (!shouldChangePage) {
					return;
				}

				if (vehicleType === "Bus") navigate("/" + CTADMVEN_FOLLOWUP_ARG_BUS);
				else navigate("/" + CTADMVEN_FOLLOWUP_ARG_TRUCK);
		}
	});

	return (
		<>
			<Button
				variant="contained"
				onClick={setModalOpen}
				color="warning"
				size="small"
				style={{ width: "100px", margin: "auto 0" }}
				startIcon={<Traffic />}
			>
				{t("open")}
			</Button>

			<DialogForm
				id="dialog-batch-edition"
				open={open}
				title={t("confirmationMsg")}
				onClose={setModalClosed}
			>
				<DialogContent>
					<Grid container spacing={2} overflow="none">
						<Grid item xs>
							{t("openClosedPeriodToFollowUp", { ns: "order" })}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<CancelButton
						id="dialog-exp-partner-form"
						onCancel={setModalClosed}
						title={t("cancel")}
					/>
					<ApplyButton
						id="dialog-batch-edition"
						onSubmit={handleSubmit}
						title={t("open")}
						startIcon={<Traffic />}
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(OpenOrderButtonAndModal);
