import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import ProductionDatesSection from "../Sections/ProductionDatesSection";
import { ProductionAccordionProps } from "../../interfaces/Accordions/ProductionAccordion";

const ProductionAccordion: React.FC<ProductionAccordionProps> = (details) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="productionAccordion"
			title={t("details.accordions.production")}
		>
			<ProductionDatesSection {...details.productionDatesDetails} />
		</AccordionCard>
	);
};

export default observer(ProductionAccordion);
