import { FirmPeriodProps } from "../interfaces/FirmPeriodProps";

export const firmPeriodModel: FirmPeriodProps = {
	line: "F",
	year: 0,
	jan: null,
	feb: null,
	mar: null,
	apr: null,
	may: null,
	jun: null,
	jul: null,
	aug: null,
	sep: null,
	oct: null,
	nov: null,
	dec: null,
	locale: "BR",
};

export function firmPeriodModelBuilder(data: FirmPeriodProps): FirmPeriodProps {
	if (!data) return firmPeriodModel;
	return {
		...firmPeriodModel,
		line: data.line ?? "F",
		year: data.year ?? 0,
		weekPeriod: data.weekPeriod,
		jan: data.jan ?? null,
		feb: data.feb ?? null,
		mar: data.mar ?? null,
		apr: data.apr ?? null,
		may: data.may ?? null,
		jun: data.jun ?? null,
		jul: data.jul ?? null,
		aug: data.aug ?? null,
		sep: data.sep ?? null,
		oct: data.oct ?? null,
		nov: data.nov ?? null,
		dec: data.dec ?? null,
		locale: data.locale ?? "BR",
	};
}
