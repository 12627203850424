import React from "react";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { filterModel } from "../models/FilterModel";
import { FilterProps } from "../interfaces/FilterProps";
import { downloadCsv } from "../../../../utils/downloadCsv";
import moment from "moment";
import { LineType } from "../../../../utils/GenericTypes";
import { DealerQuotaProposalProps } from "../../shared/interfaces/DealerQuotaProposalProps";
import { dealerQuotaProposalModel, dealerQuotaProposalModelBuilder } from "../../shared/models/DealerQuotaProposalModel";

configure({ enforceActions: "always" });

/* Store start */
class ExportReadStore {
	@observable filter: FilterProps = filterModel;
	@observable dealerRegion: string = "";
	@observable filterIsValid: boolean = false;
	@observable line: LineType = "F";
	@observable dateFilter: Date = moment().startOf("year").toDate();
	@observable dealerQuotaProposal: Array<DealerQuotaProposalProps> = [];
	@observable dealerQuotaProposalTotal: DealerQuotaProposalProps =
		dealerQuotaProposalModel;
	@observable loading: boolean = false;
	@observable isTracbelUser: boolean = false;
	@observable firmPeriodWeek: number | undefined = -1;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action setLoading = (value: boolean) => (this.loading = value);

	@action hasFilterApplied = () => this.dealerRegion !== "";

	@action setFilter = () => {
		this.line = this.filter.line;
		this.dealerRegion = this.filter.dealerRegion;
		this.dateFilter = this.filter.date;
		this.getQuotaProposalByLineDealer();
	};

	@action setFilterByKeyOnChange = (
		fieldKey: keyof FilterProps,
		newValue: any,
		isValid: boolean = false
	) => {
		this.filter[fieldKey] = newValue;

		if ("dealerRegion" === fieldKey) this.filterIsValid = isValid;
	};

	@action getQuotaProposalByLineDealer = async () => {
		this.loading = true;

		await this.api.quotaService
			.getQuotaProposalRegMktCompany(
				this.line,
				this.dealerRegion,
				this.dateFilter.toDateString()
			)
			.then((response) => {
				this.loadGrid(response);
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action private setFirmPeriodWeek = () => {
		const item = this.dealerQuotaProposal
			.slice()
			.reverse()
			.find(
				(f: DealerQuotaProposalProps) =>
					f.firmPeriodWeek && f.firmPeriodWeek >= 0
			);
		this.firmPeriodWeek = item?.firmPeriodWeek;
	};

	@action loadGrid(response: any) {
		this.dealerQuotaProposalTotal = response.data.find(
			(x: DealerQuotaProposalProps) => x.period === null
		);
		const dealerQuotaProposal = response.data.filter(
			(x: DealerQuotaProposalProps) => x.period !== null
		);
		this.dealerQuotaProposal =
			dealerQuotaProposalModelBuilder(dealerQuotaProposal);
		this.setFirmPeriodWeek();
	}

	@action getQuotaProposalOrdersCsvByRegion = async (period: any, t: any) => {
		let fileName = `${moment(period).format("YYYYMM")}${t(
			"quota"
		)}${moment().format("YYYYMMDD")}.csv`;
		this.loading = true;

		await this.api.quotaService
			.getCsvQuotaProposalRegMktCompany(
				this.filter.line,
				this.filter.dealerRegion,
				period,
				"Month"
			)
			.then((content: any) => downloadCsv(content, fileName))
			.finally(() => this.setLoading(false));
	};
}

/* Store end */

/* Store helpers */
const ExportReadStoreContext = React.createContext(new ExportReadStore());

/* Hook to use store in any functional component */
export const useExportReadStore = () =>
	React.useContext(ExportReadStoreContext);
