import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { orderKeyLabels } from "../../utils/BatchEditionUtils";
import ClearRecordItem from "../../../../../components/BatchEdition/ClearRecordItem";

const CustomerContent: React.FC = () => {
	const { t } = useTranslation("order");

	const { batchData, setFinalCustomerComment } = useStores().batchEditionStore;

	return (
		<Grid item xs={12}>
			<ClearRecordItem
				recordTitle={t(orderKeyLabels.sasCustomer.label)}
				checked={batchData.finalCustomer.clearValue}
				onClearRecord={setFinalCustomerComment}
			>
				<TextForm
					label={t(orderKeyLabels.sasCustomer.label)}
					editable
					value={batchData.finalCustomer.value}
					slotProps={{ htmlInput: { maxLength: 500 } }}
					onChange={(e) =>
						setFinalCustomerComment({
							value: e.target.value,
							clearValue: false,
						})
					}
					disabled={batchData.finalCustomer.clearValue}
				/>
			</ClearRecordItem>
		</Grid>
	);
};

export default observer(CustomerContent);
