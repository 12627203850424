import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { BUS_BRAZIL } from "../../../../../constants/Menus/OrderMenuUtils";
import { useTranslation } from "react-i18next";
import { getBusGridColumns } from "../../utils/BusGridUtils";
import OrderBookPage from "../../components/HomePage/OrderBookPage";
import { OrderbookEnum } from "../../../../../utils/enums/OrderbookEnum";

const BusBrazil: React.FC = () => {
	const { t } = useTranslation("order");
	const { setMenu, menu, reset } = useStores().listStore;
	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	React.useEffect(() => {
		reset();
		setMenu(BUS_BRAZIL);
		const gridColumns = getBusGridColumns(t, BUS_BRAZIL);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{menu === BUS_BRAZIL && (
				<OrderBookPage
					orderbookTitleSource={"orderBookBus.orderBook"}
					orderbookSubTitleSource={"orderBookBus.brazil"}
					orderbookPageTitleSource={"orderBookBus.pageTitle"}
					columns={columns}
					type={OrderbookEnum.Bus}
				/>
			)}
		</>
	);
};

export default observer(BusBrazil);
