import { TFunction } from "i18next";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { SelectOptionProps } from "../../../../utils/GenericInterfaces";

export function getQuotationGridColumns(
	t: TFunction<"quotation"[], undefined>,
	statusSASOptions: Array<SelectOptionProps>,
	reservationStatusOptions: Array<SelectOptionProps>,
	workflowStatusOptions: Array<SelectOptionProps>
): Array<IColumnProps> {
	return [
		{
			field: "quotationId",
			title: t("gridColumns.quotationId", { ns: "quotation" }),
			alwaysVisible: true,
			width: 200,
			customCell: {
				type: "linkCell",
				to: "./",
				toParams: "id",
				value: "quotationId",
			},
		},
		{
			field: "quotationDate",
			title: t("gridColumns.quotationDate", { ns: "quotation" }),
			width: 90,
			alwaysVisible: true,
			customCell: {
				type: "dateTimeCell",
				date: "quotationDate",
				format: "DD/MM/yy",
			},
		},
		{
			field: "statusSAS",
			title: t("gridColumns.statusSAS", { ns: "quotation" }),
			alwaysVisible: true,
			width: 90,
			customCell: {
				type: "optionCell",
				value: "statusSAS",
				options: statusSASOptions,
			},
		},
		{
			field: "salesman",
			title: t("gridColumns.salesman", { ns: "quotation" }),
			alwaysVisible: true,
		},
		{
			field: "commercialModel",
			title: t("gridColumns.model", { ns: "quotation" }),
			alwaysVisible: true,
		},
		{
			field: "reservedXQuantity",
			width: 120,
			title: t("gridColumns.reservedQuantity", { ns: "quotation" }),
			alwaysVisible: true,
			sortable: false,
		},
		{
			field: "engine",
			title: t("gridColumns.engine", { ns: "quotation" }),
			alwaysVisible: true,
		},
		{
			field: "cabinColor",
			title: t("gridColumns.color", { ns: "quotation" }),
			alwaysVisible: true,
		},
		{
			field: "package",
			title: t("gridColumns.package", { ns: "quotation" }),
			alwaysVisible: true,
		},
		{
			field: "customerName",
			title: t("gridColumns.customer", { ns: "quotation" }),
			alwaysVisible: true,
		},
		{
			field: "reservationStatus",
			width: 120,
			title: t("gridColumns.reservationStatus", { ns: "quotation" }),
			alwaysVisible: true,
			customCell: {
				type: "optionCell",
				value: "reservationStatus",
				options: reservationStatusOptions,
			},
		},
		{
			field: "workflowStatus",
			title: t("gridColumns.workflowStatus", { ns: "quotation" }),
			alwaysVisible: true,
			customCell: {
				type: "optionCell",
				value: "workflowStatus",
				options: workflowStatusOptions,
			},
		},
		{
			field: "deliveryDateRequested",
			title: t("gridColumns.deliveryDateRequested", { ns: "quotation" }),
			alwaysVisible: true,
			customCell: {
				type: "dateTimeCell",
				date: "deliveryDateRequested",
				format: "DD/MM/yy",
			},
		},
	];
}
