import {
	CTADMVEN_DISTRICT_EDIT,
	CTADMVEN_DISTRICT_GROUP,
	CTADMVEN_LINE_SPEED,
	CTADMVEN_PARAMETERS_PRODUCTION_WEEK,
	CTADMVEN_REGIONS_EDIT,
	CTADMVEN_REGIONS_GROUP,
	CTADMVEN_VARIANTS,
} from "../../constants/routes";
import { groupsAllowedToSeeProductionWeek } from "../../constants/AuthGroups/parametersPages";
import { AuthGroupsType } from "../../constants/AuthGroups/authGroups";
import _ from "lodash";
import {
	MenuCollapsableRouteProps,
	MenuRouteProps,
} from "../../utils/GenericInterfaces";
import ProductionWeekIndex from "./ProductionWeek/containers/ProductionWeekIndex";
import { AppRouteProps } from "../routes";
import { getMenuAuthorized, getRoute } from "../../utils/RoutesUtil";
import { TFunction } from "i18next";
import { groupsAllowedToSeeLineSpeedMenu } from "../../constants/AuthGroups/lineSpeedPages";
import LineSpeedIndex from "./LineSpeed/containers/LineSpeedIndex";
import { groupsAllowedToSeeRegionsMenu } from "../../constants/AuthGroups/regionsPage";
import RegionEditIndex from "./Regions/Edit/container/RegionEditIndex";
import RegionGroupIndex from "./Regions/Group/container/RegionGroupIndex";
import { groupsAllowedToSeeVariantsMenu } from "../../constants/AuthGroups/variantsPage";
import VariantsIndex from "./Variants/containers/VariantsIndex";
import {
	ListAlt,
	Speed,
	Edit,
	AppRegistration,
	CalendarMonth,
	Public,
} from "@mui/icons-material";
import { groupsAllowedToSeeDistrictMenu } from "../../constants/AuthGroups/districtPages";
import DistrictEditIndex from "./Districts/Edit/container/DistrictEditIndex";
import PlaceIcon from "@mui/icons-material/Place";
import LayersIcon from "@mui/icons-material/Layers";
import DistrictGroupIndex from "./Districts/Group/container/DistrictGroupIndex";

const parametersAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeProductionWeek,
	...groupsAllowedToSeeLineSpeedMenu,
	...groupsAllowedToSeeRegionsMenu,
	...groupsAllowedToSeeVariantsMenu,
]);

//routes
export const parametersMenuCollapsableRoute: MenuCollapsableRouteProps = {
	claims: parametersAllClaims,
	label: "parameters.title",
	icon: <AppRegistration />,
	getMenus: getParametersMenus,
};

const productionWeekRoute: MenuRouteProps = {
	path: CTADMVEN_PARAMETERS_PRODUCTION_WEEK,
	claims: groupsAllowedToSeeProductionWeek,
	element: <ProductionWeekIndex />,
	label: "parameters.productionWeek",
	icon: <CalendarMonth />,
	isCollapsable: true,
};

const lineSpeedRoute: MenuRouteProps = {
	path: CTADMVEN_LINE_SPEED,
	claims: groupsAllowedToSeeLineSpeedMenu,
	element: <LineSpeedIndex />,
	label: "lineSpeed",
	icon: <Speed />,
	isCollapsable: true,
};

const regionsEditRoute: MenuRouteProps = {
	path: CTADMVEN_REGIONS_EDIT,
	claims: groupsAllowedToSeeRegionsMenu,
	element: <RegionEditIndex />,
	label: "regions.edit",
	icon: <Edit />,
	isCollapsable: true,
};

const regionsGroupRoute: MenuRouteProps = {
	path: CTADMVEN_REGIONS_GROUP,
	claims: groupsAllowedToSeeRegionsMenu,
	element: <RegionGroupIndex />,
	label: "regions.group",
	icon: <Public />,
	isCollapsable: true,
};

const variantsRoute: MenuRouteProps = {
	path: CTADMVEN_VARIANTS,
	claims: groupsAllowedToSeeVariantsMenu,
	element: <VariantsIndex />,
	label: "variants",
	icon: <ListAlt />,
	isCollapsable: true,
};

const districtEditRoute: MenuRouteProps = {
	path: CTADMVEN_DISTRICT_EDIT,
	claims: groupsAllowedToSeeDistrictMenu,
	element: <DistrictEditIndex />,
	label: "district.edit",
	icon: <PlaceIcon />,
	isCollapsable: true,
};

const districtGroupRoute: MenuRouteProps = {
	path: CTADMVEN_DISTRICT_GROUP,
	claims: groupsAllowedToSeeDistrictMenu,
	element: <DistrictGroupIndex />,
	label: "district.group",
	icon: <LayersIcon />,
	isCollapsable: true,
};

export const parametersRoutes: Array<AppRouteProps> = [
	getRoute(productionWeekRoute),
	getRoute(lineSpeedRoute),
	getRoute(regionsEditRoute),
	getRoute(regionsGroupRoute),
	getRoute(variantsRoute),
	getRoute(districtEditRoute),
	getRoute(districtGroupRoute),
];

function getParametersMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean
) {
	return (
		<>
			{getMenuAuthorized(productionWeekRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(variantsRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(lineSpeedRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(regionsEditRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(regionsGroupRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(districtEditRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(districtGroupRoute, t, isAuthorizedGroup)}
		</>
	);
}
