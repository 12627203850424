import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { useStores } from "../../stores/RootStore";
import { getBooleanOptions } from "../../../../../utils/SelectOptions";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { orderKeyLabels } from "../../utils/BatchEditionUtils";

const VolvoManagementContent: React.FC = () => {
	const { t } = useTranslation(["order", "general"]);

	const { batchData, setVolvoManagementDataByKey } =
		useStores().batchEditionStore;

	const booleanOptions = getBooleanOptions(t);

	return (
		<>
			<Grid item xs={2}>
				<SelectForm
					label={t(orderKeyLabels.extraQuota.label)}
					labelId={`${orderKeyLabels.extraQuota.label}-label`}
					value={batchData.volvoManagementData.extraQuota}
					options={booleanOptions}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setVolvoManagementDataByKey(
							e.target.value as string,
							orderKeyLabels.extraQuota.key
						)
					}
				/>
			</Grid>
			<Grid item xs={2}>
				<SelectForm
					label={t(orderKeyLabels.priority.label)}
					labelId={`${orderKeyLabels.priority.label}-label`}
					value={batchData.volvoManagementData.priority}
					options={booleanOptions}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setVolvoManagementDataByKey(
							e.target.value as string,
							orderKeyLabels.priority.key
						)
					}
				/>
			</Grid>
			<Grid item xs={8}>
				<TextForm
					label={t(orderKeyLabels.commercialComment.label)}
					editable
					value={batchData.volvoManagementData.commercialComment}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500 }}
					onChange={(e) =>
						setVolvoManagementDataByKey(
							e.target.value,
							orderKeyLabels.commercialComment.key
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(VolvoManagementContent);
