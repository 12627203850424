import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { MktCompanyCommercialSectionProps } from "../../interfaces/Sections/MktCompanyCommercialSection";
import SwitchForm from "../../../../../components/FormInputs/SwitchForm";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getCurrencyOptions } from "../../../../../utils/SelectOptions";
import { maxValueValidator } from "../../../../../utils/FormValidators";
import AutoCompleteForm from "../../../../../components/FormInputs/AutoCompleteForm";
import { fieldIsEditable } from "../../utils/EditableFieldsUtils";

const MktCompanyCommercialSection: React.FC<
	MktCompanyCommercialSectionProps
> = ({
	fieldsAvailableForEdition,
	editableFields,
	setOrderMCManagementFieldByKeyOnChange,
	vehicleLocationOptions,
	paymentTermsOptions,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const currencyOptions = getCurrencyOptions();

	const formValidators = (value: string, key?: string) => {
		if (key === "comission" && !maxValueValidator(parseFloat(value), 15))
			return t("validator.maxNumber", { max: 15, ns: "general" });
		return "";
	};
	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.mktCompanyCommercial.title")}
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.mktCompanyCommercial.invoiceProposalDate")}
					value={editableFields.invoiceProposalDate}
					editable={fieldIsEditable(
						"invoiceProposalDate",
						fieldsAvailableForEdition
					)}
					onChange={(dataItem: any) =>
						setOrderMCManagementFieldByKeyOnChange(
							"invoiceProposalDate",
							dataItem
						)
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextForm
					label={t(
						"details.sections.mktCompanyCommercial.invoiceProposalNumber"
					)}
					value={editableFields.invoiceProposalNumber}
					editable={fieldIsEditable(
						"invoiceProposalNumber",
						fieldsAvailableForEdition
					)}
					inputProps={{ maxLength: 12 }}
					name="invoiceProposalNumber"
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"invoiceProposalNumber",
							e.target.value
						)
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.comission")}
					value={editableFields.comission}
					editable={fieldIsEditable("comission", fieldsAvailableForEdition)}
					name="comission"
					validator={formValidators}
					onChange={(
						e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
						hasErrors?: boolean
					) =>
						setOrderMCManagementFieldByKeyOnChange(
							"comission",
							parseFloat(parseFloat(e.target.value).toFixed(2)),
							hasErrors
						)
					}
					inputProps={{
						step: 2,
					}}
					type="number"
				/>
			</Grid>
			<Grid item xs={3}>
				<SwitchForm
					editable={fieldIsEditable("usedDelivery", fieldsAvailableForEdition)}
					label={t("details.sections.mktCompanyCommercial.usedDelivery")}
					checked={editableFields.usedDelivery}
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"usedDelivery",
							e.target.checked
						)
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.commercialCircular")}
					value={editableFields.commercialCircular}
					editable={fieldIsEditable(
						"commercialCircular",
						fieldsAvailableForEdition
					)}
					name="commercialCircular"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"commercialCircular",
							e.target.value
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.paymentForm")}
					labelId={"sections.mktCompanyCommercial-paymentForm-label"}
					value={editableFields.paymentForm}
					options={paymentTermsOptions}
					editable={fieldIsEditable("paymentForm", fieldsAvailableForEdition)}
					hasEmptyDefaultItem
					hasEditIcon
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"paymentForm",
							e.target.value as string
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.currency")}
					labelId={"sections.mktCompanyCommercial-currency-label"}
					value={editableFields.currency}
					options={currencyOptions}
					editable={fieldIsEditable("currency", fieldsAvailableForEdition)}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"currency",
							e.target.value as string
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.finalValue")}
					value={editableFields.finalValue}
					editable={fieldIsEditable("finalValue", fieldsAvailableForEdition)}
					name="finalValue"
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"finalValue",
							parseFloat(parseFloat(e.target.value).toFixed(2))
						)
					}
					inputProps={{
						step: 2,
					}}
					type="number"
				/>
			</Grid>
			<Grid item xs={12}>
				<AutoCompleteForm
					label={t("details.sections.mktCompanyCommercial.vehicleLocation")}
					labelId={"sections.mktCompanyCommercial-vehicleLocation-label"}
					value={editableFields.vehicleLocationId}
					options={vehicleLocationOptions}
					editable={fieldIsEditable(
						"vehicleLocationId",
						fieldsAvailableForEdition
					)}
					hasEmptyDefaultItem
					hasEditIcon
					onChange={(value) =>
						setOrderMCManagementFieldByKeyOnChange("vehicleLocationId", value)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(MktCompanyCommercialSection);
