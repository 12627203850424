import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { TruckEditableProps } from "../interfaces/Editable/TruckEditableDetails";
import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import _ from "lodash";
import {
	truckReadOnlyModel,
	truckReadOnlyModelBuilder,
} from "../models/TruckReadOnlyModel";
import { reservationExportModelBuilder } from "../models/ReservationExportModel";
import { invoicingDeliveryMarketCompanyModelBuilder } from "../models/InvoicingDeliveryMarketCompanyModel";
import { invoiceExportationModelBuilder } from "../models/InvoiceExportationModel";
import React from "react";
import {
	truckEditableModel,
	truckEditableModelBuilder,
} from "../models/Editable/TruckEditableModel";
import { exportLocalizationModelBuilder } from "../models/ExportLocalizationModel";
import { StoreUtils } from "../../../../utils/StoreUtils";

configure({ enforceActions: "always" });

/* Store start */
class FollowUpChlStore {
	@observable readOnlyDetails: TruckReadOnlyProps = truckReadOnlyModel;
	@observable editableDetails: TruckEditableProps = truckEditableModel;
	@observable initialEditableDetails: TruckEditableProps = truckEditableModel;
	@observable hasChanges: boolean = false;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.readOnlyDetails = truckReadOnlyModel;
		this.editableDetails = truckEditableModel;
		this.initialEditableDetails = truckEditableModel;
		this.hasChanges = false;
	};

	@action setEditableDetailsFieldByKeyOnChange = (
		fieldKey: keyof TruckEditableProps,
		newValue: string & Date
	) => {
		this.editableDetails[fieldKey] = newValue ?? "";
		this.checkChanges();
	};

	@action setReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = truckReadOnlyModelBuilder({
			...this.readOnlyDetails,
			...data,
		});
	};

	@action setEditableDetails = (data: any) => {
		this.editableDetails = truckEditableModelBuilder({
			...this.editableDetails,
			...data,
		});
		this.initialEditableDetails = truckEditableModelBuilder({
			...this.initialEditableDetails,
			...data,
		});
	};

	@action private checkChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
	};

	@action loadDetails = async (id: string) => {
		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getDetails(id),
			(data: any) => {
				this.setReadOnlyDetails(data);
				this.setEditableDetails(data);
				this.hasChanges = false;
			},
			id,
		)
	};

	@action loadReservationExport = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getReservationExportDetails(id),
			(data: any) => {
				this.setEditableDetails(data);
				this.readOnlyDetails.reservationExport = reservationExportModelBuilder(
					data
				);
			},
			id,
		)
	};

	@action loadVariants = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getDetailsVariants(id),
			this.setVariants,
			id,
		)
	};

	@action loadVariantsRespec = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getDetailsVariantsRespecList(id),
			this.setVariantsReespec,
			id,
		)
	};

	@action loadInvoiceExportation = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getInvoiceExportationByOrderId(id),
			this.setInvoiceExportation,
			id,
		)
	};

	@action loadInvoicingDeliveryMarketCompany = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getInvoicingDeliveryMarketCompanyByOrderId(id),
			this.setInvoicingDeliveryMarketCompany,
			id,
		)
	};

	@action loadLocalizationExport = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.orderUnitStatusService.getLocalizationByOrderId(id),
			this.setLocalizationExport,
			id,
		)
	};

	@action update = async () => {
		if (!this.hasChanges) return;

		await this.api.followUpChlService.update(this.editableDetails);
	};

	setVariants = (data: any) => {
		this.readOnlyDetails.variants = data ?? [];
	}

	setVariantsReespec = (data: any) => {
		this.readOnlyDetails.variantsRespec = data ?? [];
	}

	setInvoiceExportation = (data: any) => {
		this.readOnlyDetails.invoiceExportation = invoiceExportationModelBuilder(data);
	}

	setInvoicingDeliveryMarketCompany = (data: any) => {
		this.readOnlyDetails.invoicingDeliveryMarketCompany = invoicingDeliveryMarketCompanyModelBuilder(data);
	}

	setLocalizationExport = (data: any) => {
		this.readOnlyDetails.exportLocalization = exportLocalizationModelBuilder(data);
	}
}
/* Store end */

const FollowUpChlStoreContext = React.createContext(new FollowUpChlStore());

export const useFollowUpChlStore = () =>
	React.useContext(FollowUpChlStoreContext);
