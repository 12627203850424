import moment from "moment";
import { GUID_EMPTY } from "../../../app/constants/config";
import { ExportPartnerProps } from "../interface/ExportPartnerProps";

export const exportPartnerModel: ExportPartnerProps = {
	id: GUID_EMPTY,
	cdb: "",
	name: "",
	customerCDB: "",
	customerName: "",
	startDate: moment().toDate(),
	endDate: moment().toDate(),
};
