import CtadmvenApi from "../../apis/CtadmvenApi";
import { DistrictGroupProps } from "../../pages/Parameters/Districts/Group/interfaces/DistrictGroupProps";

interface IDistrictService {
	getList(): Promise<any>;
	getOrderBookFilterOptions(): Promise<any>;
	getDistrictList(): Promise<any>;
	toggleActiveDistrict(districts: Array<string>): Promise<any>;
	getListDistrictGroups(): Promise<any>;
	changeAssociationDistrictToDistrictGroup(
		districtGroupd: Array<DistrictGroupProps>
	): Promise<any>;
}
const base = "/district";

export class DistrictService implements IDistrictService {
	constructor(private api: CtadmvenApi) {}

	getList = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/listValues`);

	getOrderBookFilterOptions = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/orderBookFilter`);

	getDistrictList = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}`);

	toggleActiveDistrict = async (districts: Array<string>): Promise<any> =>
		this.api.ctadmvenClient.post(base, districts);

	getListDistrictGroups = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/group`);

	changeAssociationDistrictToDistrictGroup = async (
		districtGroupd: Array<DistrictGroupProps>
	): Promise<any> =>
		this.api.ctadmvenClient.post(`${base}/association`, districtGroupd);
}
