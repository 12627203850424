import React from "react";
import { configure } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import ListStore from "./ListStore";
import FilterStore from "./FilterStore";

import PreFilterDeliveryProbabilityStore from "../containers/PreFilters/stores/PreFilterDeliveryProbabilityStore";
import PreFilterPrevisionBillingMonthStore from "../containers/PreFilters/stores/PreFilterPrevisionBillingMonthStore";
import PreFilterFirmBillingStore from "../containers/PreFilters/stores/PreFilterFirmBillingStore";
import PreFilterFinancialDeliveryStore from "../containers/PreFilters/stores/PreFilterFinancialDeliveryStore";
import PreFilterPhysicalDeliveryStore from "../containers/PreFilters/stores/PreFilterPhysicalDeliveryStore";
import PreFilterFinalDeliveryStore from "../containers/PreFilters/stores/PreFilterFinalDeliveryStore";
import PreFilterDealerStore from "../containers/PreFilters/stores/PreFilterDealerStore";
import PreFilterDestinationStore from "../containers/PreFilters/stores/PreFilterDestinationStore";
import PreFilterCurrencyStore from "../containers/PreFilters/stores/PreFilterCurrencyStore";
import PreFilterPaymentFormStore from "../containers/PreFilters/stores/PreFilterPaymentFormStore";
import PreFilterShippingDateStore from "../containers/PreFilters/stores/PreFilterShippingDateStore";
import PreFilterCertificateDateStore from "../containers/PreFilters/stores/PreFilterCertificateDateStore";
import PreFilterVehicleLocationStore from "../containers/PreFilters/stores/PreFilterVehicleLocationStore";
import BatchEditionStore from "./BatchEditionStore";
import PreFilterChassiAOStore from "../containers/PreFilters/stores/PreFilterChassiAOStore";
import PreFilterGokDateStore from "../containers/PreFilters/stores/PreFilterGokDateStore";
import PreFilterAsWeekDateStore from "../containers/PreFilters/stores/PreFilterAsWeekDateStore";
import PreFilterBusinessControlVehicleStore from "../containers/PreFilters/stores/PreFilterBusinessControlVehicleStore";
import PreFilterPaqueteStore from "../containers/PreFilters/stores/PreFilterPaqueteStore";
import PreFilterCustomerStoreSAS from "../containers/PreFilters/stores/PreFilterCustomerStoreSAS";
import PreFilterCustomerStoreOM from "../containers/PreFilters/stores/PreFilterCustomerStoreOM";

configure({ enforceActions: "always" });

class RootStore {
	listStore: any = {};
	filterStore: any = {};
	batchEditionStore: any = {};
	preFilterDeliveryProbabilityStore: any = {};
	preFilterPrevisionBillingMonthStore: any = {};
	preFilterFirmBillingStore: any = {};
	preFilterFinancialDeliveryStore: any = {};
	preFilterPhysicalDeliveryStore: any = {};
	preFilterFinalDeliveryStore: any = {};
	preFilterDealerStore: any = {};
	preFilterPaqueteStore: any = {};
	preFilterDestinationStore: any = {};
	preFilterCurrencyStore: any = {};
	preFilterPaymentFormStore: any = {};
	preFilterAsWeekDateStore: any = {};
	preFilterGokDateStore: any = {};
	preFilterShippingDateStore: any = {};
	preFilterCertificateDateStore: any = {};
	preFilterVehicleLocationStore: any = {};
	preFilterChassiAOStore: any = {};
	preFilterBusinessControlVehicleStore: any = {};
	preFilterCustomerStoreOM: any = {};
	preFilterCustomerStoreSAS: any = {};

	constructor() {
		const api = new CtadmvenApi();

		this.listStore = new ListStore(this, api);
		this.filterStore = new FilterStore(this);
		this.batchEditionStore = new BatchEditionStore(this, api);
		this.preFilterDeliveryProbabilityStore =
			new PreFilterDeliveryProbabilityStore(this);
		this.preFilterPrevisionBillingMonthStore =
			new PreFilterPrevisionBillingMonthStore(this);
		this.preFilterFirmBillingStore = new PreFilterFirmBillingStore(this);
		this.preFilterFinancialDeliveryStore = new PreFilterFinancialDeliveryStore(
			this
		);
		this.preFilterPhysicalDeliveryStore = new PreFilterPhysicalDeliveryStore(
			this
		);
		this.preFilterFinalDeliveryStore = new PreFilterFinalDeliveryStore(this);
		this.preFilterDealerStore = new PreFilterDealerStore(this, api);
		this.preFilterPaqueteStore = new PreFilterPaqueteStore(this, api);
		this.preFilterDestinationStore = new PreFilterDestinationStore(this);
		this.preFilterCurrencyStore = new PreFilterCurrencyStore(this);
		this.preFilterPaymentFormStore = new PreFilterPaymentFormStore(this, api);
		this.preFilterAsWeekDateStore = new PreFilterAsWeekDateStore(this);
		this.preFilterGokDateStore = new PreFilterGokDateStore(this);
		this.preFilterShippingDateStore = new PreFilterShippingDateStore(this);
		this.preFilterCertificateDateStore = new PreFilterCertificateDateStore(
			this
		);
		this.preFilterVehicleLocationStore = new PreFilterVehicleLocationStore(
			this,
			api
		);
		this.preFilterChassiAOStore = new PreFilterChassiAOStore(this);
		this.preFilterBusinessControlVehicleStore =
			new PreFilterBusinessControlVehicleStore(this);

		this.preFilterCustomerStoreOM = new PreFilterCustomerStoreOM(this);
		this.preFilterCustomerStoreSAS = new PreFilterCustomerStoreSAS(this);
	}
}
const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);
export default RootStore;
