import React from "react";
import {
	Grid,
	GridDataStateChangeEvent,
	GridColumn,
	GridCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { State } from "@progress/kendo-data-query";
import { IGridProps } from "./interfaces/IGridProps";
import { IRecords } from "./interfaces/IRecords";
import { DataLoader } from "./DataLoader";
import { concat, isEqual } from "lodash";
import "./style/datagrid.css";
import {
	calculateColumnMaxWidth,
	getCellHeader,
	getCustomCell,
} from "./utils/cellFunctions";

const DEFAULT_PAGE_SIZE = 20;

export const DataGridApi: React.FC<IGridProps> = ({
	columns,
	pageSize,
	sortBy,
	filterBy,
	fetchData,
	refresh,
	className,
	calculateWidth,
	itemsToBeUpdated,
	onChangeItemToBeUpdated,
	alertOnDataStateChange,
	...props
}) => {
	const [loading, setLoading] = React.useState<boolean>(true);

	const [resetRecords, setResetRecords] = React.useState(false);

	const [records, setRecords] = React.useState<IRecords>({
		data: [],
		total: 0,
	});

	const [dataState, setDataState] = React.useState<State>({
		take: pageSize || DEFAULT_PAGE_SIZE,
		skip: 0,
		sort: sortBy,
		filter: filterBy,
	});

	const dataStateChange = (e: GridDataStateChangeEvent) => {
		const newDataState = e.dataState;
		setLoading(true);
		if (!isEqual(e.dataState.sort, dataState.sort)) {
			newDataState.skip = 0;
			setResetRecords(true);
		}
		setDataState(newDataState);
		onChangeItemToBeUpdated && onChangeItemToBeUpdated([], false);
		alertOnDataStateChange && alertOnDataStateChange();
	};

	const dataReceived = (newRecords: any) => {
		if (resetRecords || props.pageable) {
			setRecords(newRecords);
			setResetRecords(false);
		} else {
			const newData = concat(records.data, newRecords.data);

			if (props.pageable === false) {
				setRecords({ data: newData, total: newData.length });
			} else {
				setRecords({ data: newData, total: newRecords.total });
			}
		}
	};

	const refreshDataOnDataStateChange = React.useCallback(() => {
		if (isEqual(dataState.filter, filterBy)) return;
		setLoading(true);
		setResetRecords(true);
		setDataState((ds) => ({
			...ds,
			skip: 0,
			filter: filterBy,
		}));
		if (
			itemsToBeUpdated &&
			itemsToBeUpdated.length > 0 &&
			onChangeItemToBeUpdated
		)
			onChangeItemToBeUpdated([], false); //clear the items when state is changed
	}, [dataState.filter, filterBy, itemsToBeUpdated, onChangeItemToBeUpdated]);

	React.useEffect(() => {
		refreshDataOnDataStateChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterBy, dataState]); //do not change this dependency array, leave the warning :)

	return (
		<>
			<Grid
				filterable={false}
				sortable
				{...dataState}
				{...records}
				onDataStateChange={dataStateChange}
				{...props}
				className={className}
				style={{
					height: "100px",
				}}
			>
				{columns.map((col, i) => {
					if (!col.hidden || col.alwaysVisible) {
						return (
							<GridColumn
								key={i}
								width={
									calculateWidth
										? calculateColumnMaxWidth(col, records, className)
										: col.width
								}
								locked={col.locked}
								headerCell={(props: GridHeaderCellProps) =>
									getCellHeader(
										col,
										props,
										records,
										itemsToBeUpdated,
										onChangeItemToBeUpdated
									)
								}
								cell={
									col.customCell
										? (props: GridCellProps) =>
												getCustomCell(
													props,
													col.customCell,
													itemsToBeUpdated,
													onChangeItemToBeUpdated
												)
										: col.cell
								}
								{...col}
							/>
						);
					}
					return "";
				})}
			</Grid>
			<DataLoader
				dataState={dataState}
				onDataReceived={dataReceived}
				fetchData={fetchData}
				refresh={refresh}
				loading={loading}
				setLoading={setLoading}
			/>
		</>
	);
};
