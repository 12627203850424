import {
	AVAILABILITY,
	BUS_BRAZIL,
	BUS_FREE_UNIT_VCI,
	BUS_FREE_UNIT_VCI_VDB,
	BUS_MEAC,
	BUS_MKT_COMPANY,
	BUS_PI,
	BUS_TOTAL,
	EUROPE_FACTORY_ORDERBOOK,
	EXTERNAL_BRAZIL,
	FD_MKT_COMPANY,
	FD_MKT_COMPANY_BUS,
	FD_MKT_COMPANY_EUROPE,
	INTERNAL_VDB,
	MKT_COMPANY,
	PRIVATE_IMPORTERS,
	TOTAL,
	VCI,
} from "../constants/Menus/OrderMenuUtils";
import { CsvRequestOrigin } from "../pages/DownloadCenter/List/enums/CsvRequestOrigin";
import { RoleType } from "../pages/DownloadCenter/List/enums/RoleType";

const ROLE_MARKET_COMPANY = "card.role.market-company";
const ROLE_ARGENTINA_DEALER = "card.role.argentina-dealer";
const ROLE_BRAZIL_DEALER = "card.role.brazil-dealer";

const MENU_FACTORY_DELIVERY = "card.menu-key-name.factory-delivery";
const MENU_TOTAL = "card.menu-key-name.total";

export function downloadCsv(content: any, name: string) {
	const link = document.createElement("a");

	link.href = window.URL.createObjectURL(new Blob([content.data]));

	link.setAttribute("download", name);

	document.body.appendChild(link);

	link.click();
}

const roleTextMap: { [key in RoleType]: string } = {
	[RoleType.GeneralSalesSupport]: "card.role.general-sales-support",
	[RoleType.ArgentinaSalesSupport]: "card.role.argentina-sales-support",
	[RoleType.Chl_Market_Company]: "card.role.chile-sales-support",
	[RoleType.ChileSalesSupport]: "card.role.chile-sales-support",
	[RoleType.Col_Market_Company]: ROLE_MARKET_COMPANY,
	[RoleType.Mex_Sales_Support]: ROLE_MARKET_COMPANY,
	[RoleType.Meac_Sales_Support]: ROLE_MARKET_COMPANY,
	[RoleType.Arg_Market_Company]: ROLE_MARKET_COMPANY,
	[RoleType.Per_Market_Company]: ROLE_MARKET_COMPANY,
	[RoleType.Pi_Sales_Support]: ROLE_MARKET_COMPANY,
	[RoleType.ChileGeneralSalesSupport]: "card.role.general-chile-sales",
	[RoleType.Arg_Dealer_Agecer]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Caminoa]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Gerencia]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Gloker]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Gotland]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Latinacam]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Libertadores]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Rutasur]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Scevola]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Sudamericana]: ROLE_ARGENTINA_DEALER,
	[RoleType.Arg_Dealer_Sueca]: ROLE_ARGENTINA_DEALER,
	[RoleType.Bra_Dealer_Asp]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Aut]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Dic]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Dip]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Got]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Lap]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Luv]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Nor]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Riv]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Sue]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Tne]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Tno]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Tne_Tno]: ROLE_BRAZIL_DEALER,
	[RoleType.Bra_Dealer_Trv]: ROLE_BRAZIL_DEALER,
	[RoleType.None]: "card.role.none",
};

export function getRoleText(role: RoleType): string {
	return roleTextMap[role] || "";
}

export function getMenuReportName(origin: CsvRequestOrigin) {
	switch (origin) {
		case CsvRequestOrigin.ClosedPeriodArgTrucks:
			return "card.menu.closed-period-arg-trucks";
		case CsvRequestOrigin.ClosedPeriodArgBuses:
			return "card.menu.closed-period-arg-buses";
		case CsvRequestOrigin.FollowUpArgTrucks:
			return "card.menu.follow-up-arg-trucks";
		case CsvRequestOrigin.FollowUpArgBuses:
			return "card.menu.follow-up-arg-buses";
		case CsvRequestOrigin.FollowUpChlTrucks:
			return "card.menu.follow-up-chl-trucks";
		case CsvRequestOrigin.OrdersTrucks:
			return "card.menu.orders-trucks";
		case CsvRequestOrigin.OrdersBus:
			return "card.menu.orders-bus";
		case CsvRequestOrigin.Europe:
			return "card.menu.europe";
		default:
			return "";
	}
}

const menuKeyMap: { [key: string]: string } = {
	[FD_MKT_COMPANY_EUROPE]: MENU_FACTORY_DELIVERY,
	[FD_MKT_COMPANY_BUS]: MENU_FACTORY_DELIVERY,
	[FD_MKT_COMPANY]: MENU_FACTORY_DELIVERY,
	[BUS_PI]: "card.menu-key-name.private-importers",
	[PRIVATE_IMPORTERS]: "card.menu-key-name.private-importers",
	[BUS_MKT_COMPANY]: "card.menu-key-name.market-company",
	[MKT_COMPANY]: "card.menu-key-name.market-company",
	[BUS_MEAC]: "card.menu-key-name.meac",
	[BUS_FREE_UNIT_VCI_VDB]: "card.menu-key-name.free-unit-vci-vdb",
	[BUS_FREE_UNIT_VCI]: "card.menu-key-name.free-unit-vci",
	[VCI]: "card.menu-key-name.free-unit-vci",
	[BUS_TOTAL]: MENU_TOTAL,
	[TOTAL]: MENU_TOTAL,
	[EUROPE_FACTORY_ORDERBOOK]: MENU_TOTAL,
	[AVAILABILITY]: "card.menu-key-name.availability",
	[EXTERNAL_BRAZIL]: "card.menu-key-name.external-brazil",
	[BUS_BRAZIL]: "card.menu-key-name.external-brazil",
	[INTERNAL_VDB]: "card.menu-key-name.internal-vdb",
};

export function getSubMenuName(menuKey: string): string {
	return menuKeyMap[menuKey] || "";
}
