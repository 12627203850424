import CtadmvenApi from "../../apis/CtadmvenApi";

interface IOrderInvoiceService {
	getSaleInvoiceByOrderId(id: string, menuKey: string): Promise<any>;
	getLocalizationInvoicesByOrderId(id: string, menuKey: string): Promise<any>;
	getInvoiceExportationByOrderId(id: string, menuKey: string): Promise<any>;
}
const base = "/orderInvoice";

export class OrderInvoiceService implements IOrderInvoiceService {
	constructor(private api: CtadmvenApi) {}

	getSaleInvoiceByOrderId = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/sale/${menuKey}/${id}`);

	getLocalizationInvoicesByOrderId = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/localizations/${menuKey}/${id}`);

	getInvoiceExportationByOrderId = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/exp/${menuKey}/${id}`);

	getInvoicingDeliveryMarketCompanyByOrderId = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/marketcompany/${menuKey}/${id}`);
}
