import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { useLineSpeedStore } from "../store/LineSpeedStore";
import {
	DialogActions,
	DialogContent,
	IconButton,
	Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridApi } from "../../../../components/kendo/DataGridApi/DataGridApi";
import { gridConst } from "../../../../constants/gridConst";
import { LineSpeedProps } from "../interfaces/LineSpeedProps";
import LineSpeedForm from "./LineSpeedForm";
import DialogForm from "../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../components/Dialog/Buttons/ApplyButton";

const LineSpeedGrid: React.FC = () => {
	const NOT_APPLICABLE = "N/A";

	const VehicleType = {
		BUS: "Bus",
		TRUCK: "Truck",
	} as const;

	const LineType = {
		HDV: "HDV",
		FEB: "FEB",
	} as const;

	const {
		allRecords,
		listLines,
		setDataItemChanged,
		setLoading,
		deleteLineSpeed,
		setActionLabel,
		setFormVisible,
		data,
		refresh,
		dataItemChanged,
		actionLabel,
		formVisible,
	} = useLineSpeedStore();

	const { t } = useTranslation("general");
	const [dialogVisible, setDialogVisible] = React.useState(false);

	const loadDataOnMount = React.useCallback(() => {
		allRecords();
		listLines();
	}, [allRecords, listLines]);

	React.useEffect(() => {
		loadDataOnMount();
	}, [loadDataOnMount]);

	const toggleDialogForm = (dataItem: LineSpeedProps) => {
		if (dataItem) setDataItemChanged(dataItem);
		setFormVisible(!formVisible);
		setActionLabel("editRecord");
	};

	const toggleDialog = (dataItem: any) => {
		if (dataItem) setDataItemChanged(dataItem);
		setDialogVisible(!dialogVisible);
	};

	const deleteLineSpeedOnClick = () => {
		if (
			_.filter(data, (f) => f.lineId === dataItemChanged.lineId).length === 1
		) {
			toast.error(
				t("validator.must-have-record", {
					record: t("lineSpeed.fields.line"),
					length: "1",
				}).toString()
			);
			return;
		}

		onDeleteLineSpeed();
	};

	const onDeleteLineSpeed = () => {
		deleteLineSpeed(dataItemChanged.id)
			.then(() => {
				allRecords();
				toast.success(t("deletedWithSuccess").toString());
			})
			.catch((err) => toast.error(err.response.data))
			.finally(() => setLoading(false));

		toggleDialog(null);
	};

	const lcdColumn = ({ dataItem }: any) => (
		<td>
			{dataItem.line.vehicleType === VehicleType.BUS &&
			dataItem.line.name === LineType.HDV
				? dataItem.lcd
				: NOT_APPLICABLE}
		</td>
	);

	const actionColumn = ({ dataItem }: any) => (
		<td style={{ left: 0, right: 0, borderRightWidth: 1 }}>
			<Tooltip title={`${t("edit")}`} arrow>
				<IconButton onClick={() => toggleDialogForm(dataItem)}>
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title={`${t("delete")}`} arrow>
				<IconButton onClick={() => toggleDialog(dataItem)}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</td>
	);

	const columns = [
		{
			field: "line.vehicleType",
			title: t("lineSpeed.fields.vehicleType"),
			sortable: false,
		},
		{ field: "line.name", title: t("lineSpeed.fields.line"), sortable: false },
		{ field: "code", title: t("lineSpeed.fields.code"), sortable: false },
		{ field: "speed", title: t("lineSpeed.fields.speed"), sortable: false },
		{ field: "LCD", title: "LCD", cell: lcdColumn, sortable: false },
		{
			field: "",
			title: "",
			width: 100,
			cell: actionColumn,
			sortable: false,
			locked: true,
		},
	];

	return (
		<>
			<DataGridApi
				columns={columns}
				fetchData={allRecords}
				pageable={gridConst.pageable}
				pageSize={gridConst.pageSize}
				rowHeight={10}
				reorderable={true}
				resizable={true}
				className="k-grid-lg"
				refresh={refresh}
			/>
			<LineSpeedForm actionLabel={actionLabel} />
			<DialogForm
				id="line-speed-delete"
				open={dialogVisible}
				title={t("confirmationMsg")}
				onClose={toggleDialog}
			>
				<DialogContent dividers>
					{`${t("removing")} ${dataItemChanged?.line?.vehicleType} ${
						dataItemChanged?.line?.name
					} ${t("lineSpeed.fields.code")} ${dataItemChanged.code}`}
				</DialogContent>
				<DialogActions>
					<CancelButton
						id="line-speed-delete"
						onCancel={toggleDialog}
						title={t("cancel")}
					/>
					<ApplyButton
						id="line-speed-delete"
						onSubmit={deleteLineSpeedOnClick}
						title={t("confirm")}
						startIcon={<DeleteIcon />}
						color="error"
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(LineSpeedGrid);
