import { GUID_EMPTY } from "../../../../../app/constants/config";
import {
	OrderMCManagementAccountingEditableProps,
	OrderMCManagementArgMarketCompanyEditableProps,
	OrderMCManagementBusinessEditableProps,
	OrderMCManagementEditableProps,
	OrderMCManagementEngineeringEditableProps,
	OrderMCManagementFinancialEditableProps,
	OrderMCManagementLogisticEditableProps,
} from "../../interfaces/Editable/OrderMCManagementEditable";

export const orderMCManagementEditableModel: OrderMCManagementEditableProps = {
	orderId: GUID_EMPTY,
	dealerId: "",
	invoiceProposalNumber: "",
	invoiceProposalDate: null,
	comission: undefined,
	commercialCircular: "",
	usedDelivery: false,
	currency: "",
	paymentForm: "",
	finalValue: undefined,
	vehicleLocationId: "",
	salesSupportComments: "",
	financialComments: "",
	commercialComments: "",
	accountingComments: "",
	logisticComments: "",
	shippingDate: null,
	shippingNumber: "",
	certificateDate: null,
	billingForecast: null,
	shippingCompany: "",
	destination: "",
	engineeringComments: "",
	paqueteId: "",
};

export function orderMCManagementEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCManagementEditableProps {
	if (!data) return { ...orderMCManagementEditableModel, orderId: orderId };
	return {
		...orderMCManagementEditableModel,
		orderId: orderId,
		dealerId: data.dealerId ?? "",
		invoiceProposalNumber: data.invoiceProposalNumber ?? "",
		invoiceProposalDate: data.invoiceProposalDate ?? null,
		commercialCircular: data.commercialCircular ?? "",
		usedDelivery: data.usedDelivery,
		currency: data.currency ?? "",
		paymentForm: data.paymentForm ?? "",
		comission: data.comission ?? undefined,
		finalValue: data.finalValue ?? undefined,
		vehicleLocationId: data.vehicleLocationId ?? "",
		salesSupportComments: data.salesSupportComments ?? "",
		financialComments: data.financialComments ?? "",
		commercialComments: data.commercialComments ?? "",
		accountingComments: data.accountingComments ?? "",
		logisticComments: data.logisticComments ?? "",
		shippingDate: data.shippingDate ?? null,
		shippingNumber: data.shippingNumber ?? "",
		certificateDate: data.certificateDate ?? null,
		billingForecast: data.billingForecast ?? null,
		shippingCompany: data.shippingCompany ?? "",
		destination: data.destination ?? "",
		engineeringComments: data.engineeringComments ?? "",
		paqueteId: data.paqueteId ?? "",
	};
}

export const orderMCManagementArgMarketCompanyEditableModel: OrderMCManagementArgMarketCompanyEditableProps =
	{
		orderId: GUID_EMPTY,
		dealerId: "",
		invoiceProposalNumber: "",
		invoiceProposalDate: null,
		comission: undefined,
		commercialCircular: "",
		usedDelivery: false,
		currency: "",
		paymentForm: "",
		finalValue: undefined,
		vehicleLocationId: "",
		salesSupportComments: "",
		financialComments: "",
		commercialComments: "",
		accountingComments: "",
		logisticComments: "",
		shippingDate: null,
		shippingNumber: "",
		certificateDate: null,
		billingForecast: null,
		shippingCompany: "",
		destination: "",
		paqueteId: "",
	};

export function orderMCManagementArgMarketCompanyEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCManagementArgMarketCompanyEditableProps {
	if (!data)
		return {
			...orderMCManagementArgMarketCompanyEditableModel,
			orderId: orderId,
		};
	return {
		...orderMCManagementArgMarketCompanyEditableModel,
		orderId: orderId,
		dealerId: data.dealerId ?? "",
		invoiceProposalNumber: data.invoiceProposalNumber ?? "",
		invoiceProposalDate: data.invoiceProposalDate ?? null,
		commercialCircular: data.commercialCircular ?? "",
		usedDelivery: data.usedDelivery,
		currency: data.currency ?? "",
		paymentForm: data.paymentForm ?? "",
		comission: data.comission ?? undefined,
		finalValue: data.finalValue ?? undefined,
		vehicleLocationId: data.vehicleLocationId ?? "",
		salesSupportComments: data.salesSupportComments ?? "",
		financialComments: data.financialComments ?? "",
		commercialComments: data.commercialComments ?? "",
		accountingComments: data.accountingComments ?? "",
		logisticComments: data.logisticComments ?? "",
		shippingDate: data.shippingDate ?? null,
		shippingNumber: data.shippingNumber ?? "",
		certificateDate: data.certificateDate ?? null,
		billingForecast: data.billingForecast ?? null,
		shippingCompany: data.shippingCompany ?? "",
		destination: data.destination ?? "",
		paqueteId: data.paqueteId ?? "",
	};
}

export const orderMCManagementFinancialEditableModel: OrderMCManagementFinancialEditableProps =
	{
		orderId: GUID_EMPTY,
		financialComments: "",
	};

export function orderMCManagementFinancialEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCManagementFinancialEditableProps {
	if (!data)
		return { ...orderMCManagementFinancialEditableModel, orderId: orderId };

	const model: OrderMCManagementFinancialEditableProps = {
		...orderMCManagementFinancialEditableModel,
		orderId: orderId,
		financialComments: data.financialComments ?? "",
	};
	return model;
}

export const orderMCManagementLogisticEditableModel: OrderMCManagementLogisticEditableProps =
	{
		orderId: GUID_EMPTY,
		logisticComments: "",
		billingForecast: null,
		shippingCompany: "",
		destination: "",
	};

export function orderMCManagementLogisticEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCManagementLogisticEditableProps {
	if (!data)
		return { ...orderMCManagementLogisticEditableModel, orderId: orderId };

	const model: OrderMCManagementLogisticEditableProps = {
		...orderMCManagementLogisticEditableModel,
		orderId: orderId,
		logisticComments: data.logisticComments ?? "",
		billingForecast: data.billingForecast ?? null,
		shippingCompany: data.shippingCompany ?? "",
		destination: data.destination ?? "",
	};

	return model;
}

export const orderMCManagementAccountingEditableModel: OrderMCManagementAccountingEditableProps =
	{
		orderId: GUID_EMPTY,
		accountingComments: "",
	};

export function orderMCManagementAccountingEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCManagementAccountingEditableProps {
	if (!data)
		return { ...orderMCManagementAccountingEditableModel, orderId: orderId };

	const model: OrderMCManagementAccountingEditableProps = {
		...orderMCManagementAccountingEditableModel,
		orderId: orderId,
		accountingComments: data.accountingComments ?? "",
	};

	return model;
}

export const orderMCManagementBusinessEditableModel: OrderMCManagementBusinessEditableProps =
	{
		orderId: GUID_EMPTY,
		commercialComments: "",
	};

export function orderMCManagementBusinessEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCManagementBusinessEditableProps {
	if (!data)
		return { ...orderMCManagementBusinessEditableModel, orderId: orderId };

	const model: OrderMCManagementBusinessEditableProps = {
		...orderMCManagementBusinessEditableModel,
		orderId: orderId,
		commercialComments: data.commercialComments ?? "",
	};

	return model;
}

export const orderMCManagementEngineeringEditableModel: OrderMCManagementEngineeringEditableProps =
	{
		orderId: GUID_EMPTY,
		engineeringComments: "",
		paqueteId: "",
	};

export function orderMCManagementEngineeringEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCManagementEngineeringEditableProps {
	if (!data)
		return { ...orderMCManagementEngineeringEditableModel, orderId: orderId };

	const model: OrderMCManagementEngineeringEditableProps = {
		...orderMCManagementEngineeringEditableModel,
		orderId: orderId,
		engineeringComments: data.engineeringComments ?? "",
		paqueteId: data.paqueteId ?? "",
	};

	return model;
}
