import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { EUROPE_FACTORY_ORDERBOOK } from "../../../../../constants/Menus/OrderMenuUtils";
import { useTranslation } from "react-i18next";
import { getEuropeGridColumns } from "../../utils/GridUtils";
import { groupsAllowedToSeeCountryPreFilterOption } from "../../../../../constants/AuthGroups/orderDetails";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import OrderBookPage from "../../components/HomePage/OrderBookPage";
import { OrderbookEnum } from "../../../../../utils/enums/OrderbookEnum";

const EuropeOrderbook: React.FC = () => {
	const { t } = useTranslation(["order", "europe"]);
	const { setMenu, menu, reset } = useStores().listStore;
	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	const { isAuthorizedGroup } = useAuthStore();

	React.useEffect(() => {
		reset();
		setMenu(EUROPE_FACTORY_ORDERBOOK);

		const userCanSeeCountryColumn = isAuthorizedGroup(
			groupsAllowedToSeeCountryPreFilterOption
		);
		const gridColumns = getEuropeGridColumns(t).filter(
			(col: IColumnProps) =>
				col.field !== "countryName" || userCanSeeCountryColumn
		);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{menu === EUROPE_FACTORY_ORDERBOOK && (
				<OrderBookPage
					orderbookTitleSource="orderBookTrucks.orderBook"
					orderbookSubTitleSource="europeFactory.title"
					orderbookPageTitleSource="orderBookTrucks.pageTitle"
					columns={columns}
					type={OrderbookEnum.Europe}
				/>
			)}
		</>
	);
};

export default observer(EuropeOrderbook);
