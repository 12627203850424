import React from "react";
import _ from "lodash";
import { Popover, Typography } from "@mui/material";

type PopoverOverlayProps = {
	header: string;
	body: string;
	children: React.ReactNode;
};

const PopoverOverlay: React.FC<PopoverOverlayProps> = ({
	header,
	body,
	children,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const uniqueId = _.uniqueId();
	return (
		<>
			<span
				aria-owns={open ? `popover-${uniqueId}` : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				{children}
			</span>
			<Popover
				id={`popover-${uniqueId}`}
				sx={{
					pointerEvents: "none",
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 1 }} variant="h6">
					{header}
				</Typography>
				<Typography sx={{ p: 1, pt: 0 }} variant="subtitle2">
					{body}
				</Typography>
			</Popover>
		</>
	);
};
export default PopoverOverlay;
