import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import SelectForm from "../FormInputs/SelectForm";
import { getTimePeriodOptions } from "../../utils/SelectOptions";
import { SelectOptionProps } from "../../utils/GenericInterfaces";

interface TimePeriodFilterProps {
	value: number;
	set: (value: any) => void;
	timePeriodOptions?: Array<SelectOptionProps>;
}

const TimePeriodFilter: React.FC<TimePeriodFilterProps> = ({
	set,
	value = 3,
	timePeriodOptions = null,
}) => {
	const { t } = useTranslation("general");
	const [timePeriod, setTimePeriod] = useState<number>(value);

	const timePeriodFilterOptions = timePeriodOptions ?? getTimePeriodOptions(t);

	const handleChange = (event: any) => {
		const value = event.target.value;
		setTimePeriod(value);
		set({ value: value, isValid: true });
	};

	return (
		<SelectForm
			editable
			label={t("timePeriod")}
			labelId={"filter-timePeriod-label"}
			value={timePeriod}
			options={timePeriodFilterOptions}
			onChange={handleChange}
		/>
	);
};

export default observer(TimePeriodFilter);
