import { InvoiceExportationProps } from "../interfaces/InvoiceExportationDetails";

export const invoiceExportationModel: InvoiceExportationProps = {
	invoiceNumber: "",
	statusCode: "",
	boardingDate: null,
	importer: "",
	businessPartner: "",
};

export function invoiceExportationModelBuilder(data: InvoiceExportationProps) {
	if (!data) return invoiceExportationModel;
	return {
		...invoiceExportationModel,
		...data,
		invoiceNumber: data.invoiceNumber ?? "",
		statusCode: data.statusCode ?? "",
		importer: data.importer ?? "",
		businessPartner: data.businessPartner ?? "",
	} as InvoiceExportationProps;
}
