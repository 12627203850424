import { CTADMVEN_INCONSISTENCIES } from "../../constants/routes";
import { MenuRouteProps } from "../../utils/GenericInterfaces";
import { AppRouteProps } from "../routes";
import { getRoute } from "../../utils/RoutesUtil";
import { ReportProblem } from "@mui/icons-material";
import { groupsAllowedToSeeInconsistenciesMenu } from "../../constants/AuthGroups/inconsistenciesPage";
import Index from "./containers/Index";

//routes
export const inconsistenciesMenuRoute: MenuRouteProps = {
	path: CTADMVEN_INCONSISTENCIES,
	claims: groupsAllowedToSeeInconsistenciesMenu,
	element: <Index />,
	label: "inconsistencies",
	icon: <ReportProblem />,
	isCollapsable: false,
};

export const inconsistenciesRoutes: Array<AppRouteProps> = [
	getRoute(inconsistenciesMenuRoute),
];
