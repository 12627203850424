import * as React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

interface MenuListItemProps {
	primaryText: string;
	secondaryText?: string;
	icon?: React.ReactElement;
	isCollapsable?: boolean;
	component: any;
}
const MenuListItem: React.FC<MenuListItemProps> = ({
	primaryText,
	secondaryText,
	icon,
	isCollapsable,
	component,
}) => {
	return (
		<li>
			{isCollapsable && (
				<ListItem
					button
					component={component}
					key={primaryText}
					sx={{ py: 0, minHeight: 32 }}
				>
					{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
					<ListItemText
						primary={primaryText}
						primaryTypographyProps={{ fontSize: 14 }}
						inset={Boolean(!icon)}
					/>
				</ListItem>
			)}
			{!isCollapsable && (
				<ListItem button component={component} key={primaryText}>
					{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
					<ListItemText primary={primaryText} secondary={secondaryText} />
				</ListItem>
			)}
		</li>
	);
};

export default MenuListItem;
