import { GUID_EMPTY } from "../../../../../app/constants/config";
import { OrderMCManagementEditableProps } from "../../interfaces/Editable/OrderMCManagementEditable";

export const orderMCManagementEditableModel: OrderMCManagementEditableProps = {
	orderId: GUID_EMPTY,
	shippingNumber: "",
	certificateDate: null,
};

export function orderMCManagementEditableModelBuilder(
	data: any
): OrderMCManagementEditableProps {
	if (!data) return { ...orderMCManagementEditableModel };
	return {
		...orderMCManagementEditableModel,
		orderId: data.orderId,
		shippingNumber: data.shippingNumber ?? "",
		certificateDate: data.certificateDate ?? null,
	};
}
