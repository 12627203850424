import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { MktCompanyAdditionalCommentsSectionProps } from "../../interfaces/Sections/MktCompanyAdditionalCommentsSection";
import { fieldIsEditable } from "../../utils/EditableFieldsUtils";

const MktCompanyAdditionalCommentsSection: React.FC<
	MktCompanyAdditionalCommentsSectionProps
> = ({
	fieldsAvailableForEdition,
	editableFields,
	setOrderMCManagementFieldByKeyOnChange,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const salesSupportCommentsIsVisibleAndEditable = fieldIsEditable(
		"salesSupportComments",
		fieldsAvailableForEdition
	);

	const AdditionalComment = (
		name:
			| "financialComments"
			| "commercialComments"
			| "accountingComments"
			| "logisticComments"
	) => (
		<TextForm
			name={name}
			label={t(`details.sections.mktCompanyAdditionalComments.${name}`)}
			editable={fieldIsEditable(name, fieldsAvailableForEdition)}
			value={editableFields[name]}
			multiline
			maxRows={4}
			inputProps={{ maxLength: 500 }}
			onChange={(e) =>
				setOrderMCManagementFieldByKeyOnChange(name, e.target.value)
			}
		/>
	);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.mktCompanyAdditionalComments.title")}
			</Grid>
			{salesSupportCommentsIsVisibleAndEditable && (
				<Grid item xs={12}>
					<TextForm
						name={"salesSupportComments"}
						label={t(
							"details.sections.mktCompanyAdditionalComments.salesSupportComments"
						)}
						editable
						value={editableFields.salesSupportComments}
						multiline
						maxRows={4}
						inputProps={{ maxLength: 500 }}
						onChange={(e) =>
							setOrderMCManagementFieldByKeyOnChange(
								"salesSupportComments",
								e.target.value
							)
						}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				{AdditionalComment("financialComments")}
			</Grid>
			<Grid item xs={12}>
				{AdditionalComment("commercialComments")}
			</Grid>
			<Grid item xs={12}>
				{AdditionalComment("accountingComments")}
			</Grid>
			<Grid item xs={12}>
				{AdditionalComment("logisticComments")}
			</Grid>
		</>
	);
};

export default observer(MktCompanyAdditionalCommentsSection);
