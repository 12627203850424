import CtadmvenApi from "../../apis/CtadmvenApi";
import { QuotationLogProps } from "../../pages/Quotations/Details/interfaces/QuotationLogProps";

interface IQuotationLogService {
	getQuotationLogsMessages(id: string): Promise<QuotationLogProps>;
}

const base = "/QuotationLog";

export class QuotationLogService implements IQuotationLogService {
	constructor(private api: CtadmvenApi) {}

	getQuotationLogsMessages = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${id}`);
}
