import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import SelectForm from "../FormInputs/SelectForm";
import {
	getDealerBrazilOptions,
	getDealerTracbelOptions,
} from "../../utils/SelectOptions";
import { textNotEmptyValidator } from "../../utils/FormValidators";
import { useAuthStore } from "../../auth/store/AuthStore";
import { BRA_DEALER_TNE_TNO } from "../../constants/AuthGroups/authGroups";

interface DealerRegionFilterProps {
	value: string;
	set: (value: any) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const DealerBrazilFilter: React.FC<DealerRegionFilterProps> = ({
	set,
	value = "",
}) => {
	const { t } = useTranslation("general");
	const [dealer, setDealer] = useState<string>(value);

	const { isAuthorizedGroup } = useAuthStore();
	const dealerBrazilOptions = isAuthorizedGroup([BRA_DEALER_TNE_TNO])
		? getDealerTracbelOptions()
		: getDealerBrazilOptions();

	const validator = (value: string) => {
		if (!textNotEmptyValidator(value)) return "required";
		return "";
	};

	const handleChange = (event: any) => {
		const value = event.target.value;
		setDealer(value);
		if (!Boolean(validator(value))) set({ value: value, isValid: true });
		else set({ value: value, isValid: false });
	};

	return (
		<SelectForm
			editable
			label={t("region")}
			labelId={"filter-dealer-region-label"}
			value={dealer}
			options={dealerBrazilOptions}
			onChange={handleChange}
			validator={validator}
			MenuProps={MenuProps}
			defaultItem={{ value: "", label: t("select") }}
			inputProps={{ shrink: "true" }}
		/>
	);
};

export default observer(DealerBrazilFilter);
