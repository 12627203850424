import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";
import SelectPreFilter from "../components/SelectPreFilter";
import { GRID_MAX_SIZE } from "../../../constants/gridConst";

const COUNTRY_PRE_FILTER_XS = GRID_MAX_SIZE;
const COUNTRY_PRE_FILTER_SM = 6;
const COUNTRY_PRE_FILTER_MD_LG = 3;
const COUNTRY_PRE_FILTER_XL = 2;

const CountryPreFilter: React.FC<SelectPreFilterProps> = (props) => {
	const { t } = useTranslation("europe");
	const {
		id = "countryPreFilter",
		label = "preFilters.country",
		xs = COUNTRY_PRE_FILTER_XS,
		sm = COUNTRY_PRE_FILTER_SM,
		md = COUNTRY_PRE_FILTER_MD_LG,
		lg = COUNTRY_PRE_FILTER_MD_LG,
		xl = COUNTRY_PRE_FILTER_XL,
	} = props;
	return (
		<SelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};
export default observer(CountryPreFilter);
