import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";
import SelectPreFilter from "../components/SelectPreFilter";

const FinalDeliveryPreFilter: React.FC<SelectPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const { id = "finalDeliveryPreFilter", label = "preFilters.finalDelivery" } =
		props;
	return (
		<SelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			isBooleanOptions={true}
		/>
	);
};

export default observer(FinalDeliveryPreFilter);
