import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import { SelectOptionProps } from "../../../../../../utils/GenericInterfaces";

configure({ enforceActions: "always" });

class PreFilterLineStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: string = "";
	@observable preFilterOptions: Array<SelectOptionProps> = [];

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = "");

	@action get = () =>
		this.preFilter === ""
			? []
			: this.preFilter
					.split(",")
					.map((d: string) =>
						getFilterObject("order.lineCode", d, "startswith")
					);

	@action set = (line: string) => (this.preFilter = line);

	@action getOptions = async () => {
		if (this.preFilterOptions.length > 0) return; //since this filter is equal to all pages

		this.loading = true;
		await this.api.lineService
			.getPreFilterLineOptions()
			.then((rs: any) => {
				if (rs.data) {
					this.preFilterOptions = toJS(rs.data).map(
						(m: any) =>
							({
								label: m.description,
								value: m.lineCodes.join(","),
							}) as SelectOptionProps
					);
				}
			})
			.finally(() => (this.loading = false));
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const lineFilters = this.get();

		if (lineFilters) {
			orFiltersGroups.push(lineFilters);
		}
	}

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterLineStore;
