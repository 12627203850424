import { Checkbox } from "@mui/material";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import DateTimeToString from "../../../../utils/DateTimeToString";
import { CalendarDatesProps } from "../interfaces/CalendarDatesProps";
import days from "../models/days.json";
import { toJS } from "mobx";

export const getColumns = (
	translate: any,
	onChangeAvailableDay: any
): Array<GridColumnProps> => {
	return [
		{
			title: translate("month"),
			editable: false,
			cell: (props) => monthCell(props),
		} as GridColumnProps,
		{
			field: "week",
			title: translate("week"),
			editable: false,
		} as GridColumnProps,
		...days.map((d) => {
			return {
				title: translate(`days.${d}`),
				field: d,
				editable: true,
				editor: "boolean",
				className: "td-table",
				cell: (props) => cell(props, d, onChangeAvailableDay),
			} as GridColumnProps;
		}),
	] as Array<GridColumnProps>;
};

const cell = (props: GridCellProps, d: string, onChangeAvailableDay: any) => {
	let calendarDay = toJS(props.dataItem.dayGridProps).filter(
		(x: CalendarDatesProps) => x.dayOfWeek.toLowerCase() === d
	);

	if (!calendarDay || calendarDay.lenght === 0) {
		return <td></td>;
	}

	return (
		<td>
			<Checkbox
				checked={calendarDay[0].available}
				color="secondary"
				disabled={calendarDay[0].readOnly}
				onChange={() => onChangeAvailableDay(calendarDay)}
			/>
		</td>
	);
};

const monthCell = (props: GridCellProps) => {
	return <td>{DateTimeToString(props.dataItem.month, "DD/MM/YYYY")}</td>;
};
