import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { LocalizationExportAccordionProps } from "../../../interfaces/Accordions/Europe/LocalizationExportAccordion";
import LocalizationExportSection from "../../Sections/Europe/LocalizationExportSection";

const LocalizationExportAccordion: React.FC<
	LocalizationExportAccordionProps
> = (details) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="localizationAccordion"
			title={`${t("details.accordions.localizationExport")}`}
		>
			<LocalizationExportSection {...details.localizationExportDetails} />
		</AccordionCard>
	);
};

export default observer(LocalizationExportAccordion);
