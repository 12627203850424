import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";

import GeneralAccordion from "./Accordions/Europe/GeneralAccordion";
import VehicleAccordion from "./Accordions/Europe/VehicleAccordion";
import ProductionAccordion from "./Accordions/Europe/ProductionAccordion";
import InvoiceDeliveryAccordion from "./Accordions/Europe/InvoiceDeliveryAccordion";

import { useFollowUpChlEuropeStore } from "../stores/FollowUpChlEuropeStore";
import SaveBar from "../../../../components/SaveBar/SaveBar";
import NoContentResult from "../../../../components/msal/NoContentResult";
import LocalizationExportAccordion from "./Accordions/Europe/LocalizationExportAccordion";
import { getBreadcrumbs } from "./Utils/BreadcrumbsUtils";

const EuropeIndex: React.FC = () => {
	const { t } = useTranslation(["ctadmven", "menu", "general"]);
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = React.useState(true);

	const {
		readOnlyDetails,
		editableDetails,
		setEditableFieldByKeyOnChange,
		hasFormChanges,
		loadDetails,
		update,
	} = useFollowUpChlEuropeStore();

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		await loadDetails(id ?? "").finally(() => setLoading(false));
	}, [id, loadDetails]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const handleSubmit = async () => {
		setLoading(true);
		await update()
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err))
			.finally(() => fetchDetails());
	};

	const breadcrumbs = getBreadcrumbs(t);

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && readOnlyDetails.factoryOrder && (
				<>
					<TitleBox breadcrumbs={breadcrumbs} />
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs>
								<Grid container spacing={2}>
									<GeneralAccordion
										generalDetails={{ readOnlyFields: { ...readOnlyDetails } }}
										customerDetails={{
											readOnlyFields: { ...readOnlyDetails },
											editableFields: { ...editableDetails },
											setFieldByKeyOnChange: setEditableFieldByKeyOnChange,
											countryName: readOnlyDetails.countryName,
										}}
									/>
									<VehicleAccordion
										modelDetails={{ readOnlyFields: { ...readOnlyDetails } }}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsDetails={{ readOnlyFields: { ...readOnlyDetails } }}
									/>
									<ProductionAccordion
										productionDatesDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
									/>
									<LocalizationExportAccordion
										localizationExportDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
									/>
									<InvoiceDeliveryAccordion
										exportationInvoiceProps={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										exportationLocationProps={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										invoicingDeliveryMarketCompanyProps={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										reservationExportDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
									/>
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasFormChanges}
								/>
							</Grid>
						</Grid>
					</Box>
				</>
			)}
			{!loading && !readOnlyDetails.factoryOrder && <NoContentResult />}
		</>
	);
};

export default observer(EuropeIndex);
