import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { VehicleIdentificationSectionProps } from "./Interfaces/VehicleIdentificationSection";
import TextForm from "../FormInputs/TextForm";

const VehicleIdentificationSection: React.FC<
	VehicleIdentificationSectionProps
> = ({ readOnlyFields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.vehicleIdentification.title")}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t(
						"details.sections.vehicleIdentification.vehicleIdentificationNumber"
					)}
					value={readOnlyFields.vehicleIdentificationNumber}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.vehicleIdentification.engineSerialNumber")}
					value={readOnlyFields.engineSerialNumber}
				/>
			</Grid>
		</>
	);
};

export default observer(VehicleIdentificationSection);
