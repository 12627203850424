import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextPreFilter from "../components/TextPreFilter";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import { GRID_MAX_SIZE } from "../../../constants/gridConst";
const COMMERCIALMODEL_PRE_FILTER_XS = GRID_MAX_SIZE;
const COMMERCIALMODEL_PRE_FILTER_SM = 6;
export const COMMERCIALMODEL_PRE_FILTER_MD = 3;
export const COMMERCIALMODEL_PRE_FILTER_LG_XL = 2;
const CommercialModelPreFilter: React.FC<TextPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "commercialModelPreFilter",
		maxLength = 50,
		label = "preFilters.commercialModel",
		xs = COMMERCIALMODEL_PRE_FILTER_XS,
		sm = COMMERCIALMODEL_PRE_FILTER_SM,
		md = COMMERCIALMODEL_PRE_FILTER_MD,
		lg = COMMERCIALMODEL_PRE_FILTER_LG_XL,
		xl = COMMERCIALMODEL_PRE_FILTER_LG_XL,
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			maxLength={maxLength}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(CommercialModelPreFilter);
