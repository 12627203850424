import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { orderKeyLabels } from "../../utils/BatchEditionUtils";
import ClearRecordItem from "../../../../../components/BatchEdition/ClearRecordItem";

const CustomerContent: React.FC = () => {
	const { t } = useTranslation("order");

	const { batchData, setCustomerDataByKey } = useStores().batchEditionStore;

	return (
		<>
			<Grid item sm={12} md={6} lg={6}>
				<ClearRecordItem
					recordTitle={t(orderKeyLabels.salesman.label)}
					checked={batchData.customerData.salesman.clearValue}
					onClearRecord={setCustomerDataByKey}
					recordKey={orderKeyLabels.salesman.key}
				>
					<TextForm
						label={t(orderKeyLabels.salesman.label)}
						editable
						value={batchData.customerData.salesman.value}
						slotProps={{ htmlInput: { maxLength: 500 } }}
						onChange={(e) =>
							setCustomerDataByKey(
								{
									value: e.target.value,
									clearValue: false,
								},
								orderKeyLabels.salesman.key
							)
						}
						disabled={batchData.customerData.salesman.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
			<Grid item sm={12} md={6} lg={6}>
				<ClearRecordItem
					recordTitle={t(orderKeyLabels.branchOffice.label)}
					checked={batchData.customerData.branchOffice.clearValue}
					onClearRecord={setCustomerDataByKey}
					recordKey={orderKeyLabels.branchOffice.key}
				>
					<TextForm
						label={t(orderKeyLabels.branchOffice.label)}
						editable
						value={batchData.customerData.branchOffice.value}
						slotProps={{ htmlInput: { maxLength: 500 } }}
						onChange={(e) =>
							setCustomerDataByKey(
								{
									value: e.target.value,
									clearValue: false,
								},
								orderKeyLabels.branchOffice.key
							)
						}
						disabled={batchData.customerData.branchOffice.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
		</>
	);
};

export default observer(CustomerContent);
