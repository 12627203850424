import * as React from "react";
import { observer } from "mobx-react";
import { Box, Paper, Stack } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useTranslation } from "react-i18next";
import { getFollowUpChlHighlightOptions } from "../../../../../utils/SelectOptions";

const HighlightCaptions: React.FC = () => {
	const { t } = useTranslation("followUpChl");
	const highlightOptions = getFollowUpChlHighlightOptions(t);

	return (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
				mt: 2,
			}}
		>
			<Paper>
				<Stack direction="row" spacing={2} padding={1}>
					<Box>{t("gridCaptions.title")}:</Box>
					<Stack direction="row" spacing={2}>
						{highlightOptions.map((m) => (
							<Stack key={`caption-${m.value}`} direction="row" spacing={0.5}>
								<Brightness1Icon
									htmlColor={m.color}
									fontSize="small"
								></Brightness1Icon>
								<Box>{m.label}</Box>
							</Stack>
						))}
					</Stack>
				</Stack>
			</Paper>
		</Box>
	);
};

export default observer(HighlightCaptions);
