import {
	DateFormProps,
	StringFormProps,
	NumberFormProps,
} from "./GenericInterfaces";

export const dateFormModel: DateFormProps = {
	value: null,
	clearValue: false,
};

export const stringFormModel: StringFormProps = {
	value: "",
	clearValue: false,
};

export const numberFormModel: NumberFormProps = {
	value: null,
	clearValue: false,
};
