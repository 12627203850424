import { InvoiceInstructionProps } from "../interfaces/InvoiceInstructionDetails";

export const invoiceInstructionModel: InvoiceInstructionProps = {
	dealerNameWithCode: "",
	customer: "",
	creationDate: null,
	caApprovalDate: null,
	lfApprovalDate: null,
	expirationDate: null,
	dealerRegion: "",
};

export function invoiceInstructionModelBuilder(data: InvoiceInstructionProps) {
	if (!data) return invoiceInstructionModel;
	return {
		...invoiceInstructionModel,
		...data,
		dealerNameWithCode: data.dealerNameWithCode ?? "",
		customer: data.customer ?? "",
		dealerRegion: data.dealerRegion ?? "",
	} as InvoiceInstructionProps;
}
