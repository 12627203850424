import * as AuthGroups from "./authGroups";

export const groupsAllowedToEditQuotaSection: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.BRA_SALES_SUPPORT_QUOTA];

export const groupsAllowedToSeeQuotaSection: Array<AuthGroups.AuthGroupsType> =
	[
		...groupsAllowedToEditQuotaSection,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToEditChileZone: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY,
	AuthGroups.CHL_MARKET_COMPANY_SALESMAN_MANAGER,
];

export const groupsAllowedToEditCustomerSection: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.BRA_SALES_SUPPORT_VDB];

export const groupsAllowedToEditEuropeFactory: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
		AuthGroups.PI_SALES_SUPPORT,
	];

export const groupsNotAllowedToSeeDelayFields: Array<AuthGroups.AuthGroupsType> =
	[
		...AuthGroups.BRA_DEALERS,
		AuthGroups.MEX_SALES_SUPPORT,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.MEAC_SALES_SUPPORT,
	];

export const groupsAllowedToSeeAllInvoiceInstructionSection: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_BUS,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToSeeAllInvoiceInstructionExportationSection: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.PI_SALES_SUPPORT,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

const groupsAllowedToSeeMinimalInvoiceInstructionExportationSection: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.MEX_SALES_SUPPORT,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.MEAC_SALES_SUPPORT,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToSeeInvoiceInstructionExportationSection: Array<AuthGroups.AuthGroupsType> =
	[
		...groupsAllowedToSeeAllInvoiceInstructionExportationSection,
		...groupsAllowedToSeeMinimalInvoiceInstructionExportationSection,
	];

export const groupsAllowedToSeeInvoicingDeliverySection: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_BUS,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToSeeInvoiceExportationSection: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToSeeReservationAvailabilitySection: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
		...AuthGroups.BRA_DEALERS,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToEditFinalCustomerBatchDetails: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.BRA_SALES_SUPPORT_VDB];

export const groupsAllowedToEditVolvoManagementDetails: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.BRA_SALES_SUPPORT_VDB];

export const groupsAllowedToEditCommercialCommentsVolvoManagement: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
	];

export const groupsAllowedToSeeAllVolvoManagementDetails: Array<AuthGroups.AuthGroupsType> =
	[
		...groupsAllowedToEditVolvoManagementDetails,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_BUS,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToSeeCountryPreFilterOption: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.GENERAL_VDB_VIEWER,
		AuthGroups.PI_SALES_SUPPORT,
	];

export const groupsAllowedToSeeInvoicingDeliveryMarketCompanySection: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
	];
