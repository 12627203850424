import moment from "moment";
import { GUID_EMPTY } from "../../../../../app/constants/config";
import {
	OrderMCDeliveryEditableProps,
	OrderMCDeliveryFinancialEditableProps,
} from "../../interfaces/Editable/OrderMCDeliveryEditable";

export const orderMCDeliveryEditableModel: OrderMCDeliveryEditableProps = {
	orderId: GUID_EMPTY,
	deliveryProbability: "None",
	firmBilling: null,
	financialDelivery: null,
	physicalDelivery: null,
	finalDelivery: null,
	financialDeliveryUser: "",
	finalDeliveryUser: "",
	firmBillingUser: "",
	physicalDeliveryUser: "",
};

export function orderMCDeliveryEditableModelBuilder(
	data: any,
	orderId: string
): OrderMCDeliveryEditableProps {
	if (!data) return { ...orderMCDeliveryEditableModel, orderId: orderId };
	return {
		...orderMCDeliveryEditableModel,
		orderId: orderId,
		deliveryProbability: data.deliveryProbability ?? "None",
		firmBilling: getStartDate(data.firmBilling),
		financialDelivery: getStartDate(data.financialDelivery),
		physicalDelivery: getStartDate(data.physicalDelivery),
		finalDelivery: getStartDate(data.finalDelivery),
		financialDeliveryUser: data.financialDeliveryUser ?? "",
		finalDeliveryUser: data.finalDeliveryUser ?? "",
		firmBillingUser: data.firmBillingUser ?? "",
		physicalDeliveryUser: data.physicalDeliveryUser ?? "",
	};
}

export const orderMCDeliveryFinancialEditableModel: OrderMCDeliveryFinancialEditableProps =
	{
		orderId: GUID_EMPTY,
		financialDelivery: null,
		financialDeliveryUser: "",
	};

export function orderMCDeliveryFinancialEditableModelBuilder(
	data: OrderMCDeliveryFinancialEditableProps,
	orderId: string
): OrderMCDeliveryFinancialEditableProps {
	if (!data)
		return { ...orderMCDeliveryFinancialEditableModel, orderId: orderId };

	const model: OrderMCDeliveryFinancialEditableProps = {
		...orderMCDeliveryFinancialEditableModel,
		orderId: orderId,
		financialDelivery: getStartDate(data.financialDelivery),
		financialDeliveryUser: data.financialDeliveryUser ?? "",
	};

	return model;
}

function getStartDate(date: Date | null): Date | null {
	return date ? moment(date).startOf("day").toDate() : null;
}
