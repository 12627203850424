import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../auth/store/AuthStore";
import HomeVolvo from "./containers/HomeVolvo";

const Home: React.FC = () => {
	const { t } = useTranslation("general");
	const { isAuthorizedAdminOrVdbGroup, graphData } = useAuthStore();
	const userIsAdminOrVdb = isAuthorizedAdminOrVdbGroup();

	return (
		<>
			<h5 className="card-title">
				{t("welcome")} {graphData?.displayName}
			</h5>
			{userIsAdminOrVdb && <HomeVolvo />}
		</>
	);
};

export default Home;
