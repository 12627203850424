import RootStore from "../../../stores/RootStore";
import { configure } from "mobx";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import BasePreFilterDateStore from "../../../../../../components/PreFilter/Stores/BasePreFilterDateStore";

configure({ enforceActions: "always" });

class PreFilterGokDateStore extends BasePreFilterDateStore {
	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	getSpecificFilter(): any {
		return this.preFilter && this.preFilter.endDate
			? [
				getFilterObject("order.GreenOk", this.preFilter?.startDate, "gte"),
				getFilterObject("order.GreenOk", this.preFilter?.endDate, "lte"),
			]
			: this.preFilter && this.preFilter.startDate
				? [getFilterObject("order.GreenOk", this.preFilter?.startDate, "eq")]
				: [];
	}
}

export default PreFilterGokDateStore;
