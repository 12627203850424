import { Search } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import LineFilter from "../../../../../components/Filters/LineFilter";
import MonthFilter from "../../../../../components/Filters/MonthFilter";
import { useDealerReadStore } from "../../stores/DealerReadStore";

const VolvoReadFilters: React.FC = () => {
	const { setFilterByKeyOnChange, setFilter, filter, filterIsValid } =
		useDealerReadStore();

	return (
		<Box sx={{ display: "flex" }}>
			<Grid container sx={{ display: "flex", height: "90px" }} spacing={2}>
				<Grid item>
					<LineFilter
						set={(e) => setFilterByKeyOnChange("line", e.value, e.isValid)}
						value={filter.line}
					/>
				</Grid>
				<Grid item>
					<MonthFilter
						set={(e) => setFilterByKeyOnChange("date", e.value, e.isValid)}
						value={filter.date}
					/>
				</Grid>
				<Grid item sx={{ alignSelf: "center" }}>
					<Button
						variant="contained"
						onClick={setFilter}
						size="small"
						disabled={!filterIsValid}
					>
						<Search fontSize="small" />
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default observer(VolvoReadFilters);
