import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai } from "../../services/appInsights/TelemetryService";
import React from "react";

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
type TelemetryProviderProps = {
	children: React.ReactNode;
};
const TelemetryProvider: React.FC<TelemetryProviderProps> = ({ children }) => {
	const [initialized, setInitialized] = React.useState<Boolean>(false);

	React.useEffect(() => {
		const AppInsightsInstrumentationKey =
			process.env.REACT_APP_CTADMVEN_APP_INSIGHTS_KEY;
		if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey)) {
			ai.initialize(AppInsightsInstrumentationKey);
			setInitialized(true);
		}
	}, [initialized]);

	return <React.Fragment>{children}</React.Fragment>;
};

export default withAITracking(ai.reactPlugin, TelemetryProvider);
