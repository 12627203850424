import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeVariantsMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_PARAMETER,
		AuthGroups.CHL_PARAMETER,
	];

export const groupsAllowedToEditAsAdmin: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.BRA_SALES_SUPPORT_PARAMETER,
];

export const groupsAllowedToEditAsChl: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.CHL_PARAMETER,
];
