export const gridConst = {
	pageable: {
		buttonCount: 5,
		info: true,
		pageSizes: [10, 15, 20, 25, 30, 35, 40],
		previousNext: true,
	},
	pageSize: 20,
};

export const GRID_MAX_SIZE = 12;
