export const CTADMVEN_ORDER_EXTERNAL_BRAZIL = "order/externalBrazil";
export const CTADMVEN_ORDER_AVAILABILITY = "/order/availability";
export const CTADMVEN_ORDER_MKT_COMPANY = "order/mkt-company";
export const CTADMVEN_ORDER_INTERNAL_VDB = "order/internal";
export const CTADMVEN_ORDER_TOTAL = "order/total";
export const CTADMVEN_ORDER_BUS_TOTAL = "bus/total";
export const CTADMVEN_ORDER_BUS_BRAZIL = "bus/brazil";
export const CTADMVEN_ORDER_BUS_PI = "bus/pi";
export const CTADMVEN_ORDER_BUS_MKT_COMPANY_TOTAL = "bus/mkt-company";
export const CTADMVEN_ORDER_BUS_MEAC = "bus/meac";
export const CTADMVEN_ORDER_BUS_FREE_UNIT_VCI = "bus/free-vci";
export const CTADMVEN_ORDER_BUS_FREE_UNIT_VCI_VDB = "bus/free-vci-vdb";
export const CTADMVEN_ORDER_EUROPE_FACTORY_ORDERBOOK = "europeFactory";
export const CTADMVEN_EXPORT_PARTNER = "exportPartner";
export const CTADMVEN_QUOTA_VOLVO_READ = "quota/volvo-proposal";
export const CTADMVEN_ORDER_FD_MKT_COMPANY = "order/fd-mkt-company";
export const CTADMVEN_QUOTA_EDIT = "quota/edit";
export const CTADMVEN_ORDER_FD_MKT_COMPANY_BUS = "bus/fd-mkt-company";
export const CTADMVEN_QUOTA_DEALER_READ = "quota/dealer-proposal";
export const CTADMVEN_QUOTA_VOLVO_DEALER_READ = "quota/volvo-dealer-proposal";
export const CTADMVEN_QUOTA_EXPORT_READ = "quota/export-proposal";
export const CTADMVEN_ORDER_PRIVATE_IMPORTERS = "order/pi";
export const CTADMVEN_ORDER_VCI = "order/vci";
export const CTADMVEN_ORDER_FD_MKT_COMPANY_EUROPE = "fdEuropeFactory";
export const CTADMVEN_PARAMETERS_PRODUCTION_WEEK = "parameters/production-week";
export const CTADMVEN_LINE_SPEED = "line-speed";
export const CTADMVEN_REGIONS_GROUP = "region/group";
export const CTADMVEN_REGIONS_EDIT = "region/edit";
export const CTADMVEN_VARIANTS = "variants";
export const CTADMVEN_QUOTATION = "quotation";
export const CTADMVEN_DISTRICT_EDIT = "district/edit";
export const CTADMVEN_DISTRICT_GROUP = "district/group";
export const CTADMVEN_FOLLOWUP_ARG_TRUCK = "FollowUpArg";
export const CTADMVEN_FOLLOWUP_ARG_BUS = "FollowUpArg-bus";
export const CTADMVEN_INCONSISTENCIES = "inconsistencies";
export const CTADMVEN_FOLLOWUP_CHL = "followUpChl";
export const CTADMVEN_CLOSED_PERIOD_ARG_TRUCK = "closedPeriodArg";
export const CTADMVEN_CLOSED_PERIOD_ARG_BUS = "closedPeriodArg-bus";
export const CTADMVEN_DOWNLOAD_CENTER = "downloads";
