import { SummaryGridProps } from "../../../../../components/SummaryDraggableCard/SummaryDraggableCard";

export interface ProgressSummaryModelProps {
	dealer: string;
	firmBilling: number;
	financialDelivery: number;
	physicalDelivery: number;
	finalDelivery: number;
}

const progressSummaryModelInitial = {
	dealer: "",
	firmBilling: 0,
	financialDelivery: 0,
	physicalDelivery: 0,
	finalDelivery: 0,
} as ProgressSummaryModelProps;

const defaultTotal = [0, 0, 0, 0];

export function progressSummaryArrayModelBuilder(data: any[]) {
	if (!data) {
		return [];
	}

	return data.map(
		(item) =>
			({
				...progressSummaryModelBuilder(item),
			}) as ProgressSummaryModelProps
	);
}

function progressSummaryModelBuilder(data: any) {
	if (!data) {
		return progressSummaryModelInitial;
	}

	return {
		dealer: data.dealer,
		firmBilling: data.firmBilling,
		financialDelivery: data.financialDelivery,
		physicalDelivery: data.physicalDelivery,
		finalDelivery: data.finalDelivery,
	} as ProgressSummaryModelProps;
}

function getProgressSummaryAsGridProps(
	data: Array<ProgressSummaryModelProps>
) {
	let summaryGrid = [] as SummaryGridProps[];

	data.forEach((item) => {
		const baseItems = [
			{ value: item.firmBilling },
			{ value: item.financialDelivery },
			{ value: item.physicalDelivery },
			{ value: item.finalDelivery },
		];

		if (data.length > 1) {
			summaryGrid = summaryGrid.concat([{ value: item.dealer }, ...baseItems]);
		} else {
			summaryGrid = summaryGrid.concat(baseItems);
		}
	});

	return summaryGrid;
}

function getFooterTotal(data: Array<ProgressSummaryModelProps>) {
	if (!data) {
		return [0, 0, 0, 0];
	}

	const total = [0, 0, 0, 0];

	data.forEach((item) => {
		total[0] += item.firmBilling;
		total[1] += item.financialDelivery;
		total[2] += item.physicalDelivery;
		total[3] += item.finalDelivery;
	});

	return total;
}

export function getSummaryDraggableData(
	data: Array<ProgressSummaryModelProps>,
	isArgDealer: boolean
) {
	if (!data) {
		return { dataTransformed: [], total: defaultTotal };
	}

	const dataTransformed = isArgDealer
		? []
		: getProgressSummaryAsGridProps(data);
	const total = getFooterTotal(data);
	return { dataTransformed, total };
}
