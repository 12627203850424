import * as React from "react";
import { DataResult, State, toODataString } from "@progress/kendo-data-query";
import { LoadingPage } from "../../LoadingPage/LoadingPage";

interface DataLoaderProps {
	dataState: State;
	onDataReceived: (records: DataResult) => void;
	fetchData: (dataState: State) => Promise<any>;
	refresh?: number;
	loading: boolean;
	setLoading: any;
}

export const DataLoader = (props: DataLoaderProps) => {
	const lastSuccess = React.useRef<string>("");
	const pending = React.useRef<string>("");

	const requestDataIfNeeded = React.useCallback(() => {
		if (
			pending.current ||
			toODataString(props.dataState) === lastSuccess.current
		) {
			return;
		}

		pending.current = toODataString(props.dataState);

		props.setLoading(true);

		props.fetchData
			.call(undefined, props.dataState)
			.then((json) => {
				lastSuccess.current = pending.current;
				pending.current = "";

				if (toODataString(props.dataState) === lastSuccess.current) {
					props.onDataReceived.call(undefined, {
						data: json.Data || json.data,
						total: json.Total || json.total,
					});
				} else {
					requestDataIfNeeded();
				}
			})
			.finally(() => props.setLoading(false));
	}, [props]);

	React.useEffect(() => {
		lastSuccess.current = "";
		requestDataIfNeeded();
	}, [props.refresh]);

	requestDataIfNeeded();
	return <LoadingPage loading={props.loading} />;
};
