import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Tooltip } from "@mui/material";
import { Category, Public, TravelExplore, History } from "@mui/icons-material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DatesTimeLine from "./DatesTimeLine";
import { useHomeVolvoStore } from "../stores/HomeVolvoStore";
import VehicleTypeIcon from "./VehicleTypeIcon";
import VehicleKeyFilter from "./VehicleKeyFilter";
import { observer } from "mobx-react";
import DateTimeSpan from "../../../components/DateTimeSpan/DateTimeSpan";
import { LoadingPage } from "../../../components/LoadingPage/LoadingPage";
import OverlineTextCentered from "../../../components/OverlineTextCentered/OverlineTextCentered";

const HomeVolvo: React.FC = () => {
	const { t } = useTranslation(["order", "general"]);
	const { orders, loading, searchNotFound, resetStore } = useHomeVolvoStore();

	const reset = React.useCallback(() => {
		resetStore();
	}, [resetStore]);

	React.useEffect(() => {
		reset();
	}, [reset]);

	return (
		<Grid container spacing={2}>
			<VehicleKeyFilter />
			<LoadingPage loading={loading} />
			{!loading &&
				orders.map((order, i) => (
					<Grid item xs={12} key={i}>
						<Card>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item alignSelf="center" xs>
										<Stack direction="row" alignSelf="center" spacing={1}>
											<VehicleTypeIcon
												vehicleType={order.vehicleType}
												statusOk={order.statusOk}
											/>
											<Stack direction="column" alignSelf="center">
												<Tooltip
													title={order.vehicleType === "Truck" ? "AO" : "FO"}
												>
													<Typography color="text.primary">
														{order.vehicleCode}
													</Typography>
												</Tooltip>
												<Tooltip title={t("details.sections.general.chassi")}>
													<Typography color="text.secondary">
														{order.chassi ?? "-"}
													</Typography>
												</Tooltip>
											</Stack>
										</Stack>
									</Grid>
									<Grid item alignSelf="center" xs>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
											mb={1}
										>
											<Tooltip title={t("details.sections.general.foDistrict")}>
												<Public fontSize="small" />
											</Tooltip>
											<Typography variant="body2">
												{order.foDistrict}
											</Typography>
											<Tooltip
												title={t("details.sections.general.foDealerRegion")}
											>
												<TravelExplore fontSize="small" />
											</Tooltip>
											<Typography variant="body2">{order.foRegion}</Typography>
										</Stack>
										<Stack direction="row" alignItems="center" spacing={1}>
											<Tooltip
												title={t("details.sections.model.commercialModel")}
											>
												<Category fontSize="small" />
											</Tooltip>
											<Typography variant="body2">
												{order.commercialModel}
											</Typography>
										</Stack>
									</Grid>
									<Grid item xs={8}>
										<DatesTimeLine datesTimeLine={order.datesTimeLine} />
									</Grid>
									<Grid item xs={12}>
										<Stack
											direction="row"
											justifyContent="end"
											spacing={1}
											mt={2}
										>
											<Tooltip title={t("tooltips.lastEvent")}>
												<History fontSize="small" color="secondary" />
											</Tooltip>
											<Typography color="text.secondary" variant="button">
												{t(`eventType.${_.camelCase(order.lastSyncEvent)}`)}
												{": "}
												<DateTimeSpan
													date={order.lastSyncDate}
													format={"DD/MM/YYYY HH:mm:ss"}
													defaultValue="-"
													convertUtc
												/>
											</Typography>
										</Stack>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				))}
			{searchNotFound && (
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<OverlineTextCentered>
								{t("noRecordsAvailable", { ns: "general" })}
							</OverlineTextCentered>
						</CardContent>
					</Card>
				</Grid>
			)}
		</Grid>
	);
};

export default observer(HomeVolvo);
