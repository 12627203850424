import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import { IPreFilterCommonOption } from "../Interfaces/IPreFilter";
import { getFilterObject } from "../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class BasePreFilterStringArrayStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: Array<string> = [];
	@observable preFilterOptions: Array<IPreFilterCommonOption> = [];

	private field = "";

	constructor(
		rootStore: any,
		field: string
	) {
		this.rootStore = rootStore;
		this.field = field;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = [];
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = []);

	@action get = () =>
		this.preFilter.length === 0 ||
			this.preFilter.length === this.preFilterOptions.length
			? []
			: this.preFilter.map((d: string) =>
				getFilterObject(this.field, d, "eq")
			);

	@action set = (preFilter: Array<string>) =>
		(this.preFilter = preFilter);

	baseGetOptions = async (setOptions: any, serviceGet: any) => {
		if (this.preFilterOptions.length > 0) {
			return;
		}

		this.loading = true;
		await serviceGet("ARG")
			.then((rs: any) => {
				setOptions(toJS(rs.data));
			})
			.finally(() => (this.loading = false));
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const dataFilters = this.get();

		if (dataFilters) {
			orFiltersGroups.push(dataFilters);
		}
	}

	@action hasPreFilter = () => this.preFilter.length > 0;
}

export default BasePreFilterStringArrayStore;
