import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../stores/RootStore";
import { toast } from "react-toastify";
import QuotationBatchEditionFields from "./QuotationBatchEditionFields";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import { groupsQuotationManager } from "../../../../constants/AuthGroups/quotationPage";
import BatchEdition from "../../../../components/BatchEdition/BatchEdition";

const QuotationEditionModal: React.FC = () => {
	const { t } = useTranslation(["general", "quotation"]);

	const {
		resetData,
		updateBatchData,
		formHasChanges,
		loadSalesmanOptions,
		loadCustomerOptions,
	} = useStores().quotationBatchEditionStore;
	const { isAuthorizedGroup } = useAuthStore();

	const isQuotationManagerUser = isAuthorizedGroup(groupsQuotationManager);

	const { listTotalResult } = useStores().listStore;
	const validListSize = listTotalResult > 0 && listTotalResult <= 80;

	const handleSubmit = () => {
		updateBatchData()
			.then(() =>
				toast.success(t("editRecordsWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data));
	};

	const loadOptions = React.useCallback(() => {
		resetData();
		if (isQuotationManagerUser) loadSalesmanOptions();
		loadCustomerOptions();
	}, [
		resetData,
		isQuotationManagerUser,
		loadSalesmanOptions,
		loadCustomerOptions,
	]);

	React.useEffect(() => {
		loadOptions();
	}, [loadOptions]);

	return (
		<BatchEdition
			id="quotation-batch-edition"
			title={t("batchEdition", { ns: "general" })}
			onUndo={resetData}
			onSubmit={handleSubmit}
			hasChanges={formHasChanges}
			hasItemsSelected={validListSize}
		>
			<QuotationBatchEditionFields />
		</BatchEdition>
	);
};

export default observer(QuotationEditionModal);
