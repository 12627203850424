import { TFunction } from "i18next";
import { getValueGrid as getFieldGrid } from "./PaymentTermsUtil";
import { IColumnProps } from "../components/kendo/DataGridApi/interfaces/IColumnProps";
import { getFollowUpArgProbabilityOptions } from "./enums/ArgDeliveryProbabilityEnum";
import { FOLLOWUP_MENU_ARG_BUS, FOLLOWUP_MENU_ARG_TRUCK } from "../constants/Menus/FollowUpMenuUtils";

const getPaqueteColumn = (t: TFunction<"order"[], undefined>, menu: string) => {
    if (menu === FOLLOWUP_MENU_ARG_BUS || menu === FOLLOWUP_MENU_ARG_TRUCK) {
        return [{
            field: "managementDetail.paquete",
            title: t("gridFields.paquete"),
            width: 130,
            sortable: false,
        }]
    }

    return [];
}

function getCommonColumns(
    t: TFunction<"order"[], undefined>,
    language: string,
    menu: string
): Array<IColumnProps> {
    return [
        {
            field: "managementDetail.billingForecast",
            title: t("gridFields.billingForecast"),
            width: 110,
            headerAlign: "center",
            canWrap: true,
            customCell: {
                type: "dateTimeCell",
                date: "managementDetail.billingForecast",
                format: "MMMM/yy",
            },
        },
        {
            field: "firmBilling",
            title: t("gridFields.firmBilling"),
            width: 100,
            alwaysVisible: true,
            headerAlign: "center",
            canWrap: true,
            customCell: {
                type: "booleanCell",
                value: "firmBilling",
            },
        },
        {
            field: "financialDelivery",
            title: t("gridFields.financialDelivery"),
            width: 100,
            alwaysVisible: true,
            headerAlign: "center",
            canWrap: true,
            customCell: {
                type: "booleanCell",
                value: "financialDelivery",
            },
        },
        {
            field: "physicalDelivery",
            title: t("gridFields.physicalDelivery"),
            width: 100,
            alwaysVisible: true,
            headerAlign: "center",
            canWrap: true,
            customCell: {
                type: "booleanCell",
                value: "physicalDelivery",
            },
        },
        {
            field: "finalDelivery",
            title: t("gridFields.finalDelivery"),
            width: 100,
            alwaysVisible: true,
            headerAlign: "center",
            canWrap: true,
            customCell: {
                type: "booleanCell",
                value: "finalDelivery",
            },
        },
        {
            field: "order.assemblyOrder",
            title: t("gridFields.ao"),
            width: 135,
            alwaysVisible: true,
            customCell: {
                type: "linkCell",
                to: "./",
                toParams: "order.id",
                value: "order.assemblyOrder",
            },
        },
        {
            field: "order.chassi",
            title: t("gridFields.chassi"),
            alwaysVisible: true,
            width: 80,
        },
        {
            field: "order.greenOk",
            title: t("gridFields.greenOk"),
            width: 90,
            customCell: {
                type: "dateTimeCell",
                date: "order.greenOk",
                format: "DD/MM/yy",
            },
        },
        {
            field: "order.businessControlVehicle",
            title: t("gridFields.bc_vehicle"),
            width: 110,
        },
        ...getPaqueteColumn(t, menu),
        {
            field: "order.asWeekDate",
            title: t("gridFields.asWeek"),
            width: 90,
            customCell: {
                type: "dateTimeCell",
                date: "order.asWeekDate",
                format: "DD/MM/yy",
            },
        },
        {
            field: "managementDetail.dealer.name",
            title: t("gridFields.dealerName"),
        },
        {
            field: "order.finalCustomer",
            title: t("gridFields.customerSAS"),
        },
        {
            field: "managementDetail.destination",
            title: t("gridFields.destination"),
        },
        {
            field: "managementDetail.currency",
            title: t("gridFields.currency"),
            width: 80,
        },
        {
            field: "managementDetail.finalValue",
            title: t("gridFields.finalValue"),
            width: 70,
            headerAlign: "center",
            customCell: {
                type: "numberCell",
                value: "managementDetail.finalValue",
                fractionDigits: 2,
            },
        },
        {
            field: getFieldGrid(language),
            title: t("gridFields.paymentForm"),
            width: 170,
        },
        {
            field: "managementDetail.shippingDate",
            title: t("gridFields.shippingDate"),
            width: 90,
            customCell: {
                type: "dateTimeCell",
                date: "managementDetail.shippingDate",
                format: "DD/MM/yy",
            },
        },
        {
            field: "managementDetail.certificateDate",
            title: t("gridFields.certificateDate"),
            width: 90,
            customCell: {
                type: "dateTimeCell",
                date: "managementDetail.certificateDate",
                format: "DD/MM/yy",
            },
        },
        {
            field: "localization",
            title: t("gridFields.localization"),
            width: 250,
        },
        {
            field: "managementDetail.salesSupportComments",
            title: t("gridFields.salesSupportComments"),
        },
        {
            field: "managementDetail.financialComments",
            title: t("gridFields.financialComments"),
        },
        {
            field: "managementDetail.commercialComments",
            title: t("gridFields.commercialComments"),
        },
        {
            field: "managementDetail.accountingComments",
            title: t("gridFields.accountingComments"),
        },
        {
            field: "managementDetail.logisticComments",
            title: t("gridFields.logisticComments"),
        },
    ];
}

export function getClosedPeriodGridColumns(
    t: TFunction<"order"[], undefined>,
    language: string,
    menu: string
): Array<IColumnProps> {
    return getCommonColumns(t, language, menu);
}

export function getFollowUpGridColumns(
    t: TFunction<"order"[], undefined>,
    language: string,
    menu: string
): Array<IColumnProps> {
    const commonColumns = getCommonColumns(t, language, menu);

    const additionalColumns = [
        {
            field: "order.id",
            title: "",
            alwaysVisible: true,
            width: 40,
            locked: true,
            customCell: {
                type: "updateCell",
                key: "order.id",
            },
        },
        {
            field: "deliveryProbability",
            title: " ",
            alwaysVisible: true,
            width: 28,
            locked: true,
            customCell: {
                type: "optionCell",
                value: "deliveryProbability",
                options: getFollowUpArgProbabilityOptions(t),
                badge: "circle",
            },
        },
    ];

    return [...additionalColumns, ...commonColumns];
}