import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeDownloadCenterMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.CHL_MARKET_COMPANY_SALESMAN,
		AuthGroups.CHL_MARKET_COMPANY_SALESMAN_MANAGER,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_BUS,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.MEX_SALES_SUPPORT,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
		AuthGroups.MEAC_SALES_SUPPORT,
		AuthGroups.PI_SALES_SUPPORT,
		AuthGroups.GENERAL_VDB_VIEWER,
		...AuthGroups.BRA_DEALERS,
		...AuthGroups.ARG_ALL_USERS,
	];
