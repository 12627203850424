import RootStore from "../stores/RootStore";

export function newRootStore(): RootStore {
	return {
		listStore: {},
		filterStore: {},
		batchEditionStore: {},
		preFilterDeliveryProbabilityStore: {},
		preFilterPrevisionBillingMonthStore: {},
		preFilterFirmBillingStore: {},
		preFilterFinancialDeliveryStore: {},
		preFilterPhysicalDeliveryStore: {},
		preFilterFinalDeliveryStore: {},
		preFilterDealerStore: {},
		preFilterPaqueteStore: {},
		preFilterDestinationStore: {},
		preFilterCurrencyStore: {},
		preFilterPaymentFormStore: {},
		preFilterAsWeekDateStore: {},
		preFilterGokDateStore: {},
		preFilterShippingDateStore: {},
		preFilterCertificateDateStore: {},
		preFilterVehicleLocationStore: {},
		preFilterChassiAOStore: {},
		preFilterBusinessControlVehicleStore: {},
		preFilterCustomerStoreOM: {},
		preFilterCustomerStoreSAS: {},
	};
}
