import { BaseReadOnlyProps } from "../interfaces/BaseReadOnlyDetails";
import { orderMCDeliveryReadOnlyModel } from "../interfaces/OrderMCDeliveryReadOnlyModel";
import { orderMCManagementReadOnlyModel } from "../interfaces/OrderMCManagementReadOnlyModel";

export const baseReadOnlyModel: BaseReadOnlyProps = {
	id: "",
	factoryOrder: "",
	foDealerRegion: "",
	chassi: "",
	factoryOrderDistrict: "",
	saleStatus: "",
	foOriginalCustomerOM: "",
	lastUpdateEventTypeName: "",
	productionModel: "",
	lineCode: "",
	commercialModel: "",
	class: "",
	vehicleIdentificationNumber: "",
	engineSerialNumber: "",
	variants: [],
	dealerName: "",
	vehicleLocationName: "",

	bodybuilder: "",
	salesman: "",
	finalCustomer: "",
	branchOffice: "",
	generalComment: "",
	orderMCDelivery: orderMCDeliveryReadOnlyModel,
	orderMCManagement: orderMCManagementReadOnlyModel,

	lcd: null,
	cdd: null,
	cddeol: null,
	online: null,
	offline: null,
	asWeekDate: null,
	greenOk: null,
	lineSequenceDate: null,
	lineSequence: "",
	onlineDelay: 0,
	asWeekDelay: 0,
	asWeekDateInitial: null,
};

export function baseReadOnlyModelBuilder(data: BaseReadOnlyProps) {
	if (!data) return baseReadOnlyModel;
	return {
		...baseReadOnlyModel,
		...data,
		id: data.id ?? "",
		factoryOrder: data.factoryOrder ?? "",
		foDealerRegion: data.foDealerRegion ?? "",
		chassi: data.chassi ?? "",
		factoryOrderDistrict: data.factoryOrderDistrict ?? "",
		saleStatus: data.saleStatus ?? "",
		foOriginalCustomerOM: data.foOriginalCustomerOM ?? "",
		lastUpdateEventTypeName: data.lastUpdateEventTypeName ?? "",
		productionModel: data.productionModel ?? "",
		lineCode: data.lineCode ?? "",
		commercialModel: data.commercialModel ?? "",
		class: data.class ?? "",
		vehicleIdentificationNumber: data.vehicleIdentificationNumber ?? "",
		engineSerialNumber: data.engineSerialNumber ?? "",
		dealerName: data.dealerName ?? "",
		vehicleLocationName: data.vehicleLocationName ?? "",
	} as BaseReadOnlyProps;
}
