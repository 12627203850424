import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/RootStore";
import { observer } from "mobx-react";
import ClearRecordItem from "../../../../../../components/BatchEdition/ClearRecordItem";
import { followUpArgKeyLabels } from "../../../utils/BatchEditionUtils";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { NumberFormProps } from "../../../../../../utils/GenericInterfaces";
import { maxValueValidator } from "../../../../../../utils/FormValidators";
import { toast } from "react-toastify";

const ComissionForm: React.FC = () => {
	const { t } = useTranslation("order");

	const { batchData, setOrderMCManagementDetailBatchEditByKey } =
		useStores().batchEditionStore;

	const onChange = (obj: NumberFormProps, key: string) => {
		if (obj.value && !maxValueValidator(obj.value, 15)) {
			toast.error(t("validator.maxNumber", { max: 15, ns: "general" }));
			return;
		}
		setOrderMCManagementDetailBatchEditByKey(obj, key);
	};
	return (
		<ClearRecordItem
			recordTitle={t(followUpArgKeyLabels.comission.label)}
			checked={batchData.orderMCManagementDetail.comission.clearValue}
			onClearRecord={setOrderMCManagementDetailBatchEditByKey}
			recordKey={followUpArgKeyLabels.comission.key}
		>
			<TextForm
				label={t(followUpArgKeyLabels.comission.label)}
				value={batchData.orderMCManagementDetail.comission.value ?? ""}
				slotProps={{
					htmlInput: {
						step: 2,
					},
				}}
				editable
				onChange={(e) =>
					onChange(
						{
							value: parseFloat(parseFloat(e.target.value).toFixed(2)),
							clearValue: false,
						},
						followUpArgKeyLabels.comission.key
					)
				}
				disabled={batchData.orderMCManagementDetail.comission.clearValue}
				type="number"
			/>
		</ClearRecordItem>
	);
};

export default observer(ComissionForm);
