import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";
import { EuropeMenus } from "../../../constants/Menus/OrderMenuUtils";
import { getMultiValueFilterSplitter } from "../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class BasePreFilterChassiAOStore {
    @observable preFilter: string = "";
    @observable rootStore;
	@observable errorMessage: string = "";
	@observable hasFormatError: boolean = false;

	constructor(rootStore: any) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

    @action clean = () => this.reset();

    @action reset = () => {
		this.preFilter = "";
		this.errorMessage = "";
		this.setHasFormatError(false);
	};

    @action setHasFormatError = (value: boolean) => {
		this.hasFormatError = value;
		this.rootStore.filterStore.setHasFormatError(this.hasFormatError);
	};

    @action setErrorMessage = (value: string) => (this.errorMessage = value);

    setBase = (value: string, getFilterBySize: any) => {
		if (value !== "") {
			this.rootStore.filterStore.hasPreFiltersApplied = true;
		}

		this.preFilter = value;
		let preChassiOrAoSplited: string[] = [];

		if (!this.preFilter) {
			this.setHasFormatError(false);
			return;
		}

		if (this.preFilter.includes(" ")) {
			preChassiOrAoSplited = this.preFilter.split(" ");
		} else if (this.preFilter.includes(",")) {
			preChassiOrAoSplited = this.preFilter.split(",");
		} else {
			preChassiOrAoSplited = [this.preFilter];
		}

		let hasError = false;
		preChassiOrAoSplited.forEach((chassiOrAo) => {
            if (this.isEuropeMenu() && chassiOrAo.length === 7) {
				hasError = true;
				return;
			}

			const filterObj = getFilterBySize(chassiOrAo);

			if (!filterObj || !filterObj.length || !filterObj[0].field) {
				hasError = hasError || true;
			}
			else {
				hasError = hasError || false;
			}
		});

		this.setHasFormatError(hasError);
	};
    
	getFilterDescriptorBase(andFilters: Array<FilterDescriptor>, getFilterBySize: any) {
		andFilters = andFilters.concat(getFilterBySize(this.preFilter));

		return andFilters;
	}

    getFilterDescriptorBySizeBase = (
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>,
        getFilterBySize: any
	) => {
		const splitter = getMultiValueFilterSplitter(this.preFilter);
		if (!splitter) {
			andFilters = this.getFilterDescriptorBase(andFilters, getFilterBySize);
		}
		else {
			this.getMultiValueFilterDescriptor(orFiltersGroups, splitter, getFilterBySize);
		}

		return andFilters;
	};

    isEuropeMenu = () => EuropeMenus.some((x) => x === this.rootStore.listStore.menu);

	getMultiValueFilterDescriptor(
		orFiltersGroups: Array<Array<FilterDescriptor>>,
		splitBy: string,
        getFilterBySize: any
	) {
		if (this.preFilter === "") {
			return;
		}

		const splittedFilter = this.preFilter.split(splitBy);

		if (splittedFilter.every((x) => x.length === 0)) {
			return;
		}

		const filters = splittedFilter.map((x: string) => {
			return getFilterBySize(x).shift() as FilterDescriptor;
		});

		if (filters) {
			orFiltersGroups.push(filters);
		}
	}
}

export default BasePreFilterChassiAOStore;
