import moment from "moment";
import { BatchDataProps } from "../../interfaces/BatchEdition/BatchDataProps";
import { customerDataModel } from "./CustomerDataModel";
import { quotaDataModel } from "./QuotaDataModel";
import { volvoManagementDataModel } from "./VolvoManagementDataProps";
import { stringFormModel } from "../../../../../utils/GenericModels";

export const batchDataModel: BatchDataProps = {
	quotaData: quotaDataModel,
	volvoManagementData: volvoManagementDataModel,
	customerData: customerDataModel,
	generalComment: stringFormModel,
	finalCustomer: stringFormModel,
	userEmail: "",
	updateDate: moment().toDate(),
};
