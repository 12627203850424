import CtadmvenApi from "../../apis/CtadmvenApi";
import { ExportPartnerProps } from "../../pages/ExportPartner/interface/ExportPartnerProps";

const base = "/ExportPartner";

interface IExportPartnerService {
	getList(): Promise<[ExportPartnerProps]>;
}

export class ExportPartnerService implements IExportPartnerService {
	constructor(private api: CtadmvenApi) {}

	getList = async (): Promise<[ExportPartnerProps]> =>
		this.api.ctadmvenClient.get(`${base}/List`);

	createOrUpdate = async (record: ExportPartnerProps) =>
		this.api.ctadmvenClient.put(`${base}/Update`, record);

	delete = async (exportPartnerId: string) =>
		this.api.ctadmvenClient.delete(
			`${base}/Delete?exportPartnerId=${exportPartnerId}`
		);
}
