import { TFunction } from "i18next";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import {
	BusMenus,
	MarketCompanyMenus,
	OrderBookBusMenuType,
} from "../../../../constants/Menus/OrderMenuUtils";

export function getBusGridColumns(
	t: TFunction<"order", undefined>,
	menu: OrderBookBusMenuType
): Array<IColumnProps> {
	return [
		{
			menus: [...BusMenus],
			field: "factoryOrderYearSequence",
			title: t("gridFields.factoryOrder"),
			alwaysVisible: true,
			width: 100,
			customCell: {
				type: "linkCell",
				to: "./",
				toParams: "order.id",
				value: "factoryOrderYearSequence",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.factoryOrderDistrict",
			title: t("gridFields.factoryOrderDistrict"),
			alwaysVisible: true,
			width: 80,
		},
		{
			menus: [...BusMenus],
			field: "order.chassi",
			title: t("gridFields.chassi"),
			alwaysVisible: true,
			width: 60,
		},
		{
			menus: [...BusMenus],
			field: "order.foDealerRegion",
			title: t("gridFields.region"),
			alwaysVisible: true,
			width: 50,
		},
		{
			menus: [...BusMenus],
			field: "order.productionModel",
			title: t("gridFields.productionModel"),
			alwaysVisible: true,
		},
		{
			menus: [...BusMenus],
			field: "order.commercialModel",
			title: t("gridFields.commercialModel"),
			alwaysVisible: true,
		},
		{
			menus: [...BusMenus],
			field: "order.modelYear",
			title: t("gridFields.modelYear"),
		},
		{
			menus: [...BusMenus],
			field: "variant.engineEmission",
			title: t("gridFields.engineEmission"),
		},
		{
			menus: [...BusMenus],
			field: "order.lcd",
			title: t("gridFields.lcd"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.lcd",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.productionDate",
			title: t("gridFields.productionDate"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.productionDate",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.asWeekDate",
			title: t("gridFields.asWeek"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.asWeekDate",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.lineSequenceDate",
			title: t("gridFields.lineSequenceDate"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.lineSequenceDate",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.greenOk",
			title: t("gridFields.greenOk"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.greenOk",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.cdd",
			title: t("gridFields.cdd"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.cdd",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.bodyBuilder",
			title: t("gridFields.bodyBuilder"),
		},
		{
			menus: [...BusMenus],
			field: "variant.retarder",
			title: t("gridFields.retarder"),
		},
		{
			menus: [...BusMenus],
			field: "variant.gearbox",
			title: t("gridFields.gearbox"),
		},
		{
			menus: [...BusMenus],
			field: "variant.tyreBrand",
			title: t("gridFields.tyreBrand"),
		},
		{
			menus: [...BusMenus],
			field: "variant.suspension",
			title: t("gridFields.suspension"),
		},
		{
			menus: [...BusMenus],
			field: "variant.axle",
			title: t("gridFields.axle"),
		},
		{
			menus: [...BusMenus],
			field: "order.foOriginalCustomerOM",
			title: t("gridFields.customer"),
		},
		{
			menus: [...BusMenus],
			field: "generalComment",
			title: t("gridFields.generalComment"),
			minWidth: 150,
		},
		{
			menus: [...BusMenus],
			field: "invoiceInstructionCreationDate",
			title: t("gridFields.if"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "invoiceInstructionCreationDate",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "invoiceSaleDate",
			title: t("gridFields.invoiceSaleDate"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "invoiceSaleDate",
				format: "DD/MM/YY",
			},
		},
		{
			menus: [...BusMenus],
			field: "order.salesman",
			title: t("gridFields.salesman"),
		},
		{
			menus: [...MarketCompanyMenus],
			field: "order.exportationStatus",
			title: t("gridFields.stockStatus"),
		},
	].filter((f: IColumnProps) => f.menus && f.menus.includes(menu));
}
