import React from "react";
import { useTranslation } from "react-i18next";
import {
	getSummaryDraggableData,
	ProgressSummaryModelProps,
} from "../../models/Summary/ProgressSummaryModel";
import { observer } from "mobx-react";
import SummaryDraggableCard from "../../../../../components/SummaryDraggableCard/SummaryDraggableCard";
import { VehicleType } from "../../../../../utils/GenericTypes";
import { useAuthStore } from "../../../../../auth/store/AuthStore";

interface ProgressSummaryProps {
	progressSummary: Array<ProgressSummaryModelProps>;
	loading: boolean;
	getProgressSummary: () => void;
	refresh: number;
	vehicleType: VehicleType;
}

const ProgressSummary: React.FC<ProgressSummaryProps> = ({
	progressSummary,
	loading,
	getProgressSummary,
	refresh,
	vehicleType,
}) => {
	const { t } = useTranslation("order");
	const { isAuthorizedArgDealerGroup } = useAuthStore();
	const isArgDealer = isAuthorizedArgDealerGroup();

	const fetch = React.useCallback(async () => {
		getProgressSummary();
	}, [getProgressSummary]);

	React.useEffect(() => {
		fetch();
	}, [fetch, refresh]);

	const dataTransformed = getSummaryDraggableData(progressSummary, isArgDealer);
	const headers = [
		{ value: isArgDealer ? " " : t("gridFields.dealerName") },
		{ value: t("gridFields.firmBilling") },
		{ value: t("gridFields.financialDelivery") },
		{ value: t("gridFields.physicalDelivery") },
		{ value: t("gridFields.finalDelivery") },
	];

	return (
		<SummaryDraggableCard
			id={`follow-up-arg-${vehicleType}-progress-summary`}
			width={720}
			headers={headers}
			data={dataTransformed.dataTransformed}
			footerHeader={`${t("monthAdvance")}`}
			footerData={dataTransformed.total}
			loading={loading}
		/>
	);
};

export default observer(ProgressSummary);
