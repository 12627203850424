import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import { PriceAndPaymentAccordionProps } from "../../interfaces/Accordions/PriceAndPaymentAccordion";
import PriceAndPaymentSection from "../Sections/PriceAndPaymentSection";
import PaymentTermsSection from "../Sections/PaymentTermsSection";
import GlosaSection from "../Sections/GlosaSection";
import PaymentTermRetomaSection from "../Sections/PaymentTermRetomaSection";

const PriceAndPaymentAccordion: React.FC<PriceAndPaymentAccordionProps> = (
  details
) => {
  const { t } = useTranslation("quotation");

	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.priceAndPayments")}
		>
			<PriceAndPaymentSection {...details.priceAndPaymentDetails} />
			<PaymentTermsSection {...details.paymentTermsSectionProps} />
			<PaymentTermRetomaSection {...details.paymentTermRetomaSectionProps} />
			<GlosaSection {...details.glosaSectionProps} />
		</AccordionCard>
	);
};

export default observer(PriceAndPaymentAccordion);
