import { CTADMVEN_QUOTATION } from "../../constants/routes";
import { MenuRouteProps } from "../../utils/GenericInterfaces";
import { AppRouteProps } from "../routes";
import QuotationIndex from "./List/containers/QuotationIndex";
import { groupsAllowedToSeeQuotationMenu } from "../../constants/AuthGroups/quotationPage";
import { getRouteListAndDetails } from "../../utils/RoutesUtil";
import { ReceiptLong } from "@mui/icons-material";
import QuotationDetailIndex from "./Details/container/QuotationDetailIndex";

export const quotationMenuRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTATION,
	claims: groupsAllowedToSeeQuotationMenu,
	element: <QuotationIndex />,
	label: "quotation.title",
	icon: <ReceiptLong />,
	isCollapsable: false,
};

export const quotationRoutes: Array<AppRouteProps> = getRouteListAndDetails(
	quotationMenuRoute,
	<QuotationDetailIndex />
);
