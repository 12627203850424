import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import logoImage from "../../../assets/volvo-wordmark-black-114.png";
import UserMenu from "../UserMenu";

interface NavBarProps extends MuiAppBarProps {
	open?: boolean;
	menuName?: string;
	actions?: React.ReactNode;
	userName?: string;
	userJobTitle?: string;
	signOutEvent?: React.MouseEventHandler<HTMLDivElement>;
	isOwnerUser?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<NavBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	backgroundColor: "#fff",
	...(open && {
		marginLeft: 240,
		width: `calc(100% - ${240}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const NavBar: React.FC<NavBarProps> = ({
	menuName,
	actions,
	userName,
	userJobTitle,
	signOutEvent,
	isOwnerUser,
}) => {
	return (
		<Box sx={{ display: "flex" }}>
			<AppBar position="fixed">
				<Toolbar
					sx={{
						pr: "24px", // keep right padding when drawer closed
					}}
				>
					<Box
						width={240}
						sx={{
							display: "flex",
							justifyItems: "center",
							alignItems: "center",
						}}
					>
						<img src={logoImage} alt="volvo logo" />
					</Box>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						sx={{ flexGrow: 1 }}
					>
						{menuName}
					</Typography>
					{actions}
					<UserMenu
						userName={userName}
						userJobTitle={userJobTitle}
						signOutEvent={signOutEvent}
						isOwnerUser={isOwnerUser}
					/>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default NavBar;
