import CtadmvenApi from "../../apis/CtadmvenApi";
import { VolvoManagementEditableProps } from "../../pages/Order/Details/interfaces/Editable/VolvoManagementEditableDetails";
import { VolvoManagementCommentProps } from "../../pages/Order/Details/interfaces/VolvoManagementCommentDetails";

interface IVolvoManagementService {
	getByOrderId(id: string, menuKey: string): Promise<any>;
	update(
		volvoManagementDetails: VolvoManagementEditableProps,
		menuKey: string
	): Promise<any>;
	updateCommercialComments(
		commercialCommentArray: VolvoManagementCommentProps[],
		menuKey: string,
		id: string
	): Promise<any>;
	getCommercialCommentsByOrderId(id: string, menuKey: string): Promise<any>;
}
const base = "/volvoManagement";

export class VolvoManagementService implements IVolvoManagementService {
	constructor(private api: CtadmvenApi) {}

	getByOrderId = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${menuKey}/${id}`);

	update = async (
		volvoManagementDetails: VolvoManagementEditableProps,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/${menuKey}`,
			JSON.stringify(volvoManagementDetails)
		);

	updateCommercialComments = async (
		commercialCommentArray: VolvoManagementCommentProps[],
		menuKey: string,
		id: string
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/${menuKey}/${id}/CommercialComment`,
			JSON.stringify(commercialCommentArray)
		);

	getCommercialCommentsByOrderId = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${menuKey}/${id}/CommercialComments`);
}
