import {
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	Button,
	Stack,
	Grid,
	Typography,
	Tooltip,
	Chip,
} from "@mui/material";
import { useCsvQueueManagerStore } from "../stores/CsvQueueManagerStore";
import ICsvFileName from "../interfaces/ICsvFileName";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import DateTimeSpan from "../../../../components/DateTimeSpan/DateTimeSpan";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import "../styles/DownloadCard.css";
import IconText from "./IconText";
import { History } from "@mui/icons-material";
import { CsvFileStatus } from "../enums/CsvFileStatus";
import PendingIcon from "@mui/icons-material/Pending";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ErrorIcon from "@mui/icons-material/Error";
import {
	getMenuReportName,
	getRoleText,
	getSubMenuName,
} from "../../../../utils/downloadCsv";

interface DownloadCardProps {
	file: ICsvFileName;
	i: number;
}

const DownloadCard: React.FC<DownloadCardProps> = (props) => {
	const { t } = useTranslation("downloadCenter");
	const store = useCsvQueueManagerStore();

	const handleSubmit = async () => {
		store.downloadFile(
			props.file,
			t(getMenuReportName(props.file.origin)),
			t(getSubMenuName(props.file.menuKey))
		);
	};

	const getStatusIcon = () => {
		switch (props.file.status) {
			case CsvFileStatus.Pending:
				return <PendingIcon color="secondary" />;
			case CsvFileStatus.Processing:
				return <RunningWithErrorsIcon color="info" />;
			case CsvFileStatus.Completed:
				return <AddTaskIcon color="success" />;
			case CsvFileStatus.Failed:
				return <ErrorIcon color="error" />;
			default:
				return <></>;
		}
	};

	const getStatusText = () => {
		switch (props.file.status) {
			case CsvFileStatus.Pending:
				return t("card.status.pending");
			case CsvFileStatus.Processing:
				return t("card.status.processing");
			case CsvFileStatus.Completed:
				return t("card.status.completed");
			case CsvFileStatus.Failed:
				return t("card.status.failed");
			default:
				return <></>;
		}
	};

	return (
		<Card className="download-card">
			<CardContent>
				<Grid container style={{ position: "relative" }}>
					<Grid item alignSelf="center" xs>
						<Stack
							direction="column"
							alignSelf="center"
							spacing={t(getSubMenuName(props.file.menuKey)) ? 2 : 3}
						>
							<IconText
								text={t(getRoleText(props.file.role))}
								title={t("card.requested-by")}
								icon={<AccountBoxIcon />}
							/>
							<IconText
								text={t(getMenuReportName(props.file.origin))}
								title={t("card.menu-origin")}
								icon={<MenuOpenIcon />}
							/>
							{t(getSubMenuName(props.file.menuKey)) && (
								<IconText
									text={t(getSubMenuName(props.file.menuKey))}
									title={t("card.menu-key")}
									icon={<TripOriginIcon />}
								/>
							)}
						</Stack>
					</Grid>

					<Grid item xs>
						<Stack direction="column" alignSelf="center" spacing={1}>
							<Typography mt={2}></Typography>
							<IconText
								text={
									<DateTimeSpan
										date={props.file.timestamp}
										format={"DD/MM/YYYY HH:mm:ss"}
										defaultValue="-"
										convertUtc
									/>
								}
								title={t("card.created-at")}
								icon={<History />}
							/>
							<FormControlLabel
								control={<Checkbox disabled checked={props.file.hasFilters} />}
								label={t("card.filters")}
							/>
							<FormControlLabel
								control={<Checkbox disabled checked={props.file.hasVariants} />}
								label={t("card.variants")}
							/>
						</Stack>
					</Grid>

					<Grid item xs>
						<Stack direction="column" alignSelf="center" spacing={3}>
							<Typography mt={2}></Typography>
							<Stack direction="row" spacing={1} paddingLeft={2}>
								<Tooltip title={getStatusText()} arrow>
									{getStatusIcon()}
								</Tooltip>
							</Stack>
							<Stack direction="row" spacing={1}>
								<Tooltip title={t("card.download")} arrow>
									<Button
										disabled={props.file.status !== CsvFileStatus.Completed}
										onClick={handleSubmit}
									>
										<FileDownloadIcon
											fontSize="medium"
											color="action"
											className={props.file.menuKey}
											id={
												props.file.isAutogenerated
													? `download-autogenerated-${props.i}`
													: `download-${props.i}`
											}
										/>
									</Button>
								</Tooltip>
							</Stack>
						</Stack>
					</Grid>
					{props.file.isAutogenerated && (
						<Stack direction="row" spacing={1} className="autogenerated-tag">
							<Chip
								label={t("card.autogenerated")}
								color="warning"
								id={`info-is-autogenerated-${props.i}`}
							/>
						</Stack>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default observer(DownloadCard);
