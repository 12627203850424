import React from "react";
import { configure } from "mobx";
import PreFilterLineStore from "../components/PreFilters/stores/PreFilterLineStore";
import PreFilterDeliveryProposalStore from "../components/PreFilters/stores/PreFilterDeliveryProposalStore";
import PreFilterMonthAvailabilityStore from "../components/PreFilters/stores/PreFilterMonthAvailabilityStore";
import PreFilterChassiAOStore from "../components/PreFilters/stores/PreFilterChassiAOStore";
import ListStore from "./ListStore";
import FilterStore from "./FilterStore";
import PreFilterBusApplicationStore from "../components/PreFilters/stores/PreFilterBusApplicationStore";
import PreFilterRegionStore from "../components/PreFilters/stores/PreFilterRegionStore";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import PreFilterCountryStore from "../components/PreFilters/stores/PreFilterCountryStore";
import BatchEditionStore from "./BatchEditionStore";
import PreFilterAsWeekStore from "../components/PreFilters/stores/PreFilterAsWeekStore";
import PreFilterCommercialModelStore from "../components/PreFilters/stores/PreFilterCommercialModelStore";
import PreFilterLineSequenceDateStore from "../components/PreFilters/stores/PreFilterLineSequenceDateStore";
import PreFilterCustomerStoreOM from "../components/PreFilters/stores/PreFilterCustomerStoreOM";
import PreFilterCustomerStoreSAS from "../components/PreFilters/stores/PreFilterCustomerStoreSAS";

configure({ enforceActions: "always" });

class RootStore {
	filterStore: any = {};
	listStore: any = {};
	batchEditionStore: any = {};
	preFilterChassiAOStore: any = {};
	preFilterLineStore: any = {};
	preFilterDeliveryProposalStore: any = {};
	preFilterMonthAvailabilityStore: any = {};
	preFilterBusApplicationStore: any = {};
	preFilterRegionStore: any = {};
	preFilterCountryStore: any = {};
	preFilterAsWeekStore: any = {};
	preFilterCommercialModelStore: any = {};
	preFilterLineSequenceDateStore: any = {};
	preFilterCustomerStoreOM: any = {};
	preFilterCustomerStoreSAS: any = {};

	constructor() {
		const api = new CtadmvenApi();

		this.filterStore = new FilterStore(this);
		this.listStore = new ListStore(this, api);
		this.batchEditionStore = new BatchEditionStore(this, api);
		this.preFilterChassiAOStore = new PreFilterChassiAOStore(this);
		this.preFilterLineStore = new PreFilterLineStore(this, api);
		this.preFilterDeliveryProposalStore = new PreFilterDeliveryProposalStore(
			this
		);
		this.preFilterMonthAvailabilityStore = new PreFilterMonthAvailabilityStore(
			this
		);
		this.preFilterBusApplicationStore = new PreFilterBusApplicationStore(this);
		this.preFilterRegionStore = new PreFilterRegionStore(this, api);
		this.preFilterCountryStore = new PreFilterCountryStore(this, api);
		this.preFilterAsWeekStore = new PreFilterAsWeekStore(this);
		this.preFilterCommercialModelStore = new PreFilterCommercialModelStore(
			this
		);
		this.preFilterCustomerStoreSAS = new PreFilterCustomerStoreSAS(this);
		this.preFilterCustomerStoreOM = new PreFilterCustomerStoreOM(this);
		this.preFilterLineSequenceDateStore = new PreFilterLineSequenceDateStore(
			this
		);
	}
}
const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);
export default RootStore;
