import { action, configure, observable } from "mobx";
import { FilterDescriptor, } from "@progress/kendo-data-query";
import RootStore from "./RootStore";
import { SelectOptionProps } from "../../../../utils/GenericInterfaces";
import BaseFilterStore from "../../../../components/PreFilter/Stores/BaseFilterStore";

configure({ enforceActions: "always" });

class FilterStore extends BaseFilterStore {
	@observable modalOpen = false;

	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	@action setModalOpen = () => (this.modalOpen = true);
	@action setModalClosed = () => (this.modalOpen = false);

	cleanSpecificPreFilters = () => {
		this.rootStore.preFilterDeliveryProbabilityStore.clean();
		this.rootStore.preFilterPrevisionBillingMonthStore.clean();
		this.rootStore.preFilterFirmBillingStore.clean();
		this.rootStore.preFilterFinancialDeliveryStore.clean();
		this.rootStore.preFilterPhysicalDeliveryStore.clean();
		this.rootStore.preFilterFinalDeliveryStore.clean();
		this.rootStore.preFilterDealerStore.clean();
		this.rootStore.preFilterPaqueteStore.clean();
		this.rootStore.preFilterDestinationStore.clean();
		this.rootStore.preFilterCurrencyStore.clean();
		this.rootStore.preFilterPaymentFormStore.clean();
		this.rootStore.preFilterAsWeekDateStore.clean();
		this.rootStore.preFilterGokDateStore.clean();
		this.rootStore.preFilterShippingDateStore.clean();
		this.rootStore.preFilterCertificateDateStore.clean();
		this.rootStore.preFilterVehicleLocationStore.clean();
		this.rootStore.preFilterChassiAOStore.clean();
		this.rootStore.preFilterBusinessControlVehicleStore.clean();
		this.rootStore.preFilterCustomerStoreSAS.clean();
		this.rootStore.preFilterCustomerStoreOM.clean();
	};

	@action setPreFiltersCheckedOptions = (options: Array<SelectOptionProps>) => {
		this.rootStore.preFilterFirmBillingStore.setOptions(options);
		this.rootStore.preFilterFinancialDeliveryStore.setOptions(options);
		this.rootStore.preFilterPhysicalDeliveryStore.setOptions(options);
		this.rootStore.preFilterFinalDeliveryStore.setOptions(options);
	};

	applySpecificFilters(
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>): any {
		andFilters =
			this.rootStore.preFilterDeliveryProbabilityStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterPrevisionBillingMonthStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterFirmBillingStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterFinancialDeliveryStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterPhysicalDeliveryStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterFinalDeliveryStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterDestinationStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterCurrencyStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterGokDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterAsWeekDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterShippingDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterCertificateDateStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterCustomerStoreSAS.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterCustomerStoreOM.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterBusinessControlVehicleStore.getFilterDescriptor(
				andFilters
			);

		//multi options
		this.rootStore.preFilterPaqueteStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterDealerStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterPaymentFormStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterVehicleLocationStore.getFilterDescriptor(
			orFiltersGroups
		);

		andFilters =
			this.rootStore.preFilterChassiAOStore.getFilterDescriptorBySize(
				andFilters,
				orFiltersGroups
			);

		return { andFilters, orFiltersGroups };
	};
}

export default FilterStore;
