import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import {
	truckReadOnlyModel,
	truckReadOnlyModelBuilder,
} from "../models/TruckReadOnlyModel";
import { BusReadOnlyProps } from "../interfaces/BusReadOnlyDetails";
import {
	busReadOnlyModel,
	busReadOnlyModelBuilder,
} from "../models/BusReadOnlyModel";
import React from "react";
import { VehicleType } from "../../../../utils/GenericTypes";
import { orderMCDeliveryReadOnlyModelBuilder } from "../interfaces/OrderMCDeliveryReadOnlyModel";
import { orderMCManagementReadOnlyModelBuilder } from "../interfaces/OrderMCManagementReadOnlyModel";
import { OrderMCManagementEditableProps } from "../containers/Editable/OrderMCManagementEditable";
import _ from "lodash";
import { ClosedPeriodArgEditableProps } from "../interfaces/Editable/ClosedPeriodArgEditable";
import { closedPeriodArgEditableModel } from "../models/Editable/ClosedPeriodArgEditableModel";
import { orderMCManagementEditableModelBuilder } from "../models/Editable/OrderMCManagementEditableModel";
import { StoreUtils } from "../../../../utils/StoreUtils";

configure({ enforceActions: "always" });

class ClosedPeriodArgStore {
	@observable readOnlyDetails: TruckReadOnlyProps | BusReadOnlyProps =
	truckReadOnlyModel;

	api: CtadmvenApi;

	@observable vehicleType: VehicleType = "Unknown";
	@observable editableDetails: ClosedPeriodArgEditableProps =
	closedPeriodArgEditableModel;
	@observable initialEditableDetails: ClosedPeriodArgEditableProps =
	closedPeriodArgEditableModel;
	@observable hasChanges: boolean = false;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.readOnlyDetails = truckReadOnlyModel;
		this.editableDetails = closedPeriodArgEditableModel;
		this.initialEditableDetails = closedPeriodArgEditableModel;
		this.hasChanges = false;
	};

	@action private setTruckReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = truckReadOnlyModelBuilder({
			...truckReadOnlyModel,
			...data,
		});
	};

	@action private setBusReadOnlyDetailsBus = (data: any) => {
		this.readOnlyDetails = busReadOnlyModelBuilder({
			...busReadOnlyModel,
			...data,
		});
	};

	@action private setOrderMCDeliveryDetails = (data: any) => {
		this.readOnlyDetails.orderMCDelivery = orderMCDeliveryReadOnlyModelBuilder({
			...data,
		});
	};

	@action private setOrderMCManagementDetails = (data: any) => {
		this.readOnlyDetails.orderMCManagement =
			orderMCManagementReadOnlyModelBuilder({ ...data });
		this.editableDetails.orderMCManagement =
			orderMCManagementEditableModelBuilder({ ...data });
		this.initialEditableDetails.orderMCManagement =
			orderMCManagementEditableModelBuilder({ ...data });
	};

	@action loadAllDetails = async (
		orderId: string,
		vehicleType: VehicleType
	) => {
		this.resetStore();

		this.vehicleType = vehicleType;

		if (vehicleType === "Truck") await this.loadDetails(orderId);
		else await this.loadDetailsBus(orderId);

		await this.loadOrderMktCompanyManagement();
		await this.loadOrderMktCompanyDelivery();
	};

	@action private loadDetails = async (orderId: string) => {
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetails(orderId),
			this.setTruckReadOnlyDetails,
			orderId,
		);
	};

	@action private loadDetailsBus = async (orderId: string) => {
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsBus(orderId),
			this.setBusReadOnlyDetailsBus,
			orderId,
		);
	};

	@action loadVariants = async () => {
		const orderId = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsVariants(orderId),
			this.setVariants,
			orderId,
		);
	};

	@action loadVariantsBus = async () => {
		const orderId = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsVariantsBus(orderId),
			this.setVariants,
			orderId
		);
	};

	@action private loadOrderMktCompanyDelivery = async () => {
		const orderId = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsMCDelivery(orderId),
			this.setOrderMCDeliveryDetails,
			orderId,
		);
	};

	@action private loadOrderMktCompanyManagement = async () => {
		const orderId = this.readOnlyDetails.id;

		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsMCManagement(orderId),
			(data: any) => {
				this.setTruckReadOnlyDetails({
					...this.readOnlyDetails,
					...data,
				});
				this.setOrderMCManagementDetails(data);
			},
			orderId,
		)
	};

	@action openClosedPeriod = async () => {
		await this.api.closedPeriodService.openClosedPeriod(
			this.readOnlyDetails.id
		);
	};

	@action setOrderMCManagementFieldByKeyOnChange = <
		K extends keyof OrderMCManagementEditableProps,
	>(
		fieldKey: K,
		newValue: OrderMCManagementEditableProps[K],
		hasErrors?: boolean
	) => {
		if (hasErrors) return;
		const _editableDetails: OrderMCManagementEditableProps = Object.assign(
			{},
			this.editableDetails.orderMCManagement
		);
		_editableDetails[fieldKey] = newValue;
		this.editableDetails.orderMCManagement = _editableDetails;
		this.checkChanges();
	};

	@action private checkChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
	};

	@action update = async () => {
		if (!this.hasChanges) return;

		return await this.api.closedPeriodService.update(
			this.editableDetails.orderMCManagement
		);
	};

	setVariants = (data: any) => {
		this.readOnlyDetails.variants = data ?? [];
	}
}

const ClosedPeriodArgStoreContext = React.createContext(
	new ClosedPeriodArgStore()
);

export const useClosedPeriodArgStore = () =>
	React.useContext(ClosedPeriodArgStoreContext);
