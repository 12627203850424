import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import QuotaContent from "./QuotaContent";
import VolvoManagementContent from "./VolvoManagementContent";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	groupsAllowedToEditFinalCustomerBatchDetails,
	groupsAllowedToEditQuotaSection,
	groupsAllowedToEditVolvoManagementDetails,
} from "../../../../../constants/AuthGroups/orderDetails";
import { toast } from "react-toastify";
import {
	BUS_BRAZIL,
	EXTERNAL_BRAZIL,
} from "../../../../../constants/Menus/OrderMenuUtils";
import VolvoManagementGeneralCommentContent from "./VolvoManagementGeneralCommentContent";
import CustomerContent from "./CustomerContent";
import FinalCustomerContent from "./FinalCustomerContent";
import BatchEdition from "../../../../../components/BatchEdition/BatchEdition";
import {
	getIsValidListSize,
	getUserCanDoBatchEdition,
	getUserCanEditCustomerContent,
	getUserCanEditFinalCustomerContent,
	getUserCanEditVolvoManagementGeneralCommentContent,
} from "../../utils/BatchEditionUtils";

const OrderBatchEdition: React.FC = () => {
	const { t } = useTranslation("general");

	const { resetData, updateBatchData, formHasChanges } =
		useStores().batchEditionStore;
	const { listTotalResult, menu } = useStores().listStore;

	const {
		isAuthorizedGroup,
		isAuthorizedBraDealerGroup,
		isAuthorizedAdminGroup,
		graphData,
	} = useAuthStore();

	const isAdmin = isAuthorizedAdminGroup();
	const dealerCanEdit =
		isAuthorizedBraDealerGroup() &&
		[EXTERNAL_BRAZIL, BUS_BRAZIL].includes(menu);

	const userCanEditQuotaContent = isAuthorizedGroup(
		groupsAllowedToEditQuotaSection
	);
	const userCanEditVolvoManagementContent = isAuthorizedGroup(
		groupsAllowedToEditVolvoManagementDetails
	);
	const userCanEditFinalCustomerContent = getUserCanEditFinalCustomerContent(
		dealerCanEdit,
		isAuthorizedGroup(groupsAllowedToEditFinalCustomerBatchDetails)
	);
	const userCanEditVolvoManagementGeneralCommentContent =
		getUserCanEditVolvoManagementGeneralCommentContent(
			dealerCanEdit,
			userCanEditVolvoManagementContent
		);
	const userCanEditCustomerContent = getUserCanEditCustomerContent(
		dealerCanEdit,
		isAdmin
	);
	const userCanDoBatchEdition = getUserCanDoBatchEdition(
		userCanEditQuotaContent,
		userCanEditVolvoManagementContent,
		userCanEditVolvoManagementGeneralCommentContent,
		userCanEditCustomerContent
	);

	const validListSize = getIsValidListSize(listTotalResult);

	const handleSubmit = async () => {
		const userEmail = graphData?.mail ?? "";
		await updateBatchData(userEmail)
			.then(() =>
				toast.success(t("editRecordsWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => {
				toast.error(err.response.data);
				resetData();
			});
	};

	const reset = React.useCallback(() => {
		resetData();
	}, [resetData]);

	React.useEffect(() => {
		reset();
	}, [reset]);

	return (
		<>
			{userCanDoBatchEdition && (
				<BatchEdition
					id="orderbook-batch-edition"
					title={t("batchEdition", { ns: "general" })}
					onUndo={resetData}
					onSubmit={handleSubmit}
					hasChanges={formHasChanges}
					hasItemsSelected={validListSize}
				>
					<>
						{userCanEditCustomerContent && <CustomerContent />}
						{userCanEditQuotaContent && <QuotaContent />}
						{userCanEditFinalCustomerContent && <FinalCustomerContent />}
						{userCanEditVolvoManagementContent && <VolvoManagementContent />}
						{userCanEditVolvoManagementGeneralCommentContent && (
							<VolvoManagementGeneralCommentContent />
						)}
					</>
				</BatchEdition>
			)}
		</>
	);
};

export default observer(OrderBatchEdition);
