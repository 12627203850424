import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { GeneralAccordionProps } from "../../../interfaces/Accordions/Europe/GeneralAccordion";
import GeneralSection from "../../Sections/Europe/GeneralSection";
import { observer } from "mobx-react";
import CustomerSection from "../../Sections/Europe/CustomerSection";

const GeneralAccordion: React.FC<GeneralAccordionProps> = (details) => {
	const { t } = useTranslation("order");
	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.general")}
			defaultExpanded
		>
			<GeneralSection {...details.generalDetails} />
			<CustomerSection {...details.customerDetails} />
		</AccordionCard>
	);
};

export default observer(GeneralAccordion);
