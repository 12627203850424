import { PaymentTermsProps } from "../interfaces/PaymentTermsProps";
import {
	PaymentRetoma,
	QuotationPaymentTermRetoma,
} from "../interfaces/QuotationPaymentTermRetoma";
import {
	UpdateAllQuotationPaymentTerms,
	UpdatePaymentRetoma,
	UpdatePaymentTerm,
} from "../interfaces/UpdateAllQuotationPaymentTerms";

export const getUpdateAllQuotationPaymentTermsModel = (
	paymentTerms: Array<PaymentTermsProps>,
	paymentTermRetoma: QuotationPaymentTermRetoma
): UpdateAllQuotationPaymentTerms => {
	return {
		quotationId: paymentTermRetoma.quotationId,
		retomaTotalProvision: paymentTermRetoma.retomaTotalProvision,
		paymentRetomaList: getUpdatePaymentRetomaListModel(
			paymentTermRetoma.paymentRetomaList
		),
		paymentTermsList: getUpdatePaymentTermsListModel(paymentTerms),
	};
};

const getUpdatePaymentRetomaListModel = (
	paymentRetomaList: PaymentRetoma[]
): UpdatePaymentRetoma[] => {
	if (!paymentRetomaList) return [];
	return paymentRetomaList.map((m) => ({ value: m.value, plate: m.plate }));
};

const getUpdatePaymentTermsListModel = (
	paymentTerms: PaymentTermsProps[]
): UpdatePaymentTerm[] => {
	if (!paymentTerms) return [];
	return paymentTerms.map((m) => ({ value: m.value, id: m.id }));
};
