import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeFollowUpChlMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.CHL_MARKET_COMPANY_SALESMAN,
		AuthGroups.CHL_MARKET_COMPANY_SALESMAN_MANAGER,
	];

export const groupsToBatchEditFollowUpChl: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY,
	AuthGroups.CHL_MARKET_COMPANY_SALESMAN_MANAGER,
];
