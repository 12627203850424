import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import { ApplicationPreFilter } from "../../../../../../constants/ApplicationPreFilterConst";
import { SelectOptionProps } from "../../../../../../utils/GenericInterfaces";

configure({ enforceActions: "always" });

class PreFilterBusApplicationStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: string = "";
	@observable preFilterOptions: Array<SelectOptionProps> = ApplicationPreFilter;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.loading = false;
		this.preFilter = "";
		this.preFilterOptions = [
			{ label: "URB", value: "0" },
			{ label: "FRE", value: "1" },
			{ label: "ROD", value: "2" },
		];
	};

	@action clean = () => (this.preFilter = "");

	@action get = () =>
		this.preFilter === ""
			? []
			: [getFilterObject("order.busApplication", this.preFilter, "eq")];

	@action set = (busApplicationFilter: string) =>
		(this.preFilter = busApplicationFilter);

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const busApplicationFilters = this.get();

		if (busApplicationFilters)
			andFilters = andFilters.concat(busApplicationFilters);

		return andFilters;
	}

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterBusApplicationStore;
