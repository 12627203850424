import { DealerQuotaProposalProps } from "../interfaces/DealerQuotaProposalProps";

export const dealerQuotaProposalModel: DealerQuotaProposalProps = {
	proposal: 0,
	orderBook: 0,
	quotaRegion: 0,
	total: 0,
	gap: 0,
	period: null,
	firmPeriod: null,
};

function dealerQuotaProposalItemBuilder(data: DealerQuotaProposalProps) {
	if (!data) return dealerQuotaProposalModel;
	return {
		...dealerQuotaProposalModel,
		...data,
		period: data.period ?? null,
		firmPeriod: data.firmPeriod ?? null,
	} as DealerQuotaProposalProps;
}

export function dealerQuotaProposalModelBuilder(
	data: Array<DealerQuotaProposalProps>
) {
	if (!data) return [];
	return data.map((x) => dealerQuotaProposalItemBuilder(x));
}
