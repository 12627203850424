import { GUID_EMPTY } from "../../../../app/constants/config";
import { PriceAndPaymentProps } from "../interfaces/PriceAndPaymentProps";

export const priceAndPaymentModel: PriceAndPaymentProps = {
	id: GUID_EMPTY,
	totalPrice: 0,
	discount: 0,
	hasErrors: "",
};

export const priceAndPaymentModelBuilder = (
	data: any,
	id: string
): PriceAndPaymentProps => {
	if (!data) data = priceAndPaymentModel;
	return {
		id: id,
		totalPrice: data.totalPrice ?? "",
		discount: data.discount ?? "",
		hasErrors: "",
	};
};
