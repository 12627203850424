import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import markets from "../models/markets.json";
import { Checkbox, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "../styles/regions.css";
import EditIcon from "@mui/icons-material/Edit";

export const getColumns = (
	translate: any,
	onDelete: any,
	onUpdate: any,
	disableEdition: boolean
): Array<GridColumnProps> => {
	return [
		{
			field: "value",
			title: translate("region"),
			editable: false,
			className: "input-wrapper",
		} as GridColumnProps,
		...markets.map((d) => {
			return {
				title: d,
				field: d,
				editable: true,
				editor: "boolean",
				className: "td-table",
				cell: (props) => cell(props, d),
			} as GridColumnProps;
		}),
		{
			editable: true,
			cell: (props) =>
				actionCell(translate, onDelete, onUpdate, props, disableEdition),
			width: "130px",
		} as GridColumnProps,
	] as Array<GridColumnProps>;
};

const cell = (props: GridCellProps, d: string) => {
	let isChecked = props.dataItem.markets.includes(d);

	return (
		<td>
			<Checkbox checked={isChecked} disabled={true} />
		</td>
	);
};

const actionCell = (
	translate: any,
	onDelete: any,
	onUpdate: any,
	props: GridCellProps,
	disableEdition: boolean
) => {
	return (
		<td>
			<Tooltip title={`${translate("edit")}`} arrow>
				<IconButton onClick={() => onUpdate(props)} disabled={disableEdition}>
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title={`${translate("delete")}`} arrow>
				<IconButton onClick={() => onDelete(props)}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</td>
	);
};
