import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector: React.FC = (props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [lang, setLang] = React.useState<string>("en");
	const open = Boolean(anchorEl);
	const { i18n } = useTranslation();

	React.useEffect(() => {
		if (Boolean(i18n.language)) {
			setLang(i18n.language.substring(0, 2));
		}
	}, [i18n.language]);

	const language = {
		click: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			setAnchorEl(event.currentTarget);
		},
		close: () => {
			setAnchorEl(null);
		},
		select: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
			language.close();
			const lng = event.currentTarget.getAttribute("data-language") || "";

			i18n.changeLanguage(lng).then(() => {
				setLang(lng.substring(0, 2));
			});
		},
	};

	return (
		<Box pr={2}>
			<Button
				variant="text"
				aria-controls="language-menu"
				aria-haspopup="true"
				onClick={language.click}
				sx={{ padding: 0, minWidth: 0 }}
			>
				{Boolean(lang) && (
					<img src={require(`./flags/${lang}.png`)} alt="" height={20} />
				)}
			</Button>
			<Menu
				id="language-menu"
				anchorEl={anchorEl}
				onClose={language.close}
				open={open}
			>
				<MenuItem data-language="pt-BR" onClick={language.select}>
					<img
						src={require(`./flags/pt.png`)}
						alt="Português"
						height={25}
						title="Português"
					/>
				</MenuItem>
				<MenuItem data-language="en-US" onClick={language.select}>
					<img
						src={require(`./flags/en.png`)}
						alt="English"
						height={25}
						title="English"
					/>
				</MenuItem>
				<MenuItem data-language="es-ES" onClick={language.select}>
					<img
						src={require(`./flags/es.png`)}
						alt="Español"
						height={25}
						title="Español"
					/>
				</MenuItem>
			</Menu>
		</Box>
	);
};

export default LanguageSelector;
