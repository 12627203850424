import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateRangePreFilterProps } from "../Interfaces/DateRangePreFilterProps";
import DateRangePreFilter from "../components/DateRangePreFilter";

const LineSequenceDatePreFilter: React.FC<DateRangePreFilterProps> = (
	props
) => {
	const { t } = useTranslation("order");
	const {
		labelEnd = "preFilters.lineSequenceDateEnd",
		label = "preFilters.lineSequenceDate",
		id = "lineSequenceDatePreFilter",
	} = props;
	return (
		<DateRangePreFilter
			{...props}
			labelEnd={t(labelEnd)}
			label={t(label)}
			id={id}
		/>
	);
};

export default observer(LineSequenceDatePreFilter);
