export function getDateTimeCell(field: string, title: string) {
	return {
		field,
		title,
		width: 90,
		customCell: {
			type: "dateTimeCell",
			date: field,
			format: "DD/MM/yy",
		},
	};
}
