import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { QuotaInformationAccordionProps } from "../../interfaces/Accordions/QuotaInformationAccordion";
import QuotaInformationSection from "../Sections/QuotaInformationSection";

const QuotaInformationAccordion: React.FC<QuotaInformationAccordionProps> = (
	details
) => {
	const { t } = useTranslation("order");
	return (
		<AccordionCard
			id="quotaInformationAccordion"
			title={t("details.accordions.quotaInformation")}
		>
			<QuotaInformationSection {...details.quotaInformationDetails} />
		</AccordionCard>
	);
};

export default observer(QuotaInformationAccordion);
