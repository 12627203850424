import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MultiSelectPreFilterProps } from "../Interfaces/MultiSelectPreFilterProps";
import MultiSelectPreFilter from "../components/MultiSelectPreFilter";

export const PAQUETE_PRE_FILTER_XS = 12;
export const PAQUETE_PRE_FILTER_SM = 6;
export const PAQUETE_PRE_FILTER_MD_LG = 4;
export const PAQUETE_PRE_FILTER_XL = 2;

const PaquetePreFilter: React.FC<MultiSelectPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		preFilterStore,
		id = "paquetePreFilter",
		label = "preFilters.paquete",
		xs = PAQUETE_PRE_FILTER_XS,
		sm = PAQUETE_PRE_FILTER_SM,
		md = PAQUETE_PRE_FILTER_MD_LG,
		lg = PAQUETE_PRE_FILTER_MD_LG,
		xl = PAQUETE_PRE_FILTER_XL,
	} = props;
	return (
		<MultiSelectPreFilter
			preFilterStore={preFilterStore}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(PaquetePreFilter);
