import { TFunction } from "i18next";
import { MenuLink } from "../app/MenuItems";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { AuthGroupsType } from "../constants/AuthGroups/authGroups";
import { AppRouteProps } from "../pages/routes";
import { MenuCollapsableRouteProps, MenuRouteProps } from "./GenericInterfaces";
import MenuCollapsableListItems from "../components/header/MenuCollapsableListItems";
import { ReactElement, JSXElementConstructor } from "react";

export function getRoute(route: MenuRouteProps): AppRouteProps {
	return {
		path: route.path,
		element: <ProtectedRoute element={route.element} claims={route.claims} />,
	};
}

export function getRouteListAndDetails(
	route: MenuRouteProps,
	detailsElement: ReactElement<any, string | JSXElementConstructor<any>>
): AppRouteProps[] {
	return [
		getRoute(route),
		getRoute({
			...route,
			path: `${route.path}/:id`,
			element: detailsElement,
		}),
	];
}

export function getMenuAuthorized(
	route: MenuRouteProps,
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean
) {
	return isAuthorizedGroup(route.claims) ? getMenu(route, t) : <></>;
}

export function getMenu(
	route: MenuRouteProps,
	t: TFunction<"menu", undefined>
) {
	return (
		<MenuLink
			primaryText={t(route.label)}
			isCollapsable={route.isCollapsable}
			to={route.path}
			icon={route.icon}
		/>
	);
}

export function getMenuCollabsableAuthorized(
	route: MenuCollapsableRouteProps,
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean
) {
	return isAuthorizedGroup(route.claims) ? (
		<MenuCollapsableListItems
			mainPrimaryText={t(route.label)}
			mainSecondaryText={route.labelSecondary ? t(route.labelSecondary) : ""}
			icon={route.icon}
			listItems={route.getMenus(t, isAuthorizedGroup, open)}
			drawerOpen={open}
		/>
	) : (
		<></>
	);
}
