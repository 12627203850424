import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { ModelSectionProps } from "../../interfaces/Sections/ModelSection";

const ModelSection: React.FC<ModelSectionProps> = ({ readOnlyFields }) => {
	const { t } = useTranslation("quotation");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.model.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialModel")}
					value={readOnlyFields.commercialModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.package")}
					value={readOnlyFields.package}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.cabin")}
					value={readOnlyFields.cabinStyle}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.color")}
					value={readOnlyFields.cabinColor}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSection);
