export const followUpArgKeyLabels = {
	deliveryProbability: {
		label: "details.sections.mktCompanyDelivery.deliveryProbability",
	},
	billingForecast: {
		key: "billingForecast",
		label: "details.sections.forecastBilling.billingForecast",
	},
	firmBilling: {
		key: "firmBilling",
		label: "details.sections.mktCompanyDelivery.firmBillingDate",
	},
	financialDelivery: {
		key: "financialDelivery",
		label: "details.sections.mktCompanyDelivery.financialDelivery",
	},
	physicalDelivery: {
		key: "physicalDelivery",
		label: "details.sections.mktCompanyDelivery.physicalDelivery",
	},
	dealer: {
		key: "dealerId",
		label: "details.sections.customer.dealer",
	},
	sasCustomer: {
		label: "details.sections.customer.sasCustomer",
	},
	destination: {
		label: "details.sections.forecastBilling.destination",
	},
	currency: {
		key: "currency",
		label: "details.sections.mktCompanyCommercial.currency",
	},
	paymentForm: {
		key: "paymentTermsId",
		label: "details.sections.mktCompanyCommercial.paymentForm",
	},
	shippingDate: {
		key: "shippingDate",
		label: "details.sections.forecastBilling.shippingDate",
	},
	certificateDate: {
		key: "certificateDate",
		label: "details.sections.forecastBilling.certificateDate",
	},
	paquete: {
		key: "paqueteId",
		label: "details.sections.mktCompanyCommercial.paquete",
	},
	vehicleLocation: {
		key: "vehicleLocationId",
		label: "details.sections.mktCompanyCommercial.vehicleLocation",
	},
	logisticComments: {
		key: "logisticComments",
		label: "details.sections.mktCompanyAdditionalComments.logisticComments",
	},
	commercialComments: {
		key: "commercialComments",
		label: "details.sections.mktCompanyAdditionalComments.commercialComments",
	},
	engineeringComments: {
		key: "engineeringComments",
		label: "details.sections.engineeringComments",
	},
	salesSupportComments: {
		key: "salesSupportComments",
		label: "details.sections.mktCompanyAdditionalComments.salesSupportComments",
	},
	financialComments: {
		key: "financialComments",
		label: "details.sections.mktCompanyAdditionalComments.financialComments",
	},
	comission: {
		key: "comission",
		label: "details.sections.mktCompanyCommercial.comission",
	},
	generalComment: {
		key: "generalComment",
		label: "details.sections.generalComments.title",
	},
};
