import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";

interface DateTimeSpanProps {
	date: Date | null;
	format?: string;
	defaultValue?: string;
	convertUtc?: boolean;
}
const DateTimeSpan: React.FC<DateTimeSpanProps> = ({
	date,
	format,
	defaultValue,
	convertUtc,
}) => {
	const { i18n } = useTranslation();
	const locale = Boolean(i18n.language) ? i18n.language.substring(0, 2) : "en";

	const dtNormalized = convertUtc && date ? moment.utc(date).toDate() : date;

	const finalDate = dtNormalized
		? moment(dtNormalized)
				.locale(locale)
				.format(format ?? "DD/MM/YYYY")
				.toString()
		: defaultValue;

	return <span>{finalDate}</span>;
};
export default DateTimeSpan;
