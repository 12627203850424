import React from "react";
import DateTimeString from "../../../utils/DateTimeToString";
import { observer } from "mobx-react";
import { Grid, Link } from "@mui/material";
import {
    Grid as KendoGrid,
    GridColumn,
    GridFooterCellProps,
    GridCellProps,
} from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { CARD_SIZE } from "./cardSize";
import { DealerQuotaProposalProps } from "./interfaces/DealerQuotaProposalProps";

export interface IQuotaSharedReadGrid {
    getQuotaProposal: (period: any, t: any) => Promise<void>;
    hasFilterApplied: () => boolean;
    dealerQuotaProposal: DealerQuotaProposalProps[];
    dealerQuotaProposalTotal: DealerQuotaProposalProps;
}

const QuotaSharedReadGrid: React.FC<IQuotaSharedReadGrid> = ({
    getQuotaProposal,
    hasFilterApplied,
    dealerQuotaProposal,
    dealerQuotaProposalTotal
}) => {
    const { t } = useTranslation("quota");

    const MonthCell = (props: GridCellProps) => (
        <td>
            <span>
                {DateTimeString(props.dataItem[props.field ?? ""], "MMM/yyyy", "TOTAL")}
            </span>
        </td>
    );

    const DateCell = (props: GridCellProps) => (
        <td>
            <span>
                {DateTimeString(props.dataItem[props.field ?? ""], "DD/MM/YY", "-")}
            </span>
        </td>
    );

    const TotalCell = (props: GridFooterCellProps, dealerQuotaProposalProps: DealerQuotaProposalProps) => {
        let total = "";

        if (props.field === "period") {
            total = "TOTAL";
        }
        else {
            total =
                dealerQuotaProposalProps[
                    props.field as keyof DealerQuotaProposalProps
                ]?.toString() ?? "";
        }

        return (
            <td>
                <span>{total}</span>
            </td>
        );
    };

    const getCustomCell = (props: any) => {
        if (props.dataItem.total === 0) {
            return (
                <td>
                    <span>{props.dataItem.total}</span>
                </td>
            );
        }

        return (
            <td>
                <Link
                    sx={{ fontSize: 13 }}
                    className="link"
                    component="button"
                    onClick={() => {
                        getQuotaProposal(props.dataItem.period, t);
                    }}
                >
                    <span>{props.dataItem.total}</span>
                </Link>
            </td>
        );
    };

    return (
        <Grid item sx={{ width: CARD_SIZE }}>
            {hasFilterApplied() && (
                <KendoGrid
                    data={dealerQuotaProposal}
                    scrollable="none"
                    className="k-grid-sm"
                >
                    <GridColumn
                        field="period"
                        title={t("gridColumns.month")}
                        cell={MonthCell}
                        footerCell={(props: GridFooterCellProps) => TotalCell(props, dealerQuotaProposalTotal)}
                    />
                    <GridColumn
                        field="proposal"
                        title={t("gridColumns.proposal")}
                        footerCell={(props: GridFooterCellProps) => TotalCell(props, dealerQuotaProposalTotal)}
                    />
                    <GridColumn
                        field="orderBook"
                        title={t("gridColumns.orderBook")}
                        footerCell={(props: GridFooterCellProps) => TotalCell(props, dealerQuotaProposalTotal)}
                    />
                    <GridColumn
                        field="quotaRegion"
                        title={t("gridColumns.quotaRegion")}
                        footerCell={(props: GridFooterCellProps) => TotalCell(props, dealerQuotaProposalTotal)}
                    />
                    <GridColumn
                        field="total"
                        title={t("gridColumns.total")}
                        footerCell={(props: GridFooterCellProps) => TotalCell(props, dealerQuotaProposalTotal)}
                        cell={getCustomCell}
                    />
                    <GridColumn
                        field="gap"
                        title={t("gridColumns.gap")}
                        footerCell={(props: GridFooterCellProps) => TotalCell(props, dealerQuotaProposalTotal)}
                    />
                    <GridColumn
                        field="firmPeriod"
                        title={t("gridColumns.firmPeriod")}
                        cell={DateCell}
                    />
                </KendoGrid>
            )}
        </Grid>
    )
};

export default observer(QuotaSharedReadGrid);