import React from "react";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import _ from "lodash";
import {
	europeReadOnlyModel,
	europeReadOnlyModelBuilder,
} from "../models/EuropeReadOnlyModel";
import {
	europeEditableModel,
	europeEditableModelBuilder,
} from "../models/Editable/EuropeEditableModel";
import { EuropeReadOnlyDetailsProps } from "../interfaces/EuropeReadOnlyDetails";
import { EuropeEditableProps } from "../interfaces/Editable/EuropeEditableDetails";

configure({ enforceActions: "always" });

/* Store start */
class FollowUpChlEuropeStore {
	@observable readOnlyDetails: EuropeReadOnlyDetailsProps = europeReadOnlyModel;

	@observable editableDetails: EuropeEditableProps = europeEditableModel;
	@observable initialEditableDetails: EuropeEditableProps = europeEditableModel;

	@observable hasFormChanges: boolean = false;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.readOnlyDetails = europeReadOnlyModel;

		this.editableDetails = europeEditableModel;
		this.initialEditableDetails = europeEditableModel;

		this.hasFormChanges = false;
	};

	@action setEditableFieldByKeyOnChange = <K extends keyof EuropeEditableProps>(
		fieldKey: K,
		newValue: EuropeEditableProps[K]
	) => {
		const _editableDetails: EuropeEditableProps = Object.assign(
			{},
			this.editableDetails
		);
		_editableDetails[fieldKey] = newValue;
		this.editableDetails = _editableDetails;
		this.checkFormChanges();
	};

	@action setReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = europeReadOnlyModelBuilder({
			...this.readOnlyDetails,
			...data,
		});
	};

	@action setEditableDetails = (data: any) => {
		this.editableDetails = europeEditableModelBuilder({
			...this.editableDetails,
			...data,
		});
		this.initialEditableDetails = europeEditableModelBuilder({
			...this.initialEditableDetails,
			...data,
		});
	};

	@action checkFormChanges = () =>
		(this.hasFormChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		));

	@action loadDetails = async (sasId: string) => {
		this.resetStore();
		if (!sasId) return;

		await this.api.followUpChlService
			.getEuropeDetails(sasId)
			.then((response) => {
				this.setReadOnlyDetails(response.data);
				this.setEditableDetails(response.data);
			});
	};

	@action update = async () =>
		await this.api.followUpChlService.updateEurope(this.editableDetails);
}
/* Store end */

/* Store helpers */
const FollowUpChlEuropeStoreContext = React.createContext(
	new FollowUpChlEuropeStore()
);

/* Hook to use store in any functional component */
export const useFollowUpChlEuropeStore = () =>
	React.useContext(FollowUpChlEuropeStoreContext);
