import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Save, Undo } from "@mui/icons-material";

interface SaveBarProps {
	hasFormChanges: boolean;
	onResetClick: () => void;
	resetLabel: string;
	onSaveClick: () => void;
	saveLabel: string;
	disabled?: boolean;
}

const SaveBar: React.FC<SaveBarProps> = ({
	hasFormChanges,
	resetLabel,
	onResetClick,
	saveLabel,
	onSaveClick,
	disabled = false,
}) => {
	return (
		<>
			{hasFormChanges && (
				<Paper
					sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1300 }}
					elevation={6}
				>
					<BottomNavigation showLabels>
						<BottomNavigationAction
							label={resetLabel}
							icon={<Undo />}
							onClick={onResetClick}
						/>
						<BottomNavigationAction
							label={saveLabel}
							icon={<Save />}
							onClick={onSaveClick}
							disabled={disabled}
							style={{ backgroundColor: disabled ? "#EFEFEF" : "" }}
						/>
					</BottomNavigation>
				</Paper>
			)}
		</>
	);
};

export default SaveBar;
