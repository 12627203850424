import { TFunction } from "i18next"

export const GetBreadCrumbs = (t: TFunction<"order"[], undefined>, menuType: string) => [
    {
        title: t("home", { ns: "menu" }),
        href: "/",
        main: false,
    },
    {
        title: `${t("followup.title", { ns: "menu" })} - ${t(menuType, {
            ns: "menu",
        })}`,
        main: true,
    },
];