import { AuthGroupsType } from "../../../../constants/AuthGroups/authGroups";
import {
	groupsAllowedToEditAsAdmin,
	groupsAllowedToEditAsChl,
} from "../../../../constants/AuthGroups/variantsPage";
import { variantAdminPutPropsModel } from "../models/VariantAdminPutPropsModel";
import { variantBasePutPropsModel } from "../models/VariantBasePutPropsModel";

export const UPDATE_PERMISSION_ADMIN = "UpdateAdmin";
export const UPDATE_PERMISSION_CHL = "UpdateForChl";

function getVariantsEditableInterfaceObjectKeys(obj: any) {
	return Object.keys(obj);
}

export function getUserUpdatePermission(
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean
) {
	if (isAuthorizedGroup(groupsAllowedToEditAsAdmin)) {
		return UPDATE_PERMISSION_ADMIN;
	}
	if (isAuthorizedGroup(groupsAllowedToEditAsChl)) {
		return UPDATE_PERMISSION_CHL;
	}

	return "";
}

export function getFieldsAvailableForEditionByUser(
	userUpdatePermission: string
) {
	switch (userUpdatePermission) {
		case UPDATE_PERMISSION_ADMIN:
			return getVariantsEditableInterfaceObjectKeys(variantAdminPutPropsModel);
		case UPDATE_PERMISSION_CHL:
			return getVariantsEditableInterfaceObjectKeys(variantBasePutPropsModel);
		default:
			return [];
	}
}

export function fieldIsEditable(
	fieldName: string,
	fieldsAvailableForEditionByUser: Array<string>
) {
	return fieldsAvailableForEditionByUser.includes(fieldName);
}
