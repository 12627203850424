import React from "react";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import ICsvFileName from "../interfaces/ICsvFileName";
import { downloadCsv } from "../../../../utils/downloadCsv";

configure({ enforceActions: "always" });

/* Store start */
class CsvQueueManagerStore {
	@observable loading = false;
	@observable files: Array<ICsvFileName> = [];

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action setLoading = (value: boolean) => (this.loading = value);
	@action setFiles = (value: Array<ICsvFileName>) => (this.files = value);

	@action downloadFile = async (
		file: ICsvFileName,
		origin: string,
		menu: string
	) => {
		this.setLoading(true);

		const fileName = `${origin}_${menu}_${new Date(file.timestamp).toString()}.csv`;

		return await this.api.csvQueueManagerService
			.download(file.rowKey)
			.then((result: any) => {
				downloadCsv(result, fileName);
			})
			.finally(() => this.setLoading(false));
	};

	@action getAvailableDownloads = async () => {
		this.setLoading(true);

		return await this.api.csvQueueManagerService
			.list()
			.then((result: any) => this.setFiles(result.data))
			.finally(() => this.setLoading(false));
	};
}
/* Store end */

/* Store helpers */
const CsvQueueManagerStoreContext = React.createContext(
	new CsvQueueManagerStore()
);

/* Hook to use store in any functional component */
export const useCsvQueueManagerStore = () =>
	React.useContext(CsvQueueManagerStoreContext);
