import { SaleInvoiceProps } from "../interfaces/SaleInvoiceDetails";

export const saleInvoiceModel: SaleInvoiceProps = {
	customerName: "",
	dealerRegion: "",
	date: null,
	isAntecipaded: false,
	cancelDate: null,
};

export function saleInvoiceModelBuilder(data: SaleInvoiceProps) {
	if (!data) return saleInvoiceModel;
	return {
		...saleInvoiceModel,
		...data,
		dealerRegion: data.dealerRegion ?? "",
		customerName: data.customerName ?? "",
	} as SaleInvoiceProps;
}
