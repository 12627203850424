import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const NoContentResult: React.FC = () => {
	const { t } = useTranslation("msal");

	return (
		<Box
			display="flex"
			height="80vh"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
		>
			<span
				style={{
					fontSize: 250,
					fontFamily: "Roboto",
					fontWeight: 700,
					lineHeight: "180px",
				}}
			>
				204
			</span>
			<span
				style={{
					fontSize: 60,
					fontFamily: "Roboto",
					backgroundColor: "#1e1e1e",
					color: "white",
					padding: "0 16px",
				}}
			>
				{t("noContent.noContent").toUpperCase()}
			</span>
			<span style={{ fontSize: 25, fontFamily: "Roboto" }}>
				{t("noContent.message")}
			</span>
		</Box>
	);
};

export default NoContentResult;
