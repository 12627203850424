import React from "react";
import { observer } from "mobx-react";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./QuotationGrid";
import { getQuotationGridColumns } from "../utils/GridUtils";
import { useStores } from "../stores/RootStore";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import {
	groupsAllowedToSeeQuotationMenu,
	groupsQuotationManager,
	groupsToExportQuotationCsv,
} from "../../../../constants/AuthGroups/quotationPage";
import QuotationEditionModal from "./QuotationEditionModal";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import {
	getQuotationReservationStatusOptions,
	getQuotationStatusSASOptions,
	getQuotationWorkflowStatusOptions,
} from "../../../../utils/SelectOptions";
import HomePageCsvExport from "./HomePageCsvExport";
import QuotationPreFilters from "./PreFilters/QuotationPreFilters";

const QuotationIndex: React.FC = () => {
	const { t } = useTranslation(["quotation", "menu"]);
	const { reset } = useStores().listStore;
	const { getSalesmanOptions } = useStores().preFilterSalesmanStore;
	const { setIsQuotationManagerUser, loading } = useStores().listStore;
	const { isAuthorizedGroup } = useAuthStore();
	const isAllowedToExportCsv = isAuthorizedGroup(groupsToExportQuotationCsv);

	const isQuotationManagerUser = setIsQuotationManagerUser(
		isAuthorizedGroup(groupsQuotationManager)
	);

	const canUserSeeBatchEdition = isAuthorizedGroup(
		groupsAllowedToSeeQuotationMenu
	);

	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	var statusSASOptions = getQuotationStatusSASOptions(t);
	var reservationStatusOptions = getQuotationReservationStatusOptions(t);
	var workflowStatusOptions = getQuotationWorkflowStatusOptions(t);

	React.useEffect(() => {
		reset();
		if (isQuotationManagerUser) {
			getSalesmanOptions();
		}
		const gridColumns = getQuotationGridColumns(
			t,
			statusSASOptions,
			reservationStatusOptions,
			workflowStatusOptions
		);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("quotation.title", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box sx={{ display: "flex" }}>
					<TitleBox breadcrumbs={breadcrumbs} />
					{isAllowedToExportCsv && <HomePageCsvExport />}
				</Box>
			</Grid>
			{canUserSeeBatchEdition && <QuotationEditionModal />}
			<QuotationPreFilters />
			<Grid item xs={12}>
				<LoadingPage loading={loading} />
				{columns && <HomePageGrid columns={columns} />}
			</Grid>
		</Grid>
	);
};

export default observer(QuotationIndex);
