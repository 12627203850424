import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import SelectForm from "../FormInputs/SelectForm";
import { getDateTypeOptions } from "../../utils/SelectOptions";
import { textNotEmptyValidator } from "../../utils/FormValidators";
import { DateType } from "../../utils/GenericTypes";

interface DateTypeFilterProps {
	value: DateType;
	set: (value: any) => void;
}

const DateTypeFilter: React.FC<DateTypeFilterProps> = ({
	set,
	value = "proposal",
}) => {
	const { t } = useTranslation("quota");
	const [dateType, setDateType] = useState<string>(value);
	const dateTypeOptions = getDateTypeOptions(t);

	const validator = (value: string) => {
		if (!textNotEmptyValidator(value)) return "required";
		return "";
	};

	const handleChange = (event: any) => {
		const value = event.target.value;
		setDateType(value);
		set({ value: value, isValid: !Boolean(validator(value)) });
	};

	return (
		<SelectForm
			editable
			label={t("date-type.name")}
			labelId={"filter-date-type-label"}
			value={dateType}
			options={dateTypeOptions}
			onChange={handleChange}
			validator={validator}
		/>
	);
};

export default observer(DateTypeFilter);
