import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import { Grid } from "@mui/material";
import SummaryDraggableCard from "../../../../../components/SummaryDraggableCard/SummaryDraggableCard";
import {
	getHeaders,
	getSummaryDraggableData,
} from "../../models/Summary/SummaryModel";
import ProgressSummary from "./ProgressSummary";

const SummaryBus: React.FC = () => {
	const { t } = useTranslation("order");
	const {
		summaryBus,
		loadingSummaryBus,
		getSummaryBus,
		refresh,
		progressSummaryBus,
		loadingProgressSummaryBus,
		getProgressSummaryBus,
	} = useStores().listStore;

	const fetch = React.useCallback(async () => {
		getSummaryBus();
	}, [getSummaryBus]);

	React.useEffect(() => {
		fetch();
	}, [fetch, refresh]);

	const dataTransformed = getSummaryDraggableData(summaryBus);
	const headers = getHeaders(t);

	return (
		<>
			<Grid item xs="auto">
				<ProgressSummary
					progressSummary={progressSummaryBus}
					loading={loadingProgressSummaryBus}
					getProgressSummary={getProgressSummaryBus}
					refresh={refresh}
					vehicleType={"Bus"}
				/>
			</Grid>
			<Grid item xs="auto">
				<SummaryDraggableCard
					id={"follow-up-arg-bus-summary"}
					width={350}
					headers={headers}
					data={dataTransformed.dataTransformed}
					footerHeader={`${t("estimatedOfMonth")}:`}
					footerData={dataTransformed.estimatedMonth}
					loading={loadingSummaryBus}
				/>
			</Grid>
		</>
	);
};

export default observer(SummaryBus);
