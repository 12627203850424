import {
	GridCellProps,
	GridColumnProps,
	GridFooterCellProps,
} from "@progress/kendo-react-grid";
import { QuotaProposalEditProps } from "../interfaces/QuotaProposalEditProps";
import months from "../models/months.json";

const EXP_REGION = "EXP";

export const setAllInEdit = (quotaProposalEdit: QuotaProposalEditProps[]) =>
	quotaProposalEdit.map((item) => Object.assign({ inEdit: true }, item));

export const getColumns = (
	translate: any,
	dataState: QuotaProposalEditProps[]
): Array<GridColumnProps> => {
	return [
		{
			field: "region",
			title: translate("region"),
			editable: false,
			cell: (props) => regionCell(props, translate),
		} as GridColumnProps,
		...months.map((m) => {
			return {
				title: translate(`months.${m}`),
				field: m,
				editable: true,
				editor: "numeric",
				className: "td-table",
				footerCell: (props) => totalCell(props, dataState),
			} as GridColumnProps;
		}),
	] as Array<GridColumnProps>;
};

const regionCell = (props: GridCellProps, translate: any) => {
	return (
		<td>
			{props.dataItem.region === EXP_REGION
				? translate("quota.proposal.totalExportation")
				: props.dataItem.region}
		</td>
	);
};

const totalCell = (
	props: GridFooterCellProps,
	dataState: QuotaProposalEditProps[]
) => {
	const field = props.field || "";
	const total = dataState.reduce(
		(acc: any, current: { [x: string]: any }) => acc + current[field],
		0
	);

	return (
		<td colSpan={props.colSpan} style={props.style}>
			{total}
		</td>
	);
};
