export function getMultiValueFilterSplitter(filterValue: string) {
	return filterValue.indexOf(",") > -1
		? ","
		: filterValue.indexOf(" ") > -1
			? " "
			: undefined;
}

function formatAssemblyOrderToOriginalFormat(aoValue: string) {
	aoValue = aoValue.substring(0, 2).concat(" ").concat(aoValue.substring(2));
	aoValue = "AO" + aoValue;
	return aoValue;
}

function formatFactoryOrderToOriginalFormat(foValue: string) {
	foValue = "000" + foValue;
	return foValue;
}

export function getFilterObjectArrayOfOrderKeyBySize(
	chassiField: string,
	aoField: string,
	foField: string,
	prefix: string,
	filterValue: string
) {
	filterValue = filterValue.trim();

	if (filterValue === "") return [];

	var operator = "eq";
	var field = "";

	switch (filterValue.length) {
		case 6:
			field = prefix + chassiField;
			break;

		case 7:
			field = prefix + foField;
			operator = "endswith";
			break;

		case 8:
			field = prefix + aoField;
			filterValue =
				aoField === "omNumber"
					? filterValue
					: formatAssemblyOrderToOriginalFormat(filterValue);
			break;

		case 11:
			field = prefix + foField;
			filterValue = formatFactoryOrderToOriginalFormat(filterValue);
			break;

		case 14:
			field = prefix + foField;
			break;

		default:
			break;
	}

	return [getFilterObject(field, filterValue, operator)];
}

export function getFilterObjectArrayOfStringKeyBySize(
	length: number,
	filterValue: string,
	field: string,
	operator: string
) {
	if (filterValue === "") return [];

	var operation = filterValue.length === length ? "eq" : operator;

	return [getFilterObject(field, filterValue, operation)];
}

export function getFilterObject(field: string, value: any, operator: string) {
	return {
		field: field,
		operator: operator,
		value: value,
		ignoreCase: true,
	};
}
