import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import FinancialDeliveryForm from "./Form/FinancialDeliveryForm";
import FinancialCommentsForm from "./Form/FinancialCommentsForm";
import GeneralCommentsForm from "./Form/GeneralCommentsForm";

const FinancialUserDeliveryContent: React.FC = () => {
	return (
		<>
			<Grid item sm={6} md={3} lg={3}>
				<FinancialDeliveryForm />
			</Grid>
			<Grid item sm={6} md={9} lg={9}>
				<FinancialCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<GeneralCommentsForm />
			</Grid>
		</>
	);
};

export default observer(FinancialUserDeliveryContent);
