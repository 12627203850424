import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/RootStore";
import { observer } from "mobx-react";
import SelectForm from "../../../../../../components/FormInputs/SelectForm";
import ClearRecordItem from "../../../../../../components/BatchEdition/ClearRecordItem";
import { followUpArgKeyLabels } from "../../../utils/BatchEditionUtils";

const PaqueteForm: React.FC = () => {
	const { t } = useTranslation("order");

	const {
		batchData,
		paqueteOptions,
		setOrderMCManagementDetailBatchEditByKey,
	} = useStores().batchEditionStore;

	return (
		<ClearRecordItem
			recordTitle={t(followUpArgKeyLabels.paquete.label)}
			checked={batchData.orderMCManagementDetail.paqueteId.clearValue}
			onClearRecord={setOrderMCManagementDetailBatchEditByKey}
			recordKey={followUpArgKeyLabels.paquete.key}
		>
			<SelectForm
				label={t(followUpArgKeyLabels.paquete.label)}
				labelId={`${followUpArgKeyLabels.paquete.label}-label`}
				value={batchData.orderMCManagementDetail.paqueteId.value}
				options={paqueteOptions}
				editable
				hasEmptyDefaultItem
				onChange={(e) =>
					setOrderMCManagementDetailBatchEditByKey(
						{
							value: e.target.value as string,
							clearValue: false,
						},
						followUpArgKeyLabels.paquete.key
					)
				}
				disabled={batchData.orderMCManagementDetail.paqueteId.clearValue}
			/>
		</ClearRecordItem>
	);
};

export default observer(PaqueteForm);
