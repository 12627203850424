import CtadmvenApi from "../../apis/CtadmvenApi";
import { QuotaProposalEditInsertProps } from "../../pages/Quota/Edit/interfaces/QuotaProposalEditInsertProps";
import { DateType } from "../../utils/GenericTypes";

export interface IQuotaService {
	getReadOnlyList(
		line: string,
		date: string,
		timePeriod: number,
		proposalType: string,
		dateType: DateType
	): Promise<any>;

	getEditList(market: string, line: string, year: number): Promise<any>;

	insertEditQuotaProposal(data: QuotaProposalEditInsertProps): Promise<any>;

	getDealerQuotaReadOnly(line: string, date: string): Promise<any>;

	getDealerQuotaByLineRegion(
		line: string,
		region: string,
		date: string
	): Promise<any>;

	getQuotaProposalRegMktCompany(
		line: string,
		region: string,
		date: string
	): Promise<any>;

	getCsvQuotaProposalRegMktCompany(
		line: string,
		region: string,
		period: string,
		dateType: DateType
	): Promise<any>;

	getCsvQuotaProposalDealerOrders(line: string, period: string): Promise<any>;

	getCsvQuotaProposalDealerOrdersByRegion(
		line: string,
		region: string,
		period: string,
		dateType: DateType
	): Promise<any>;

	getCsvDeliveryProposalVolvoVDB(
		line: string,
		region: string,
		date: string,
		deliveryProposalType: string,
		dateType: DateType
	): Promise<any>;

	getTracbelDealerProposalByRegion(
		line: string,
		region: string,
		date: string
	): Promise<any>;

	getTracbelCsvQuotaProposalDealerOrdersByRegion(
		line: string,
		region: string,
		date: string
	): Promise<any>;
}
const base = "/quota";

export class QuotaService implements IQuotaService {
	constructor(private api: CtadmvenApi) {}

	getReadOnlyList = async (
		line: string,
		date: string,
		timePeriod: number,
		proposalType: string,
		dateType: DateType
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/proposalReadList?line=${line}&date=${date}&timePeriod=${timePeriod}&proposalType=${proposalType}&dateType=${dateType}`
		);

	getEditList = async (
		market: string,
		line: string,
		year: number
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/proposalEditList?market=${market}&line=${line}&year=${year}`
		);

	insertEditQuotaProposal = async (
		data: QuotaProposalEditInsertProps
	): Promise<any> => this.api.ctadmvenClient.post(`${base}/proposal`, data);

	getDealerQuotaReadOnly = async (line: string, date: string): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/dealerProposal?line=${line}&date=${date}`
		);

	getDealerQuotaByLineRegion = async (
		line: string,
		region: string,
		date: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/dealerProposalByRegion?line=${line}&region=${region}&date=${date}`
		);

	getQuotaProposalRegMktCompany = async (
		line: string,
		region: string,
		date: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/getQuotaProposalRegMktCompany?line=${line}&region=${region}&date=${date}`
		);

	getCsvQuotaProposalRegMktCompany = async (
		line: string,
		region: string,
		period: string,
		dateType: DateType
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/getCsvQuotaProposalRegMktCompany?line=${line}&region=${region}&period=${period}&dateType=${dateType}`
		);

	getCsvQuotaProposalDealerOrders = async (
		line: string,
		period: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/getCsvQuotaProposalDealerOrders?line=${line}&period=${period}`
		);

	getCsvQuotaProposalDealerOrdersByRegion = async (
		line: string,
		region: string,
		period: string,
		dateType: DateType
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/getCsvQuotaProposalDealerOrdersByRegion?line=${line}&region=${region}&period=${period}&dateType=${dateType}`
		);

	getCsvDeliveryProposalVolvoVDB = async (
		line: string,
		region: string,
		period: string,
		deliveryProposalType: string,
		dateType: DateType
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/getCsvQuotaProposalRegVolvo?line=${line}&region=${region}&period=${period}&deliveryProposalType=${deliveryProposalType}&dateType=${dateType}`
		);

	getTracbelDealerProposalByRegion = async (
		line: string,
		region: string,
		date: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/getTracbelDealerProposalByRegion?line=${line}&region=${region}&date=${date}`
		);

	getTracbelCsvQuotaProposalDealerOrdersByRegion = async (
		line: string,
		region: string,
		period: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/getTracbelCsvQuotaProposalDealerOrdersByRegion?line=${line}&region=${region}&period=${period}`
		);
}
