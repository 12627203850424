import * as React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { getFollowUpChlGridColumns } from "../../utils/GridUtils";
import { useStores } from "../../stores/RootStore";
import { Grid } from "@mui/material";
import { DataGridApi } from "../../../../../components/kendo/DataGridApi/DataGridApi";
import { gridConst } from "../../../../../constants/gridConst";
import HighlightCaptions from "./HighlightCaptions";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";

const Index: React.FC = () => {
	const { t } = useTranslation(["followUpChl", "order", "menu"]);

	const columns = getFollowUpChlGridColumns(t);

	const { getAllRecords, refresh, loading } = useStores().listStore;
	const { filters } = useStores().filterStore;

	return (
		<>
			{columns && (
				<Grid item xs={12}>
					<DataGridApi
						columns={columns}
						fetchData={getAllRecords}
						pageable={gridConst.pageable}
						pageSize={gridConst.pageSize}
						filterBy={toJS(filters)}
						rowHeight={10}
						reorderable
						resizable
						className="k-grid-lg"
						calculateWidth
						refresh={refresh}
					/>
					<HighlightCaptions />
					<LoadingPage loading={loading} />
				</Grid>
			)}
		</>
	);
};

export default observer(Index);
