import { observer } from "mobx-react";
import React from "react";
import { useDealerReadStore } from "../../stores/DealerReadStore";
import QuotaSharedReadGrid from "../../../shared/QuotaSharedReadGrid";

const DealerReadGrid: React.FC = () => {
	const {
		getQuotaProposalByDealer,
		dealerQuotaProposal,
		dealerQuotaProposalTotal,
		filter,
		getQuotaProposalOrdersCsv,
	} = useDealerReadStore();

	const fetchQuotaProposal = React.useCallback(async () => {
		await getQuotaProposalByDealer();
	}, [getQuotaProposalByDealer]);

	React.useEffect(() => {
		fetchQuotaProposal();
	}, [fetchQuotaProposal, filter]);

	return (
		<QuotaSharedReadGrid
			dealerQuotaProposal={dealerQuotaProposal}
			dealerQuotaProposalTotal={dealerQuotaProposalTotal}
			getQuotaProposal={getQuotaProposalOrdersCsv}
			hasFilterApplied={() => true}
		></QuotaSharedReadGrid>
	);
};

export default observer(DealerReadGrid);
