import React from "react";
import { DeleteForever, DeleteOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Checkbox, Stack, Tooltip } from "@mui/material";

interface RecordProps {
	value: any;
	clearValue: boolean;
}

export interface ClearRecordItemProps {
	recordTitle: string;
	checked: boolean;
	onClearRecord: (obj: RecordProps, recordKey?: string) => void;
	recordKey?: string;
	children: React.ReactNode;
}

const ClearRecordItem: React.FC<ClearRecordItemProps> = ({
	recordTitle,
	checked,
	onClearRecord,
	recordKey,
	children,
}) => {
	const { t } = useTranslation("general");

	return (
		<Stack direction={"row"} spacing={1}>
			{children}
			<div className="mtpx8">
				<Tooltip
					title={t("clearRecord", {
						record: recordTitle,
					})}
					arrow
				>
					<Checkbox
						checked={checked}
						color="secondary"
						onChange={(e) =>
							onClearRecord(
								{
									value: null,
									clearValue: e.target.checked,
								},
								recordKey
							)
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</div>
		</Stack>
	);
};

export default ClearRecordItem;
