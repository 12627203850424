import * as React from "react";
import {
	Collapse,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

interface MenuCollapsableListItemsProps {
	drawerOpen: boolean;
	mainPrimaryText: string;
	mainSecondaryText?: string;
	icon: React.ReactNode;
	listItems: React.ReactNode;
}

const MenuCollapsableListItems: React.FC<MenuCollapsableListItemsProps> = ({
	drawerOpen,
	mainPrimaryText,
	mainSecondaryText,
	icon,
	listItems,
}) => {
	const [open, setOpen] = React.useState(false);

	const handleListItemMainClick = () => {
		setOpen(!open);
	};

	return (
		<React.Fragment>
			<ListItemButton onClick={handleListItemMainClick}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText
					primary={mainPrimaryText}
					primaryTypographyProps={{
						fontSize: 15,
						fontWeight: "medium",
						lineHeight: "10px",
						mb: "4px",
					}}
					secondary={mainSecondaryText}
					secondaryTypographyProps={{
						noWrap: true,
						fontSize: 12,
						lineHeight: "10px",
					}}
				/>
				<KeyboardArrowDown
					sx={{
						mr: -1,
						transform: open ? "rotate(-180deg)" : "rotate(0)",
						transition: "0.2s",
					}}
				/>
			</ListItemButton>
			{drawerOpen && (
				<Collapse in={open} timeout="auto" unmountOnExit>
					{listItems}
				</Collapse>
			)}
		</React.Fragment>
	);
};

export default MenuCollapsableListItems;
