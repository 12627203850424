import menu from "./menu.json";
import msal from "./msal.json";
import order from "./order.json";
import general from "./general.json";
import quota from "./quota.json";
import europe from "./europe.json";
import quotation from "./quotation.json";
import followUpChl from "./followUpChl.json";
import downloadCenter from "./download-center.json";

const enLocales = {
	menu,
	msal,
	order,
	general,
	quota,
	europe,
	quotation,
	followUpChl,
	downloadCenter,
};

export default enLocales;
