import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import CommercialCommentsForm from "./Form/CommercialCommentsForm";
import GeneralCommentsForm from "./Form/GeneralCommentsForm";

const BusinessUserContent: React.FC = () => {
	return (
		<>
			<Grid item xs={12}>
				<CommercialCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<GeneralCommentsForm />
			</Grid>
		</>
	);
};

export default observer(BusinessUserContent);
