import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import DateTimeSpan from "../../../../components/DateTimeSpan/DateTimeSpan";

export const getColumns = (translate: any): Array<GridColumnProps> => {
	return [
		{
			title: translate("creationDate"),
			field: "creationDate",
			editable: false,
			cell: (props) => dateCell(props),
			width: "200px",
		} as GridColumnProps,
		{
			field: "message",
			title: translate("message"),
			editable: false,
			// width: "800px",
		} as GridColumnProps,
	] as Array<GridColumnProps>;
};

const dateCell = (props: GridCellProps) => {
	return (
		<td>
			{
				<DateTimeSpan
					date={props.dataItem.creationDate}
					format={"DD/MM/YYYY HH:mm:ss"}
					defaultValue="-"
					convertUtc
				/>
			}
		</td>
	);
};
