import { CTADMVEN_FOLLOWUP_CHL } from "../../constants/routes";
import { MenuRouteProps } from "../../utils/GenericInterfaces";
import { AuthGroupsType } from "../../constants/AuthGroups/authGroups";
import { AppRouteProps } from "../routes";
import _ from "lodash";
import { TFunction } from "i18next";
import { groupsAllowedToSeeFollowUpChlMenu } from "../../constants/AuthGroups/followUpChlPage";
import Index from "./List/containers/Index";
import TruckIndex from "./Details/containers/TruckIndex";
import EuropeIndex from "./Details/containers/EuropeIndex";
import { getMenuAuthorized, getRoute } from "../../utils/RoutesUtil";
import MenuListSubHeader from "../../components/header/MenuListSubHeader";

export const followUpChlAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeFollowUpChlMenu,
]);

const followUpChlRoute: MenuRouteProps = {
	path: CTADMVEN_FOLLOWUP_CHL,
	claims: groupsAllowedToSeeFollowUpChlMenu,
	element: <Index />,
	label: "truck",
	isCollapsable: true,
};

export function getFollowUpChlMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean
) {
	if (!isAuthorizedGroup(followUpChlAllClaims)) {
		return <></>;
	}

	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("chile")} />
			{getMenuAuthorized(followUpChlRoute, t, isAuthorizedGroup)}
		</>
	);
}

export const followUpChileRoutes: Array<AppRouteProps> = [
	...getTruckListRoute(followUpChlRoute),
];

function getTruckListRoute(
	followUpListRoute: MenuRouteProps
): Array<AppRouteProps> {
	return [
		getRoute(followUpListRoute),
		getRoute({
			...followUpListRoute,
			path: `${followUpListRoute.path}/BR/:id`,
			element: <TruckIndex />,
		}),
		getRoute({
			...followUpListRoute,
			path: `${followUpListRoute.path}/EU/:id`,
			element: <EuropeIndex />,
		}),
	];
}
