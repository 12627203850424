import React from "react";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { InvoicingDeliveryMarketCompanySectionProps } from "./Interfaces/InvoicingDeliveryMarketCompanySection";
import BaseInvoicingDeliveryMarketCompanySection from "./BaseInvoicingDeliveryMarketCompanySection";

const InvoicingDeliveryMarketCompanySection: React.FC<
	InvoicingDeliveryMarketCompanySectionProps
> = ({ readOnlyFields, loadInvoicingDeliveryMarketCompany }) => {
	const [loading, setLoading] = React.useState(true);

	const fetchInvoicingDeliveryMarketCompany = React.useCallback(async () => {
		if (!readOnlyFields.id) {
			await loadInvoicingDeliveryMarketCompany().finally(() => setLoading(false));
		}
		else {
			await loadInvoicingDeliveryMarketCompany(readOnlyFields.id).finally(() => setLoading(false));
		}
	}, [loadInvoicingDeliveryMarketCompany]);

	React.useEffect(() => {
		fetchInvoicingDeliveryMarketCompany();
	}, [fetchInvoicingDeliveryMarketCompany]);

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<BaseInvoicingDeliveryMarketCompanySection readOnlyFields={readOnlyFields} />
			)}
		</>
	);
};

export default observer(InvoicingDeliveryMarketCompanySection);
