import { BaseEditableProps } from "../../interfaces/Editable/BaseEditableDetails";

export const baseEditableModel: BaseEditableProps = {
	id: "",
	finalCustomer: "",
	bodybuilder: "",
	branchOffice: "",
	salesman: "",
	generalComment: "",
	zoneId: "",
};

export function baseEditableModelBuilder(data: BaseEditableProps) {
	if (!data) return baseEditableModel;
	return {
		id: data.id ?? "",
		bodybuilder: data.bodybuilder ?? "",
		finalCustomer: data.finalCustomer ?? "",
		branchOffice: data.branchOffice ?? "",
		salesman: data.salesman ?? "",
		generalComment: data.generalComment ?? "",
		zoneId: data.zoneId ?? "",
	} as BaseEditableProps;
}
