import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { VariantsSectionProps } from "../../interfaces/Sections/VariantSection";
import { getLanguage } from "../../../../../utils/Language";
import { QuotationVariantProps } from "../../interfaces/QuotationVariantProps";
import { VariantComponent } from "./Component/VariantComponent";

const VariantsSection: React.FC<VariantsSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation(["quotation"]);
	const { i18n } = useTranslation();
	const language = getLanguage(i18n);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.variants.title")}
			</Grid>
			<VariantComponent
				variants={readOnlyFields.variants.filter(
					(variant: QuotationVariantProps) => !variant.isOptional
				)}
				language={language}
				t={t}
			/>
		</>
	);
};

export default observer(VariantsSection);
