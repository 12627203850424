import { observer } from "mobx-react";
import React from "react";
import { useExportReadStore } from "../stores/ExportReadStore";
import DealerExportFilter from "../../../../components/Filters/DealerExportFilter";
import QuotaReadFilters from "../../../../components/Sections/QuotaReadFilters";

const ExportReadFilters: React.FC = () => {
	const { setFilterByKeyOnChange, setFilter, filter, filterIsValid } =
		useExportReadStore();

	return (
		<QuotaReadFilters
			setFilterByKeyOnChange={setFilterByKeyOnChange}
			setFilter={setFilter}
			filter={filter}
			filterIsValid={filterIsValid} >
			<DealerExportFilter
				set={(e) =>
					setFilterByKeyOnChange("dealerRegion", e.value, e.isValid)
				}
				value={filter.dealerRegion}
			/>
		</QuotaReadFilters>
	);
};

export default observer(ExportReadFilters);
