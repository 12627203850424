import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import PreFilter from "../../../../../components/PreFilter/PreFilter";
import { useTranslation } from "react-i18next";
import { groupsAllowedToSeeCountryPreFilterOption } from "../../../../../constants/AuthGroups/orderDetails";
import ChassiAOPreFilter from "../../../../../components/PreFilter/containers/ChassiAOPreFilter";
import CountryPreFilter from "../../../../../components/PreFilter/containers/CountryPreFilter";
import CommercialModelPreFilter from "../../../../../components/PreFilter/containers/CommercialModelPreFilter";
import { ChassiType } from "../../../../../constants/chassiAoOmFoConst";

const BusBatchEdition: React.FC = () => {
	const {
		preFilterCountryStore,
		preFilterChassiAOStore,
		preFilterCommercialModelStore,
	} = useStores();
	const { getOptions: getOptionsCountry } = preFilterCountryStore;

	const { isAuthorizedGroup } = useAuthStore();
	const { menu } = useStores().listStore;
	const { t } = useTranslation("order");

	const {
		applyFilters,
		hasPreFiltersApplied,
		hasFormatError,
		filters,
		removeFilter,
		cleanSpecificPreFilters,
		onUndo,
	} = useStores().filterStore;

	const shouldShowCountryPreFilter = isAuthorizedGroup(
		groupsAllowedToSeeCountryPreFilterOption
	);

	React.useEffect(() => {
		if (shouldShowCountryPreFilter) {
			getOptionsCountry(menu);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu]);

	React.useEffect(() => {
		return () => {
			cleanSpecificPreFilters();
		};
	}, []);

	return (
		<PreFilter
			id="orderbook-pre-filter-edition"
			title={t("preFilters.quickFilter", { ns: "order" })}
			onUndo={onUndo}
			onSubmit={applyFilters}
			hasPreFiltersApplied={hasPreFiltersApplied}
			hasFormatError={hasFormatError}
			filters={filters}
			chassiType={ChassiType.ChassiFOOM}
			removeFilter={removeFilter}
		>
			<ChassiAOPreFilter
				vehicleType="Truck"
				type={ChassiType.ChassiFOOM}
				preFilterStore={preFilterChassiAOStore}
			/>
			{shouldShowCountryPreFilter && (
				<CountryPreFilter preFilterStore={preFilterCountryStore} />
			)}
			<CommercialModelPreFilter
				preFilterStore={preFilterCommercialModelStore}
			/>
		</PreFilter>
	);
};

export default observer(BusBatchEdition);
