/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { CircularProgress, Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";
import { ReservationsProps } from "../../interfaces/ReservationsProps";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { useQuotationDetailStore } from "../../store/QuotationDetailStore";
import { toast } from "react-toastify";
import { ReservationSectionProps } from "../../interfaces/Sections/ReservationSection";

const ReservationsSection: React.FC<ReservationSectionProps> = (details) => {
	const { t } = useTranslation(["quotation"]);

	const {
		setReservations,
		checkAndSetReservationsHasErrors: checkAndSetHasErrors,
		getFactoryOrderByChassi,
		getChassiByFactoryOrder,
		hasDuplicate,
		setChassiError,
		setHasFoError,
		editableDetails,
	} = useQuotationDetailStore();

	const [loadingApplication, setLoadingApplication] = React.useState(false);

	const { editable } = details;

	React.useEffect(() => {
		checkAndSetHasErrors();
	}, [
		editableDetails.reservations.map((x) => x.chassiError),
		editableDetails.reservations.map((x) => x.foError),
		hasDuplicate,
		checkAndSetHasErrors,
	]);

	const chassiFormValidator = (value: string) => {
		if (!value || value.length === 0) return "";

		if (value.length !== 7)
			return t("validator.exactlyNumber", { number: 7, ns: "general" });

		if (
			/^\d+$/.test(value[0]) || //First digit must be letter
			!/^\d+$/.test(value.slice(1))
		)
			// Others digits must be number
			return t("validator.invalidFormat", { ns: "general" });

		return "";
	};

	const foFormValidator = (value: string) => {
		if (!value || value.length === 0) return "";

		let areOnlyNumbers = /^\d+$/.test(value);

		if (value.length !== 14)
			return t("validator.exactlyNumber", { number: 14, ns: "general" });

		if (!areOnlyNumbers) return t("validator.numberType", { ns: "general" });
		return "";
	};

	const onChassiChange = async (chassi: string, id: string) => {
		if (chassiFormValidator(chassi).length > 0) return;

		setLoadingApplication(true);

		await getFactoryOrderByChassi(chassi, id, t)
			.catch((err) => toast.error(err.response.data))
			.finally(() => setLoadingApplication(false));
	};

	const onFactoryOrderChange = async (factoryOrder: string, id: string) => {
		if (foFormValidator(factoryOrder).length > 0) return;

		if (factoryOrder.length === 0) {
			let appMerge = editableDetails.reservations.find((x) => x.id === id);

			if (!appMerge) return;

			appMerge.chassi = "";
			appMerge.deliveryDateProposal = null;
			appMerge.invoiceDateProposal = null;
			appMerge.commercialModel = "";
			appMerge.orderId = "";
			appMerge.europeOrderSASId = "";
		}

		setLoadingApplication(true);

		await getChassiByFactoryOrder(factoryOrder, id, t)
			.catch((err) => toast.error(err.response.data))
			.finally(() => setLoadingApplication(false));
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.reservations.title")}
				{loadingApplication && <CircularProgress color="inherit" />}
			</Grid>
			<Grid item xs={12}>
				{hasDuplicate && (
					<span className="error">
						{t("validator.fieldDuplicated", { ns: "general" })}
					</span>
				)}
			</Grid>
			{editableDetails.reservations?.length > 0 ? (
				editableDetails.reservations.map(
					(appMerge: ReservationsProps, idx: number) => {
						const canChangeVehicle = editable && !appMerge.adelanto;
						return (
							<React.Fragment key={idx}>
								<Grid item xs={3}>
									<TextForm
										label={
											idx === 0
												? t("details.sections.reservations.factoryOrder")
												: ""
										}
										value={appMerge.factoryOrder || ""}
										validator={foFormValidator}
										name="factoryOrder"
										inputProps={{ maxLength: 14 }}
										onChange={(
											e: React.ChangeEvent<
												HTMLTextAreaElement | HTMLInputElement
											>,
											_?: boolean,
											error?: string
										) => {
											setReservations("factoryOrder", appMerge, e.target.value);
											setHasFoError(error || "", appMerge.id);
											onFactoryOrderChange(e.target.value, appMerge.id);
										}}
										editable={canChangeVehicle}
									/>
									{appMerge.foError ===
										t("validator.notFound", { ns: "general" }) && (
										<span className="error">{appMerge.foError}</span>
									)}
								</Grid>
								<Grid item xs={3}>
									<TextForm
										label={
											idx === 0 ? t("details.sections.reservations.chassi") : ""
										}
										value={appMerge.chassi || ""}
										name="chassi"
										validator={chassiFormValidator}
										inputProps={{ maxLength: 7 }}
										onChange={(
											e: React.ChangeEvent<
												HTMLTextAreaElement | HTMLInputElement
											>,
											_?: boolean,
											error?: string
										) => {
											setReservations("chassi", appMerge, e.target.value);
											setChassiError(error || "", appMerge.id);
											onChassiChange(e.target.value, appMerge.id);
										}}
										editable={canChangeVehicle}
									/>
									{appMerge.chassiError ===
										t("validator.notFound", { ns: "general" }) && (
										<span className="error">{appMerge.chassiError}</span>
									)}
								</Grid>
								<Grid item xs={2}>
									<TextForm
										label={
											idx === 0
												? t("details.sections.reservations.commercialModel")
												: ""
										}
										value={appMerge.commercialModel || ""}
										name="commercialModel"
									/>
								</Grid>
								<Grid item xs={2}>
									<DateForm
										label={
											idx === 0
												? t(
														"details.sections.reservations.deliveryDateProposal"
													)
												: ""
										}
										value={appMerge.deliveryDateProposal || null}
										inputFormat="dd/MM/yy"
										editable={editable}
										onChange={(dataItem: any) =>
											setReservations(
												"deliveryDateProposal",
												appMerge,
												dataItem
											)
										}
									/>
								</Grid>
								<Grid item xs={2}>
									<DateForm
										label={
											idx === 0
												? t("details.sections.reservations.invoiceDateProposal")
												: ""
										}
										value={appMerge.invoiceDateProposal || null}
										inputFormat="dd/MM/yy"
										editable={editable}
										onChange={(dataItem: any) =>
											setReservations("invoiceDateProposal", appMerge, dataItem)
										}
									/>
								</Grid>
							</React.Fragment>
						);
					}
				)
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(ReservationsSection);
