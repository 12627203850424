import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { MktCompanyCommercialSectionProps } from "../../interfaces/Sections/MktCompanyCommercialSection";
import SwitchForm from "../../../../../components/FormInputs/SwitchForm";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getCurrencyOptions } from "../../../../../utils/SelectOptions";

const MktCompanyCommercialSection: React.FC<
	MktCompanyCommercialSectionProps
> = ({ readOnlyFields }) => {
	const { t, i18n } = useTranslation("order");
	const currencyOptions = getCurrencyOptions();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const getPaymentTerms = (language: string) => {
		switch (language) {
			case "pt-BR":
				return readOnlyFields.paymentFormPtBr;
			case "es-ES":
				return readOnlyFields.paymentFormEsEs;
			default:
				return readOnlyFields.paymentFormEnUs;
		}
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.mktCompanyCommercial.title")}
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.mktCompanyCommercial.invoiceProposalDate")}
					value={readOnlyFields.invoiceProposalDate}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextForm
					label={t(
						"details.sections.mktCompanyCommercial.invoiceProposalNumber"
					)}
					value={readOnlyFields.invoiceProposalNumber}
					name="invoiceProposalNumber"
				/>
			</Grid>
			<Grid item xs={3}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.comission")}
					value={readOnlyFields.comission}
					name="comission"
					type="number"
				/>
			</Grid>
			<Grid item xs={3}>
				<SwitchForm
					label={t("details.sections.mktCompanyCommercial.usedDelivery")}
					checked={readOnlyFields.usedDelivery}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.commercialCircular")}
					value={readOnlyFields.commercialCircular}
					name="commercialCircular"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.paymentForm")}
					value={getPaymentTerms(language)}
					name="paymentForm"
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.currency")}
					labelId={"sections.mktCompanyCommercial-currency-label"}
					value={readOnlyFields.currency}
					options={currencyOptions}
					hasEditIcon
					hasEmptyDefaultItem
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.finalValue")}
					value={readOnlyFields.finalValue}
					name="finalValue"
					type="number"
				/>
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.vehicleLocation")}
					value={readOnlyFields.vehicleLocationName}
					name="vehicleLocationName"
				/>
			</Grid>
		</>
	);
};

export default observer(MktCompanyCommercialSection);
