import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import {
	groupsAllowedToSeeInvoicingDeliverySection,
	groupsAllowedToSeeReservationAvailabilitySection,
} from "../../../../../constants/AuthGroups/orderDetails";
import { EolRulesInvoicingDeliveryAccordionProps } from "../../interfaces/Accordions/EolRulesInvoicingDeliveryAccordion";
import InvoiceInstructionSection from "../Sections/InvoiceInstructionSection";
import InvoicingDeliverySection from "../Sections/InvoicingDeliverySection";
import ReservationAvailabilitySection from "../Sections/ReservationAvailabilitySection";

const EolRulesInvoicingDeliveryAccordion: React.FC<
	EolRulesInvoicingDeliveryAccordionProps
> = (details) => {
	const { t } = useTranslation("order");
	const { isAuthorizedGroup } = useAuthStore();

	const userCanSeeInvoicingDeliverySection = isAuthorizedGroup(
		groupsAllowedToSeeInvoicingDeliverySection
	);

	const userCanSeeReservationAvailabilitySection = isAuthorizedGroup(
		groupsAllowedToSeeReservationAvailabilitySection
	);

	return (
		<AccordionCard
			id="invoicingAndDeliveryAccordion"
			title={t("details.accordions.eolRulesInvoicingDelivery")}
		>
			{userCanSeeReservationAvailabilitySection && (
				<ReservationAvailabilitySection
					{...details.reservationAvailabilityDetails}
				/>
			)}
			<InvoiceInstructionSection {...details.invoiceInstructionDetails} />
			{(details.userIsOwner || userCanSeeInvoicingDeliverySection) && (
				<InvoicingDeliverySection {...details.invoicingDeliveryDetails} />
			)}
		</AccordionCard>
	);
};

export default observer(EolRulesInvoicingDeliveryAccordion);
