import moment from "moment";
import { TIMEOUT_CSV_BUTTON } from "../constants/csvButtonStorage";

export default function checkButtonTimer(item: string) {
	const currentTime = Date.now();
	let isButtonDisabled = false;

	const buttonDisabledUntil = localStorage.getItem(item);
	if (buttonDisabledUntil) {
		const buttonDisabledUntilDate = new Date(buttonDisabledUntil);

		if (moment(currentTime).isBefore(buttonDisabledUntilDate)) {
			isButtonDisabled = true;
		}
	}

	return isButtonDisabled;
}

export function getCsvButtonTimer() {
	return moment(new Date()).add(TIMEOUT_CSV_BUTTON, "ms").toDate();
}
