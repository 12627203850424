import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import { Checkbox, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const getColumns = (
	translate: any,
	onUpdate: any
): Array<GridColumnProps> => {
	return [
		{
			field: "family",
			title: translate("family"),
		} as GridColumnProps,
		{
			field: "descriptionEnUs",
			title: translate("descriptionEnUs"),
		} as GridColumnProps,
		{
			field: "descriptionPtBr",
			title: translate("descriptionPtBr"),
		} as GridColumnProps,
		{
			field: "descriptionEsEs",
			title: translate("descriptionEsEs"),
		} as GridColumnProps,
		{
			field: "forTruck",
			title: translate("truck"),
			cell: (props) => cell(props, "forTruck"),
		} as GridColumnProps,
		{
			field: "forBus",
			title: translate("bus"),
			cell: (props) => cell(props, "forBus"),
		} as GridColumnProps,
		{
			field: "forFollowUpChl",
			title: translate("followUpChl"),
			cell: (props) => cell(props, "forFollowUpChl"),
		} as GridColumnProps,
		{
			editable: true,
			cell: (props) => actionCell(translate, onUpdate, props),
			width: "130px",
		} as GridColumnProps,
	] as Array<GridColumnProps>;
};

const cell = (props: GridCellProps, field: string) => {
	let isChecked = props.dataItem[field];

	return (
		<td>
			<Checkbox checked={isChecked} disabled={true} />
		</td>
	);
};

const actionCell = (translate: any, onUpdate: any, props: GridCellProps) => {
	return (
		<td>
			<Tooltip title={`${translate("edit")}`} arrow>
				<IconButton onClick={() => onUpdate(props)}>
					<EditIcon />
				</IconButton>
			</Tooltip>
		</td>
	);
};
