import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { ProductionAccordionProps } from "../../../interfaces/Accordions/Europe/ProductionAccordion";
import ProductionDatesSection from "../../Sections/Europe/ProductionDatesSection";

const ProductionAccordion: React.FC<ProductionAccordionProps> = (details) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="productionAccordion"
			title={t("details.accordions.production")}
		>
			<ProductionDatesSection {...details.productionDatesDetails} />
		</AccordionCard>
	);
};

export default observer(ProductionAccordion);
