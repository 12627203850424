import React from "react";
import { observer } from "mobx-react";
import DateForm from "../../../../components/FormInputs/DateForm";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import TimePeriodFilter from "../../../../components/Filters/TimePeriodFilter";
import { useProductionWeekStore } from "../stores/ProductionWeekStore";
import { getTimePeriodOptionsForProductionWeek } from "../../../../utils/SelectOptions";
import LineFilter from "../../../../components/Filters/LineFilter";
import { Search } from "@mui/icons-material";

const ProductionWeekFilters: React.FC = () => {
	const { t } = useTranslation("general");

	const { filter, setFilterByKeyOnChange, setFilter, filterIsValid } =
		useProductionWeekStore();

	return (
		<>
			<Grid container sx={{ display: "flex", height: "90px" }} spacing={2}>
				<Grid item>
					<TimePeriodFilter
						set={(e) =>
							setFilterByKeyOnChange("timePeriod", e.value, e.isValid)
						}
						value={filter.timePeriod}
						timePeriodOptions={getTimePeriodOptionsForProductionWeek(t)}
					/>
				</Grid>

				<Grid item>
					<DateForm
						editable
						label={t("startDate")}
						inputFormat="dd/MM/yyyy"
						value={filter.date}
						onChange={(e: any) => {
							setFilterByKeyOnChange("date", e, !isNaN(Date.parse(e)));
						}}
					/>
				</Grid>

				<Grid item>
					<LineFilter
						set={(e) => setFilterByKeyOnChange("line", e.value, e.isValid)}
						value={filter.line}
					/>
				</Grid>

				<Grid item sx={{ alignSelf: "center" }}>
					<Button
						variant="contained"
						onClick={setFilter}
						size="small"
						disabled={!filterIsValid}
					>
						<Search fontSize="small" />
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default observer(ProductionWeekFilters);
