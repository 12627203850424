import { GUID_EMPTY } from "../../../../app/constants/config";
import { OrderMCManagementReadOnlyProps } from "./OrderMCManagementReadOnly";

export const orderMCManagementReadOnlyModel: OrderMCManagementReadOnlyProps = {
	orderId: GUID_EMPTY,
	dealerId: "",
	dealerName: "",
	invoiceProposalNumber: "",
	invoiceProposalDate: null,
	comission: undefined,
	commercialCircular: "",
	usedDelivery: false,
	currency: "",
	paymentForm: "",
	finalValue: undefined,
	vehicleLocationId: "",
	vehicleLocationName: "",
	salesSupportComments: "",
	financialComments: "",
	commercialComments: "",
	accountingComments: "",
	logisticComments: "",
	shippingDate: null,
	billingForecast: null,
	shippingCompany: "",
	destination: "",
	paymentFormEnUs: "",
	paymentFormEsEs: "",
	paymentFormPtBr: "",
	engineeringComments: "",
};

export function orderMCManagementReadOnlyModelBuilder(
	data: any
): OrderMCManagementReadOnlyProps {
	if (!data) return { ...orderMCManagementReadOnlyModel };
	return {
		...orderMCManagementReadOnlyModel,
		dealerId: data.dealerId ?? "",
		dealerName: data.dealerName ?? "",
		invoiceProposalNumber: data.invoiceProposalNumber ?? "",
		invoiceProposalDate: data.invoiceProposalDate ?? null,
		commercialCircular: data.commercialCircular ?? "",
		usedDelivery: data.usedDelivery,
		currency: data.currency ?? "",
		paymentForm: data.paymentForm ?? "",
		comission: data.comission ?? undefined,
		finalValue: data.finalValue ?? undefined,
		vehicleLocationId: data.vehicleLocationId ?? "",
		vehicleLocationName: data.vehicleLocationName ?? "",
		salesSupportComments: data.salesSupportComments ?? "",
		financialComments: data.financialComments ?? "",
		commercialComments: data.commercialComments ?? "",
		accountingComments: data.accountingComments ?? "",
		logisticComments: data.logisticComments ?? "",
		shippingDate: data.shippingDate ?? null,
		billingForecast: data.billingForecast ?? null,
		shippingCompany: data.shippingCompany ?? "",
		destination: data.destination ?? "",
		paymentFormEnUs: data.paymentFormEnUs ?? "",
		paymentFormEsEs: data.paymentFormEsEs ?? "",
		paymentFormPtBr: data.paymentFormPtBr ?? "",
		engineeringComments: data.engineeringComments ?? "",
	};
}
