import { VolvoManagementEditableProps } from "../../interfaces/Editable/VolvoManagementEditableDetails";

export const volvoManagementEditableModel: VolvoManagementEditableProps = {
	orderId: "",
	commercialAreaClassification: "",
	location: "",
	followUp: null,
	responsible: "",
	costCenter: "",
	builded: false,
	purpose: "",
	freeDate: null,
	extraQuota: false,
	priority: false,
};

export function volvoManagementEditableModelBuilder(
	data: VolvoManagementEditableProps,
	orderId: string
): VolvoManagementEditableProps {
	if (!data) return { ...volvoManagementEditableModel, orderId: orderId };
	return {
		...volvoManagementEditableModel,
		...data,
		orderId: orderId,
		commercialAreaClassification: data.commercialAreaClassification ?? "",
		location: data.location ?? "",
		followUp: data.followUp ?? null,
		responsible: data.responsible ?? "",
		costCenter: data.costCenter ?? "",
		purpose: data.purpose ?? "",
		freeDate: data.freeDate ?? null,
	};
}
