import { GridCellProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useInconsistenciesStore } from "../stores/InconsistenciesStore";
import { DataGridApi } from "../../../components/kendo/DataGridApi/DataGridApi";
import { gridConst } from "../../../constants/gridConst";
import { IColumnProps } from "../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import {
	DirectionsBus,
	LocalShipping,
	ReportProblem,
} from "@mui/icons-material";
import { InconsistencyProps } from "../interfaces/InconsistencyProps";

const InconsistenciesGrid: React.FC = () => {
	const { t } = useTranslation("order");
	const { getInconsistencies } = useInconsistenciesStore();

	const VehicleTypeCell = (props: GridCellProps) => {
		const vehicleType = props.dataItem[props.field ?? ""];
		return (
			<td>
				<span>
					{vehicleType === 0 ? (
						<LocalShipping />
					) : vehicleType === 1 ? (
						<DirectionsBus />
					) : (
						<ReportProblem
							color="error"
							titleAccess={t("inconsistency.VehicleTypeUnknown")}
						/>
					)}
				</span>
			</td>
		);
	};

	const InconsistencyCell = (props: GridCellProps) => {
		const inconsistency = props.dataItem[
			props.field ?? ""
		] as InconsistencyProps;
		const errorIcon = inconsistency.hasError ? (
			<ReportProblem
				color="error"
				sx={{ mr: 1 }}
				titleAccess={t(`inconsistency.${inconsistency.error}`)}
			/>
		) : (
			<></>
		);
		return (
			<td>
				{errorIcon}
				<span style={{ verticalAlign: inconsistency.hasError ? "super" : "" }}>
					{inconsistency.value}
				</span>
			</td>
		);
	};

	const columns = [
		{
			field: "vehicleType",
			title: " ",
			alwaysVisible: true,
			width: 40,
			cell: VehicleTypeCell,
		},
		{
			field: "fo",
			title: t("gridFields.factoryOrder"),
			alwaysVisible: true,
		},
		{
			field: "chassi",
			title: t("gridFields.chassi"),
			alwaysVisible: true,
		},
		{
			field: "foDistrict",
			title: t("gridFields.factoryOrderDistrict"),
			alwaysVisible: true,
			sortable: false,
			cell: InconsistencyCell,
		},
		{
			field: "foRegion",
			title: t("gridFields.region"),
			alwaysVisible: true,
			sortable: false,
			cell: InconsistencyCell,
		},
		{
			field: "asWeek",
			title: t("gridFields.asWeek"),
			alwaysVisible: true,
			sortable: false,
			cell: InconsistencyCell,
		},
		{
			field: "commercialModel",
			title: t("gridFields.commercialModel"),
			alwaysVisible: true,
			sortable: false,
			cell: InconsistencyCell,
		},
	] as Array<IColumnProps>;

	return (
		<DataGridApi
			columns={columns}
			fetchData={getInconsistencies}
			pageable={gridConst.pageable}
			pageSize={gridConst.pageSize}
			rowHeight={10}
			reorderable
			resizable
			className="k-grid-lg"
			calculateWidth
		/>
	);
};

export default observer(InconsistenciesGrid);
