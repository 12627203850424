import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import SelectPreFilter from "../components/SelectPreFilter";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";

const FinancialDeliveryPreFilter: React.FC<SelectPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "financialDeliveryPreFilter",
		label = "preFilters.financialDelivery",
	} = props;
	return (
		<SelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			isBooleanOptions={true}
		/>
	);
};

export default observer(FinancialDeliveryPreFilter);
