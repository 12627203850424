import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import { PaqueteProps } from "../../../../Details/interfaces/PaqueteProps";
import { IPreFilterCommonOption } from "../../../../../../components/PreFilter/Interfaces/IPreFilter";

configure({ enforceActions: "always" });

class PreFilterPaqueteStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: Array<string> = [];
	@observable preFilterOptions: Array<IPreFilterCommonOption> = [];

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action private setPaqueteOptions = (data: any) => {
		this.preFilterOptions = [];

		if (!data) return;

		this.preFilterOptions = data.map((m: PaqueteProps) => ({
			value: m.id,
			label: m.paqueteDescription,
		}));
	};

	@action reset = () => {
		this.preFilter = [];
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = []);

	@action get = () =>
		this.preFilter.length === 0 ||
		this.preFilter.length === this.preFilterOptions.length
			? []
			: this.preFilter.map((d: string) =>
					getFilterObject("managementDetail.paqueteId", d, "eq")
				);

	@action set = (paqueteIds: Array<string>) => {
		this.preFilter = paqueteIds;
	};

	@action getOptions = async () => {
		if (this.preFilterOptions.length > 0) return;

		this.loading = true;
		await this.api.paqueteService
			.getPaqueteList()
			.then((rs: any) => {
				this.setPaqueteOptions(toJS(rs.data));
			})
			.finally(() => (this.loading = false));
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const paqueteFilters = this.get();

		if (paqueteFilters) orFiltersGroups.push(paqueteFilters);
	}

	@action hasPreFilter = () => this.preFilter.length > 0;
}

export default PreFilterPaqueteStore;
