import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import GeneralSection from "../Sections/GeneralSection";
import { observer } from "mobx-react";
import { GeneralAccordionProps } from "../../interfaces/Accordions/GeneralAccordion";
import LastUpdateChip from "../../../../../components/LastUpdateChip/LastUpdateChip";

const GeneralAccordion: React.FC<GeneralAccordionProps> = (details) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.general")}
			summaryBody={<LastUpdateChip {...details.lastUpdateDetails} />}
			defaultExpanded
		>
			<GeneralSection {...details.generalDetails} />
		</AccordionCard>
	);
};

export default observer(GeneralAccordion);
