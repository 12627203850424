import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { InvoiceExportationSectionProps } from "../../interfaces/Sections/InvoiceExportationSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";

const InvoiceExportationSection: React.FC<InvoiceExportationSectionProps> = ({
	readOnlyFields,
	loadInvoiceExportation,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);

	const fetchInvoiceExportation = React.useCallback(async () => {
		await loadInvoiceExportation().finally(() => setLoading(false));
	}, [loadInvoiceExportation]);

	React.useEffect(() => {
		fetchInvoiceExportation();
	}, [fetchInvoiceExportation]);

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid className="accordionSubTitle" item xs={12}>
						{t("details.sections.invoiceExportation.title")}
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.invoiceExportation.invoiceNumber")}
							value={readOnlyFields.invoiceExportation.invoiceNumber}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.invoiceExportation.status")}
							value={readOnlyFields.invoiceExportation.statusCode}
						/>
					</Grid>
					<Grid item xs={4}>
						<DateForm
							label={t("details.sections.invoiceExportation.boardingDate")}
							value={readOnlyFields.invoiceExportation.boardingDate}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextForm
							label={t("details.sections.invoiceExportation.importer")}
							value={readOnlyFields.invoiceExportation.importer}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextForm
							label={t("details.sections.invoiceExportation.businessPartner")}
							value={readOnlyFields.invoiceExportation.businessPartner}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(InvoiceExportationSection);
