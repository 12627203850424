import { Box, Grid, Link, Skeleton, Typography } from "@mui/material";
import {
	GridToolbar,
	Grid as KendoGrid,
	GridColumn,
} from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import { QuotaProposalProps } from "../../interfaces/QuotaProposalProps";
import { useVolvoReadStore } from "../../stores/VolvoReadStore";
import { getColumns } from "../../utils/GridUtils";

interface VolvoReadGridProps {
	type: string;
}

const VolvoReadGrid: React.FC<VolvoReadGridProps> = ({ type }) => {
	const { t } = useTranslation("quota");
	const [data, setData] = React.useState<Array<QuotaProposalProps>>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [loadingPage, setLoadingPage] = React.useState<boolean>(false);
	const {
		getQuotaProposalByType,
		getQuotaProposalOrdersCsvVolvo,
		brMarketTotal,
		allMarketTotal,
		line,
		date,
		timePeriod,
		dateType,
	} = useVolvoReadStore();

	const fetchQuotaProposal = React.useCallback(async () => {
		setLoading(true);
		setData([]);
		if (type === "BR" || type === "ALL") return;
		const result = await getQuotaProposalByType(type).finally(() =>
			setLoading(false)
		);
		setData(result);
	}, [getQuotaProposalByType, type]);

	const fetchQuotaBrMarkets = React.useCallback(() => {
		if (type === "BR") {
			if (brMarketTotal.length < timePeriod) return;
			setData(brMarketTotal);
			setLoading(false);
		}
		return;
	}, [brMarketTotal, timePeriod, type]);

	const fetchQuotaAllMarkets = React.useCallback(() => {
		if (type === "ALL") {
			if (allMarketTotal.length < timePeriod) return;
			setData(allMarketTotal);
			setLoading(false);
		}
		return;
	}, [allMarketTotal, timePeriod, type]);

	React.useEffect(() => {
		fetchQuotaProposal();
	}, [fetchQuotaProposal, line, date, timePeriod, dateType]);

	React.useEffect(() => {
		fetchQuotaBrMarkets();
	}, [fetchQuotaBrMarkets, brMarketTotal]);

	React.useEffect(() => {
		fetchQuotaAllMarkets();
	}, [fetchQuotaAllMarkets, allMarketTotal]);

	const getCustomCell = (props: any, quota: any) => {
		if (props.dataItem.volvoVDB === 0) {
			return (
				<td>
					<span>{props.dataItem.volvoVDB}</span>
				</td>
			);
		}

		return (
			<td>
				<Link
					className="link"
					component="button"
					onClick={async () => {
						setLoadingPage(true);
						await getQuotaProposalOrdersCsvVolvo(
							props.dataItem.region,
							quota.period,
							quota.type,
							t
						);
						setLoadingPage(false);
					}}
				>
					<span> {props.dataItem.volvoVDB}</span>
				</Link>
			</td>
		);
	};

	return (
		<Grid item lg={12} key={`row-${type}`}>
			<LoadingPage loading={loadingPage} />
			{!loading && (
				<Box sx={{ display: "flex" }}>
					{data.map((quota, idx) => (
						<Grid item sx={{ width: "390px" }} key={`div-${type}-${idx}`}>
							<KendoGrid
								data={quota.data}
								scrollable="none"
								className="k-grid-sm"
							>
								<GridToolbar>
									<Typography variant="subtitle1" p={0} m={0}>{`${moment(
										quota.period
									).format("MM/YYYY")} ${quota.type} (${line})`}</Typography>
								</GridToolbar>
								{getColumns(quota, t).map((col: any, i: number) => {
									if (col.field && col.field === "volvoVDB")
										return (
											<GridColumn
												key={i}
												{...col}
												field="volvoVDB"
												cell={(dataItem) => getCustomCell(dataItem, quota)}
											/>
										);
									return <GridColumn key={i} {...col} />;
								})}
							</KendoGrid>
						</Grid>
					))}
				</Box>
			)}
			{loading && <Skeleton variant="rounded" width="100%" height={60} />}
		</Grid>
	);
};

export default observer(VolvoReadGrid);
