import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface StickyFooterProps {
	systemName?: string;
	systemVersion?: string;
}

const StickyFooter: React.FC<StickyFooterProps> = ({
	systemName,
	systemVersion,
}) => {
	return (
		<Box
			component="footer"
			sx={{
				textAlign: "end",
				position: "absolute",
				bottom: 0,
				width: "100%",
			}}
		>
			<Typography
				variant="body2"
				color="text.secondary"
				sx={{
					lineHeight: "2.5rem",
					verticalAlign: "middle",
					paddingRight: 4,
				}}
			>
				{"Copyright © Volvo do Brasil"}
				{systemName && ` - ${systemName}`}
				{systemVersion && ` [${systemVersion}]`}
			</Typography>
		</Box>
	);
};

export default StickyFooter;
