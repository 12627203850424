import React from "react";
import { observer } from "mobx-react";
import { Button } from "@mui/material";

type ApplyButtonProps = {
	id: string;
	title: string;
	onSubmit: (() => void) | ((e: any) => void);
	startIcon: React.ReactNode;
	color?:
		| "error"
		| "inherit"
		| "primary"
		| "secondary"
		| "success"
		| "info"
		| "warning"
		| undefined;
	disabled?: boolean;
};

const ApplyButton: React.FC<ApplyButtonProps> = ({
	id,
	title,
	onSubmit,
	startIcon,
	color,
	disabled,
}) => {
	return (
		<Button
			id={`apply-btn-${id}`}
			variant="contained"
			onClick={onSubmit}
			size="small"
			startIcon={startIcon}
			color={color}
			disabled={disabled}
		>
			{title}
		</Button>
	);
};

export default observer(ApplyButton);
