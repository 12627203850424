import React, { useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Grid, Tooltip } from "@mui/material";
import DateForm from "../../FormInputs/DateForm";
import { handleDateChange } from "../../../utils/DateUtils";
import { DateRangePreFilterProps } from "../Interfaces/DateRangePreFilterProps";
import { ddMMyyyy } from "../../../constants/dateFormats";

const DateRangePreFilter: React.FC<DateRangePreFilterProps> = ({
	preFilterStore,
	startDate = moment().startOf("month").month(0).year(2020).toDate(),
	endDate = moment().startOf("month").month(0).year(2030).toDate(),
	xs = 6,
	sm = 6,
	md = 3,
	lg = 2,
	xl = 2,
	labelEnd = "",
	label = "",
}) => {
	const { setStart, setEnd, preFilter } = preFilterStore;
	const [startDateIsValid, setStartDateIsValid] = useState<boolean>(preFilter);

	return (
		<>
			<Tooltip title={label} placement="top">
				<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
					<DateForm
						editable
						label={label}
						inputFormat={ddMMyyyy}
						value={preFilter?.startDate}
						onChange={(e: any) =>
							handleDateChange(
								e,
								ddMMyyyy,
								setStart,
								setStartDateIsValid,
								setEnd
							)
						}
						size="small"
						minDate={startDate}
						maxDate={endDate}
					/>
				</Grid>
			</Tooltip>
			<Tooltip title={labelEnd} placement="top">
				<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
					<DateForm
						editable
						label={labelEnd}
						inputFormat={ddMMyyyy}
						value={preFilter?.endDate}
						onChange={(e: any) => handleDateChange(e, ddMMyyyy, setEnd)}
						size="small"
						disabled={!(startDateIsValid && preFilter?.startDate)}
						minDate={moment(preFilter?.startDate).add(1, "days").toDate()}
						maxDate={endDate}
					/>
				</Grid>
			</Tooltip>
		</>
	);
};

export default observer(DateRangePreFilter);
