import CtadmvenApi from "../../apis/CtadmvenApi";
import { VariantAdminPutProps } from "../../pages/Parameters/Variants/interfaces/VariantAdminPutProps";
import { VariantBasePutProps } from "../../pages/Parameters/Variants/interfaces/VariantBasePutProps";

const base = "/Variant";

export class VariantService {
	constructor(private api: CtadmvenApi) {}

	getVariants = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}`);

	update = async (variant: VariantAdminPutProps): Promise<any> =>
		this.api.ctadmvenClient.put(base, variant);

	updateForChl = async (variant: VariantBasePutProps): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/updateForChl`, variant);
}
