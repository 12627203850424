import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { TruckEditableProps } from "../../pages/Order/Details/interfaces/Editable/TruckEditableDetails";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";
import { GeneralCommentEditable } from "../../pages/Order/Details/interfaces/Editable/GeneralCommentEditable";

interface IOrderService {
	listPaginated(dataState: State, menu: string): Promise<any>;
	getDetails(id: string, menuKey: string): Promise<any>;
	getDetailsVariants(id: string, menuKey: string): Promise<any>;
	getDetailsVariantsRespecList(id: string, menuKey: string): Promise<any>;
	getQuotaDetails(id: string, menuKey: string): Promise<any>;
	getReservationAvailabilityDetails(id: string, menuKey: string): Promise<any>;
	update(orderDetails: TruckEditableProps, menuKey: string): Promise<any>;
	updateGeneralComment(
		generalCommentProps: GeneralCommentEditable,
		menuKey: string
	): Promise<any>;
	updateBatch(
		dataState: State,
		orderDetails: any,
		menuKey: string
	): Promise<any>;
	csvExport(
		dataState: State,
		menuKey: string,
		csvOptions: CsvOptionsProps
	): Promise<any>;
	updateZone(id: string, zoneId: string): Promise<any>;
	getVehicleInfoByChassi(chassi: string, region: string): Promise<any>;
	getVehicleInfoByFactoryOrder(
		factoryOrder: string,
		region: string
	): Promise<any>;
	getInconsistencies(dataState: State): Promise<any>;
}

const base = "/order";

export class OrderService implements IOrderService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State, menu: string): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list/${menu}?${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${menuKey}/${id}`);

	getDetailsVariants = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variants/${menuKey}/${id}`);

	getDetailsVariantsRespecList = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/respecVariant/${menuKey}/${id}`);

	getQuotaDetails = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/quota/${menuKey}/${id}`);

	getReservationAvailabilityDetails = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/reservationAvailability/${menuKey}/${id}`
		);

	update = async (
		orderDetails: TruckEditableProps,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/${menuKey}`,
			JSON.stringify(orderDetails)
		);

	updateZone = async (id: string, zoneId: string): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/zone/${id}`, JSON.stringify(zoneId));

	updateGeneralComment = (
		generalCommentProps: GeneralCommentEditable,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/GeneralComments/${menuKey}`,
			JSON.stringify(generalCommentProps)
		);

	updateBatch = async (
		dataState: State,
		orderDetails: any,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batch/${menuKey}?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(orderDetails)
		);

	csvExport = async (
		dataState: State,
		menuKey: string,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv/${menuKey}?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	getVehicleInfoByChassi = async (
		chassi: string,
		region: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/vehicleInfoByChassi/${chassi}?region=${region}`
		);

	getVehicleInfoByFactoryOrder = async (
		factoryOrder: string,
		region: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/vehicleInfoByFactoryOrder/${factoryOrder}?region=${region}`
		);

	getInconsistencies = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/listInconsistencies?${toDataSourceRequestString(dataState)}`
		);
}
