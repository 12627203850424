import CtadmvenApi from "../../apis/CtadmvenApi";
import { ProductionWeekCalendarProps } from "../../pages/Parameters/ProductionWeek/interfaces/ProductionWeekCalendarProps";

interface IParametersService {
	getProductionWeekCalendar(
		productionLine: string,
		initialDate: string,
		monthsToAdd: number
	): Promise<any>;
	updateProductionWeekCalendar(
		calendar: ProductionWeekCalendarProps
	): Promise<any>;
}
const base = "/Calendar";

export class ParametersService implements IParametersService {
	constructor(private api: CtadmvenApi) {}

	getProductionWeekCalendar = async (
		productionLine: string,
		initialDate: string,
		monthsToAdd: number
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}?productionLine=${productionLine}&initialDate=${initialDate}&monthsToAdd=${monthsToAdd}`
		);

	updateProductionWeekCalendar = async (
		calendar: ProductionWeekCalendarProps
	): Promise<any> => this.api.ctadmvenClient.put(base, calendar);
}
