import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import { HashRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import I18nProvider from "./app/i18n/I18nProvider";
import OthersIntlProvider from "./app/othersIntl/OthersIntlProvider";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<HashRouter basename={process.env.PUBLIC_URL}>
				<CookiesProvider>
					<I18nProvider>
						<OthersIntlProvider>
							<App />
						</OthersIntlProvider>
					</I18nProvider>
				</CookiesProvider>
			</HashRouter>
		</MsalProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
