import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { ReservationAvailabilitySectionProps } from "../../interfaces/Sections/ReservationAvailabilitySection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { getBooleanOptions } from "../../../../../utils/SelectOptions";
import SelectForm from "../../../../../components/FormInputs/SelectForm";

const ReservationAvailabilitySection: React.FC<
	ReservationAvailabilitySectionProps
> = ({ readOnlyFields, loadReservationAvailability }) => {
	const { t } = useTranslation(["order", "general"]);

	const [loading, setLoading] = React.useState(true);

	const fetchReservationAvailability = React.useCallback(async () => {
		await loadReservationAvailability(readOnlyFields.id).finally(() => setLoading(false));
	}, [loadReservationAvailability]);

	React.useEffect(() => {
		fetchReservationAvailability();
	}, [fetchReservationAvailability]);

	const booleanOptions = getBooleanOptions(t);

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid className="accordionSubTitle" item xs={12}>
						{t("details.sections.reservationAvailability.title")}
					</Grid>
					<Grid item xs={2}>
						<SelectForm
							label={t("details.sections.reservationAvailability.available")}
							labelId={"sections.reservationAvailability-available-label"}
							value={readOnlyFields.available}
							options={booleanOptions}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextForm
							label={t(
								"details.sections.reservationAvailability.shineDealerRegion"
							)}
							value={readOnlyFields.shineDealerRegion}
						/>
					</Grid>
					<Grid item xs={5}>
						<DateForm
							label={t("details.sections.reservationAvailability.shine")}
							value={readOnlyFields.shine}
						/>
					</Grid>
					<Grid item xs={2}>
						<SelectForm
							label={t("details.sections.reservationAvailability.reserved")}
							labelId={"sections.reservationAvailability-reserved-label"}
							value={readOnlyFields.reserved}
							options={booleanOptions}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextForm
							label={t(
								"details.sections.reservationAvailability.reservationDealerRegion"
							)}
							value={readOnlyFields.reservationDealerRegion}
						/>
					</Grid>
					<Grid item xs={5}>
						<DateForm
							label={t(
								"details.sections.reservationAvailability.reservationCreationDate"
							)}
							value={readOnlyFields.reservationCreationDate}
						/>
					</Grid>
					<Grid item xs={7}></Grid>
					<Grid item xs={5}>
						<DateForm
							label={t(
								"details.sections.reservationAvailability.reservationExpiryDate"
							)}
							value={readOnlyFields.reservationExpiryDate}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(ReservationAvailabilitySection);
