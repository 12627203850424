import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

const cleanStore = (store: any) => store.clean();

const cleanFilterStore = (fieldToRemove: string, rootStore: any) => {
    const storeMap: { [key: string]: any } = {
        'order.chassi': rootStore.preFilterChassiAOStore,
        'chassi': rootStore.preFilterChassiAOStore,
        'omNumber': rootStore.preFilterChassiAOStore,
        'order.factoryOrder': rootStore.preFilterChassiAOStore,
        'factoryOrder': rootStore.preFilterChassiAOStore,
        'order.foDealerRegion': rootStore.preFilterRegionStore,
        'order.foOriginalCustomerOM': rootStore.preFilterCustomerStoreOM,
        'customerOM': rootStore.preFilterCustomerOMStore,
        'order.finalCustomer': rootStore.preFilterCustomerStoreSAS,
        'customer': rootStore.preFilterCustomerSASStore,
        'order.busApplication': rootStore.preFilterBusApplicationStore,
        'order.asWeek': rootStore.preFilterAsWeekStore,
        'asWeek': rootStore.preFilterAsWeekStore,
        'order.commercialModel': rootStore.preFilterCommercialModelStore,
        'commercialModel': rootStore.preFilterCommercialModelStore,
        'order.lineSequenceDate': rootStore.preFilterLineSequenceDateStore,
        'lineSequenceDate': rootStore.preFilterLineSequenceDateStore,
        'order.lineCode': rootStore.preFilterLineStore,
        'lineCode': rootStore.preFilterLineStore,
        'countryName': rootStore.preFilterCountryStore,
        'order.deliveryProposal': rootStore.preFilterDeliveryProposalStore,
        'deliveryProposal': rootStore.preFilterDeliveryProposalStore,
        'order.availabilityDate': rootStore.preFilterMonthAvailabilityStore,
        'availabilityDate': rootStore.preFilterMonthAvailabilityStore,
        'deliveryProbability': rootStore.preFilterDeliveryProbabilityStore,
        'managementDetail.billingForecast': rootStore.preFilterPrevisionBillingMonthStore,
        'firmBilling': rootStore.preFilterFirmBillingStore,
        'financialDelivery': rootStore.preFilterFinancialDeliveryStore,
        'physicalDelivery': rootStore.preFilterPhysicalDeliveryStore,
        'finalDelivery': rootStore.preFilterFinalDeliveryStore,
        'managementDetail.dealerId': rootStore.preFilterDealerStore,
        'managementDetail.destination': rootStore.preFilterDestinationStore,
        'managementDetail.currency': rootStore.preFilterCurrencyStore,
        'managementDetail.paymentTermsId': rootStore.preFilterPaymentFormStore,
        'order.GreenOk': rootStore.preFilterGokDateStore,
        'order.asWeekDate': rootStore.preFilterAsWeekDateStore,
        'managementDetail.shippingDate': rootStore.preFilterShippingDateStore,
        'managementDetail.certificateDate': rootStore.preFilterCertificateDateStore,
        'order.businessControlVehicle': rootStore.preFilterBusinessControlVehicleStore,
        'managementDetail.vehicleLocationId': rootStore.preFilterVehicleLocationStore,
        'managementDetail.paqueteId': rootStore.preFilterPaqueteStore,
        'zone': rootStore.preFilterZoneStore,
        'orderSalesman': rootStore.preFilterSalesmanStore,
        'quotationCustomer': rootStore.preFilterQuotationCustomerStore,
        'quotationSalesman': rootStore.preFilterQuotationSalesmanStore,
        'quotationId': rootStore.preFilterQuotationIDStore,
        'quotationDate': rootStore.preFilterQuotationDateStore,
        'reservations.factoryOrder': rootStore.preFilterFactoryOrderStore,
        'reservations.chassi': rootStore.preFilterChassiStore,
        'statusSAS': rootStore.preFilterStatusSASStore,
        'reservationStatus': rootStore.preFilterReservationStatusStore,
        'salesman.id': rootStore.preFilterSalesmanStore,
    };

    const store = storeMap[fieldToRemove];
    if (store) {
        cleanStore(store);
    }
};

export const removeFilter = (filterToRemove: any, filters: CompositeFilterDescriptor | undefined, rootStore: any)
    : CompositeFilterDescriptor | undefined => {
    const field = filterToRemove.field;

    let filteredFilters = removeElementByField(filters?.filters, field);

    if (CHASSI_FO_OM_AO.includes(field)) {
        CHASSI_FO_OM_AO.forEach((item: string) => {
            filteredFilters = removeElementByField(filteredFilters, item);
        })
    }

    cleanFilterStore(field, rootStore);

    return { logic: 'and', filters: filteredFilters };
}

const removeElementByField = (filters: any, fieldToRemove: string) => {
    return filters.reduce((acc: any, filter: any) => {
        const shouldRemove = filter.field === fieldToRemove;

        if (!shouldRemove) {
            if (filter.filters) {
                filter.filters = removeElementByField(filter.filters, fieldToRemove);
                if (filter.filters.length > 0) {
                    acc.push(filter);
                }
            } else {
                acc.push(filter);
            }
        }
        return acc;
    }, []);
}

export const CHASSI_FO_OM_AO = [
    'order.chassi',
    'chassi',
    'omNumber',
    'order.factoryOrder',
    'factoryOrder'
]