import { action, configure, observable } from "mobx";
import { FilterDescriptor, } from "@progress/kendo-data-query";
import RootStore from "./RootStore";
import BaseFilterStore from "../../../../components/PreFilter/Stores/BaseFilterStore";

configure({ enforceActions: "always" });

class FilterStore extends BaseFilterStore {
	@observable modalOpen = false;

	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	@action setModalOpen = () => (this.modalOpen = true);
	@action setModalClosed = () => (this.modalOpen = false);

	cleanSpecificPreFilters = () => {
		this.rootStore.preFilterDealerStore.clean();
		this.rootStore.preFilterDestinationStore.clean();
		this.rootStore.preFilterPaymentFormStore.clean();
		this.rootStore.preFilterAsWeekDateStore.clean();
		this.rootStore.preFilterGokDateStore.clean();
		this.rootStore.preFilterShippingDateStore.clean();
		this.rootStore.preFilterCertificateDateStore.clean();
		this.rootStore.preFilterVehicleLocationStore.clean();
		this.rootStore.preFilterChassiAOStore.clean();
		this.rootStore.preFilterCustomerStoreSAS.clean();
		this.rootStore.preFilterBusinessControlVehicleStore.clean();
		this.rootStore.preFilterPrevisionBillingMonthStore.clean();
		this.rootStore.preFilterCurrencyStore.clean();
	};

	applySpecificFilters(
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>): any {
		//one option
		andFilters =
			this.rootStore.preFilterDestinationStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterGokDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterAsWeekDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterShippingDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterCertificateDateStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterCustomerStoreSAS.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterBusinessControlVehicleStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterPrevisionBillingMonthStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterCurrencyStore.getFilterDescriptor(andFilters);

		//multi options
		this.rootStore.preFilterDealerStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterPaymentFormStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterVehicleLocationStore.getFilterDescriptor(
			orFiltersGroups
		);

		//both options
		andFilters =
			this.rootStore.preFilterChassiAOStore.getFilterDescriptorBySize(
				andFilters,
				orFiltersGroups
			);

		return { andFilters, orFiltersGroups };
	};
}

export default FilterStore;
