import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { followUpArgKeyLabels } from "../../utils/BatchEditionUtils";
import ClearRecordItem from "../../../../../components/BatchEdition/ClearRecordItem";
import DateForm from "../../../../../components/FormInputs/DateForm";
import FinancialDeliveryForm from "./Form/FinancialDeliveryForm";
import TextForm from "../../../../../components/FormInputs/TextForm";
import PaqueteForm from "./Form/PaqueteForm";
import { getCurrencyOptions } from "../../../../../utils/SelectOptions";
import ComissionForm from "./Form/ComissionForm";
import SalesSupportCommentsForm from "./Form/SalesSupportCommentsForm";
import FinancialCommentsForm from "./Form/FinancialCommentsForm";
import CommercialCommentsForm from "./Form/CommercialCommentsForm";
import LogisticCommentsForm from "./Form/LogisticCommentsForm";
import GeneralCommentsForm from "./Form/GeneralCommentsForm";
import EngineeringCommentsForm from "./Form/EngineeringCommentsForm";
import DestinationForm from "./Form/DestinationForm";

const AdminUserContent: React.FC = () => {
	const { t } = useTranslation("order");
	const currencyOptions = getCurrencyOptions();
	const {
		batchData,
		deliveryProbabilityOptions,
		setMCDeliveryDataByKey,
		setDeliveryProbability,
		paymentTermsOptions,
		vehicleLocationOptions,
		dealerOptions,
		setCustomerSAS,
		setOrderMCManagementDetailBatchEditByKey,
	} = useStores().batchEditionStore;

	return (
		<>
			<Grid item sm={6} md={3} lg={3}>
				<SelectForm
					label={t(followUpArgKeyLabels.deliveryProbability.label)}
					labelId={`${followUpArgKeyLabels.deliveryProbability.label}-label`}
					value={batchData.orderMCDelivery.deliveryProbability}
					options={deliveryProbabilityOptions}
					size="small"
					editable
					onChange={(e: any) => setDeliveryProbability(e.target.value)}
				/>
			</Grid>
			<BillingForecast
				t={t}
				batchData={batchData}
				setOrderMCManagementDetailBatchEditByKey={setOrderMCManagementDetailBatchEditByKey}
			></BillingForecast>
			<FirmBilling
				t={t}
				batchData={batchData}
				setMCDeliveryDataByKey={setMCDeliveryDataByKey}
			></FirmBilling>
			<Grid item sm={6} md={3} lg={3}>
				<FinancialDeliveryForm />
			</Grid>
			<PhysicalDelivery
				t={t}
				batchData={batchData}
				setMCDeliveryDataByKey={setMCDeliveryDataByKey}
			></PhysicalDelivery>
			<Grid item sm={6} md={3} lg={3}>
				<ClearRecordItem
					recordTitle={t(followUpArgKeyLabels.dealer.label)}
					checked={batchData.orderMCManagementDetail.dealerId.clearValue}
					onClearRecord={setOrderMCManagementDetailBatchEditByKey}
					recordKey={followUpArgKeyLabels.dealer.key}
				>
					<SelectForm
						label={t(followUpArgKeyLabels.dealer.label)}
						labelId={followUpArgKeyLabels.dealer.label}
						value={batchData.orderMCManagementDetail.dealerId.value}
						options={dealerOptions}
						size="small"
						editable
						hasEmptyDefaultItem
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey(
								{
									value: e.target.value as string,
									clearValue: false,
								},
								followUpArgKeyLabels.dealer.key
							)
						}
						disabled={batchData.orderMCManagementDetail.dealerId.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
			<Grid item sm={6} md={6} lg={6}>
				<ClearRecordItem
					recordTitle={t(followUpArgKeyLabels.sasCustomer.label)}
					checked={batchData.customerSAS.clearValue}
					onClearRecord={setCustomerSAS}
				>
					<TextForm
						label={t(followUpArgKeyLabels.sasCustomer.label)}
						editable
						value={batchData.customerSAS.value}
						slotProps={{ htmlInput: { maxLength: 100 } }}
						onChange={(e) =>
							setCustomerSAS({
								value: e.target.value,
								clearValue: false,
							})
						}
						disabled={batchData.customerSAS.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
			<Grid item xs={4}>
				<DestinationForm />
			</Grid>
			<Grid item xs={2}>
				<ComissionForm />
			</Grid>
			<PaymentForm
				t={t}
				batchData={batchData}
				setOrderMCManagementDetailBatchEditByKey={setOrderMCManagementDetailBatchEditByKey}
				paymentTermsOptions={paymentTermsOptions}
			></PaymentForm>
			<Currency
				t={t}
				batchData={batchData}
				setOrderMCManagementDetailBatchEditByKey={setOrderMCManagementDetailBatchEditByKey}
				currencyOptions={currencyOptions}
			></Currency>
			<ShippingDate
				t={t}
				batchData={batchData}
				setOrderMCManagementDetailBatchEditByKey={setOrderMCManagementDetailBatchEditByKey}
			></ShippingDate>
			<CertificateDate
				t={t}
				batchData={batchData}
				setOrderMCManagementDetailBatchEditByKey={setOrderMCManagementDetailBatchEditByKey}
			></CertificateDate>
			<Grid item sm={6} md={3} lg={3}>
				<PaqueteForm />
			</Grid>
			<CurrentLocation
				t={t}
				batchData={batchData}
				setOrderMCManagementDetailBatchEditByKey={setOrderMCManagementDetailBatchEditByKey}
				vehicleLocationOptions={vehicleLocationOptions}
			></CurrentLocation>
			<Comments></Comments>
		</>
	);
};

const BillingForecast: React.FC<any> = ({ t, batchData, setOrderMCManagementDetailBatchEditByKey }) => {
	return (
		<Grid item sm={6} md={3} lg={3}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.billingForecast.label)}
				checked={batchData.orderMCManagementDetail.billingForecast.clearValue}
				onClearRecord={setOrderMCManagementDetailBatchEditByKey}
				recordKey={followUpArgKeyLabels.billingForecast.key}
			>
				<DateForm
					label={t(followUpArgKeyLabels.billingForecast.label)}
					inputFormat="MMMM/yy"
					value={batchData.orderMCManagementDetail.billingForecast.value}
					editable
					onChange={(event: any) =>
						setOrderMCManagementDetailBatchEditByKey(
							{
								value: event,
								clearValue: false,
							},
							followUpArgKeyLabels.billingForecast.key
						)
					}
					disabled={
						batchData.orderMCManagementDetail.billingForecast.clearValue
					}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const FirmBilling: React.FC<any> = ({ t, batchData, setMCDeliveryDataByKey }) => {
	return (
		<Grid item sm={6} md={3} lg={3}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.firmBilling.label)}
				checked={batchData.orderMCDelivery.firmBilling.clearValue}
				onClearRecord={setMCDeliveryDataByKey}
				recordKey={followUpArgKeyLabels.firmBilling.key}
			>
				<DateForm
					label={t(followUpArgKeyLabels.firmBilling.label)}
					inputFormat="dd/MM/yy"
					value={batchData.orderMCDelivery.firmBilling.value}
					editable
					onChange={(event: any) =>
						setMCDeliveryDataByKey(
							{
								value: event,
								clearValue: false,
							},
							followUpArgKeyLabels.firmBilling.key
						)
					}
					disabled={batchData.orderMCDelivery.firmBilling.clearValue}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const PhysicalDelivery: React.FC<any> = ({ t, batchData, setMCDeliveryDataByKey }) => {
	return (
		<Grid item sm={6} md={3} lg={3}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.physicalDelivery.label)}
				checked={batchData.orderMCDelivery.physicalDelivery.clearValue}
				onClearRecord={setMCDeliveryDataByKey}
				recordKey={followUpArgKeyLabels.physicalDelivery.key}
			>
				<DateForm
					label={t(followUpArgKeyLabels.physicalDelivery.label)}
					inputFormat="dd/MM/yy"
					value={batchData.orderMCDelivery.physicalDelivery.value}
					editable
					onChange={(event: any) =>
						setMCDeliveryDataByKey(
							{
								value: event,
								clearValue: false,
							},
							followUpArgKeyLabels.physicalDelivery.key
						)
					}
					disabled={batchData.orderMCDelivery.physicalDelivery.clearValue}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const PaymentForm: React.FC<any> = ({ t, batchData, setOrderMCManagementDetailBatchEditByKey, paymentTermsOptions }) => {
	return (
		<Grid item xs={4}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.paymentForm.label)}
				checked={batchData.orderMCManagementDetail.paymentTermsId.clearValue}
				onClearRecord={setOrderMCManagementDetailBatchEditByKey}
				recordKey={followUpArgKeyLabels.paymentForm.key}
			>
				<SelectForm
					label={t(followUpArgKeyLabels.paymentForm.label)}
					labelId={`${followUpArgKeyLabels.paymentForm.label}-label`}
					value={batchData.orderMCManagementDetail.paymentTermsId.value}
					options={paymentTermsOptions}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey(
							{
								value: e.target.value as string,
								clearValue: false,
							},
							followUpArgKeyLabels.paymentForm.key
						)
					}
					disabled={
						batchData.orderMCManagementDetail.paymentTermsId.clearValue
					}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const Currency: React.FC<any> = ({ t, batchData, setOrderMCManagementDetailBatchEditByKey, currencyOptions }) => {
	return (
		<Grid item sm={2} md={2} lg={2}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.currency.label)}
				checked={batchData.orderMCManagementDetail.currency.clearValue}
				onClearRecord={setOrderMCManagementDetailBatchEditByKey}
				recordKey={followUpArgKeyLabels.currency.key}
			>
				<SelectForm
					label={t(followUpArgKeyLabels.currency.label)}
					labelId={`${followUpArgKeyLabels.currency.label}-label`}
					value={batchData.orderMCManagementDetail.currency.value}
					options={currencyOptions}
					size="small"
					editable
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey(
							{
								value: e.target.value as string,
								clearValue: false,
							},
							followUpArgKeyLabels.currency.key
						)
					}
					disabled={batchData.orderMCManagementDetail.currency.clearValue}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const ShippingDate: React.FC<any> = ({ t, batchData, setOrderMCManagementDetailBatchEditByKey }) => {
	return (
		<Grid item sm={6} md={3} lg={3}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.shippingDate.label)}
				checked={batchData.orderMCManagementDetail.shippingDate.clearValue}
				onClearRecord={setOrderMCManagementDetailBatchEditByKey}
				recordKey={followUpArgKeyLabels.shippingDate.key}
			>
				<DateForm
					label={t(followUpArgKeyLabels.shippingDate.label)}
					value={batchData.orderMCManagementDetail.shippingDate.value}
					editable
					onChange={(event: any) =>
						setOrderMCManagementDetailBatchEditByKey(
							{
								value: event,
								clearValue: false,
							},
							followUpArgKeyLabels.shippingDate.key
						)
					}
					disabled={batchData.orderMCManagementDetail.shippingDate.clearValue}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const CertificateDate: React.FC<any> = ({ t, batchData, setOrderMCManagementDetailBatchEditByKey }) => {
	return (
		<Grid item sm={6} md={3} lg={3}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.certificateDate.label)}
				checked={batchData.orderMCManagementDetail.certificateDate.clearValue}
				onClearRecord={setOrderMCManagementDetailBatchEditByKey}
				recordKey={followUpArgKeyLabels.certificateDate.key}
			>
				<DateForm
					label={t(followUpArgKeyLabels.certificateDate.label)}
					value={batchData.orderMCManagementDetail.certificateDate.value}
					editable
					onChange={(event: any) =>
						setOrderMCManagementDetailBatchEditByKey(
							{
								value: event,
								clearValue: false,
							},
							followUpArgKeyLabels.certificateDate.key
						)
					}
					disabled={
						batchData.orderMCManagementDetail.certificateDate.clearValue
					}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const CurrentLocation: React.FC<any> = ({ t, batchData, setOrderMCManagementDetailBatchEditByKey, vehicleLocationOptions }) => {
	return (
		<Grid item sm={12} md={3} lg={3}>
			<ClearRecordItem
				recordTitle={t(followUpArgKeyLabels.vehicleLocation.label)}
				checked={
					batchData.orderMCManagementDetail.vehicleLocationId.clearValue
				}
				onClearRecord={setOrderMCManagementDetailBatchEditByKey}
				recordKey={followUpArgKeyLabels.vehicleLocation.key}
			>
				<SelectForm
					label={t(followUpArgKeyLabels.vehicleLocation.label)}
					labelId={`${followUpArgKeyLabels.vehicleLocation.label}-label`}
					value={batchData.orderMCManagementDetail.vehicleLocationId.value}
					options={vehicleLocationOptions}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey(
							{
								value: e.target.value as string,
								clearValue: false,
							},
							followUpArgKeyLabels.vehicleLocation.key
						)
					}
					disabled={
						batchData.orderMCManagementDetail.vehicleLocationId.clearValue
					}
				/>
			</ClearRecordItem>
		</Grid>
	)
}

const Comments: React.FC = () => {
	return (
		<>
			<Grid item xs={12}>
				<SalesSupportCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<FinancialCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<CommercialCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<LogisticCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<GeneralCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<EngineeringCommentsForm />
			</Grid>
		</>
	)
}

export default observer(AdminUserContent);
