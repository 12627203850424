import { TFunction } from "i18next";
import { SummaryGridProps } from "../../../../../components/SummaryDraggableCard/SummaryDraggableCard";
import {
	getEstimatedOfMonth,
	getHeaders,
	getSumryGridProps,
	summaryModelBuilder,
	SummaryModelProps,
} from "./SummaryModel";

export interface SummaryTruckModelProps extends SummaryModelProps {
	line: string;
}

export function summaryTruckModelBuilder(data: any[]) {
	if (!data) {
		return [];
	}

	return data.map(
		(item) =>
			({
				...summaryModelBuilder(item),
				line: item.line,
			}) as SummaryTruckModelProps
	);
}

export function getSummaryDraggableData(data: SummaryTruckModelProps[]) {
	let dataTransformed = [] as SummaryGridProps[];
	let estimatedMonth = 0;

	if (!data) {
		return { dataTransformed, estimatedMonth };
	}

	let totalFirmSum = 0;
	let totalProbableSum = 0;
	let totalNoneSum = 0;
	data.forEach((summaryTruckModelProps) => {
		dataTransformed = dataTransformed.concat([
			{
				value: summaryTruckModelProps.line,
			},
			...getSumryGridProps(summaryTruckModelProps),
		]);

		totalFirmSum += summaryTruckModelProps.firm;
		totalProbableSum += summaryTruckModelProps.probable;
		totalNoneSum += summaryTruckModelProps.none;
	});

	const totalSummary: SummaryModelProps = {
		firm: totalFirmSum,
		probable: totalProbableSum,
		none: totalNoneSum,
	};

	dataTransformed = dataTransformed.concat([
		{ value: "Total" },
		...getSumryGridProps(totalSummary),
	]);
	estimatedMonth = getEstimatedOfMonth(totalFirmSum, totalProbableSum);

	return { dataTransformed, estimatedMonth };
}

export function getHeadersTruck(t: TFunction<"order", undefined>) {
	return [{ value: t("line") }, ...getHeaders(t)];
}
