import { EuropeEditableProps } from "../../interfaces/Editable/EuropeEditableDetails";

export const europeEditableModel: EuropeEditableProps = {
	sasId: "",
	bodybuilder: "",
	salesman: "",
	customerSAS: "",
};

export function europeEditableModelBuilder(data: EuropeEditableProps) {
	if (!data) return europeEditableModel;
	return {
		sasId: data.sasId ?? "",
		bodybuilder: data.bodybuilder ?? "",
		salesman: data.salesman ?? "",
		customerSAS: data.customerSAS ?? "",
		zoneId: data.zoneId,
	} as EuropeEditableProps;
}
