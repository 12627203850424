import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";
import { LocalizationExportSectionProps } from "../../interfaces/Sections/LocalizationExportSection";

const LocalizationInvoicesSection: React.FC<LocalizationExportSectionProps> = ({
	readOnlyFields,
	loadLocalizationExport,
}) => {
	const { t } = useTranslation(["order", "general"]);

	const fetchLocalizationExport = React.useCallback(async () => {
		await loadLocalizationExport();
	}, [loadLocalizationExport]);

	React.useEffect(() => {
		fetchLocalizationExport();
	}, [fetchLocalizationExport]);

	return (
		<>
			{readOnlyFields.thirdPartnerName ||
			readOnlyFields.documentDate ||
			readOnlyFields.facility ? (
				<>
					<Grid item xs={9}>
						<TextForm
							label={t("details.sections.localizationExport.thirdPartnerName")}
							value={readOnlyFields.thirdPartnerName}
						/>
					</Grid>
					<Grid item xs={3}>
						<DateForm
							label={t("details.sections.localizationExport.documentDate")}
							value={readOnlyFields.documentDate}
							inputFormat="dd/MM/yyyy"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							label={t("details.sections.localizationExport.facility")}
							value={readOnlyFields.facility}
						/>
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(LocalizationInvoicesSection);
