import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import { observer } from "mobx-react";

const LastUpdateChip: React.FC = () => {
	const { t } = useTranslation("general");

	return (
		<Box display="flex" justifyContent="flex-end" width={"80%"}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="flex-end"
				gap="15px"
			>
				<Chip
					variant="outlined"
					color="warning"
					size="small"
					label={t("unsavedChanges")}
				/>
			</Box>
		</Box>
	);
};

export default observer(LastUpdateChip);
