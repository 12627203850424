import { VehicleTypeEnum } from "../../../../../utils/enums/VehicleTypeEnum";
import { stringFormModel } from "../../../../../utils/GenericModels";
import {
	BatchBaseDataProps,
	BatchBusinessUserDataProps,
	BatchDataProps,
	BatchEngineeringUserDataProps,
	BatchFinancialUserDataProps,
	BatchLogisticUserDataProps,
} from "../../interfaces/BatchEdition/BatchDataProps";
import { orderMCDelivery } from "./OrderMCDeliveryBatchEdit";
import { orderMCManagementDetail } from "./OrderMCManagementDetailBatchEdit";

const batchBaseDataInitialModel: BatchBaseDataProps = {
	itemsToBeUpdated: [],
	generalComment: stringFormModel,
	vehicleType: VehicleTypeEnum.Unknown,
};

export const batchDataInitialModel: BatchDataProps = {
	...batchBaseDataInitialModel,
	orderMCDelivery: orderMCDelivery,
	orderMCManagementDetail: orderMCManagementDetail,
	customerSAS: stringFormModel,
};

export const batchBaseDataModelBuilder = (
	data: BatchDataProps
): BatchBaseDataProps => {
	return {
		itemsToBeUpdated: data.itemsToBeUpdated,
		generalComment: data.generalComment,
		vehicleType: data.vehicleType,
	};
};

export const batchFinancialUserDataModelBuilder = (
	data: BatchDataProps
): BatchFinancialUserDataProps => {
	return {
		...batchBaseDataModelBuilder(data),
		financialComments: data.orderMCManagementDetail.financialComments,
		financialDelivery: data.orderMCDelivery.financialDelivery,
	};
};

export const batchEngineeringUserDataModelBuilder = (
	data: BatchDataProps
): BatchEngineeringUserDataProps => {
	return {
		...batchBaseDataModelBuilder(data),
		paqueteId: data.orderMCManagementDetail.paqueteId,
		engineeringComments: data.orderMCManagementDetail.engineeringComments,
	};
};

export const batchLogisticUserDataModelBuilder = (
	data: BatchDataProps
): BatchLogisticUserDataProps => {
	return {
		...batchBaseDataModelBuilder(data),
		destination: data.orderMCManagementDetail.destination,
		logisticComments: data.orderMCManagementDetail.logisticComments,
	};
};

export const batchBusinessUserDataModelBuilder = (
	data: BatchDataProps
): BatchBusinessUserDataProps => {
	return {
		...batchBaseDataModelBuilder(data),
		commercialComments: data.orderMCManagementDetail.commercialComments,
	};
};
