import React from "react";
import { action, makeObservable, observable } from "mobx";
import { loginRequest, groups } from "../authConfig";
import { callMsGraph } from "../graph";
import {
	AccountInfo,
	InteractionRequiredAuthError,
	InteractionStatus,
	IPublicClientApplication,
} from "@azure/msal-browser";
import { GraphData } from "../interfaces/GraphData";
import * as AuthGroups from "../../constants/AuthGroups/authGroups";

type IdTokenClaims = {
	groups: Array<string>;
};

/* Store start */
class AuthStore {
	constructor() {
		makeObservable(this);
	}

	@observable graphData: GraphData | null = null;
	@observable signingIn: boolean = true;
	@observable groups: Array<AuthGroups.AuthGroupsType> = [];
	@observable isOwnerUser: boolean = false;

	@action handleLogin = (
		loginType: "popup" | "redirect",
		instance: IPublicClientApplication
	) => {
		if (loginType === "popup") {
			instance
				.loginPopup(loginRequest)
				.catch((e) => {
					console.log(e);
				})
				.finally(() => (this.signingIn = false));
		} else if (loginType === "redirect") {
			instance
				.loginRedirect(loginRequest)
				.catch((e) => {
					console.log(e);
				})
				.finally(() => (this.signingIn = false));
		}
	};

	@action handleLogout = (
		logoutType: "popup" | "redirect",
		instance: IPublicClientApplication
	) => {
		if (logoutType === "popup") {
			instance
				.logoutPopup({
					postLogoutRedirectUri: "/",
					mainWindowRedirectUri: "/",
				})
				.finally(() => localStorage.removeItem("webApiAccessToken"));
		} else if (logoutType === "redirect") {
			instance
				.logoutRedirect({
					postLogoutRedirectUri: "/",
				})
				.finally(() => localStorage.removeItem("webApiAccessToken"));
		}
	};

	@action setSigningIn = (signingIn: boolean) => (this.signingIn = signingIn);

	@action setIsOwnerUser = () =>
		(this.isOwnerUser = this.isAuthorizedGroup(AuthGroups.OWNER_GROUPS));

	@action setGraphData = (accessToken: string) =>
		callMsGraph(accessToken).then((response) => (this.graphData = response));

	@action setUserGroups = (claims: IdTokenClaims) => {
		const sasGroupValues = Object.values(groups);
		const userGroupKeys = claims.groups.filter((f) =>
			sasGroupValues.includes(f)
		);

		const authorizedGroups: string[] = [];

		userGroupKeys.forEach((element) => {
			const authorizedGroup = Object.keys(groups).find(
				(key) => groups[key as AuthGroups.AuthGroupsType] === element
			);
			if (authorizedGroup && !authorizedGroups.includes(authorizedGroup))
				authorizedGroups.push(authorizedGroup);
		});

		this.groups = authorizedGroups as Array<AuthGroups.AuthGroupsType>;
	};

	@action setAccessTokenAndGraphData = (
		accounts: AccountInfo[],
		instance: IPublicClientApplication,
		inProgress: InteractionStatus
	) => {
		const accessTokenRequest = {
			...loginRequest,
			account: accounts[0],
		};
		if (inProgress === InteractionStatus.None) {
			instance
				.acquireTokenSilent(accessTokenRequest)
				.then((accessTokenResponse) => {
					// Acquire token silent success
					this.setGraphData(accessTokenResponse.accessToken);
					localStorage.setItem(
						"webApiAccessToken",
						accessTokenResponse.idToken
					);
					this.setUserGroups(
						accessTokenResponse.idTokenClaims as IdTokenClaims
					);
				})
				.then(() => this.setIsOwnerUser())
				.catch((error) => {
					if (error instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect(accessTokenRequest);
					}
					console.log(error);
				});
		}
	};

	@action isAuthorizedGroup = (claims: Array<AuthGroups.AuthGroupsType>) =>
		this.groups.some((group) => claims.includes(group));

	@action isAuthorizedGroupByClaim = (claim: AuthGroups.AuthGroupsType) =>
		this.groups.some((group) => claim === group);

	@action getDealerOrMKTCompanyGroup = () =>
		this.groups.find((group) =>
			[
				...AuthGroups.BRA_DEALERS,
				AuthGroups.CHL_MARKET_COMPANY,
				AuthGroups.ARG_MARKET_COMPANY,
				AuthGroups.PER_MARKET_COMPANY,
			].includes(group)
		);

	@action isDealerOrMKTCompanyOwner = (dealerRegion: string) => {
		const dealerGroup = this.getDealerOrMKTCompanyGroup();
		if (dealerGroup === undefined) {
			return false;
		}

		dealerRegion = dealerRegion.trim();

		const regionCheckMap: { [key: string]: (region: string) => boolean } = {
			[AuthGroups.CHL_MARKET_COMPANY]: (region) => region === "CHL",
			[AuthGroups.ARG_MARKET_COMPANY]: (region) => region === "ARG",
			[AuthGroups.PER_MARKET_COMPANY]: (region) => region === "PER",
			[AuthGroups.BRA_DEALER_ASP]: (region) => region === "ASP",
			[AuthGroups.BRA_DEALER_AUT]: (region) => region === "AUT",
			[AuthGroups.BRA_DEALER_DIC]: (region) => region === "DIC",
			[AuthGroups.BRA_DEALER_DIP]: (region) => region === "DIP",
			[AuthGroups.BRA_DEALER_GOT]: (region) => region === "GOT",
			[AuthGroups.BRA_DEALER_LAP]: (region) => region === "LAP",
			[AuthGroups.BRA_DEALER_LUV]: (region) => region === "LUV",
			[AuthGroups.BRA_DEALER_NOR]: (region) => region === "NOR",
			[AuthGroups.BRA_DEALER_RIV]: (region) => region === "RIV",
			[AuthGroups.BRA_DEALER_SUE]: (region) => region === "SUE",
			[AuthGroups.BRA_DEALER_TNE]: (region) => region === "TNE",
			[AuthGroups.BRA_DEALER_TNO]: (region) => region === "TNO",
			[AuthGroups.BRA_DEALER_TNE_TNO]: (region) => ["TNE", "TNO"].includes(region),
			[AuthGroups.BRA_DEALER_TRV]: (region) => region === "TRV"
		};

		const checkRegion = regionCheckMap[dealerGroup];
		return checkRegion ? checkRegion(dealerRegion) : false;
	};

	@action isAuthorizedBraDealerGroup = () =>
		this.isAuthorizedGroup(AuthGroups.BRA_DEALERS);

	@action isAuthorizedArgDealerGroup = () =>
		this.isAuthorizedGroup(AuthGroups.ARG_DEALERS);

	@action isAuthorizedAdminGroup = () =>
		this.isAuthorizedGroupByClaim(AuthGroups.ADMIN);

	@action isAuthorizedAdminOrVdbGroup = () =>
		this.isAuthorizedGroup([
			AuthGroups.ADMIN,
			AuthGroups.BRA_SALES_SUPPORT_VDB,
		]);
}
/* Store end */

/* Store helpers */
const AuthStoreContext = React.createContext(new AuthStore());

/* Hook to use store in any functional component */
export const useAuthStore = () => React.useContext(AuthStoreContext);
