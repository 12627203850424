import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { QuotationLogProps } from "../../../interfaces/QuotationLogProps";
import { getColumns } from "../../../util/GridUtils";

interface TableGridProps {
	data: QuotationLogProps[];
	t: any;
}

export const TableGrid: React.FC<TableGridProps> = ({ data, t }) => {
	return (
		<>
			<Grid style={{ height: "200px" }} data={data} editField="inEdit">
				{getColumns(t).map((col: any, i: number) => {
					return <GridColumn key={i} {...col} />;
				})}
			</Grid>
		</>
	);
};
