import RootStore from "../stores/RootStore";

export function newRootStore(): RootStore {
	return {
		commonStore: {},
		busStore: {},
		truckStore: {},
		volvoManagementStore: {},
	};
}
