import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
	Grid,
	GridCellProps,
	GridColumn,
	GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useVariantsStore } from "../stores/VariantsStore";
import { getColumns } from "../utils/GridUtils";
import EditForm from "./EditForm";

const EditGrid: React.FC = () => {
	const { t } = useTranslation("general");
	const { setDataItemChanged, variants, setFormVisible } = useVariantsStore();

	const onUpdate = (e: GridCellProps) => {
		setFormVisible(true);
		setDataItemChanged(e.dataItem);
	};

	const [sort, setSort] = React.useState<Array<SortDescriptor>>([]);

	return (
		<>
			<EditForm />
			<Grid
				data={orderBy(variants, sort)}
				scrollable="none"
				sortable={true}
				sort={sort}
				onSortChange={(e: GridSortChangeEvent) => {
					setSort(e.sort);
				}}
			>
				{getColumns(t, onUpdate).map((col: any, i: number) => {
					return <GridColumn key={i} {...col} />;
				})}
			</Grid>
		</>
	);
};

export default observer(EditGrid);
