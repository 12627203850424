import {
	CTADMVEN_ORDER_BUS_BRAZIL,
	CTADMVEN_ORDER_BUS_FREE_UNIT_VCI,
	CTADMVEN_ORDER_BUS_MEAC,
	CTADMVEN_ORDER_BUS_MKT_COMPANY_TOTAL,
	CTADMVEN_ORDER_BUS_PI,
	CTADMVEN_ORDER_BUS_TOTAL,
	CTADMVEN_ORDER_EXTERNAL_BRAZIL,
	CTADMVEN_ORDER_AVAILABILITY,
	CTADMVEN_ORDER_MKT_COMPANY,
	CTADMVEN_ORDER_INTERNAL_VDB,
	CTADMVEN_ORDER_TOTAL,
	CTADMVEN_ORDER_FD_MKT_COMPANY,
	CTADMVEN_ORDER_EUROPE_FACTORY_ORDERBOOK,
	CTADMVEN_ORDER_FD_MKT_COMPANY_BUS,
	CTADMVEN_ORDER_PRIVATE_IMPORTERS,
	CTADMVEN_ORDER_VCI,
	CTADMVEN_ORDER_FD_MKT_COMPANY_EUROPE,
	CTADMVEN_ORDER_BUS_FREE_UNIT_VCI_VDB,
	CTADMVEN_EXPORT_PARTNER,
} from "../../constants/routes";
import { AuthGroupsType } from "../../constants/AuthGroups/authGroups";
import { AppRouteProps } from "../routes";
import TruckIndex from "./Details/containers/TruckIndex";
import MktCompany from "./List/containers/MktCompany/MktCompany";
import ExternalBrazil from "./List/containers/ExternalBrazil/ExternalBrazil";
import Availability from "./List/containers/Availability/Availability";
import InternalVdb from "./List/containers/InternalVdb/InternalVdb";
import Total from "./List/containers/Total/Total";
import { TFunction } from "i18next";
import BusBrazil from "./List/containers/BusBrazil/BusBrazil";
import BusFree from "./List/containers/BusFree/BusFree";
import BusMeac from "./List/containers/BusMeac/BusMeac";
import BusMktCompany from "./List/containers/BusMktCompany/BusMktCompany";
import BusPrivateImporters from "./List/containers/BusPrivateImporters/BusPrivateImporters";
import BusTotal from "./List/containers/BusTotal/BusTotal";
import BusIndex from "./Details/containers/BusIndex";
import {
	MenuCollapsableRouteProps,
	MenuRouteProps,
} from "../../utils/GenericInterfaces";
import FactoryDeliveriesMktCompany from "./List/containers/FactoryDeliveriesMktCompany/FactoryDeliveriesMktCompany";
import EuropeOrderbook from "./List/containers/EuropeOrderbook/EuropeOrderbook";
import PrivateImporters from "./List/containers/PrivateImporters/PrivateImporters";
import VolvoCoInventory from "./List/containers/VolvoCoInventory/VolvoCoInventory";
import FactoryDeliveriesMktCompanyBus from "./List/containers/FactoryDeliveriesMktCompanyBus/FactoryDeliveriesMktCompanyBus";
import {
	groupsAllowedToSeeBusBrazilMenu,
	groupsAllowedToSeeBusFreeMenu,
	groupsAllowedToSeeBusFreeVciVdbMenu,
	groupsAllowedToSeeBusMeacMenu,
	groupsAllowedToSeeBusMktCompanyMenu,
	groupsAllowedToSeeBusPrivateImportersMenu,
	groupsAllowedToSeeBusTotalMenu,
	groupsAllowedToSeeFactoryDeliveriesEuropeMktCompanyBusMenu,
	groupsAllowedToSeeFactoryDeliveriesMktCompanyBusMenu,
} from "../../constants/AuthGroups/orderListBus";
import _ from "lodash";
import {
	groupsAllowedToSeeAvailabilityMenu,
	groupsAllowedToSeeEuropeFactoryMenu,
	groupsAllowedToSeeExternalBrazilMenu,
	groupsAllowedToSeeMktCompanyMenu,
	groupsAllowedToSeeFactoryDeliveriesMktCompanyMenu,
	groupsAllowedToSeeInternalVdbMenu,
	groupsAllowedToSeePrivateImportersMenu,
	groupsAllowedToSeeTotalMenu,
	groupsAllowedToSeeVCIMenu,
	groupsAllowedToSeeFactoryDeliveriesEuropeMktCompanyMenu,
} from "../../constants/AuthGroups/orderListTruck";
import { groupsAllowedToSeeExportPartnerMenu } from "../../constants/AuthGroups/exportPartnersPages";
import EuropeIndex from "./Details/containers/EuropeIndex";
import FactoryDeliveriesMktCompanyEurope from "./List/containers/FactoryDeliveriesMktCompanyEurope/FactoryDeliveriesMktCompanyEurope";
import BusFreeVciVdb from "./List/containers/BusFreeVciVdb/BusFreeVciVdb";
import { DirectionsBus, Handshake, Receipt } from "@mui/icons-material";
import {
	getMenuAuthorized,
	getRouteListAndDetails,
} from "../../utils/RoutesUtil";
import ExportPartnerIndex from "../ExportPartner/containers/ExportPartnerIndex";
import MenuListSubHeader from "../../components/header/MenuListSubHeader";
import LocalShipping from "@mui/icons-material/LocalShipping";

const orderBookTruckAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeAvailabilityMenu,
	...groupsAllowedToSeeExternalBrazilMenu,
	...groupsAllowedToSeeMktCompanyMenu,
	...groupsAllowedToSeeInternalVdbMenu,
	...groupsAllowedToSeeTotalMenu,
	...groupsAllowedToSeePrivateImportersMenu,
]);
const orderBookBusAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeBusBrazilMenu,
	...groupsAllowedToSeeBusFreeMenu,
	...groupsAllowedToSeeBusFreeVciVdbMenu,
	...groupsAllowedToSeeBusMeacMenu,
	...groupsAllowedToSeeBusMktCompanyMenu,
	...groupsAllowedToSeeBusPrivateImportersMenu,
	...groupsAllowedToSeeBusTotalMenu,
]);
const orderBookFactoryDeliveriesAllClaims: Array<AuthGroupsType> =
	_.uniq([
		...groupsAllowedToSeeFactoryDeliveriesMktCompanyBusMenu,
		...groupsAllowedToSeeFactoryDeliveriesMktCompanyMenu,
	]);
const orderBookFactoryDeliveriesEuropeAllClaims: Array<AuthGroupsType> =
	_.uniq([
		...groupsAllowedToSeeFactoryDeliveriesEuropeMktCompanyBusMenu,
		...groupsAllowedToSeeFactoryDeliveriesEuropeMktCompanyMenu,
	]);
const orderEuropeFactoryAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeEuropeFactoryMenu,
]);

//routes
export const orderBookTruckMenuCollapsableRoute: MenuCollapsableRouteProps = {
	label: "orderBookTrucks.orderBook",
	labelSecondary: "truck",
	icon: <LocalShipping />,
	getMenus: getOrderTruckMenus,
	claims: orderBookTruckAllClaims,
};
export const orderBookBusMenuCollapsableRoute: MenuCollapsableRouteProps = {
	label: "orderBookBus.orderBook",
	labelSecondary: "bus",
	icon: <DirectionsBus />,
	getMenus: getOrderBusMenus,
	claims: orderBookBusAllClaims,
};
export const factoryDeliveriesMenuCollapsableRoute: MenuCollapsableRouteProps =
	{
		label: "orderBookFactoryDeliveries.orderBook",
		icon: <Receipt />,
		getMenus: getFactoryDeliveriesMenus,
		claims: [
			...orderBookFactoryDeliveriesAllClaims,
			...orderBookFactoryDeliveriesEuropeAllClaims,
		],
	};

export const exportPartnerRoute: MenuRouteProps = {
	path: CTADMVEN_EXPORT_PARTNER,
	claims: groupsAllowedToSeeExportPartnerMenu,
	element: <ExportPartnerIndex />,
	label: "exportPartner",
	isCollapsable: false,
	icon: <Handshake />,
};

const externalBrazilRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_EXTERNAL_BRAZIL,
	claims: groupsAllowedToSeeExternalBrazilMenu,
	element: <ExternalBrazil />,
	label: "orderBookTrucks.externalBrazil",
	isCollapsable: true,
};

const availabilityRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_AVAILABILITY,
	claims: groupsAllowedToSeeAvailabilityMenu,
	element: <Availability />,
	label: "orderBookTrucks.availability",
	isCollapsable: true,
};

const mktCompanyRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_MKT_COMPANY,
	claims: groupsAllowedToSeeMktCompanyMenu,
	element: <MktCompany />,
	label: "orderBookTrucks.mktCompany",
	isCollapsable: true,
};

const internalVdbRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_INTERNAL_VDB,
	claims: groupsAllowedToSeeInternalVdbMenu,
	element: <InternalVdb />,
	label: "orderBookTrucks.internalVdb",
	isCollapsable: true,
};

const totalRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_TOTAL,
	claims: groupsAllowedToSeeTotalMenu,
	element: <Total />,
	label: "orderBookTrucks.total",
	isCollapsable: true,
};

const europeFactoryRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_EUROPE_FACTORY_ORDERBOOK,
	claims: groupsAllowedToSeeEuropeFactoryMenu,
	element: <EuropeOrderbook />,
	label: "europeFactory.marketCompany",
	isCollapsable: true,
};

const busBrazilRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_BUS_BRAZIL,
	claims: groupsAllowedToSeeBusBrazilMenu,
	element: <BusBrazil />,
	label: "orderBookBus.brazil",
	isCollapsable: true,
};

const busFreeRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_BUS_FREE_UNIT_VCI,
	claims: groupsAllowedToSeeBusFreeMenu,
	element: <BusFree />,
	label: "orderBookBus.freeUnitVci",
	isCollapsable: true,
};

const busFreeVciVdbRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_BUS_FREE_UNIT_VCI_VDB,
	claims: groupsAllowedToSeeBusFreeVciVdbMenu,
	element: <BusFreeVciVdb />,
	label: "orderBookBus.freeUnitVciVdb",
	isCollapsable: true,
};

const busMeacRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_BUS_MEAC,
	claims: groupsAllowedToSeeBusMeacMenu,
	element: <BusMeac />,
	label: "orderBookBus.meac",
	isCollapsable: true,
};

const busMktCompanyRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_BUS_MKT_COMPANY_TOTAL,
	claims: groupsAllowedToSeeBusMktCompanyMenu,
	element: <BusMktCompany />,
	label: "orderBookBus.marketCompany",
	isCollapsable: true,
};

const busPrivateImportersRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_BUS_PI,
	claims: groupsAllowedToSeeBusPrivateImportersMenu,
	element: <BusPrivateImporters />,
	label: "orderBookBus.privateImporters",
	isCollapsable: true,
};

const busTotalRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_BUS_TOTAL,
	claims: groupsAllowedToSeeBusTotalMenu,
	element: <BusTotal />,
	label: "orderBookBus.total",
	isCollapsable: true,
};

const factoryDeliveriesMktCompanyRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_FD_MKT_COMPANY,
	claims: groupsAllowedToSeeFactoryDeliveriesMktCompanyMenu,
	element: <FactoryDeliveriesMktCompany />,
	label: "orderBookFactoryDeliveries.marketCompany",
	isCollapsable: true,
};

const factoryDeliveriesMktCompanyBusRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_FD_MKT_COMPANY_BUS,
	claims: groupsAllowedToSeeFactoryDeliveriesMktCompanyBusMenu,
	element: <FactoryDeliveriesMktCompanyBus />,
	label: "orderBookFactoryDeliveriesBus.marketCompany",
	isCollapsable: true,
};

const factoryDeliveriesMktCompanyEuropeRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_FD_MKT_COMPANY_EUROPE,
	claims: groupsAllowedToSeeFactoryDeliveriesMktCompanyMenu,
	element: <FactoryDeliveriesMktCompanyEurope />,
	label: "europeFactory.marketCompany",
	isCollapsable: true,
};

const privateImportersRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_PRIVATE_IMPORTERS,
	claims: groupsAllowedToSeePrivateImportersMenu,
	element: <PrivateImporters />,
	label: "orderBookTrucks.privateImporters",
	isCollapsable: true,
};

const vciRoute: MenuRouteProps = {
	path: CTADMVEN_ORDER_VCI,
	claims: groupsAllowedToSeeVCIMenu,
	element: <VolvoCoInventory />,
	label: "orderBookTrucks.vci",
	isCollapsable: true,
};

export const orderRoutes: Array<AppRouteProps> = [
	...getTruckListRoute(externalBrazilRoute),
	...getTruckListRoute(availabilityRoute),
	...getTruckListRoute(mktCompanyRoute),
	...getTruckListRoute(internalVdbRoute),
	...getTruckListRoute(totalRoute),
	...getTruckListRoute(factoryDeliveriesMktCompanyRoute),
	...getTruckListRoute(privateImportersRoute),
	...getTruckListRoute(vciRoute),

	...getBusListRoute(busBrazilRoute),
	...getBusListRoute(busFreeRoute),
	...getBusListRoute(busFreeVciVdbRoute),
	...getBusListRoute(busMeacRoute),
	...getBusListRoute(busMktCompanyRoute),
	...getBusListRoute(busPrivateImportersRoute),
	...getBusListRoute(busTotalRoute),
	...getBusListRoute(factoryDeliveriesMktCompanyBusRoute),
];

export const europeFactoryRoutes: Array<AppRouteProps> = [
	...getEuropeRoutes(europeFactoryRoute),
	...getEuropeRoutes(factoryDeliveriesMktCompanyEuropeRoute),
];

// route utils
function getTruckListRoute(
	orderListRoute: MenuRouteProps
): Array<AppRouteProps> {
	return getRouteListAndDetails(orderListRoute, <TruckIndex />);
}

function getBusListRoute(orderListRoute: MenuRouteProps): Array<AppRouteProps> {
	return getRouteListAndDetails(orderListRoute, <BusIndex />);
}

function getEuropeRoutes(route: MenuRouteProps): Array<AppRouteProps> {
	return getRouteListAndDetails(route, <EuropeIndex />);
}

//menu utils
function getOrderTruckMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean = false
) {
	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("brazil")} />
			{getMenuAuthorized(totalRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(internalVdbRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(externalBrazilRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(availabilityRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(mktCompanyRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(privateImportersRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(vciRoute, t, isAuthorizedGroup)}
			{isAuthorizedGroup(orderEuropeFactoryAllClaims) && (
				<>
					<MenuListSubHeader drawerOpen={open} text={t("europe")} />
					{getMenuAuthorized(europeFactoryRoute, t, isAuthorizedGroup)}
				</>
			)}
		</>
	);
}

function getOrderBusMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean = false
) {
	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("brazil")} />
			{getMenuAuthorized(busTotalRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(busBrazilRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(busFreeRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(busFreeVciVdbRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(busMeacRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(busMktCompanyRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(busPrivateImportersRoute, t, isAuthorizedGroup)}
		</>
	);
}

function getFactoryDeliveriesMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean = false
) {
	return (
		<>
			{isAuthorizedGroup(orderBookFactoryDeliveriesAllClaims) &&
				getOrderFactoryDeliveriesBrMenus(t, isAuthorizedGroup, open)}
			{isAuthorizedGroup(orderBookFactoryDeliveriesEuropeAllClaims) &&
				getOrderFactoryDeliveriesEuMenus(t, isAuthorizedGroup, open)}
		</>
	);
}

function getOrderFactoryDeliveriesBrMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean
) {
	if (!isAuthorizedGroup(orderBookFactoryDeliveriesAllClaims)) {
		return <></>;
	}

	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("brazil")} />
			{getMenuAuthorized(
				factoryDeliveriesMktCompanyRoute,
				t,
				isAuthorizedGroup
			)}
			{getMenuAuthorized(
				factoryDeliveriesMktCompanyBusRoute,
				t,
				isAuthorizedGroup
			)}
		</>
	);
}

function getOrderFactoryDeliveriesEuMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean
) {
	if (!isAuthorizedGroup(orderBookFactoryDeliveriesEuropeAllClaims)) {
		return <></>;
	}

	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("europe")} />
			{getMenuAuthorized(
				factoryDeliveriesMktCompanyEuropeRoute,
				t,
				isAuthorizedGroup
			)}
		</>
	);
}
