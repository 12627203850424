import React from "react";
import { observer } from "mobx-react";

import "../styles/Index.css";

import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import DownloadsGrid from "./DownloadsGrid";
import { useCsvQueueManagerStore } from "../stores/CsvQueueManagerStore";

import { Grid, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";

const Total: React.FC = () => {
	const { t } = useTranslation(["menu", "order"]);

	const store = useCsvQueueManagerStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("download-center.title", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<>
			<Grid item lg={12}>
				<div className="title-wrapper">
					<TitleBox breadcrumbs={breadcrumbs} />
					<Tooltip title={t("tooltips.refresh", { ns: "order" })} arrow>
						<IconButton
							id="refresh-list"
							color="info"
							onClick={store.getAvailableDownloads}
							disabled={store.loading}
						>
							<RefreshIcon />
						</IconButton>
					</Tooltip>
				</div>
			</Grid>
			<Grid>
				<DownloadsGrid />
			</Grid>
		</>
	);
};

export default observer(Total);
