import * as React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Step, StepIconProps, StepLabel, Stepper } from "@mui/material";
import { CalendarMonth, EventAvailable } from "@mui/icons-material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { KeyDateProps } from "../interfaces/KeyDateProps";
import DateTimeString from "../../../utils/DateTimeToString";

interface DatesTimeLineProps {
	datesTimeLine: Array<KeyDateProps>;
}

function DateTimeLineIcon(props: StepIconProps) {
	const { completed, active } = props;
	if (completed || active) return <EventAvailable color="secondary" />;
	return <CalendarMonth color="primary" />;
}

const DatesTimeLine: React.FC<DatesTimeLineProps> = ({ datesTimeLine }) => {
	const { t } = useTranslation("order");
	const activeStep = _.findLastIndex(datesTimeLine, (s: any) => s.value);
	return (
		<Stack sx={{ width: "100%" }} spacing={4}>
			<Stepper activeStep={activeStep} alternativeLabel>
				{datesTimeLine.map((date) => (
					<Step key={date.key}>
						<StepLabel StepIconComponent={DateTimeLineIcon}>
							<div>{t(`dateTimeLine.${date.key}`)}</div>
							<Typography variant="caption" sx={{ width: "100%" }}>
								{date.value ? DateTimeString(date.value, "DD/MM/YY") : ""}
							</Typography>
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Stack>
	);
};

export default DatesTimeLine;
