import { ReservationAvailabilityProps } from "../interfaces/ReservationAvailabilityDetails";

export const reservationAvailabilityModel: ReservationAvailabilityProps = {
	available: false,
	shineDealerRegion: "",
	shine: null,
	reserved: false,
	reservationDealerRegion: "",
	reservationCreationDate: null,
	reservationExpiryDate: null,
};

export function reservationAvailabilityModelBuilder(
	data: ReservationAvailabilityProps
) {
	if (!data) return reservationAvailabilityModel;
	return {
		...reservationAvailabilityModel,
		...data,
		shineDealerRegion: data.shineDealerRegion ?? "",
		reservationDealerRegion: data.reservationDealerRegion ?? "",
	} as ReservationAvailabilityProps;
}
