import React from "react";
import AccordionCard, {
	AccordionCardProps,
} from "../AccordionCard/AccordionCard";
import BatchEditionActions, {
	BatchEditionActionsProps,
} from "./BatchEditionActions";
import UnsavedChangesChip from "./UnsavedChangesChip";

interface BatchEditionProps
	extends AccordionCardProps,
		BatchEditionActionsProps {}

const BatchEdition: React.FC<BatchEditionProps> = (props) => {
	const summaryBody = props.hasChanges ? <UnsavedChangesChip /> : null;
	return (
		<AccordionCard
			{...props}
			summaryBody={summaryBody}
			actions={<BatchEditionActions {...props} />}
		/>
	);
};

export default BatchEdition;
