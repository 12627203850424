import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";

const DateTimeString = (
	date: Date,
	format: string,
	defaultValue: string = "-",
	convertUtc: boolean = false
) => {
	const { i18n } = useTranslation();
	const locale = Boolean(i18n.language) ? i18n.language.substring(0, 2) : "en";

	const dtNormalized = convertUtc ? moment.utc(date).toDate() : date;

	return date
		? moment(dtNormalized).locale(locale).format(format).toString()
		: defaultValue;
};
export default DateTimeString;
