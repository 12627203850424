import { InvoiceInstructionExportationProps } from "../interfaces/InvoiceInstructionExportationDetails";

export const invoiceInstructionExportationModel: InvoiceInstructionExportationProps =
	{
		dealerNameWithCode: "",
		dealerNameCountry: "",
		currency: "",
		shippingMethod: "",
		carrier: "",
		saleType: 0,
		paymentTerms: "",
		incoterms: "",
		exportationType: "",
		importerValue: 0,
		shippingValue: 0,
		insuranceValue: 0,
		customsValue: 0,
		comissions: [],
		mktApproved: false,
		financialApproved: false,
	};

export function invoiceInstructionExportationModelBuilder(
	data: InvoiceInstructionExportationProps
) {
	return {
		...invoiceInstructionExportationModel,
		...data,
		dealerNameWithCode: data.dealerNameWithCode ?? "",
		dealerNameCountry: data.dealerNameCountry ?? "",
		currency: data.currency ?? "",
		shippingMethod: data.shippingMethod ?? "",
		carrier: data.carrier ?? "",
		saleType: data.saleType ?? 0,
		paymentTerms: data.paymentTerms ?? "",
		incoterms: data.incoterms ?? "",
		exportationType: data.exportationType ?? "",
		importerValue: data.importerValue ?? 0,
		shippingValue: data.shippingValue ?? 0,
		insuranceValue: data.insuranceValue ?? 0,
		customsValue: data.customsValue ?? 0,
		comissions: data.comissions ?? [],
	} as InvoiceInstructionExportationProps;
}
