import React, { useEffect, useRef } from "react";
import { FilterAlt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import UndoButton from "../Dialog/Buttons/UndoButton";
import ApplyButton from "../Dialog/Buttons/ApplyButton";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

export interface PreFilterEditionActionsProps {
	onUndo: () => void;
	onSubmit: () => void;
	hasPreFiltersApplied: boolean;
	hasFormatError: boolean;
	filters: CompositeFilterDescriptor | undefined;
	chassiType: string;
	removeFilter: any;
	page?: string;
}

const PreFilterEditionActions: React.FC<PreFilterEditionActionsProps> = ({
	onUndo,
	onSubmit,
	hasFormatError,
}) => {
	const { t } = useTranslation("order");
	const hasFormatErrorRef = useRef(hasFormatError);

	useEffect(() => {
		hasFormatErrorRef.current = hasFormatError;
	}, [hasFormatError]);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === "Enter" && !hasFormatErrorRef.current) {
			onSubmit();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [onSubmit]);

	return (
		<>
			<UndoButton
				id="undo-pre-filter"
				onUndo={onUndo}
				title={t("clear")}
			/>
			<ApplyButton
				id="dialog-pre-filter"
				onSubmit={onSubmit}
				title={t("preFilters.apply")}
				startIcon={<FilterAlt />}
				disabled={hasFormatError}
			/>
		</>
	);
};

export default PreFilterEditionActions;
