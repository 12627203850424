import React from "react";
import { DatePicker, DateView } from "@mui/x-date-pickers";

type DateFormProps = {
	//couldnt import datepickerprops properly
	label: string;
	inputFormat?: string;
	value: Date | null;
	onChange?: any;
	size?: "small" | "medium";
	minDate?: Date;
	maxDate?: Date;
	disabled?: boolean;
	//
	editable?: boolean;
	validator?: (value: Date | null) => string;
};

const DateForm: React.FC<DateFormProps> = ({ editable, ...props }) => {
	return (
		<>{editable ? <EditableForm {...props} /> : <ReadOnlyForm {...props} />}</>
	);
};

const ReadOnlyForm: React.FC<DateFormProps> = ({ size, ...props }) => {
	const views: DateView[] | undefined =
		props.inputFormat &&
		["MM/yyyy", "MMMM/yyyy", "MMMM/yy"].includes(props.inputFormat)
			? ["month", "year"]
			: undefined;
	return (
		<DatePicker
			label={props.label}
			value={props.value ? new Date(props.value) : null}
			minDate={props.minDate}
			maxDate={props.maxDate}
			views={views}
			readOnly
			onChange={() => {}}
			format={props.inputFormat ?? "dd/MM/yy"}
			disabled={props.disabled}
			slotProps={{
				textField: {
					InputProps: {
						readOnly: true,
					},
					InputLabelProps: { shrink: true },
					variant: "standard",
					fullWidth: true,
					size: size,
				},
			}}
		/>
	);
};

const EditableForm: React.FC<DateFormProps> = ({
	validator,
	size,
	...props
}) => {
	const errorMessage = validator && props.value ? validator(props.value) : "";
	const hasError = Boolean(errorMessage);
	const views: DateView[] | undefined =
		props.inputFormat &&
		["MM/yyyy", "MMMM/yyyy", "MMMM/yy"].includes(props.inputFormat)
			? ["month", "year"]
			: undefined;

	return (
		<DatePicker
			label={props.label}
			format={props.inputFormat ?? "dd/MM/yy"}
			views={views}
			value={props.value ? new Date(props.value) : null}
			onChange={props.onChange}
			minDate={props.minDate}
			maxDate={props.maxDate}
			disabled={props.disabled}
			slotProps={{
				textField: {
					variant: "standard",
					size: size,
					error: hasError,
					helperText: errorMessage,
					fullWidth: true,
					InputLabelProps: { shrink: true },
				},
			}}
		/>
	);
};

export default DateForm;
