import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { EuropeEditableProps } from "../../pages/Order/Details/interfaces/EuropeEditableDetails";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";

interface IEuropeFactoryService {
	listPaginated(dataState: State, menu: string): Promise<any>;
	csvExport(
		dataState: State,
		menu: string,
		culture: CsvOptionsProps
	): Promise<any>;
	getDetails(menu: string, europeOrderSASId: string): Promise<any>;
	update(menu: string, orderDetails: EuropeEditableProps): Promise<any>;
	getFactoryOrderByChassi(chassi: string, country: string): Promise<any>;
	getChassiByFactoryOrder(factoryOrder: string, country: string): Promise<any>;
}

const base = "/europeOrderbook";

export class EuropeFactoryService implements IEuropeFactoryService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State, menu: string): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list/${menu}?${toDataSourceRequestString(dataState)}`
		);

	csvExport = async (
		dataState: State,
		menuKey: string,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv/${menuKey}?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	getDetails = async (menu: string, europeOrderSASId: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${menu}/${europeOrderSASId}`);

	update = async (
		menu: string,
		orderDetails: EuropeEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/${menu}`,
			JSON.stringify(orderDetails)
		);

	getCountryFilterOptions = async (menu: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/filterCountry/${menu}`);

	getFactoryOrderByChassi = async (
		chassi: string,
		country: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/vehicleInfoByChassi/${chassi}?country=${country}`
		);

	getChassiByFactoryOrder = async (
		factoryOrder: string,
		country: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/vehicleInfoByFactoryOrder/${factoryOrder}?country=${country}`
		);
}
