import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import MonthPreFilter from "../components/MonthPreFilter";
import { MonthPreFilterProps } from "../Interfaces/MonthPreFilterProps";

const MonthAvailabilityPreFilter: React.FC<MonthPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		label = "preFilters.monthAvailability",
		id = "monthAvailabilityPreFilter",
	} = props;
	return <MonthPreFilter {...props} label={t(label)} id={id} />;
};

export default observer(MonthAvailabilityPreFilter);
