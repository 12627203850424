import { QuotationManagerEditableDetails } from "../interfaces/QuotationManagerEditableDetails";
import {
	quotationBaseEditableDetailsModel,
	quotationBaseEditableDetailsModelBuilder,
} from "./QuotationBaseEditableDetailsModel";

export const quotationManagerEditableDetailsModel: QuotationManagerEditableDetails =
	{
		...quotationBaseEditableDetailsModel,
		salesmanId: "",
	};

export function quotationManagerEditableDetailsModelBuilder(
	data: QuotationManagerEditableDetails
) {
	if (!data) return quotationManagerEditableDetailsModel;
	var QuotationBaseEditableDetails =
		quotationBaseEditableDetailsModelBuilder(data);
	return {
		...QuotationBaseEditableDetails,
		salesmanId: data.salesmanId ?? "",
	} as QuotationManagerEditableDetails;
}
