import { BusReadOnlyProps } from "../interfaces/BusReadOnlyDetails";
import {
	baseReadOnlyModel,
	baseReadOnlyModelBuilder,
} from "./BaseReadOnlyModel";

export const busReadOnlyModel: BusReadOnlyProps = {
	...baseReadOnlyModel,
	busApplication: "",
};

export function busReadOnlyModelBuilder(data: BusReadOnlyProps) {
	if (!data) return busReadOnlyModel;
	let model = baseReadOnlyModelBuilder(data);
	return {
		...busReadOnlyModel,
		...model,
		busApplication: data.busApplication ?? "",
	} as BusReadOnlyProps;
}
