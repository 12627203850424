import { Backdrop, CircularProgress } from "@mui/material";
import * as React from "react";

interface LoadingPageProps {
	loading: boolean;
}

export const LoadingPage: React.FC<LoadingPageProps> = (props) => {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={props.loading}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};
