import React from "react";
import { configure } from "mobx";

import CtadmvenApi from "../../../../apis/CtadmvenApi";
import BusStore from "./BusStore";
import CommonStore from "./CommonStore";
import TruckStore from "./TruckStore";
import VolvoManagementStore from "./VolvoManagementStore";

configure({ enforceActions: "always" });

class RootStore {
	commonStore: any = {};
	busStore: any = {};
	truckStore: any = {};
	volvoManagementStore: any = {};

	constructor() {
		const api = new CtadmvenApi();

		this.commonStore = new CommonStore(this, api);
		this.busStore = new BusStore(this, api);
		this.truckStore = new TruckStore(this, api);
		this.volvoManagementStore = new VolvoManagementStore(this, api);
	}
}
const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);
export default RootStore;
