import React from "react";
import { observer } from "mobx-react";
import { Button } from "@mui/material";
import { Undo } from "@mui/icons-material";

type UndoButtonProps = {
	id: string;
	title: string;
	onUndo: () => void;
	disabled?: boolean;
};

const UndoButton: React.FC<UndoButtonProps> = ({
	id,
	title,
	onUndo,
	disabled,
}) => {
	return (
		<Button
			id={`undo-btn-${id}`}
			variant="outlined"
			color="secondary"
			startIcon={<Undo />}
			onClick={onUndo}
			disabled={disabled}
		>
			{title}
		</Button>
	);
};

export default observer(UndoButton);
