import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextPreFilter from "../components/TextPreFilter";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import { GRID_MAX_SIZE } from "../../../constants/gridConst";

const FACTORYORDER_PRE_FILTER_XS = GRID_MAX_SIZE;
const FACTORYORDER_PRE_FILTER_SM = 6;
const FACTORYORDER_PRE_FILTER_MD = 5;
const FACTORYORDER_PRE_FILTER_LG_XL = 4;

const FactoryOrderPreFilter: React.FC<TextPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "factoryOrderPreFilter",
		label = "preFilters.factoryOrder",
		xs = FACTORYORDER_PRE_FILTER_XS,
		sm = FACTORYORDER_PRE_FILTER_SM,
		md = FACTORYORDER_PRE_FILTER_MD,
		lg = FACTORYORDER_PRE_FILTER_LG_XL,
		xl = FACTORYORDER_PRE_FILTER_LG_XL,
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			tooltip={t("tooltips.multipleSearch")}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(FactoryOrderPreFilter);
