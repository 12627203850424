import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ModelSectionProps } from "../../interfaces/Sections/ModelSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";

const ModelSection: React.FC<ModelSectionProps> = ({
	readOnlyFields,
	editableFields,
	setOrderMCManagementFieldByKeyOnChange,
	userCanEditPaquete,
	paqueteOptions,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.model.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.productionModel")}
					value={readOnlyFields.productionModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.factoryYear")}
					value={readOnlyFields.factoryYear}
					type="number"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.lineCode")}
					value={readOnlyFields.lineCode}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialModel")}
					value={readOnlyFields.commercialModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.modelYear")}
					value={readOnlyFields.modelYear}
					type="number"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.class")}
					value={readOnlyFields.class}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialTrimmingPackages")}
					value={readOnlyFields.commercialTrimmingPackages}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.model.paquete")}
					labelId={"sections-model-paquete"}
					value={editableFields.paqueteId}
					options={paqueteOptions}
					editable={userCanEditPaquete}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"paqueteId",
							e.target.value as string
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSection);
