import { TruckEditableProps } from "../../interfaces/Editable/TruckEditableDetails";

export const truckEditableModel: TruckEditableProps = {
	id: "",
	finalCustomer: "",
	bodybuilder: "",
	generalComment: "",
	zoneId: "",
};

export function truckEditableModelBuilder(data: TruckEditableProps) {
	if (!data) return truckEditableModel;
	return {
		id: data.id ?? "",
		bodybuilder: data.bodybuilder ?? "",
		finalCustomer: data.finalCustomer ?? "",
		generalComment: data.generalComment ?? "",
		zoneId: data.zoneId ?? "",
	} as TruckEditableProps;
}
