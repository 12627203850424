import {
	FollowUpArgAccountingEditableProps,
	FollowUpArgArgMarketCompanyEditableProps,
	FollowUpArgBusinessEditableProps,
	FollowUpArgEditableProps,
	FollowUpArgEngineeringEditableProps,
	FollowUpArgFinancialEditableProps,
	FollowUpArgGeneralCommentEditableProps,
	FollowUpArgLogisticEditableProps,
} from "../../interfaces/Editable/FollowUpArgEditable";
import {
	orderMCDeliveryEditableModel,
	orderMCDeliveryEditableModelBuilder,
	orderMCDeliveryFinancialEditableModel,
	orderMCDeliveryFinancialEditableModelBuilder,
} from "./OrderMCDeliveryEditableModel";
import {
	orderMCManagementAccountingEditableModel,
	orderMCManagementAccountingEditableModelBuilder,
	orderMCManagementArgMarketCompanyEditableModel,
	orderMCManagementArgMarketCompanyEditableModelBuilder,
	orderMCManagementBusinessEditableModel,
	orderMCManagementBusinessEditableModelBuilder,
	orderMCManagementEditableModel,
	orderMCManagementEditableModelBuilder,
	orderMCManagementEngineeringEditableModel,
	orderMCManagementEngineeringEditableModelBuilder,
	orderMCManagementFinancialEditableModel,
	orderMCManagementFinancialEditableModelBuilder,
	orderMCManagementLogisticEditableModel,
	orderMCManagementLogisticEditableModelBuilder,
} from "./OrderMCManagementEditableModel";

export const followUpArgEditableModel: FollowUpArgEditableProps = {
	id: "",
	finalCustomer: "",
	bodybuilder: "",
	salesman: "",
	branchOffice: "",
	generalComment: "",

	orderMCDelivery: orderMCDeliveryEditableModel,
	orderMCManagement: orderMCManagementEditableModel,
};

export function followUpArgEditableModelBuilder(data: any) {
	if (!data) return followUpArgEditableModel;
	const id = data.id ?? "";
	return {
		id: id,
		bodybuilder: data.bodybuilder ?? "",
		salesman: data.salesman ?? "",
		finalCustomer: data.finalCustomer ?? "",
		branchOffice: data.branchOffice ?? "",
		generalComment: data.generalComment ?? "",
		orderMCDelivery: data.orderMCDelivery
			? orderMCDeliveryEditableModelBuilder(data.orderMCDelivery, id)
			: orderMCDeliveryEditableModel,
		orderMCManagement: data.orderMCManagement
			? orderMCManagementEditableModelBuilder(data.orderMCManagement, id)
			: orderMCManagementEditableModel,
	} as FollowUpArgEditableProps;
}

const followUpArgArgMarketCompanyEditableModel: FollowUpArgArgMarketCompanyEditableProps =
	{
		id: "",
		finalCustomer: "",
		bodybuilder: "",
		salesman: "",
		branchOffice: "",
		generalComment: "",
		orderMCDelivery: orderMCDeliveryEditableModel,
		orderMCManagement: orderMCManagementEditableModel,
	};

export function followUpArgArgMarketCompanyEditableModelBuilder(data: any) {
	if (!data) return followUpArgArgMarketCompanyEditableModel;
	const id = data.id ?? "";
	return {
		id: id,
		bodybuilder: data.bodybuilder ?? "",
		salesman: data.salesman ?? "",
		finalCustomer: data.finalCustomer ?? "",
		branchOffice: data.branchOffice ?? "",
		generalComment: data.generalComment ?? "",
		orderMCDelivery: data.orderMCDelivery
			? orderMCDeliveryEditableModelBuilder(data.orderMCDelivery, id)
			: orderMCDeliveryEditableModel,
		orderMCManagement: data.orderMCManagement
			? orderMCManagementArgMarketCompanyEditableModelBuilder(
					data.orderMCManagement,
					id
				)
			: orderMCManagementArgMarketCompanyEditableModel,
	} as FollowUpArgArgMarketCompanyEditableProps;
}

export const followUpArgGeneralCommentEditableModel: FollowUpArgGeneralCommentEditableProps =
	{
		id: "",
		generalComment: "",
	};

export function followUpArgGeneralCommentEditableModelBuilder(data: any) {
	if (!data) return followUpArgEditableModel;
	const id = data.id ?? "";
	return {
		id: id,
		generalComment: data.generalComment ?? "",
	} as FollowUpArgGeneralCommentEditableProps;
}

export const followUpArgFinancialEditableModel: FollowUpArgFinancialEditableProps =
	{
		id: "",
		generalComment: "",
		orderMCDelivery: orderMCDeliveryFinancialEditableModel,
		orderMCManagement: orderMCManagementFinancialEditableModel,
	};

export function followUpArgFinancialEditableModelBuilder(
	data: any
): FollowUpArgFinancialEditableProps {
	if (!data) return { ...followUpArgFinancialEditableModel };
	const id = data.id ?? "";
	const model: FollowUpArgFinancialEditableProps = {
		id: id,
		generalComment: data.generalComment ?? "",
		orderMCDelivery: data.orderMCDelivery
			? orderMCDeliveryFinancialEditableModelBuilder(
					data.orderMCDelivery,
					data.id
				)
			: orderMCDeliveryFinancialEditableModel,
		orderMCManagement: data.orderMCManagement
			? orderMCManagementFinancialEditableModelBuilder(
					data.orderMCManagement,
					data.id
				)
			: orderMCManagementFinancialEditableModel,
	};

	return model;
}

export const followUpArgLogisticEditableModel: FollowUpArgLogisticEditableProps =
	{
		id: "",
		generalComment: "",
		orderMCManagement: orderMCManagementLogisticEditableModel,
	};

export function followUpArgLogisticEditableModelBuilder(
	data: any
): FollowUpArgLogisticEditableProps {
	if (!data) return { ...followUpArgEditableModel };
	const id = data.id ?? "";
	const model: FollowUpArgLogisticEditableProps = {
		id: id,
		generalComment: data.generalComment ?? "",
		orderMCManagement: data.orderMCManagement
			? orderMCManagementLogisticEditableModelBuilder(
					data.orderMCManagement,
					data.id
				)
			: orderMCManagementLogisticEditableModel,
	};

	return model;
}

export const followUpArgAccountingEditableModel: FollowUpArgAccountingEditableProps =
	{
		id: "",
		generalComment: "",
		orderMCManagement: orderMCManagementAccountingEditableModel,
	};

export function followUpArgAccountingEditableModelBuilder(
	data: any
): FollowUpArgAccountingEditableProps {
	if (!data) return { ...followUpArgEditableModel };
	const id = data.id ?? "";
	const model: FollowUpArgAccountingEditableProps = {
		id: id,
		generalComment: data.generalComment ?? "",
		orderMCManagement: data.orderMCManagement
			? orderMCManagementAccountingEditableModelBuilder(
					data.orderMCManagement,
					data.id
				)
			: orderMCManagementAccountingEditableModel,
	};

	return model;
}

export const followUpArgBusinessEditableModel: FollowUpArgBusinessEditableProps =
	{
		id: "",
		generalComment: "",
		orderMCManagement: orderMCManagementBusinessEditableModel,
	};

export function followUpArgBusinessEditableModelBuilder(
	data: any
): FollowUpArgBusinessEditableProps {
	if (!data) return { ...followUpArgEditableModel };
	const id = data.id ?? "";
	const model: FollowUpArgBusinessEditableProps = {
		id: id,
		generalComment: data.generalComment ?? "",
		orderMCManagement: data.orderMCManagement
			? orderMCManagementBusinessEditableModelBuilder(
					data.orderMCManagement,
					data.id
				)
			: orderMCManagementBusinessEditableModel,
	};

	return model;
}

export const followUpArgEngineeringEditableModel: FollowUpArgEngineeringEditableProps =
	{
		id: "",
		generalComment: "",
		orderMCManagement: orderMCManagementEngineeringEditableModel,
	};

export function followUpArgEngineeringEditableModelBuilder(
	data: any
): FollowUpArgEngineeringEditableProps {
	if (!data) return { ...followUpArgEditableModel };
	const id = data.id ?? "";
	const model: FollowUpArgEngineeringEditableProps = {
		id: id,
		generalComment: data.generalComment ?? "",
		orderMCManagement: data.orderMCManagement
			? orderMCManagementEngineeringEditableModelBuilder(
					data.orderMCManagement,
					data.id
				)
			: orderMCManagementEngineeringEditableModel,
	};

	return model;
}
