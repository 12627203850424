import React from "react";
import {
	SwitchProps,
	FormGroup,
	Switch,
	FormControlLabel,
} from "@mui/material";

type SwitchFormProps = SwitchProps & {
	editable?: boolean;
	label?: string;
};

const SwitchForm: React.FC<SwitchFormProps> = ({ editable, ...props }) => {
	return (
		<>{editable ? <EditableForm {...props} /> : <ReadOnlyForm {...props} />}</>
	);
};

const ReadOnlyForm: React.FC<SwitchFormProps> = ({ label, ...props }) => {
	return (
		<FormGroup>
			<FormControlLabel
				control={<Switch disabled color="secondary" {...props} />}
				label={label}
			/>
		</FormGroup>
	);
};

const EditableForm: React.FC<SwitchFormProps> = ({ label, ...props }) => {
	return (
		<FormGroup>
			<FormControlLabel
				control={<Switch color="secondary" {...props} />}
				label={label}
			/>
		</FormGroup>
	);
};

export default SwitchForm;
