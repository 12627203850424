import React from "react";
import { observer } from "mobx-react";
import { DialogTitle, DialogTitleProps, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

type DialogTitleClosableProps = DialogTitleProps & {
	onClose: (() => void) | ((e: any) => void);
};

const DialogTitleClosable: React.FC<DialogTitleClosableProps> = ({
	children,
	onClose,
	...other
}) => {
	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			<strong>{children}</strong>
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

export default observer(DialogTitleClosable);
