import * as React from "react";
import { Avatar, Tooltip } from "@mui/material";
import { VehicleType } from "../../../utils/GenericTypes";
import { DirectionsBus, LocalShipping, Warning } from "@mui/icons-material";
interface VehicleTypeIconProps {
	vehicleType: VehicleType;
	statusOk: boolean;
}
const VehicleTypeIcon: React.FC<VehicleTypeIconProps> = ({
	vehicleType,
	statusOk,
}) => {
	const iconColor = statusOk
		? vehicleType === "Unknown"
			? "#FFA000"
			: "#1ABC31"
		: "#BF2012";
	const icon =
		vehicleType === "Unknown" ? (
			<Warning />
		) : vehicleType === "Truck" ? (
			<LocalShipping />
		) : (
			<DirectionsBus />
		);

	return (
		<Tooltip title={vehicleType}>
			<Avatar
				alt={vehicleType}
				sx={{ width: 64, height: 64, bgcolor: iconColor }}
			>
				{icon}
			</Avatar>
		</Tooltip>
	);
};

export default VehicleTypeIcon;
