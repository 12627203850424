import { GUID_EMPTY } from "../../../../app/constants/config";
import { QuotationBaseEditableDetails } from "../interfaces/QuotationBaseEditableDetails";

export const quotationBaseEditableDetailsModel: QuotationBaseEditableDetails = {
	id: GUID_EMPTY,
	rut: "",
	financialCustomerCode: "",
};

export function quotationBaseEditableDetailsModelBuilder(
	data: QuotationBaseEditableDetails
) {
	if (!data) return quotationBaseEditableDetailsModel;

	return {
		id: data.id ?? GUID_EMPTY,
		rut: data.rut ?? "",
		financialCustomerCode: data.financialCustomerCode ?? "",
	} as QuotationBaseEditableDetails;
}
