import { QuotationReadOnlyDetails } from "../interfaces/QuotationReadOnlyDetails";
import { QuotationRestoreOrCancelOptions } from "../interfaces/QuotationRestoreOrCancelOptions";

export const quotationRestoreOrCancelOptionsModel: QuotationRestoreOrCancelOptions =
	{
		id: "",
		isRestoreAction: false,
	};

export function quotationRestoreOrCancelOptionsModelBuilder(
	data: QuotationReadOnlyDetails
): QuotationRestoreOrCancelOptions {
	if (!data) return quotationRestoreOrCancelOptionsModel;

	return {
		id: data.id,
		isRestoreAction: data.statusSAS === 1,
	};
}
