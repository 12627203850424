import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import SelectPreFilter from "../components/SelectPreFilter";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";

export const LINE_PRE_FILTER_XS = 3;
export const LINE_PRE_FILTER_SM = 2;
const LINE_PRE_FILTER_MD_LG_XL = 1;

const LinePreFilter: React.FC<SelectPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "linePreFilter",
		label = "preFilters.line",
		xs = LINE_PRE_FILTER_XS,
		sm = LINE_PRE_FILTER_SM,
		md = LINE_PRE_FILTER_MD_LG_XL,
		lg = LINE_PRE_FILTER_MD_LG_XL,
		xl = LINE_PRE_FILTER_MD_LG_XL,
	} = props;
	return (
		<SelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(LinePreFilter);
