import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import DestinationForm from "./Form/DestinationForm";
import LogisticCommentsForm from "./Form/LogisticCommentsForm";
import GeneralCommentsForm from "./Form/GeneralCommentsForm";

const LogisticUserContent: React.FC = () => {
	return (
		<>
			<Grid item xs={12}>
				<DestinationForm />
			</Grid>
			<Grid item xs={12}>
				<LogisticCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<GeneralCommentsForm />
			</Grid>
		</>
	);
};

export default observer(LogisticUserContent);
