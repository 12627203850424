import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { useExportReadStore } from "../stores/ExportReadStore";
import ExportReadFilters from "./ExportReadFilters";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import ExportReadGrid from "./ExportReadGrid";
import FirmPeriodWeek from "./FirmPeriodWeek";

const ExportReadIndex: React.FC = () => {
	const { t } = useTranslation("quota");
	const { loading, firmPeriodWeek } = useExportReadStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("quota.exportRead", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<>
			<LoadingPage loading={loading} />
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs} />
				<ExportReadFilters />
			</Grid>
			<Grid item lg={12}>
				<ExportReadGrid />
			</Grid>
			<Grid item lg={12}>
				<FirmPeriodWeek firmPeriodWeek={firmPeriodWeek} />
			</Grid>
		</>
	);
};

export default observer(ExportReadIndex);
