import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { MenuItems } from "./MenuItems";
import { Container, Toolbar } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useAuthStore } from "../auth/store/AuthStore";
import { observer } from "mobx-react";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import NavBar from "../components/header/NavBar";
import MenuDrawer from "../components/header/MenuDrawer";
import StickyFooter from "../components/footer/StickyFooter";
import { trackUserGroupEvent } from "../services/appInsights/TelemetryService";

const theme = createTheme({
	palette: {
		primary: {
			light: "#ffffff",
			main: "#e1dfdd",
			dark: "#afadab",
			contrastText: "#53565a",
		},
		secondary: {
			light: "#7f8387",
			main: "#53565a",
			dark: "#2a2d31",
			contrastText: "#ffffff",
		},
		success: {
			main: "#1ABC31",
			contrastText: "#ffffff",
		},
		warning: {
			main: "#FFA000",
		},
		error: {
			main: "#BF2012",
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					scrollbarColor: "#afadab #e1dfdd",
					"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
						backgroundColor: "#e1dfdd",
					},
					"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
						borderRadius: 8,
						backgroundColor: "#afadab",
						border: "2px solid #e1dfdd",
					},
					"&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
						{
							backgroundColor: "#afadab",
						},
					"&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
						{
							backgroundColor: "#afadab",
						},
					"&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
						{
							backgroundColor: "#afadab",
						},
					"&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
						backgroundColor: "#e1dfdd",
					},
				},
			},
		},
	},
});

interface DashboardContentProps {
	children: React.ReactNode;
}

const DashboardContent: React.FC<DashboardContentProps> = ({ children }) => {
	const [open, setOpen] = React.useState(false);
	const openDrawer = () => setOpen(true);
	const closeDrawer = () => setOpen(false);

	const {
		graphData,
		setAccessTokenAndGraphData,
		handleLogout,
		groups,
		isOwnerUser,
	} = useAuthStore();

	const { instance, inProgress, accounts } = useMsal();

	const setAccessToken = React.useCallback(() => {
		setAccessTokenAndGraphData(accounts, instance, inProgress);
	}, [instance, accounts, inProgress, setAccessTokenAndGraphData]);

	React.useEffect(() => {
		setAccessToken();
	}, [setAccessToken]);

	const HOUR_MS = 3000000; //50 min
	React.useEffect(() => {
		//every 50 minutes refresh token and graph data
		const interval = setInterval(() => {
			setAccessToken();
		}, HOUR_MS);
		return () => clearInterval(interval);
	}, [setAccessToken]);

	React.useEffect(() => {
		trackUserGroupEvent(groups);
	}, [groups]);

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				{graphData && (
					<>
						<NavBar
							open={open}
							menuName={process.env.REACT_APP_NAME}
							userName={graphData.displayName}
							userJobTitle={graphData.jobTitle}
							signOutEvent={() => handleLogout("redirect", instance)}
							actions={<LanguageSelector />}
							isOwnerUser={isOwnerUser}
						/>
						<MenuDrawer
							open={open}
							openDrawer={openDrawer}
							closeDrawer={closeDrawer}
							menuList={<MenuItems open={open} />}
						/>
						<Box
							component="main"
							sx={{
								backgroundColor: () => theme.palette.grey[100],
								minHeight: "100vh",
								width: "100%",
								position: "relative",
							}}
						>
							<Toolbar />
							<Box
								sx={{
									paddingBottom: "2.5rem",
									width: "calc(100vw - 84px)",
								}}
							>
								<Container maxWidth={false}>{children}</Container>
							</Box>
							<StickyFooter
								systemName={process.env.REACT_APP_NAME}
								systemVersion={process.env.REACT_APP_VERSION}
							/>
						</Box>
					</>
				)}
			</Box>
		</ThemeProvider>
	);
};

export default observer(DashboardContent);
