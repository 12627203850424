import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { useVolvoDealerReadStore } from "../../stores/VolvoDealerReadStore";
import VolvoDealerReadFilters from "./VolvoDealerReadFilters";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import VolvoDealerReadGrid from "./VolvoDealerReadGrid";
import FirmPeriodWeek from "../FirmPeriodWeek";

const VolvoDealerReadIndex: React.FC = () => {
	const { t } = useTranslation("quota");
	const { loading, firmPeriodWeek } = useVolvoDealerReadStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("quota.dealerRead", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<>
			<LoadingPage loading={loading} />
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs} />
				<VolvoDealerReadFilters />
			</Grid>
			<Grid item lg={12}>
				<VolvoDealerReadGrid />
			</Grid>
			<Grid item lg={12}>
				<FirmPeriodWeek firmPeriodWeek={firmPeriodWeek} />
			</Grid>
		</>
	);
};

export default observer(VolvoDealerReadIndex);
