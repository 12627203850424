import React from "react";
import { Save } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import UndoButton from "../Dialog/Buttons/UndoButton";
import ApplyButton from "../Dialog/Buttons/ApplyButton";

export interface BatchEditionActionsProps {
	onUndo: () => void;
	onSubmit: () => void;
	hasChanges: boolean;
	hasItemsSelected: boolean;
}

const BatchEditionActions: React.FC<BatchEditionActionsProps> = ({
	onUndo,
	onSubmit,
	hasChanges,
	hasItemsSelected,
}) => {
	const { t } = useTranslation("general");
	const handleSubmit = () => {
		onSubmit();
		onUndo();
	};
	return (
		<>
			<UndoButton
				id="undo-batch-edition"
				onUndo={onUndo}
				disabled={!hasChanges}
				title={t("clear")}
			/>
			<ApplyButton
				id="submit-batch-edition"
				onSubmit={handleSubmit}
				title={t("saveChanges")}
				startIcon={<Save />}
				disabled={!(hasChanges && hasItemsSelected)}
			/>
		</>
	);
};

export default BatchEditionActions;
