import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { CommercialSectionProps } from "../../interfaces/Sections/CommercialSection";
import SwitchForm from "../../../../../components/FormInputs/SwitchForm";

const CommercialSection: React.FC<CommercialSectionProps> = ({
	editable,
	editableFields,
	setEditableFieldByKeyOnChange,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}></Grid>
			<Grid item>
				<SwitchForm
					editable={editable}
					label={t("details.sections.commercial.extraQuota")}
					checked={editableFields.extraQuota}
					onChange={(e) =>
						setEditableFieldByKeyOnChange("extraQuota", e.target.checked)
					}
				/>
			</Grid>
			<Grid item>
				<SwitchForm
					editable={editable}
					label={t("details.sections.commercial.priority")}
					checked={editableFields.priority}
					onChange={(e) =>
						setEditableFieldByKeyOnChange("priority", e.target.checked)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(CommercialSection);
