import { Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { useGroupRegionStore } from "../stores/GroupRegionStore";
import { marketsGroupRegion } from "../models/MarketsGroupRegion";
import PopoverOverlay from "../../../../../components/PopoverOverlay/PopoverOverlay";
import InfoIcon from "@mui/icons-material/Info";
import _ from "lodash";
import "../styles/regionGroupGrid.css";
import { toast } from "react-toastify";
import SaveBar from "../../../../../components/SaveBar/SaveBar";

const RegionGroupGrid: React.FC = () => {
	const { t } = useTranslation("general");

	const {
		regionGroupEdit,
		regionGroupInitial,
		resetRegionsGroupEdit,
		setRegionIntoRegionGroup,
		updateRegionGroupRegions,
	} = useGroupRegionStore();

	const handleSubmit = () => {
		updateRegionGroupRegions()
			.then(() => toast.success(t(`updatedRecordWithSuccess`).toString()))
			.catch((err) => toast.error(err.response.data));
	};

	return (
		<>
			<Grid item lg={12}>
				<Stack spacing={3}>
					{marketsGroupRegion.map((marketGroup, i) => (
						<AccordionCard
							id={marketGroup.name}
							title={t(marketGroup.name)}
							key={i}
							defaultExpanded
						>
							{regionGroupEdit.map(
								(regionGroup, i) =>
									marketGroup.key.includes(regionGroup.markets) && (
										<AccordionCard
											id="brazilAccordion"
											title={regionGroup.name}
											gridNumber={4}
											defaultExpanded
											preTitle={
												<PopoverOverlay
													header={regionGroup.name}
													body={regionGroup.description}
												>
													<span>
														<InfoIcon
															fontSize="small"
															style={{ fontSize: 16 }}
														/>
													</span>
												</PopoverOverlay>
											}
											key={i}
										>
											<div style={{ paddingLeft: 25 }}>
												{regionGroup.regions?.map((region, i) => (
													<FormControlLabel
														control={
															<Checkbox
																checked={region?.checked}
																color="secondary"
																onChange={() =>
																	setRegionIntoRegionGroup(region, regionGroup)
																}
															/>
														}
														label={region?.value}
														key={i}
													/>
												))}
											</div>
										</AccordionCard>
									)
							)}
						</AccordionCard>
					))}
				</Stack>
			</Grid>
			<Grid item lg={12} mt={4}>
				<SaveBar
					hasFormChanges={!_.isEqual(regionGroupEdit, regionGroupInitial)}
					onResetClick={resetRegionsGroupEdit}
					resetLabel={t("clear")}
					onSaveClick={handleSubmit}
					saveLabel={t("save")}
				/>
			</Grid>
		</>
	);
};

export default observer(RegionGroupGrid);
