import { BaseReadOnlyProps } from "../interfaces/BaseReadOnlyDetails";
import { invoiceExportationModel } from "./InvoiceExportationModel";
import { invoiceInstructionExportationModel } from "./InvoiceInstructionExportationModel";
import { invoiceInstructionModel } from "./InvoiceInstructionModel";
import { reservationExportModel } from "./ReservationExportModel";
import { reservationAvailabilityModel } from "./ReservationAvailabilityModel";
import { saleInvoiceModel } from "./SaleInvoiceModel";
import { invoicingDeliveryMarketCompanyModel } from "./InvoicingDeliveryMarketCompanyModel";
import { exportLocalizationModel } from "./ExportLocalizationModel";

export const baseReadOnlyModel: BaseReadOnlyProps = {
	id: "",
	factoryOrder: "",
	foDealerRegion: "",
	chassi: "",
	factoryOrderDistrict: "",
	saleStatus: "",
	foOriginalCustomerOM: "",
	lastUpdateEventTypeName: "",
	productionModel: "",
	lineCode: "",
	commercialModel: "",
	class: "",
	vehicleIdentificationNumber: "",
	engineSerialNumber: "",
	variants: [],
	lcd: null,
	cdd: null,
	cddeol: null,
	online: null,
	offline: null,
	asWeekDate: null,
	greenOk: null,
	lineSequenceDate: null,
	lineSequence: "",
	onlineDelay: 0,
	asWeekDelay: 0,
	asWeekDateInitial: null,
	cddRespec: null,
	respecStartDate: null,
	variantsRespec: [],
	localizationInvoices: [],
	respecEndDate: null,
	gateDate: null,
	deliveryDate: null,
	isDistrictBrazil: true,
	isDistrictChile: false,
	saleInvoice: saleInvoiceModel,
	invoiceExportation: invoiceExportationModel,
	invoiceInstruction: invoiceInstructionModel,
	invoiceInstructionExportation: invoiceInstructionExportationModel,
	locationStatus: 0,
	reservationAvailability: reservationAvailabilityModel,
	reservationExport: reservationExportModel,
	invoicingDeliveryMarketCompany: invoicingDeliveryMarketCompanyModel,
	localizationExport: exportLocalizationModel,
};

export function baseReadOnlyModelBuilder(data: BaseReadOnlyProps) {
	if (!data) return baseReadOnlyModel;
	return {
		...baseReadOnlyModel,
		...data,
		id: data.id ?? "",
		factoryOrder: data.factoryOrder ?? "",
		foDealerRegion: data.foDealerRegion ?? "",
		chassi: data.chassi ?? "",
		factoryOrderDistrict: data.factoryOrderDistrict ?? "",
		saleStatus: data.saleStatus ?? "",
		foOriginalCustomerOM: data.foOriginalCustomerOM ?? "",
		lastUpdateEventTypeName: data.lastUpdateEventTypeName ?? "",
		productionModel: data.productionModel ?? "",
		lineCode: data.lineCode ?? "",
		commercialModel: data.commercialModel ?? "",
		class: data.class ?? "",
		vehicleIdentificationNumber: data.vehicleIdentificationNumber ?? "",
		engineSerialNumber: data.engineSerialNumber ?? "",
		lineSequence: data.lineSequence ?? "",
		locationStatus: data.locationStatus ?? 0,
	} as BaseReadOnlyProps;
}
