import { TFunction } from "i18next";
import { SelectOptionProps } from "../GenericInterfaces";

const ArgDeliveryProbabilityEnum = {
	None: 0,
	Probable: 1,
	Firm: 2,
};

export const ArgDeliveryProbabilityNoneColor = "#BF2012";
export const ArgDeliveryProbabilityProbableColor = "#FFA000";
export const ArgDeliveryProbabilityFirmColor = "#1ABC31";

export function getFollowUpArgProbabilityOptions(
	t: TFunction<"order", undefined>
): Array<SelectOptionProps> {
	return [
		{
			label: t("deliveryProbability.none"),
			value: ArgDeliveryProbabilityEnum.None,
			color: ArgDeliveryProbabilityNoneColor,
		},
		{
			label: t("deliveryProbability.probable"),
			value: ArgDeliveryProbabilityEnum.Probable,
			color: ArgDeliveryProbabilityProbableColor,
		},
		{
			label: t("deliveryProbability.firm"),
			value: ArgDeliveryProbabilityEnum.Firm,
			color: ArgDeliveryProbabilityFirmColor,
		},
	];
}
