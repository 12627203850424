import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateRangePreFilterProps } from "../Interfaces/DateRangePreFilterProps";
import DateRangePreFilter from "../components/DateRangePreFilter";

const GokDatePreFilter: React.FC<DateRangePreFilterProps> = ({
	startDate,
	endDate,
	preFilterStore,
	xs = 6,
	labelEnd = "preFilters.gokDate",
	label = "preFilters.gokDate",
	id = "gokDatePreFilter",
}) => {
	const { t } = useTranslation("order");

	return (
		<DateRangePreFilter
			startDate={startDate}
			endDate={endDate}
			preFilterStore={preFilterStore}
			xs={xs}
			labelEnd={t(labelEnd)}
			label={t(label)}
			id={id}
		/>
	);
};

export default observer(GokDatePreFilter);
