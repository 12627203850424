import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import { Grid } from "@mui/material";
import SummaryDraggableCard from "../../../../../components/SummaryDraggableCard/SummaryDraggableCard";
import {
	getHeadersTruck,
	getSummaryDraggableData,
} from "../../models/Summary/SummaryTruckModel";
import ProgressSummary from "./ProgressSummary";

const SummaryTruck: React.FC = () => {
	const { t } = useTranslation("order");
	const {
		summaryTruck,
		loadingSummaryTruck,
		getSummaryTruck,
		refresh,
		progressSummaryTrucks,
		loadingProgressSummaryTrucks,
		getProgressSummaryTrucks,
	} = useStores().listStore;

	const fetch = React.useCallback(async () => {
		getSummaryTruck();
	}, [getSummaryTruck]);

	React.useEffect(() => {
		fetch();
	}, [fetch, refresh]);

	const dataTransformed = getSummaryDraggableData(summaryTruck);
	const headers = getHeadersTruck(t);

	return (
		<>
			<Grid item xs="auto">
				<ProgressSummary
					progressSummary={progressSummaryTrucks}
					loading={loadingProgressSummaryTrucks}
					getProgressSummary={getProgressSummaryTrucks}
					refresh={refresh}
					vehicleType={"Truck"}
				/>
			</Grid>
			<Grid item xs="auto">
				<SummaryDraggableCard
					id={"follow-up-arg-truck-summary"}
					width={340}
					headers={headers}
					data={dataTransformed.dataTransformed}
					footerHeader={`${t("estimatedOfMonth")}:`}
					footerData={dataTransformed.estimatedMonth}
					loading={loadingSummaryTruck}
				/>
			</Grid>
		</>
	);
};

export default observer(SummaryTruck);
