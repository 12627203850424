import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getChileZoneOptions } from "../../../../../utils/SelectOptions";
import { followUpChlKeyLabels } from "../../utils/BatchEditionUtils";
import ClearRecordItem from "../../../../../components/BatchEdition/ClearRecordItem";
import AutoCompleteForm from "../../../../../components/FormInputs/AutoCompleteForm";

const OrderContent: React.FC = () => {
	const { t } = useTranslation("order");
	const chlZonesOptions = getChileZoneOptions(t);

	const { batchData, customerOptions, setBatchDataByKey, loading } =
		useStores().batchEditionStore;

	return (
		<>
			<Grid item sm={6} md={2} lg={2}>
				<ClearRecordItem
					recordTitle={t(followUpChlKeyLabels.zone.label)}
					checked={batchData.zone.clearValue}
					onClearRecord={setBatchDataByKey}
					recordKey={followUpChlKeyLabels.zone.key}
				>
					<SelectForm
						label={t(followUpChlKeyLabels.zone.label)}
						labelId={`${followUpChlKeyLabels.zone.label}-label`}
						value={batchData.zone.value}
						options={chlZonesOptions}
						size="small"
						editable
						hasEmptyDefaultItem
						onChange={(e) =>
							setBatchDataByKey(
								{
									value: e.target.value,
									clearValue: false,
								},
								followUpChlKeyLabels.zone.key
							)
						}
						disabled={batchData.zone.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
			<Grid item sm={6} md={5} lg={5}>
				<ClearRecordItem
					recordTitle={t(followUpChlKeyLabels.customer.label)}
					checked={batchData.customer.clearValue}
					onClearRecord={setBatchDataByKey}
					recordKey={followUpChlKeyLabels.customer.key}
				>
					<AutoCompleteForm
						label={t(followUpChlKeyLabels.customer.label)}
						labelId={`${followUpChlKeyLabels.customer.label}-label`}
						options={customerOptions}
						value={batchData.customer.value}
						editable
						onChange={(e) =>
							setBatchDataByKey(
								{
									value: e,
									clearValue: false,
								},
								followUpChlKeyLabels.customer.key
							)
						}
						hasEmptyDefaultItem
						disabled={batchData.customer.clearValue}
						loading={loading}
					/>
				</ClearRecordItem>
			</Grid>
			<Grid item sm={12} md={5} lg={5}>
				<ClearRecordItem
					recordTitle={t(followUpChlKeyLabels.generalComments.label)}
					checked={batchData.generalComment.clearValue}
					onClearRecord={setBatchDataByKey}
					recordKey={followUpChlKeyLabels.generalComments.key}
				>
					<TextForm
						label={t(followUpChlKeyLabels.generalComments.label)}
						editable
						value={batchData.generalComment.value}
						multiline
						maxRows={4}
						inputProps={{ maxLength: 500 }}
						onChange={(e) =>
							setBatchDataByKey(
								{
									value: e.target.value,
									clearValue: false,
								},
								followUpChlKeyLabels.generalComments.key
							)
						}
						disabled={batchData.generalComment.clearValue}
						helperText={t("tooltips.brFactoryOnly")}
					/>
				</ClearRecordItem>
			</Grid>
		</>
	);
};

export default observer(OrderContent);
