import React from "react";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../apis/CtadmvenApi";
import { HomeVolvoProps } from "../interfaces/HomeVolvoProps";

configure({ enforceActions: "always" });

/* Store start */
class HomeVolvoStore {
	@observable loading: boolean = false;
	@observable searchNotFound: boolean = false;
	@observable vehicleKeyFilter: string = "";
	@observable orders: Array<HomeVolvoProps> = [];

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.setLoading(false);
		this.setOrders([]);
		this.setVehicleKeyFilter("");
		this.setSearchNotFound(false);
	};

	@action setLoading = (loading: boolean) => {
		this.loading = loading;
	};

	@action setVehicleKeyFilter = (key: string) => {
		this.vehicleKeyFilter = key;
	};

	@action setSearchNotFoundBasedOnOrders = () => {
		if (this.orders.length === 0) this.searchNotFound = true;
		else this.searchNotFound = false;
	};

	@action setSearchNotFound = (value: boolean) => {
		this.searchNotFound = value;
	};

	@action setOrders = (orders: Array<HomeVolvoProps>) => {
		this.orders = toJS(orders);
	};

	@action getOrders = async () => {
		this.setLoading(true);
		await this.api.homeService
			.getOrders(this.vehicleKeyFilter)
			.then((response) => {
				this.setOrders(response.data);
				this.setVehicleKeyFilter("");
			})
			.finally(() => {
				this.setLoading(false);
				this.setSearchNotFoundBasedOnOrders();
			});
	};
}

/* Store end */

/* Store helpers */
const HomeVolvoStoreContext = React.createContext(new HomeVolvoStore());

/* Hook to use store in any functional component */
export const useHomeVolvoStore = () => React.useContext(HomeVolvoStoreContext);
