import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { MktCompanyAdditionalCommentsSectionProps } from "../../interfaces/Sections/MktCompanyAdditionalCommentsSection";

const MktCompanyAdditionalCommentsSection: React.FC<
	MktCompanyAdditionalCommentsSectionProps
> = ({ readOnlyFields, shouldSeeAndUpdate }) => {
	const { t } = useTranslation("order");

	const AdditionalComment = (
		name:
			| "financialComments"
			| "commercialComments"
			| "accountingComments"
			| "logisticComments"
	) => (
		<TextForm
			name={name}
			label={t(`details.sections.mktCompanyAdditionalComments.${name}`)}
			value={readOnlyFields[name]}
			multiline
		/>
	);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.mktCompanyAdditionalComments.title")}
			</Grid>
			{shouldSeeAndUpdate && (
				<Grid item xs={12}>
					<TextForm
						name={"salesSupportComments"}
						label={t(
							"details.sections.mktCompanyAdditionalComments.salesSupportComments"
						)}
						value={readOnlyFields.salesSupportComments}
						multiline
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				{AdditionalComment("financialComments")}
			</Grid>
			<Grid item xs={12}>
				{AdditionalComment("commercialComments")}
			</Grid>
			<Grid item xs={12}>
				{AdditionalComment("accountingComments")}
			</Grid>
			<Grid item xs={12}>
				{AdditionalComment("logisticComments")}
			</Grid>
		</>
	);
};

export default observer(MktCompanyAdditionalCommentsSection);
