import { Grid } from "@mui/material";
import React from "react";

interface PaymentTermContainerComponentProps {
	children: React.ReactNode;
}

export const PaymentTermContainerComponent: React.FC<
	PaymentTermContainerComponentProps
> = ({ children }) => {
	return (
		<React.Fragment>
			<Grid container spacing={2} style={{ paddingBottom: "15px" }}>
				{children}
			</Grid>
		</React.Fragment>
	);
};
