import { action, configure, makeObservable } from "mobx";
import RootStore from "./RootStore";

configure({ enforceActions: "always" });

class BaseTruckAndBusStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action loadReservationAvailability = async (id: string) =>
        await this.rootStore.commonStore.loadReservationAvailability(id, this.setReservationAvailability);

    @action loadVariantsRespec = async (id: string) =>
        await this.rootStore.commonStore.loadVariantsRespec(id, this.setVariantsReespec);

    @action loadSaleInvoice = async (id: string) =>
        await this.rootStore.commonStore.loadSaleInvoice(id, this.setSaleInvoice);

    @action loadLocalizationInvoices = async (id: string) =>
        await this.rootStore.commonStore.loadLocalizationInvoices(id, this.setLocalizationInvoices);

    @action loadInvoiceExportation = async (id: string) =>
        await this.rootStore.commonStore.loadInvoiceExportation(id, this.setInvoiceExportation);

    @action loadInvoicingDeliveryMarketCompany = async (id: string) =>
        await this.rootStore.commonStore.loadInvoicingDeliveryMarketCompany(id, this.setInvoicingDeliveryMarketCompany);

    @action loadInvoiceInstruction = async (id: string, userCanSeeAllInfo: boolean) =>
        await this.rootStore.commonStore.loadInvoiceInstruction(id, userCanSeeAllInfo, this.loadAllInvoiceInstruction, this.loadMinimalInvoiceInstruction)

    @action loadAllInvoiceInstruction = async (id: string) =>
        await this.rootStore.commonStore.loadAllInvoiceInstruction(id, this.setAllInvoiceInstruction);

    @action loadMinimalInvoiceInstruction = async (id: string) =>
        await this.rootStore.commonStore.loadMinimalInvoiceInstruction(id, this.setMinimalInvoiceInstruction);

    @action loadInvoiceInstructionExportation = async (id: string, userCanSeeAllInfo: boolean) =>
        await this.rootStore.commonStore.loadInvoiceInstructionExportation(id, userCanSeeAllInfo, this.loadAllInvoiceInstructionExportation, this.loadMinimalInvoiceInstructionExportation)

    @action loadAllInvoiceInstructionExportation = async (id: string) =>
        await this.rootStore.commonStore.loadAllInvoiceInstructionExportation(id, this.setAllInvoiceInstructionExportation);

    @action loadMinimalInvoiceInstructionExportation = async (id: string) =>
        await this.rootStore.commonStore.loadMinimalInvoiceInstructionExportation(id, this.setMinimalInvoiceInstructionExportation);

    setReservationAvailability = (data: any) => { }

    setVariants = (data: any) => { }

    setVariantsReespec = (data: any) => { }

    setSaleInvoice = (data: any) => { }

    setLocalizationInvoices = (data: any) => { }

    setInvoiceExportation = (data: any) => { }

    setInvoicingDeliveryMarketCompany = (data: any) => { }

    setAllInvoiceInstruction = (data: any) => { }

    setMinimalInvoiceInstruction = (data: any) => { }

    setAllInvoiceInstructionExportation = (data: any) => { }

    setMinimalInvoiceInstructionExportation = (data: any) => { }
}

export default BaseTruckAndBusStore;