import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeRegionPreFilter: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_BUS,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_DEALER_TNE_TNO,
		AuthGroups.MEX_SALES_SUPPORT,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.MEAC_SALES_SUPPORT,
		AuthGroups.PI_SALES_SUPPORT,
	];

export const groupsAllowedToSeeBusBrazilMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		...AuthGroups.BRA_DEALERS,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_BUS,
	];

export const groupsAllowedToSeeBusFreeMenu: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY,
	AuthGroups.COL_MARKET_COMPANY,
	AuthGroups.MEX_SALES_SUPPORT,
	AuthGroups.MEAC_SALES_SUPPORT,
	AuthGroups.ARG_MARKET_COMPANY,
	AuthGroups.PER_MARKET_COMPANY,
	AuthGroups.PI_SALES_SUPPORT,
];

export const groupsAllowedToSeeBusFreeVciVdbMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		...AuthGroups.BRA_DEALERS,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_BUS,
	];

export const groupsAllowedToSeeBusMeacMenu: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.BRA_SALES_SUPPORT_VDB,
	AuthGroups.MEX_SALES_SUPPORT,
	AuthGroups.MEAC_SALES_SUPPORT,
];

export const groupsAllowedToSeeBusMktCompanyMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
	];

export const groupsAllowedToSeeBusPrivateImportersMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.PI_SALES_SUPPORT,
	];

export const groupsAllowedToSeeBusTotalMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToSeeFactoryDeliveriesMktCompanyBusMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
	];

export const groupsAllowedToSeeFactoryDeliveriesEuropeMktCompanyBusMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
	];
