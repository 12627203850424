import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import PaqueteForm from "./Form/PaqueteForm";
import GeneralCommentsForm from "./Form/GeneralCommentsForm";
import EngineeringCommentsForm from "./Form/EngineeringCommentsForm";

const EngineeringUserContent: React.FC = () => {
	return (
		<>
			<Grid item sm={12} md={3} lg={3}>
				<PaqueteForm />
			</Grid>
			<Grid item sm={12} md={9} lg={9}>
				<GeneralCommentsForm />
			</Grid>
			<Grid item xs={12}>
				<EngineeringCommentsForm />
			</Grid>
		</>
	);
};

export default observer(EngineeringUserContent);
