import { EuropeReadOnlyDetailsProps } from "../interfaces/EuropeReadOnlyDetails";

export const europeReadOnlyModel: EuropeReadOnlyDetailsProps = {
	sasId: "",

	assemblyOrder: "",
	factoryOrder: "",
	factory: "",
	deliveryProposal: null,
	chassi: "",

	//customer
	endCustomerName: "",

	//vehicle model
	industrialModel: "",
	commercialModel: "",
	factoryYear: undefined,
	traction: "",
	tractionType: "",
	weigth: undefined,
	power: undefined,

	//vin
	vehicleIdentificationNumber: "",
	engineSerialNumber: "",

	//variants
	variants: [],

	//production dates
	lcd: null,
	cdd: null,
	asweek: "",
	greenOk: null,
	dps: null,

	//exportation location
	estimatedDeparture: null,
	realDeparture: null,
	estimatedArrival: null,
	realArrival: null,

	//exportation reservation
	exportationStatus: "",
	available: false,
	availableDate: null,
	reserved: false,
	quotationNumber: "",
	reservationCreationDate: null,

	//exportation invoice
	invoiceDate: null,
	invoiceNumber: "",
	currency: "",
	incoterm: "",

	//invoicing and Delivery MC
	invoicedNumber: "",
	invoicedDate: null,
	deliveredNumber: "",
	deliveredDate: null,

	//localization export
	thirdPartnerName: "",
	documentDate: null,
	facility: "",

	countryName: "",
};

export function europeReadOnlyModelBuilder(data: EuropeReadOnlyDetailsProps) {
	if (!data) return europeReadOnlyModel;

	return {
		sasId: data.sasId ?? "",
		factoryOrder: data.factoryOrder ?? "",
		assemblyOrder: data.assemblyOrder ?? "",
		factory: data.factory ?? "",
		deliveryProposal: data.deliveryProposal ?? null,
		chassi: data.chassi ?? "",
		endCustomerName: data.endCustomerName ?? "",
		industrialModel: data.industrialModel ?? "",
		commercialModel: data.commercialModel ?? "",
		factoryYear: data.factoryYear ?? undefined,
		traction: data.traction ?? "",
		tractionType: data.tractionType ?? "",
		weigth: data.weigth ?? undefined,
		power: data.power ?? undefined,
		vehicleIdentificationNumber: data.vehicleIdentificationNumber ?? "",
		engineSerialNumber: data.engineSerialNumber ?? "",
		variants: data.variants ?? [],
		lcd: data.lcd ?? null,
		cdd: data.cdd ?? null,
		asweek: data.asweek ?? "",
		greenOk: data.greenOk ?? null,
		dps: data.dps ?? null,
		estimatedDeparture: data.estimatedDeparture ?? null,
		realDeparture: data.realDeparture ?? null,
		estimatedArrival: data.estimatedArrival ?? null,
		realArrival: data.realArrival ?? null,
		invoiceDate: data.invoiceDate ?? null,
		invoiceNumber: data.invoiceNumber ?? "",
		currency: data.currency ?? "",
		incoterm: data.incoterm ?? "",
		exportationStatus: data.exportationStatus ?? "",
		available: data.available ?? false,
		availableDate: data.availableDate ?? null,
		reserved: data.reserved ?? false,
		quotationNumber: data.quotationNumber ?? "",
		reservationCreationDate: data.reservationCreationDate ?? null,
		invoicedNumber: data.invoicedNumber ?? "",
		invoicedDate: data.invoicedDate ?? null,
		deliveredNumber: data.deliveredNumber ?? "",
		deliveredDate: data.deliveredDate ?? null,
		thirdPartnerName: data.thirdPartnerName ?? "",
		documentDate: data.documentDate ?? null,
		facility: data.facility ?? "",
		countryName: data.countryName ?? "",
	} as EuropeReadOnlyDetailsProps;
}
