import { action, makeObservable, observable } from "mobx";
import React from "react";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import { RegionsEditProps } from "../../interfaces/RegionsEditProps";
import { RegionGroupProps } from "../interfaces/RegionGroupProps";
import { cloneDeep } from "lodash";

class GroupRegionStore {
	@observable loading: boolean = false;
	@observable regionGroupInitial: RegionGroupProps[] = [];
	@observable regionGroupEdit: RegionGroupProps[] = [];

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action cleanData = () => {
		this.regionGroupInitial = [];
		this.regionGroupEdit = [];
	};

	@action getListRegionGroups = async () => {
		this.loading = true;

		this.api.regionService
			.listRegionGroups()
			.then((rs) => {
				this.regionGroupInitial = rs?.data;
				this.regionGroupEdit = rs?.data;
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action updateRegionGroupRegions = async () => {
		this.loading = true;

		this.api.regionService
			.updateRegionGroupRegions(this.regionGroupEdit)
			.then(async () => {
				await this.getListRegionGroups();
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action resetRegionsGroupEdit = () => {
		this.regionGroupEdit = cloneDeep(this.regionGroupInitial);
	};

	@action setRegionIntoRegionGroup = (
		region: RegionsEditProps | undefined,
		regionGroup: RegionGroupProps
	) => {
		let group = this.regionGroupEdit.filter((x) => x.id === regionGroup.id);

		if (group.length === 0) return;

		let regions = group.map((x) => x.regions)[0];

		if (regions && regions.length === 0) return;

		let regionInsideGroup = regions?.filter((x) => x?.id === region?.id)[0];

		if (!regionInsideGroup) return;

		regionInsideGroup!.checked = !regionInsideGroup!.checked;
	};
}

const GroupRegionStoreContext = React.createContext(new GroupRegionStore());

export const useGroupRegionStore = () =>
	React.useContext(GroupRegionStoreContext);
