export type AuthGroupsType =
	| typeof ADMIN
	| typeof CHL_MARKET_COMPANY
	| typeof CHL_MARKET_COMPANY_SALESMAN
	| typeof CHL_MARKET_COMPANY_SALESMAN_MANAGER
	| typeof CHL_PARAMETER
	| typeof BRA_SALES_SUPPORT_EXPORT
	| typeof BRA_SALES_SUPPORT_QUOTA
	| typeof BRA_SALES_SUPPORT_VDB
	| typeof BRA_SALES_SUPPORT_MANAGEMENT_TRUCK
	| typeof BRA_SALES_SUPPORT_MANAGEMENT_BUS
	| typeof BRA_SALES_SUPPORT_PARAMETER
	| typeof MEX_SALES_SUPPORT
	| typeof MEAC_SALES_SUPPORT
	| typeof PI_SALES_SUPPORT
	| typeof COL_MARKET_COMPANY
	| typeof MEX_SALES_SUPPORT
	| typeof ARG_MARKET_COMPANY
	| typeof PER_MARKET_COMPANY
	| typeof GENERAL_VDB_VIEWER
	| typeof ARG_MARKET_COMPANY_FINANCIAL
	| typeof ARG_MARKET_COMPANY_BUSINESS
	| typeof ARG_MARKET_COMPANY_LOGISTIC
	| typeof ARG_MARKET_COMPANY_ENGINEERING
	| typeof ARG_MARKET_COMPANY_ACCOUNTING
	| BraDealerAuthGroupsType
	| ArgAuthGroupsType;

export const ADMIN = "admin";
export const CHL_MARKET_COMPANY = "chl_sas";
export const CHL_MARKET_COMPANY_SALESMAN = "chl_market_company_salesman";
export const CHL_MARKET_COMPANY_SALESMAN_MANAGER =
	"chl_market_company_salesman_manager";
export const CHL_PARAMETER = "chl_parameter";

export const BRA_DEALER_ASP = "bra_dealer_asp";
export const BRA_DEALER_AUT = "bra_dealer_aut";
export const BRA_DEALER_DIC = "bra_dealer_dic";
export const BRA_DEALER_DIP = "bra_dealer_div";
export const BRA_DEALER_GOT = "bra_dealer_got";
export const BRA_DEALER_LAP = "bra_dealer_lap";
export const BRA_DEALER_LUV = "bra_dealer_luv";
export const BRA_DEALER_NOR = "bra_dealer_nor";
export const BRA_DEALER_RIV = "bra_dealer_riv";
export const BRA_DEALER_SUE = "bra_dealer_sue";
export const BRA_DEALER_TNE = "bra_dealer_tne";
export const BRA_DEALER_TNO = "bra_dealer_tno";
export const BRA_DEALER_TNE_TNO = "bra_dealer_tne_tno";
export const BRA_DEALER_TRV = "bra_dealer_trv";

export const BRA_SALES_SUPPORT_EXPORT = "bra_sales_support_export";
export const BRA_SALES_SUPPORT_QUOTA = "bra_sales_support_quota";
export const BRA_SALES_SUPPORT_VDB = "bra_sales_support_vdb";
export const BRA_SALES_SUPPORT_MANAGEMENT_TRUCK =
	"bra_sales_support_management_truck";
export const BRA_SALES_SUPPORT_MANAGEMENT_BUS =
	"bra_sales_support_management_bus";
export const BRA_SALES_SUPPORT_PARAMETER = "bra_sales_support_parameter";

export const MEX_SALES_SUPPORT = "mex_sales_support";
export const MEAC_SALES_SUPPORT = "meac_sales_support";
export const PI_SALES_SUPPORT = "pi_sales_support";
export const COL_MARKET_COMPANY = "col_market_company";
export const PER_MARKET_COMPANY = "per_market_company";
export const GENERAL_VDB_VIEWER = "general_vdb_viewer";

export const ARG_MARKET_COMPANY = "arg_market_company";
export const ARG_MARKET_COMPANY_FINANCIAL = "arg_market_company_financial";
export const ARG_MARKET_COMPANY_BUSINESS = "arg_market_company_business";
export const ARG_MARKET_COMPANY_LOGISTIC = "arg_market_company_logistic";
export const ARG_MARKET_COMPANY_ENGINEERING = "arg_market_company_engineering";
export const ARG_MARKET_COMPANY_ACCOUNTING = "arg_market_company_accounting";

export const ARG_DEALER_AGECER = "arg_dealer_agecer";
export const ARG_DEALER_CAMINOA = "arg_dealer_caminoa";
export const ARG_DEALER_GERENCIA = "arg_dealer_gerencia";
export const ARG_DEALER_GLOKER = "arg_dealer_gloker";
export const ARG_DEALER_GOTLAND = "arg_dealer_gotland";
export const ARG_DEALER_LATINACAM = "arg_dealer_latinacam";
export const ARG_DEALER_LIBERTADORES = "arg_dealer_libertadores";
export const ARG_DEALER_RUTASUR = "arg_dealer_rutasur";
export const ARG_DEALER_SCEVOLA = "arg_dealer_Scevola";
export const ARG_DEALER_SUDAMERICANA = "arg_dealer_sudamericana";
export const ARG_DEALER_SUECA = "arg_dealer_sueca";

type BraDealerAuthGroupsType =
	| typeof BRA_DEALER_ASP
	| typeof BRA_DEALER_AUT
	| typeof BRA_DEALER_DIC
	| typeof BRA_DEALER_DIP
	| typeof BRA_DEALER_GOT
	| typeof BRA_DEALER_LAP
	| typeof BRA_DEALER_LUV
	| typeof BRA_DEALER_NOR
	| typeof BRA_DEALER_RIV
	| typeof BRA_DEALER_SUE
	| typeof BRA_DEALER_TNE
	| typeof BRA_DEALER_TNO
	| typeof BRA_DEALER_TNE_TNO
	| typeof BRA_DEALER_TRV;

export const BRA_DEALERS: Array<BraDealerAuthGroupsType> = [
	BRA_DEALER_ASP,
	BRA_DEALER_AUT,
	BRA_DEALER_DIC,
	BRA_DEALER_DIP,
	BRA_DEALER_GOT,
	BRA_DEALER_LAP,
	BRA_DEALER_LUV,
	BRA_DEALER_NOR,
	BRA_DEALER_RIV,
	BRA_DEALER_SUE,
	BRA_DEALER_TNE,
	BRA_DEALER_TNO,
	BRA_DEALER_TNE_TNO,
	BRA_DEALER_TRV,
];

type ArgDealerAuthGroupsType =
	| typeof ARG_DEALER_AGECER
	| typeof ARG_DEALER_CAMINOA
	| typeof ARG_DEALER_GERENCIA
	| typeof ARG_DEALER_GLOKER
	| typeof ARG_DEALER_GOTLAND
	| typeof ARG_DEALER_LATINACAM
	| typeof ARG_DEALER_LIBERTADORES
	| typeof ARG_DEALER_RUTASUR
	| typeof ARG_DEALER_SCEVOLA
	| typeof ARG_DEALER_SUDAMERICANA
	| typeof ARG_DEALER_SUECA;

export const ARG_DEALERS: Array<ArgDealerAuthGroupsType> = [
	ARG_DEALER_AGECER,
	ARG_DEALER_CAMINOA,
	ARG_DEALER_GERENCIA,
	ARG_DEALER_GLOKER,
	ARG_DEALER_GOTLAND,
	ARG_DEALER_LATINACAM,
	ARG_DEALER_LIBERTADORES,
	ARG_DEALER_RUTASUR,
	ARG_DEALER_SCEVOLA,
	ARG_DEALER_SUDAMERICANA,
	ARG_DEALER_SUECA,
];

type ArgAuthGroupsType =
	| typeof ARG_MARKET_COMPANY
	| typeof ARG_MARKET_COMPANY_FINANCIAL
	| typeof ARG_MARKET_COMPANY_BUSINESS
	| typeof ARG_MARKET_COMPANY_LOGISTIC
	| typeof ARG_MARKET_COMPANY_ENGINEERING
	| typeof ARG_MARKET_COMPANY_ACCOUNTING
	| ArgDealerAuthGroupsType;

export const ARG_ALL_USERS: Array<ArgAuthGroupsType> = [
	ARG_MARKET_COMPANY,
	ARG_MARKET_COMPANY_FINANCIAL,
	ARG_MARKET_COMPANY_BUSINESS,
	ARG_MARKET_COMPANY_LOGISTIC,
	ARG_MARKET_COMPANY_ENGINEERING,
	ARG_MARKET_COMPANY_ACCOUNTING,
	...ARG_DEALERS,
];

export const OWNER_GROUPS: Array<AuthGroupsType> = [
	ADMIN,
	BRA_SALES_SUPPORT_VDB,
	BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
	BRA_SALES_SUPPORT_MANAGEMENT_BUS,
	CHL_MARKET_COMPANY,
	ARG_MARKET_COMPANY,
];
