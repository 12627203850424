import React from "react";
import { observer } from "mobx-react";
import { Tooltip } from "@mui/material";

interface HeaderCellProps {
	title: string;
	tooltip: string;
}

const HeaderCell: React.FC<HeaderCellProps> = ({ title, tooltip }) => {
	return (
		<Tooltip title={tooltip}>
			<span>{title}</span>
		</Tooltip>
	);
};

export default observer(HeaderCell);
