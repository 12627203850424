import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { ForecastBillingSectionProps } from "../../interfaces/Sections/ForecastBillingSection";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { fieldIsEditable } from "../../utils/EditableFieldsUtils";

const ForecastBillingSection: React.FC<ForecastBillingSectionProps> = ({
	fieldsAvailableForEdition,
	editableFields,
	setOrderMCManagementFieldByKeyOnChange,
	shippingCompaniesOptions,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.forecastBilling.title")}
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.shippingDate")}
					value={editableFields.shippingDate}
					editable={fieldIsEditable("shippingDate", fieldsAvailableForEdition)}
					onChange={(dataItem: any) =>
						setOrderMCManagementFieldByKeyOnChange("shippingDate", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.forecastBilling.shippingNumber")}
					value={editableFields.shippingNumber}
					editable={fieldIsEditable(
						"shippingNumber",
						fieldsAvailableForEdition
					)}
					name="shippingNumber"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"shippingNumber",
							e.target.value
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.certificateDate")}
					value={editableFields.certificateDate}
					editable={fieldIsEditable(
						"certificateDate",
						fieldsAvailableForEdition
					)}
					onChange={(dataItem: any) =>
						setOrderMCManagementFieldByKeyOnChange("certificateDate", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.billingForecast")}
					inputFormat="MMMM/yy"
					value={editableFields.billingForecast}
					editable={fieldIsEditable(
						"billingForecast",
						fieldsAvailableForEdition
					)}
					onChange={(dataItem: any) =>
						setOrderMCManagementFieldByKeyOnChange("billingForecast", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.forecastBilling.shippingCompany")}
					labelId={"sections.forecastBilling-shippingCompany-label"}
					value={editableFields.shippingCompany}
					options={shippingCompaniesOptions}
					editable={fieldIsEditable(
						"shippingCompany",
						fieldsAvailableForEdition
					)}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"shippingCompany",
							e.target.value as string
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.forecastBilling.destination")}
					value={editableFields.destination}
					editable={fieldIsEditable("destination", fieldsAvailableForEdition)}
					name="destination"
					inputProps={{ maxLength: 150 }}
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"destination",
							e.target.value
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(ForecastBillingSection);
