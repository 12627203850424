import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ModelSectionProps } from "../../interfaces/Sections/ModelSection";
import TextForm from "../../../../../components/FormInputs/TextForm";

const ModelSection: React.FC<ModelSectionProps> = ({ readOnlyFields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.model.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.productionModel")}
					value={readOnlyFields.productionModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.factoryYear")}
					value={readOnlyFields.factoryYear}
					type="number"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.lineCode")}
					value={readOnlyFields.lineCode}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialModel")}
					value={readOnlyFields.commercialModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.modelYear")}
					value={readOnlyFields.modelYear}
					type="number"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.class")}
					value={readOnlyFields.class}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialTrimmingPackages")}
					value={readOnlyFields.commercialTrimmingPackages}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSection);
