import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import english from "./locales/en";
import spanish from "./locales/es";
import portuguese from "./locales/pt";

const resources = {
	en: english,
	pt: portuguese,
	es: spanish,
};

const defaultInst = i18n.createInstance();

defaultInst
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: {
			"pt-BR": ["pt"],
			"en-US": ["en"],
			"en-GB": ["en"],
			"es-ES": ["es"],
			default: ["en"],
		},
		resources: resources,
		react: {
			useSuspense: false,
		},
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default defaultInst;
