import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";
import SelectPreFilter from "../components/SelectPreFilter";

const QUOTATIONRESERVATIONSTATUS_PRE_FILTER_XS = 4;
const QUOTATIONRESERVATIONSTATUS_PRE_FILTER_SM = 3;
const QUOTATIONRESERVATIONSTATUS_PRE_FILTER_MD_LG_XL = 1;
const QuotationReservationStatusPreFilter: React.FC<SelectPreFilterProps> = (
	props
) => {
	const { t } = useTranslation("order");
	const {
		id = "quotationReservationStatusPreFilter",
		label = "preFilters.reservationStatus",
		xs = QUOTATIONRESERVATIONSTATUS_PRE_FILTER_XS,
		sm = QUOTATIONRESERVATIONSTATUS_PRE_FILTER_SM,
		md = QUOTATIONRESERVATIONSTATUS_PRE_FILTER_MD_LG_XL,
		lg = QUOTATIONRESERVATIONSTATUS_PRE_FILTER_MD_LG_XL,
		xl = QUOTATIONRESERVATIONSTATUS_PRE_FILTER_MD_LG_XL,
	} = props;
	return (
		<SelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(QuotationReservationStatusPreFilter);
