import * as React from "react";
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineSeparator,
} from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Grid, Link } from "@mui/material";
import { observer } from "mobx-react";

interface GeneralTimeLineItemProps {
	href: string;
	children: React.ReactNode;
}

const GeneralTimeLineItem: React.FC<GeneralTimeLineItemProps> = ({
	href,
	children,
}) => {
	const scroll = () => {
		const section = document.querySelector(href);
		if (!section) return;
		section.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "center",
		});
	};

	return (
		<TimelineItem>
			<TimelineSeparator>
				<TimelineDot />
				<TimelineConnector />
			</TimelineSeparator>
			<TimelineContent>
				<Link
					underline="hover"
					variant="button"
					color={"secondary"}
					onClick={scroll}
				>
					{children}
				</Link>
			</TimelineContent>
		</TimelineItem>
	);
};

const DetailsTimeline: React.FC = () => {
	const { t } = useTranslation("order");

	return (
		<Grid item xs={2} p={0} m={0}>
			<Timeline
				position="left"
				sx={{ p: 0, m: 0, position: "fixed", right: 0, top: 80 }}
				className="right"
			>
				<GeneralTimeLineItem href="#generalAccordion">
					{t("details.accordions.general")}
				</GeneralTimeLineItem>
				<GeneralTimeLineItem href="#commercialAccordion">
					{t("details.accordions.commercial")}
				</GeneralTimeLineItem>
				<GeneralTimeLineItem href="#vehicleAccordion">
					{t("details.accordions.vehicle")}
				</GeneralTimeLineItem>
				<GeneralTimeLineItem href="#invoicingAndDeliveryAccordion">
					{t("details.accordions.delivery")}
				</GeneralTimeLineItem>
			</Timeline>
		</Grid>
	);
};

export default observer(DetailsTimeline);
