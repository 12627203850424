import { action, configure, makeObservable, observable } from "mobx";
import React from "react";
import CtadmvenApi from "../../../apis/CtadmvenApi";
import { ExportPartnerProps } from "../interface/ExportPartnerProps";
import { exportPartnerModel } from "../model/ExportPartnerModel";

configure({ enforceActions: "always" });

class ExportPartnerStore {
	@observable loading: boolean = true;
	@observable refresh: number = 0;
	@observable exportPartnerList: Array<ExportPartnerProps> = [];
	@observable model: ExportPartnerProps = exportPartnerModel;
	@observable dataItemChanged: ExportPartnerProps = exportPartnerModel;
	@observable formVisible: boolean = false;
	@observable actionLabel: string = "";

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.model = exportPartnerModel;
		this.loading = true;
	};

	@action setLoading = (value: boolean) => (this.loading = value);

	@action setFormVisible = (value: boolean) => (this.formVisible = value);

	@action setActionLabel = (value: string) => (this.actionLabel = value);

	@action setDataItemChanged = (value: ExportPartnerProps) =>
		(this.dataItemChanged = value);

	@action setDataItemChangedFieldByKey = (
		fieldKey: keyof ExportPartnerProps,
		newValue: any
	) => {
		if (
			newValue &&
			(fieldKey === "customerCDB" || fieldKey === "cdb") &&
			newValue.length > 11
		)
			return;
		this.dataItemChanged[fieldKey] = newValue;
	};

	@action allRecords = async () => {
		this.setLoading(true);

		return await this.api.exportPartnerService
			.getList()
			.then((response) => (this.exportPartnerList = response))
			.finally(() => this.setLoading(false));
	};

	@action createOrUpdate = async (record: any) => {
		this.setLoading(true);

		if (!record) return;

		return await this.api.exportPartnerService
			.createOrUpdate(record)
			.finally(() => this.refresh++);
	};

	@action deleteExportPartner = async (exportPartnerId: string) => {
		this.setLoading(true);
		this.setDataItemChanged(exportPartnerModel);
		return await this.api.exportPartnerService
			.delete(exportPartnerId)
			.finally(() => this.refresh++);
	};
}

const ExportPartnerStoreContext = React.createContext(new ExportPartnerStore());

export const useExportPartnerStore = () =>
	React.useContext(ExportPartnerStoreContext);
