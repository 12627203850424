import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MultiSelectPreFilterProps } from "../Interfaces/MultiSelectPreFilterProps";
import MultiSelectPreFilter from "../components/MultiSelectPreFilter";

const PAYMENTFORM_PRE_FILTER_XS = 12;
const PAYMENTFORM_PRE_FILTER_SM = 7;
const PAYMENTFORM_PRE_FILTER_MD = 7;
const PAYMENTFORM_PRE_FILTER_LG = 5;
const PAYMENTFORM_PRE_FILTER_XL = 4;

const VehicleLocationPreFilter: React.FC<MultiSelectPreFilterProps> = (
	props
) => {
	const { t } = useTranslation("order");
	const {
		id = "vehicleLocationPreFilter",
		label = "preFilters.vehicleLocation",
		xs = PAYMENTFORM_PRE_FILTER_XS,
		sm = PAYMENTFORM_PRE_FILTER_SM,
		md = PAYMENTFORM_PRE_FILTER_MD,
		lg = PAYMENTFORM_PRE_FILTER_LG,
		xl = PAYMENTFORM_PRE_FILTER_XL,
	} = props;
	return (
		<MultiSelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(VehicleLocationPreFilter);
