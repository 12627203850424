import { action, makeObservable, observable } from "mobx";
import React from "react";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { VariantGetProps } from "../interfaces/VariantGetProps";
import {
	getFieldsAvailableForEditionByUser,
	UPDATE_PERMISSION_ADMIN,
	UPDATE_PERMISSION_CHL,
} from "../utils/EditableFieldsUtils";
import { variantAdminPutPropsModelBuilder } from "../models/VariantAdminPutPropsModel";
import { variantBasePutPropsModelBuilder } from "../models/VariantBasePutPropsModel";
import {
	variantGetPropsModel,
	variantGetPropsModelArrayBuilder,
} from "../models/VariantGetPropsModel";
import { toast } from "react-toastify";
import { TFunction } from "i18next";

class VariantsStore {
	@observable variants: Array<VariantGetProps> = [];
	@observable dataItemChanged: VariantGetProps = variantGetPropsModel;
	@observable loading: boolean = false;
	@observable isFormVisible: boolean = false;
	@observable userUpdatePermission: string = "";
	@observable editableFields: Array<string> = [];

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action setVariants = (variants: Array<VariantGetProps>) =>
		(this.variants = variantGetPropsModelArrayBuilder(variants));

	@action setDataItemChanged = (dataItemChanged: VariantGetProps) =>
		(this.dataItemChanged = dataItemChanged);

	@action resetDataItemChanged = () =>
		(this.dataItemChanged = variantGetPropsModel);

	@action setLoading = (loading: boolean) => (this.loading = loading);

	@action setFormVisible = (isFormVisible: boolean) =>
		(this.isFormVisible = isFormVisible);

	@action setUserUpdatePermission = (userUpdatePermission: string) => {
		this.userUpdatePermission = userUpdatePermission;
		this.editableFields =
			getFieldsAvailableForEditionByUser(userUpdatePermission);
	};

	@action fetchData = async () => {
		this.setLoading(true);

		await this.api.variantService
			.getVariants()
			.then((rs) => {
				this.setVariants(rs?.data?.data);
			})
			.finally(() => {
				this.setLoading(false);
			});
	};

	@action updateVariant = async (t: TFunction<"general", undefined>) => {
		this.setLoading(true);
		let variant = null;

		if (this.userUpdatePermission === UPDATE_PERMISSION_ADMIN) {
			variant = variantAdminPutPropsModelBuilder(this.dataItemChanged);
			await this.api.variantService.update(variant);
		} else if (this.userUpdatePermission === UPDATE_PERMISSION_CHL) {
			variant = variantBasePutPropsModelBuilder(this.dataItemChanged);
			await this.api.variantService.updateForChl(variant);
		} else {
			toast.error(t("unauthorizedToPerformAction"));
			this.setLoading(false);
			return;
		}

		await this.fetchData();
		this.setLoading(false);
	};
}

const VariantsStoreContext = React.createContext(new VariantsStore());

export const useVariantsStore = () => React.useContext(VariantsStoreContext);
