import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { GRID_MAX_SIZE } from "../../../constants/gridConst";
import AutoCompletePreFilter from "../components/AutoCompletePreFilter";
import { AutoCompletePreFilterProps } from "../Interfaces/AutoCompletePreFilterProps";

const QUOTATIONSALESMAN_PRE_FILTER_XS_SM = GRID_MAX_SIZE;
const QUOTATIONSALESMAN_PRE_FILTER_MD_LG = 6;
const QUOTATIONSALESMAN_PRE_FILTER_XL = 4;

const QuotationSalesmanACFilter: React.FC<AutoCompletePreFilterProps> = (
	props
) => {
	const { t } = useTranslation(["order", "quotation"]);
	const {
		id = "salesmanACPreFilter",
		label = "preFilters.salesman",
		xs = QUOTATIONSALESMAN_PRE_FILTER_XS_SM,
		sm = QUOTATIONSALESMAN_PRE_FILTER_XS_SM,
		md = QUOTATIONSALESMAN_PRE_FILTER_MD_LG,
		lg = QUOTATIONSALESMAN_PRE_FILTER_MD_LG,
		xl = QUOTATIONSALESMAN_PRE_FILTER_XL,
		tooltip = "tooltips.salesmanSearch",
	} = props;
	return (
		<AutoCompletePreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
			tooltip={t(tooltip, { ns: "quotation" })}
		/>
	);
};

export default observer(QuotationSalesmanACFilter);
