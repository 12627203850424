import React from "react";
import { observer } from "mobx-react";
import { Grid, Tooltip } from "@mui/material";
import AutoCompleteForm from "../../FormInputs/AutoCompleteForm";
import { AutoCompletePreFilterProps } from "../Interfaces/AutoCompletePreFilterProps";
const AutoCompletePreFilter: React.FC<AutoCompletePreFilterProps> = ({
	preFilterStore,
	id,
	label = "",
	xs = 12,
	sm,
	md,
	lg,
	xl,
	tooltip,
}) => {
	const { preFilter, set, preFilterOptions } = preFilterStore;

	return (
		<Tooltip title={label} placement="top">
			<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
				<AutoCompleteForm
					label={label}
					labelId={`pre-filter-${id}-label`}
					value={preFilter}
					options={preFilterOptions}
					editable
					onChange={(e) => set(e)}
					id={id}
					hasEmptyDefaultItem
					isCustomSearch
					editTooltip={tooltip}
				/>
			</Grid>
		</Tooltip>
	);
};

export default observer(AutoCompletePreFilter);
