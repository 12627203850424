import { VariantAdminPutProps } from "../interfaces/VariantAdminPutProps";
import {
	variantBasePutPropsModel,
	variantBasePutPropsModelBuilder,
} from "./VariantBasePutPropsModel";

export const variantAdminPutPropsModel: VariantAdminPutProps = {
	...variantBasePutPropsModel,
	descriptionEnUs: "",
	descriptionPtBr: "",
	forTruck: false,
	forBus: false,
};

export function variantAdminPutPropsModelBuilder(data: any) {
	if (!data) {
		return variantAdminPutPropsModel;
	}
	return {
		...variantBasePutPropsModelBuilder(data),
		descriptionEnUs: data.descriptionEnUs ?? "",
		descriptionPtBr: data.descriptionPtBr ?? "",
		forTruck: data.forTruck ?? false,
		forBus: data.forBus ?? false,
	} as VariantAdminPutProps;
}
