import RootStore from "../stores/RootStore";

export function newRootStore(): RootStore {
	return {
		filterStore: {},
		listStore: {},
		quotationBatchEditionStore: {},
		preFilterQuotationIDStore: {},
		preFilterQuotationDateStore: {},
		preFilterStatusSASStore: {},
		preFilterFactoryOrderStore: {},
		preFilterChassiStore: {},
		preFilterSalesmanStore: {},
		preFilterReservationStatusStore: {},
	};
}
