import { Grid } from "@mui/material";
import { TFunction } from "i18next";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import OverlineTextCentered from "../../../../../../components/OverlineTextCentered/OverlineTextCentered";
import { getLabel } from "../../../../../../utils/VariantsUtil";
import { QuotationVariantProps } from "../../../interfaces/QuotationVariantProps";
import _ from "lodash";

export const VariantComponent: React.FC<{
	variants: Array<QuotationVariantProps>;
	language: string;
	t: TFunction<"quotation"[], undefined>;
}> = ({ variants, language, t }) => {
	return (
		<>
			{variants?.length > 0 ? (
				_.sortBy(variants, "key").map(
					(variant: QuotationVariantProps, idx: number) => (
						<Grid key={idx} item xs={4}>
							<TextForm
								label={
									getLabel(variant, language).length > 0
										? getLabel(variant, language)
										: "-"
								}
								value={
									!variant.variantDescription
										? variant.value
										: `${variant.value} - ${variant.variantDescription}`
								}
							/>
						</Grid>
					)
				)
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};
