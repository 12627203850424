import RootStore from "../stores/RootStore";

export function newRootStore(): RootStore {
	return {
		listStore: {},
		filterStore: {},
		batchEditionStore: {},
		preFilterAsWeekStore: {},
		preFilterChassiAOStore: {},
		preFilterCommercialModelStore: {},
		preFilterDeliveryProposalStore: {},
		preFilterLineSequenceDateStore: {},
		preFilterLineStore: {},
		preFilterMonthAvailabilityStore: {},
		preFilterQuotationCustomerStore: {},
		preFilterQuotationSalesmanStore: {},
		preFilterSalesmanStore: {},
		preFilterZoneStore: {},
		preFilterCustomerSASStore: {},
		preFilterCustomerOMStore: {},
	};
}
