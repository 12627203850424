import { Save, Undo } from "@mui/icons-material";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import DateForm from "../../../../components/FormInputs/DateForm";
import { useEditQuotaStore } from "../stores/EditQuotaStore";
import months from "../models/months.json";
import TextForm from "../../../../components/FormInputs/TextForm";
import { MonthKeys } from "../interfaces/FirmPeriodProps";
import { toast } from "react-toastify";

const FirmPeriod: React.FC = () => {
	const { t } = useTranslation(["quota", "general"]);
	const {
		setFirmPeriodByKeyOnChange,
		putFirmPeriod,
		setFirmPeriodInitialAndNew,
		firmPeriod,
		firmPeriodInitial,
	} = useEditQuotaStore();

	const handleSubmit = async () => {
		await putFirmPeriod()
			.then(() => {
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString());
			})
			.catch((err: any) => toast.error(err.response.data));
	};

	return (
		<Card>
			<CardActions>
				<Grid container spacing={2}>
					<Grid item xs>
						<Typography>{t("firmPeriod.title").toUpperCase()}</Typography>
					</Grid>
					<Grid item sx={{ alignSelf: "center" }}>
						<Button
							color="secondary"
							onClick={() => setFirmPeriodInitialAndNew(firmPeriodInitial)}
							disabled={_.isEqual(firmPeriod, firmPeriodInitial)}
						>
							<Undo fontSize="small" />
							{t("clear")}
						</Button>
						<Button
							color="secondary"
							type={"submit"}
							onClick={handleSubmit}
							className="ms-2 btn-sm"
							disabled={_.isEqual(firmPeriod, firmPeriodInitial)}
						>
							<Save fontSize="small" />
							{t("save")}
						</Button>
					</Grid>
				</Grid>
			</CardActions>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography>{t("firmPeriod.weekPeriodTitle")}</Typography>
							<Grid item xs={1}>
								<TextForm
									editable
									id={"firmPeriodWeek"}
									value={firmPeriod.weekPeriod}
									onChange={(e: any) =>
										setFirmPeriodByKeyOnChange("weekPeriod", e.target.value)
									}
									type="number"
								/>
							</Grid>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					{months.map((m) => (
						<Grid item sx={{ width: 115 }} key={`${m}-grid`}>
							<DateForm
								editable
								label={t(`months.${m}`, { ns: "general" })}
								key={m}
								value={firmPeriod[m as MonthKeys] as Date | null}
								onChange={(e: any) =>
									setFirmPeriodByKeyOnChange(m as MonthKeys, e)
								}
							/>
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default observer(FirmPeriod);
