import CtadmvenApi from "../../apis/CtadmvenApi";
import { CountryType } from "../../utils/GenericTypes";

interface IVehicleLocationService {
	getDictionaryIdNameByCountry(country: CountryType): Promise<any>;
}
const base = "/vehicleLocation";

export class VehicleLocationService implements IVehicleLocationService {
	constructor(private api: CtadmvenApi) {}

	getDictionaryIdNameByCountry = async (country: CountryType): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/dictionaryIdName/${country}`);
}
