import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Stack } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";
import { LocalCustomizationsSectionProps } from "../../interfaces/Sections/LocalCustomizationsSection";
import { LocalCustomizationProps } from "../../interfaces/LocalCustomizationProps";
import { parseNumberToDecimal } from "../../../../../utils/NumberOperations";

const LocalCustomizationsSection: React.FC<LocalCustomizationsSectionProps> = ({
	editableFields,
	setLocalCustomizationComment,
	editable,
	currency,
	currentExchangeRate,
}) => {
	const { t } = useTranslation(["quotation"]);
	const exchangeRateToApply = currency === "CLP" ? 1 : currentExchangeRate;

	return (
		<>
			{editableFields?.length > 0 ? (
				editableFields.map(
					(localCustomization: LocalCustomizationProps, idx: number) => (
						<Grid item xs={12} key={`localcustomization-${idx}`}>
							<Stack direction="row" spacing={2}>
								<Grid item xs={4}>
									<TextForm
										label={t(
											"details.sections.localCustomizations.customization",
											{ ns: "quotation" }
										)}
										value={localCustomization.description}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextForm
										label={t(
											"details.sections.localCustomizations.additionalComments",
											{ ns: "quotation" }
										)}
										value={localCustomization.comment || ""}
										name="AdditionalComments"
										inputProps={{ maxLength: 150 }}
										onChange={(e) =>
											setLocalCustomizationComment(
												localCustomization,
												e.target.value
											)
										}
										editable={editable}
									/>
								</Grid>
								<Grid item xs>
									<TextForm
										label={`${t("details.sections.localCustomizations.price", {
											ns: "quotation",
										})} CLP`}
										value={parseNumberToDecimal(
											localCustomization.price * exchangeRateToApply,
											2
										)}
										type="number"
										isCurrency
										inputProps={{ min: 1 }}
										name="localCustomizationspriceclp"
									/>
								</Grid>
								{currency === "USD" && (
									<Grid item xs>
										<TextForm
											label={`${t(
												"details.sections.localCustomizations.price",
												{
													ns: "quotation",
												}
											)} USD`}
											value={parseNumberToDecimal(localCustomization.price, 2)}
											type="number"
											isCurrency
											inputProps={{ min: 1 }}
											name="localCustomizationspriceusd"
										/>
									</Grid>
								)}
							</Stack>
						</Grid>
					)
				)
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(LocalCustomizationsSection);
