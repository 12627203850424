import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { VariantsSectionProps } from "../Interfaces/Europe/VariantsSection";
import TextForm from "../../FormInputs/TextForm";
import OverlineTextCentered from "../../OverlineTextCentered/OverlineTextCentered";
import { VariantNoDescriptionProps } from "../Interfaces/VariantDetails";

const VariantsSection: React.FC<VariantsSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation(["order", "general", "europe"]);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.variants.title")}
			</Grid>
			{readOnlyFields.variants?.length > 0 ? (
				readOnlyFields.variants.map(
					(variant: VariantNoDescriptionProps, idx: number) => (
						<Grid key={idx} item xs={4}>
							<TextForm
								label={t("variants." + variant.family, { ns: "europe" })}
								value={variant.value}
							/>
						</Grid>
					)
				)
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(VariantsSection);
