import { GUID_EMPTY } from "../../../../app/constants/config";
import { LineSpeedProps } from "../interfaces/LineSpeedProps";
import { lineModel } from "./LineModel";

export const lineSpeedModel: LineSpeedProps = {
	id: GUID_EMPTY,
	code: null,
	speed: null,
	lcd: null,
	lineId: GUID_EMPTY,
	line: lineModel,
};
