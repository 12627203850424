import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeClosedPeriodArg: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, ...AuthGroups.ARG_ALL_USERS];

export const groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.ARG_MARKET_COMPANY_FINANCIAL,
		AuthGroups.ARG_MARKET_COMPANY_ACCOUNTING,
		AuthGroups.ARG_MARKET_COMPANY_BUSINESS,
		AuthGroups.ARG_MARKET_COMPANY_LOGISTIC,
		AuthGroups.ARG_MARKET_COMPANY_ENGINEERING,
	];

export const groupsAllowedToUpdateForecastBillingClosedPeriodOrdersArg: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.ARG_MARKET_COMPANY];
