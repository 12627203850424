import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { BusEditableProps } from "../../pages/Order/Details/interfaces/Editable/BusEditableDetails";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";

interface IBusService {
	listPaginated(dataState: State, menu: string): Promise<any>;
	getDetails(id: string, menuKey: string): Promise<any>;
	getDetailsVariants(id: string, menuKey: string): Promise<any>;
	csvExport(
		dataState: State,
		menuKey: string,
		csvOptions: CsvOptionsProps
	): Promise<any>;
	update(orderDetails: BusEditableProps, menuKey: string): Promise<any>;
	updateZone(id: string, zoneId: string): Promise<any>;
}

const base = "/bus";

export class BusService implements IBusService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State, menu: string): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list/${menu}?${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${menuKey}/${id}`);

	getDetailsVariants = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variants/${menuKey}/${id}`);

	csvExport = async (
		dataState: State,
		menuKey: string,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv/${menuKey}?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	update = async (
		orderDetails: BusEditableProps,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/${menuKey}`,
			JSON.stringify(orderDetails)
		);

	updateZone = async (id: string, zoneId: string): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/zone/${id}`, JSON.stringify(zoneId));
}
