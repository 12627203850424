import RootStore from "../../../stores/RootStore";
import { configure } from "mobx";
import BasePreFilterStringStore from "../../../../../../components/PreFilter/Stores/BasePreFilterStringStore";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterAsWeekStore extends BasePreFilterStringStore {
	filterKey = "order.asWeek";
    filterSize = 5;
    operator = "startswith";

	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	getSpecificFilter(): any {
		return getFilterObjectArrayOfStringKeyBySize(
			this.filterSize,
			this.preFilter,
			this.filterKey,
			this.operator
		);
	};
}

export default PreFilterAsWeekStore;
