import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Link, Skeleton } from "@mui/material";
import { VariantsRespecificationSectionProps } from "../../interfaces/Sections/VariantsRespecificationSection";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";

const VariantsRespecificationSection: React.FC<
	VariantsRespecificationSectionProps
> = ({ readOnlyFields, loadVariantsRespec }) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);
	const edbVolvo = "https://edb.volvo.net/kola/kap.php?action=Submit&s=";

	const fetchVariantsRespec = React.useCallback(async () => {
		await loadVariantsRespec().finally(() => setLoading(false));
	}, [loadVariantsRespec]);

	React.useEffect(() => {
		fetchVariantsRespec();
	}, [fetchVariantsRespec]);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.variantsRespecification.title")}
			</Grid>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : readOnlyFields.variantsRespec?.length > 0 ? (
				readOnlyFields.variantsRespec.map((variant: string, idx: number) => (
					<Grid key={idx} item>
						<Link
							href={`${edbVolvo}${variant}`}
							color="secondary"
							target="_blank"
							rel="noreferrer"
							pr={2}
						>
							{variant}
						</Link>
					</Grid>
				))
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(VariantsRespecificationSection);
