import { InvoicingDeliveryMarketCompanyProps } from "../interfaces/InvoicingDeliveryMarketCompanyDetails";

export const invoicingDeliveryMarketCompanyModel: InvoicingDeliveryMarketCompanyProps =
	{
		invoicedNumber: "",
		invoicedDate: null,
		deliveredNumber: "",
		deliveredDate: null,
	};

export function invoicingDeliveryMarketCompanyModelBuilder(
	data: InvoicingDeliveryMarketCompanyProps
) {
	if (!data) return invoicingDeliveryMarketCompanyModel;
	return {
		...invoicingDeliveryMarketCompanyModel,
		...data,
		invoicedNumber: data.invoicedNumber ?? "",
		invoicedDate: data.invoicedDate ?? null,
		deliveredNumber: data.deliveredNumber ?? "",
		deliveredDate: data.deliveredDate ?? null,
	} as InvoicingDeliveryMarketCompanyProps;
}
