import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { CustomerSectionProps } from "../../interfaces/Sections/CustomerSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	groupsAllowedToEditChileZone,
	groupsAllowedToEditCustomerSection,
} from "../../../../../constants/AuthGroups/orderDetails";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getChileZoneOptions } from "../../../../../utils/SelectOptions";

const CHILE = "CHL";

const CustomerSection: React.FC<CustomerSectionProps> = ({
	readOnlyFields,
	editableFields,
	setFieldByKeyOnChange,
	userIsOwner,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const { isAuthorizedGroup } = useAuthStore();

	const userCanEditInfo =
		userIsOwner || isAuthorizedGroup(groupsAllowedToEditCustomerSection);

	const userCanEditChileZone = isAuthorizedGroup(groupsAllowedToEditChileZone);

	const salesmanEditable = userCanEditInfo && !readOnlyFields.isDistrictChile;

	const zoneOptions = getChileZoneOptions(t);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.customer.title")}
				{readOnlyFields.saleStatus && " - " + readOnlyFields.saleStatus}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.originalCustomer")}
					value={readOnlyFields.foOriginalCustomerOM}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.sasCustomer")}
					value={editableFields.finalCustomer}
					editable={userCanEditInfo}
					name="FinalCustomer"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setFieldByKeyOnChange("finalCustomer", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.bodybuilder")}
					value={editableFields.bodybuilder}
					editable={userCanEditInfo}
					name="Bodybuilder"
					inputProps={{ maxLength: 50 }}
					onChange={(e) => setFieldByKeyOnChange("bodybuilder", e.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.salesman")}
					value={editableFields.salesman}
					editable={salesmanEditable}
					name="Salesman"
					inputProps={{ maxLength: 50 }}
					onChange={(e) => setFieldByKeyOnChange("salesman", e.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				{readOnlyFields.foDealerRegion.trim() === CHILE ? (
					<SelectForm
						label={t("details.sections.customer.zone")}
						labelId={"sections.customer-zone-label"}
						value={editableFields.zoneId}
						options={zoneOptions}
						editable={userCanEditChileZone}
						hasEditIcon
						hasEmptyDefaultItem
						onChange={(e) =>
							setFieldByKeyOnChange("zoneId", e.target.value as string)
						}
					/>
				) : (
					<TextForm
						label={t("details.sections.customer.branchoffice")}
						value={editableFields.branchOffice}
						editable={userCanEditInfo}
						name="BranchOffice"
						inputProps={{ maxLength: 50 }}
						onChange={(e) =>
							setFieldByKeyOnChange("branchOffice", e.target.value)
						}
					/>
				)}
			</Grid>
		</>
	);
};

export default observer(CustomerSection);
