import React from "react";
import {
	RadioGroupProps,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormControl,
	FormLabel,
} from "@mui/material";

type RadioItemProps = {
	label: string;
	value: any;
	color: string;
};

type RadioGroupFormProps = RadioGroupProps & {
	editable?: boolean;
	label: string;
	labelId: string;
	items: Array<RadioItemProps>;
};

const RadioGroupForm: React.FC<RadioGroupFormProps> = ({
	editable,
	label,
	labelId,
	items,
	value,
	...props
}) => {
	return (
		<FormControl>
			<FormLabel id={`formlabelid-${labelId}`}>{label}</FormLabel>
			<RadioGroup
				{...props}
				aria-labelledby={`radioGroupAriaLabelled-${labelId}`}
				name={`radioGroupName-${labelId}`}
				value={value}
			>
				{items.map((item) => (
					<FormControlLabel
						key={`radioControlLabelKey-${item.value}`}
						value={item.value}
						control={
							<Radio
								key={`radioKey-${item.value}`}
								disabled={!editable}
								// color={item.color}
								sx={{
									color: item.color,
									"&.Mui-checked": {
										color: item.color,
									},
								}}
							/>
						}
						label={item.label}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioGroupForm;
