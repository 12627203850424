import React from "react";
import { observer } from "mobx-react";
import { Grid, Tooltip } from "@mui/material";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import TextForm from "../../FormInputs/TextForm";
const TextPreFilter: React.FC<TextPreFilterProps> = ({
	preFilterStore,
	id,
	label = "",
	xs,
	sm,
	md,
	lg,
	xl,
	maxLength = 500,
	showError,
	tooltip,
}) => {
	const { preFilter, set, applyFilters } = preFilterStore;
	let hasFormatError = undefined;
	if (showError) {
		hasFormatError = preFilterStore.hasFormatError;
	}
	return (
		<Tooltip title={label} placement="top">
			<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
				<TextForm
					value={preFilter}
					onChange={(e: any) => set(e.target.value)}
					onEnter={applyFilters}
					id={id}
					label={label}
					size="small"
					slotProps={{ htmlInput: { maxLength } }}
					editable
					error={hasFormatError}
					tooltip={tooltip}
				/>
			</Grid>
		</Tooltip>
	);
};

export default observer(TextPreFilter);
