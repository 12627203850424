import * as React from "react";
import { Divider, ListSubheader, Typography } from "@mui/material";

interface MenuListSubHeaderProps {
	drawerOpen: boolean;
	text: string;
}

const MenuListSubHeader: React.FC<MenuListSubHeaderProps> = ({
	drawerOpen,
	text,
}) => {
	return (
		<React.Fragment>
			<Divider />
			{drawerOpen && (
				<ListSubheader component="div" sx={{ mt: 1 }}>
					<Typography variant="subtitle2" fontSize={12}>
						<strong>{text.toUpperCase()}</strong>
					</Typography>
				</ListSubheader>
			)}
		</React.Fragment>
	);
};

export default MenuListSubHeader;
