import CtadmvenApi from "../../apis/CtadmvenApi";

interface IHomeService {
	getOrders(vehicleKey: string): Promise<any>;
}
const base = "/home";

export class HomeService implements IHomeService {
	constructor(private api: CtadmvenApi) {}

	getOrders = async (vehicleKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${vehicleKey}`);
}
