import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import EditFilters from "./EditFilters";
import DateTimeToString from "../../../../utils/DateTimeToString";
import { useEditQuotaStore } from "../stores/EditQuotaStore";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import EditGrid from "./EditGrid";
import _ from "lodash";
import FirmPeriod from "./FirmPeriod";

const QuotaProposalEditPageIndex: React.FC = () => {
	const { t } = useTranslation("ctadmven");
	const {
		lastUpdateDate,
		loading,
		quotaProposalEdit,
		cleanData,
		logEvent,
		loadingFirmPeriod,
		firmPeriod,
	} = useEditQuotaStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("quota.edit", { ns: "menu" })} - ${DateTimeToString(
				lastUpdateDate,
				"LLL"
			)}`,
			main: true,
		},
	];

	useEffect(() => {
		return () => {
			cleanData();
		};
	}, []);

	const dateTime = DateTimeToString(
		logEvent?.updatedAt ?? new Date(),
		"DD/MM/YYYY HH:mm",
		"-",
		true
	);

	return (
		<>
			<LoadingPage loading={loading} />
			<TitleBox breadcrumbs={breadcrumbs}>
				{!_.isEmpty(logEvent) && (
					<div className="fs14">
						{logEvent.wasManualLastUpdated && (
							<span>
								{t("updatedManuallyAt", {
									reference: logEvent.reference,
									dateTime,
									ns: "general",
								})}
							</span>
						)}
						{!logEvent.wasManualLastUpdated && (
							<span>
								{t("syncronizedAt", {
									reference: logEvent.reference,
									dateTime,
									ns: "general",
								})}
							</span>
						)}
					</div>
				)}
			</TitleBox>
			<Grid container spacing={2}>
				<Grid item lg={12}>
					<EditFilters />
				</Grid>
				{loadingFirmPeriod ? (
					<Grid item xs={12}>
						<Skeleton variant="rounded" width="100%" height={100} />
					</Grid>
				) : firmPeriod.year > 0 ? (
					<>
						<Grid item lg={12}>
							<FirmPeriod />
						</Grid>
					</>
				) : (
					<></>
				)}
				<Grid item lg={12}>
					{quotaProposalEdit && quotaProposalEdit.length > 0 && <EditGrid />}
				</Grid>
			</Grid>
		</>
	);
};

export default observer(QuotaProposalEditPageIndex);
