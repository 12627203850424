import { observer } from "mobx-react";
import { gridConst } from "../../../constants/gridConst";
import { useExportPartnerStore } from "../stores/ExportPartnerStore";
import React from "react";
import { DataGridApi } from "../../../components/kendo/DataGridApi/DataGridApi";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	IconButton,
	Tooltip,
	DialogActions,
	DialogContent,
} from "@mui/material";
import { ExportPartnerProps } from "../interface/ExportPartnerProps";
import { toast } from "react-toastify";
import ExportPartnerForm from "./ExportPartnerForm";
import DialogForm from "../../../components/Dialog/DialogForm";
import CancelButton from "../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../components/Dialog/Buttons/ApplyButton";

const ExportPartnerGrid: React.FC = () => {
	const { t } = useTranslation(["general", "menu"]);
	const {
		allRecords,
		deleteExportPartner,
		refresh,
		setLoading,
		setFormVisible,
		setDataItemChanged,
		setActionLabel,
		actionLabel,
		formVisible,
		dataItemChanged,
	} = useExportPartnerStore();

	const [dialogVisible, setDialogVisible] = React.useState(false);

	const toggleDialogForm = (dataItem: ExportPartnerProps) => {
		if (dataItem) setDataItemChanged(dataItem);
		setFormVisible(!formVisible);
		setActionLabel("editRecord");
	};

	const toggleDialog = (dataItem: any) => {
		if (dataItem) setDataItemChanged(dataItem);
		setDialogVisible(!dialogVisible);
	};

	const actionColumn = ({ dataItem }: any) => (
		<td style={{ left: 0, right: 0, borderRightWidth: 1 }}>
			<Tooltip title={`${t("edit")}`} arrow>
				<IconButton onClick={() => toggleDialogForm(dataItem)}>
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title={`${t("delete")}`} arrow>
				<IconButton onClick={() => toggleDialog(dataItem)}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</td>
	);

	const onDeleteExportPartner = () => {
		deleteExportPartner(dataItemChanged.id)
			.then(() => {
				allRecords();
				toast.success(t("deletedWithSuccess").toString());
			})
			.catch((err) => toast.error(err.response.data))
			.finally(() => setLoading(false));

		toggleDialog(null);
	};

	const columns = [
		{ field: "customerCDB", title: t("customerCDB") },
		{ field: "customerName", title: t("customer") },
		{ field: "cdb", title: t("exportPartnerCDB") },
		{ field: "name", title: t("exportPartner") },
		{
			field: "startDate",
			title: t("startDate"),
			customCell: {
				type: "dateTimeCell",
				date: "startDate",
				format: "DD/MM/YY",
			},
		},
		{
			field: "endDate",
			title: t("endDate"),
			customCell: {
				type: "dateTimeCell",
				date: "endDate",
				format: "DD/MM/YY",
			},
		},
		{
			field: "",
			title: "",
			width: 100,
			cell: actionColumn,
			sortable: false,
			locked: true,
		},
	];

	return (
		<>
			<DataGridApi
				columns={columns}
				fetchData={allRecords}
				pageable={gridConst.pageable}
				pageSize={gridConst.pageSize}
				rowHeight={10}
				reorderable={true}
				resizable={true}
				className="k-grid-lg"
				refresh={refresh}
			/>
			<ExportPartnerForm actionLabel={actionLabel} />
			<DialogForm
				id="dialog-exp=partner-delete"
				open={dialogVisible}
				title={t("confirmationMsg")}
				onClose={toggleDialog}
			>
				<DialogContent dividers>
					{`${t("removing")} ${dataItemChanged.name}`}
				</DialogContent>
				<DialogActions>
					<CancelButton
						id="dialog-exp=partner-delete"
						onCancel={toggleDialog}
						title={t("cancel")}
					/>
					<ApplyButton
						id="dialog-exp=partner-delete"
						onSubmit={onDeleteExportPartner}
						title={t("confirm")}
						startIcon={<DeleteIcon />}
						color="error"
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(ExportPartnerGrid);
