import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { CustomerSectionProps } from "../../interfaces/Sections/CustomerSection";
import TextForm from "../../../../../components/FormInputs/TextForm";

const CustomerSection: React.FC<CustomerSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.customer.title")}
				{readOnlyFields.saleStatus && " - " + readOnlyFields.saleStatus}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.originalCustomer")}
					value={readOnlyFields.foOriginalCustomerOM}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.sasCustomer")}
					value={readOnlyFields.finalCustomer}
					name="FinalCustomer"
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.bodybuilder")}
					value={readOnlyFields.bodybuilder}
					name="Bodybuilder"
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.salesman")}
					value={readOnlyFields.salesman}
					name="Salesman"
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.branchoffice")}
					value={readOnlyFields.branchOffice}
					name="BranchOffice"
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.dealer")}
					value={readOnlyFields.dealerName}
					name="DealerName"
				/>
			</Grid>
		</>
	);
};

export default observer(CustomerSection);
