import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/RootStore";
import { observer } from "mobx-react";
import ClearRecordItem from "../../../../../../components/BatchEdition/ClearRecordItem";
import { followUpArgKeyLabels } from "../../../utils/BatchEditionUtils";
import TextForm from "../../../../../../components/FormInputs/TextForm";

const EngineeringCommentsForm: React.FC = () => {
	const { t } = useTranslation("order");

	const { batchData, setOrderMCManagementDetailBatchEditByKey } =
		useStores().batchEditionStore;

	return (
		<ClearRecordItem
			recordTitle={t(followUpArgKeyLabels.engineeringComments.label)}
			checked={batchData.orderMCManagementDetail.engineeringComments.clearValue}
			onClearRecord={setOrderMCManagementDetailBatchEditByKey}
			recordKey={followUpArgKeyLabels.engineeringComments.key}
		>
			<TextForm
				label={t(followUpArgKeyLabels.engineeringComments.label)}
				value={batchData.orderMCManagementDetail.engineeringComments.value}
				slotProps={{ htmlInput: { maxLength: 500 } }}
				editable
				onChange={(e) =>
					setOrderMCManagementDetailBatchEditByKey(
						{
							value: e.target.value,
							clearValue: false,
						},
						followUpArgKeyLabels.engineeringComments.key
					)
				}
				disabled={
					batchData.orderMCManagementDetail.engineeringComments.clearValue
				}
				multiline
				maxRows={4}
			/>
		</ClearRecordItem>
	);
};

export default observer(EngineeringCommentsForm);
