import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { BusEditableProps } from "../interfaces/Editable/BusEditableDetails";
import { BusReadOnlyProps } from "../interfaces/BusReadOnlyDetails";
import _ from "lodash";
import {
	busReadOnlyModel,
	busReadOnlyModelBuilder,
} from "../models/BusReadOnlyModel";
import {
	busEditableModel,
	busEditableModelBuilder,
} from "../models/Editable/BusEditableModel";
import { saleInvoiceModelBuilder } from "../models/SaleInvoiceModel";
import { invoiceExportationModelBuilder } from "../models/InvoiceExportationModel";
import { invoiceInstructionModelBuilder } from "../models/InvoiceInstructionModel";
import { invoiceInstructionExportationModelBuilder } from "../models/InvoiceInstructionExportationModel";
import { reservationAvailabilityModelBuilder } from "../models/ReservationAvailabilityModel";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import { invoicingDeliveryMarketCompanyModelBuilder } from "../models/InvoicingDeliveryMarketCompanyModel";
import { StoreUtils } from "../../../../utils/StoreUtils";
import BaseTruckAndBusStore from "./BaseTruckAndBusStore";

configure({ enforceActions: "always" });

/* Store start */
class BusStore extends BaseTruckAndBusStore {
	@observable menuKey: string = "";
	@observable readOnlyDetails: BusReadOnlyProps = busReadOnlyModel;
	@observable editableDetails: BusEditableProps = busEditableModel;
	@observable initialEditableDetails: BusEditableProps = busEditableModel;
	@observable hasChanges: boolean = false;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		super(mainStore);
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action resetStore = (menuKey: string) => {
		this.readOnlyDetails = busReadOnlyModel;
		this.editableDetails = busEditableModel;
		this.initialEditableDetails = busEditableModel;
		this.hasChanges = false;
		this.menuKey = menuKey;
	};

	@action setEditableDetailsFieldByKeyOnChange = (
		fieldKey: keyof BusEditableProps,
		newValue: string & Date
	) => {
		this.editableDetails[fieldKey] = newValue ?? "";
		this.checkChanges();
	};

	@action setReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = busReadOnlyModelBuilder({
			...this.readOnlyDetails,
			...data,
		});
	};

	@action setEditableDetails = (data: any) => {
		this.editableDetails = busEditableModelBuilder({
			...this.editableDetails,
			...data,
		});
		this.initialEditableDetails = busEditableModelBuilder({
			...this.initialEditableDetails,
			...data,
		});
	};

	@action private checkChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
		this.rootStore.commonStore.setHasChanges();
	};

	@action loadDetails = async (
		id: string,
		menuKey: string,
		userDisplayName: string
	) => {
		this.rootStore.commonStore.resetAllStores(id, menuKey);

		await StoreUtils.LoadData(
			() => this.api.busService.getDetails(id, menuKey),
			(data: any) => {
				this.setReadOnlyDetails(data);
				this.setEditableDetails(data);
				this.rootStore.commonStore.setStore(data.id, menuKey);
				this.rootStore.commonStore.setBaseFields(userDisplayName);
			},
			id,
			menuKey
		);
	};

	@action loadVariants = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(() => this.api.busService.getDetailsVariants(id, this.menuKey), this.setVariants, id, this.menuKey);
	};

	@action update = async () => {
		await this.rootStore.commonStore.update();

		if (!this.hasChanges) {
			return Promise.resolve(null);
		}

		if (this.hasZoneChanges()) {
			await this.api.busService.updateZone(
				this.editableDetails.id,
				this.editableDetails.zoneId
			);
		}

		return await this.api.busService.update(this.editableDetails, this.menuKey);
	};

	private hasZoneChanges = (): boolean =>
		this.editableDetails.zoneId !== this.initialEditableDetails.zoneId;

	setVariants = (data: any) => {
		this.readOnlyDetails.variants = data ?? [];
	}

	setVariantsReespec = (data: any) => {
		this.readOnlyDetails.variantsRespec = data ?? [];
	}

	setSaleInvoice = (data: any) => {
		this.readOnlyDetails.saleInvoice = saleInvoiceModelBuilder(data);
	}

	setLocalizationInvoices = (data: any) => {
		this.readOnlyDetails.localizationInvoices = data ?? [];
	}

	setReservationAvailability = (data: any) => {
		this.readOnlyDetails.reservationAvailability = reservationAvailabilityModelBuilder(data);
	}

	setInvoiceExportation = (data: any) => {
		this.readOnlyDetails.invoiceExportation = invoiceExportationModelBuilder(data);
	}

	setInvoicingDeliveryMarketCompany = (data: any) => {
		this.readOnlyDetails.invoicingDeliveryMarketCompany = invoicingDeliveryMarketCompanyModelBuilder(data);
	}

	setAllInvoiceInstruction = (data: any) => {
		this.readOnlyDetails.invoiceInstruction = invoiceInstructionModelBuilder(data);
	}

	setMinimalInvoiceInstruction = (data: any) => {
		this.readOnlyDetails.invoiceInstruction = invoiceInstructionModelBuilder(data);
	}

	setInvoiceInstructionExportation = (data: any) => {
		this.readOnlyDetails.invoiceInstructionExportation = invoiceInstructionExportationModelBuilder(data);
	}

	setAllInvoiceInstructionExportation = (data: any) => {
		this.readOnlyDetails.invoiceInstructionExportation = invoiceInstructionExportationModelBuilder(data);
	}

	setMinimalInvoiceInstructionExportation = (data: any) => {
		this.readOnlyDetails.invoiceInstructionExportation = invoiceInstructionExportationModelBuilder(data);
	}
}
/* Store end */

export default BusStore;
