import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { useGroupDistrictStore } from "../stores/GroupDistrictStore";
import DistrictGroupGrid from "./DistrictGroupGrid";

const DistrictGroupIndex: React.FC = () => {
	const { t } = useTranslation("general");

	const { loading, cleanData, getListDistrictGroups, districtGroupEdit } =
		useGroupDistrictStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("district.group", { ns: "menu" })}`,
			main: true,
		},
	];

	useEffect(() => {
		getListDistrictGroups().catch((err) => toast.error(err.response.data));
	}, []);

	useEffect(() => {
		return () => {
			cleanData();
		};
	}, []);

	return (
		<>
			<LoadingPage loading={loading} />
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs} />
			</Grid>
			{districtGroupEdit && districtGroupEdit.length > 0 && (
				<DistrictGroupGrid />
			)}
		</>
	);
};

export default observer(DistrictGroupIndex);
