import React from "react";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../apis/CtadmvenApi";
import { State } from "@progress/kendo-data-query";

configure({ enforceActions: "always" });

/* Store start */
class InconsistenciesStore {
	@observable loading = false;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action setLoading = (value: boolean) => (this.loading = value);

	@action createDataStateObj = (dataState: State) =>
		({ ...dataState }) as State;

	@action getInconsistencies = async (dataState: State) => {
		const dataStateObj = this.createDataStateObj(dataState);
		if (!dataStateObj) return;

		this.setLoading(true);

		return await this.api.orderService
			.getInconsistencies(dataStateObj)
			.then((json) => Object.assign({ selected: false }, json.data))
			.finally(() => this.setLoading(false));
	};
}
/* Store end */

/* Store helpers */
const InconsistenciesStoreContext = React.createContext(
	new InconsistenciesStore()
);

/* Hook to use store in any functional component */
export const useInconsistenciesStore = () =>
	React.useContext(InconsistenciesStoreContext);
