import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
	Checkbox,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup,
	Grid,
	Stack,
} from "@mui/material";
import TextForm from "../../../../components/FormInputs/TextForm";
import { useVariantsStore } from "../stores/VariantsStore";
import { toast } from "react-toastify";
import DialogForm from "../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../components/Dialog/Buttons/ApplyButton";
import { Save } from "@mui/icons-material";
import { VariantAdminPutProps } from "../interfaces/VariantAdminPutProps";
import { fieldIsEditable } from "../utils/EditableFieldsUtils";

const EditForm: React.FC = () => {
	const { t } = useTranslation("general");

	const {
		isFormVisible,
		setFormVisible,
		updateVariant,
		setDataItemChanged,
		dataItemChanged,
		resetDataItemChanged,
		editableFields,
		loading,
	} = useVariantsStore();

	const toggleDialog = () => {
		setFormVisible(!isFormVisible);
	};

	const handleClose = () => {
		resetDataItemChanged();
		toggleDialog();
	};

	const handleSubmit = () => {
		updateVariant(t)
			.finally(() => {
				toggleDialog();
				resetDataItemChanged();
			})
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err) => toast.error(err.response));
	};

	const setCheckbox = <K extends keyof VariantAdminPutProps>(fieldKey: K) => {
		let isChecked = dataItemChanged[fieldKey] as boolean;

		setDataItemChanged({
			...dataItemChanged,
			[fieldKey]: !isChecked,
		});
	};

	const setDescription = <K extends keyof VariantAdminPutProps>(
		fieldKey: K,
		value: string
	) => {
		setDataItemChanged({
			...dataItemChanged,
			[fieldKey]: value,
		});
	};

	return (
		<DialogForm
			id="dialog-variants-edition"
			open={isFormVisible}
			title={t("editRecord", {
				name: `${t("family")} ${dataItemChanged.family}`,
			})}
			onClose={handleClose}
		>
			<DialogContent>
				<Grid container spacing={2} overflow="none">
					<Grid item xs={12}>
						<TextForm
							editable={fieldIsEditable("descriptionEnUs", editableFields)}
							id={"descriptionEnUs"}
							label={t("descriptionEnUs")}
							value={dataItemChanged?.descriptionEnUs}
							onChange={(e) =>
								setDescription("descriptionEnUs", e.target.value)
							}
							type="text"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							editable={fieldIsEditable("descriptionPtBr", editableFields)}
							id={"descriptionPtBr"}
							label={t("descriptionPtBr")}
							value={dataItemChanged?.descriptionPtBr}
							onChange={(e) =>
								setDescription("descriptionPtBr", e.target.value)
							}
							type="text"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							editable={fieldIsEditable("descriptionEsEs", editableFields)}
							id={"descriptionEsEs"}
							label={t("descriptionEsEs")}
							value={dataItemChanged?.descriptionEsEs}
							onChange={(e) =>
								setDescription("descriptionEsEs", e.target.value)
							}
							type="text"
						/>
					</Grid>
					<Grid item xs={12}>
						<FormGroup>
							<Stack direction={"row"}>
								<Grid item xs={4}>
									<FormControlLabel
										control={
											<Checkbox
												checked={dataItemChanged?.forTruck}
												color="secondary"
												onChange={() => setCheckbox("forTruck")}
												disabled={!fieldIsEditable("forTruck", editableFields)}
											/>
										}
										label={t("truck")}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControlLabel
										control={
											<Checkbox
												checked={dataItemChanged?.forBus}
												color="secondary"
												onChange={() => setCheckbox("forBus")}
												disabled={!fieldIsEditable("forBus", editableFields)}
											/>
										}
										label={t("bus")}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControlLabel
										control={
											<Checkbox
												checked={dataItemChanged?.forFollowUpChl}
												color="secondary"
												onChange={() => setCheckbox("forFollowUpChl")}
												disabled={
													!fieldIsEditable("forFollowUpChl", editableFields)
												}
											/>
										}
										label={t("followUpChl")}
									/>
								</Grid>
							</Stack>
						</FormGroup>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<CancelButton
					id="dialog-variants-edition"
					onCancel={handleClose}
					title={t("cancel")}
				/>
				<ApplyButton
					id="dialog-variants-edition"
					onSubmit={handleSubmit}
					title={t("saveChanges", { ns: "general" })}
					startIcon={<Save />}
					disabled={loading}
				/>
			</DialogActions>
		</DialogForm>
	);
};

export default observer(EditForm);
