import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
	Alert,
	Button,
	DialogActions,
	DialogContent,
	Stack,
	Tooltip,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useStores } from "../../stores/RootStore";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import SwitchForm from "../../../../../components/FormInputs/SwitchForm";
import {
	BusMenus,
	EuropeMenus,
	TruckBrMenus,
} from "../../../../../constants/Menus/OrderMenuUtils";
import { toast } from "react-toastify";
import {
	CSV_DISABLED_BUSES_ORDERS,
	CSV_DISABLED_EUROPE_ORDERS,
	CSV_DISABLED_TRUCKS_ORDERS,
} from "../../../../../constants/csvButtonStorage";
import checkButtonTimer from "../../../../../utils/CheckCsvTimer";
import { CsvQueueStatus } from "../../../../../utils/enums/CsvQueueStatusEnum";

const HomePageCsvExport: React.FC = () => {
	const { t } = useTranslation("general");
	const { i18n } = useTranslation();
	const {
		csvExport,
		setIncludeVariants,
		includeVariants,
		setCsvLineFilter,
		loading,
		menu,
	} = useStores().listStore;
	const [open, setOpen] = React.useState(false);

	const handleSubmit = async () => {
		const culture = i18n.language;
		setOpen(false);
		const status = (await csvExport(culture)) as CsvQueueStatus;
		switch (status) {
			case CsvQueueStatus.Error:
				toast.error(t("csvRequestError"));
				break;
			case CsvQueueStatus.AddedToQueue:
				toast.success(t("csvHasBeenRequested"));
				break;
			case CsvQueueStatus.AlreadyInQueue:
				toast.warning(t("csvAlreadyInQueue"));
				break;
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		if (
			(EuropeMenus.some((europeMenu) => europeMenu === menu) &&
				checkButtonTimer(CSV_DISABLED_EUROPE_ORDERS)) ||
			(BusMenus.some((busMenu) => busMenu === menu) &&
				checkButtonTimer(CSV_DISABLED_BUSES_ORDERS)) ||
			(TruckBrMenus.some((truckMenu) => truckMenu === menu) &&
				checkButtonTimer(CSV_DISABLED_TRUCKS_ORDERS))
		) {
			toast.warning(t("csvExportDownloadTimeWarning"));
			return;
		}

		setIncludeVariants(false);
		setCsvLineFilter("");
		setOpen(true);
	};

	return (
		<>
			<LoadingPage loading={loading} />
			<Tooltip title={`${t("csvExport")}`} arrow>
				<Button onClick={handleOpen} color="secondary">
					<FileDownloadIcon fontSize="medium" />
				</Button>
			</Tooltip>
			<DialogForm
				id="dialog-csv-export"
				open={open}
				title={t("csvOrderBookExportDialogTitle")}
				onClose={handleClose}
				onEnterSubmit={handleSubmit}
			>
				<DialogContent>
					<Stack>
						<SwitchForm
							editable
							label={`${t("include")} ${t("variants")}`}
							checked={includeVariants}
							onChange={(e) => setIncludeVariants(e.target.checked)}
						/>
						<Alert severity="warning">{t("csvExportDialogTimeWarning")}</Alert>
					</Stack>
				</DialogContent>
				<DialogActions>
					<CancelButton
						id="dialog-csv-export"
						onCancel={handleClose}
						title={t("clear")}
					/>
					<ApplyButton
						id="dialog-csv-export"
						onSubmit={handleSubmit}
						title={t("confirm")}
						startIcon={<FileDownloadIcon />}
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(HomePageCsvExport);
