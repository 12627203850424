import { NavLink } from "react-router-dom";
import {
	IUpdateCell,
	IColumnProps,
	IDateTimeCell,
	ILinkCell,
	INumberCell,
	IOptionCell,
	IBooleanCell,
} from "../interfaces/IColumnProps";
import { Checkbox, Stack } from "@mui/material";
import { Launch } from "@mui/icons-material";
import {
	GRID_COL_INDEX_ATTRIBUTE,
	GridCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import moment from "moment";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { CSSProperties } from "react";
import { IRecords } from "../interfaces/IRecords";
import calculateSize from "calculate-size";
import CheckIcon from "@mui/icons-material/Check";

const getValue = (dataItem: any, keyStr: string) => {
	const keys = keyStr.split(".");
	keys.forEach((key) => {
		if (dataItem) dataItem = dataItem[key];
		else dataItem = undefined;
	});
	return dataItem;
};

const createCell = (
	gridCellProps: GridCellProps,
	element: any,
	extendedStyle?: React.CSSProperties
) => {
	return (
		<td
			style={{ ...gridCellProps.style, ...extendedStyle }}
			className={gridCellProps.className}
			colSpan={gridCellProps.colSpan}
			role={"gridcell"}
			aria-colindex={gridCellProps.ariaColumnIndex}
			aria-selected={gridCellProps.isSelected}
			{...{ [GRID_COL_INDEX_ATTRIBUTE]: gridCellProps.columnIndex }}
		>
			{element}
		</td>
	);
};

export const calculateColumnMaxWidth = (
	col: any,
	records: IRecords,
	className: string | undefined
) => {
	let field = col.field;
	let maxWidth = 0;
	let minWidth = col.minWidth ?? 60;
	let fontSize = "1.5rem";
	records.data.forEach((item: any) => {
		if (className === "k-grid-sm") {
			fontSize = "1rem";
		}
		const size = calculateSize(getValue(item, field), {
			font: "Roboto",
			fontSize: fontSize,
		});

		if (size.width > maxWidth) maxWidth = size.width;
	});
	if (maxWidth < minWidth) maxWidth = minWidth;
	return maxWidth;
};

export const getCustomCell = (
	gridCellProps: GridCellProps,
	customCell: any,
	itemsToBeUpdated?: Array<string>,
	onChangeItemToBeUpdated?: (
		item: string | Array<string>,
		checked: boolean
	) => any
) => {
	switch (customCell.type) {
		case "linkCell":
			return getLinkCell(gridCellProps, customCell);
		case "dateTimeCell":
			return getDateTimeCell(gridCellProps, customCell);
		case "optionCell":
			return getOptionCell(gridCellProps, customCell);
		case "numberCell":
			return getNumberCell(gridCellProps, customCell);
		case "booleanCell":
			return getBooleanCell(gridCellProps, customCell);
		case "updateCell":
			if (itemsToBeUpdated && onChangeItemToBeUpdated)
				return getUpdateCell(
					gridCellProps,
					customCell,
					itemsToBeUpdated,
					onChangeItemToBeUpdated
				);
			break;
	}

	return createCell(gridCellProps, <></>);
};

export const getCellHeader = (
	col: IColumnProps,
	gridCellProps: GridHeaderCellProps,
	records: IRecords,
	itemsToBeUpdated?: Array<string>,
	onChangeItemToBeUpdated?: (
		item: string | Array<string>,
		checked: boolean
	) => any
) => {
	if (
		col.customCell &&
		col.customCell.type === "updateCell" &&
		itemsToBeUpdated &&
		onChangeItemToBeUpdated
	) {
		const items = records.data.map((m: any) => getValue(m, col.customCell.key));

		return (
			<Checkbox
				sx={{ p: 0, m: 0 }}
				checked={itemsToBeUpdated.length === records.data.length}
				color="secondary"
				onChange={(e) => onChangeItemToBeUpdated(items, e.target.checked)}
			/>
		);
	}

	const whiteSpace = col.canWrap ? "normal" : "nowrap";
	const alignment = col.headerAlign ?? "left";
	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			className="k-link"
			onClick={gridCellProps.onClick}
			style={{ textAlign: alignment }}
			//Don't add href, not sortable cells will stop working, leave the warning :)
		>
			<span className="k-column-title" style={{ whiteSpace: whiteSpace }}>
				{gridCellProps.title}
			</span>
			{gridCellProps.children}
		</a>
	);
};

const getLinkCell = (gridCellProps: GridCellProps, customCell: ILinkCell) => {
	const dataItem = gridCellProps.dataItem;
	const toParams = customCell.toParams
		.split(";")
		.map((m) => getValue(dataItem, m))
		.join("/");
	const element = (
		<NavLink to={customCell.to + toParams} target="_blank" rel="noreferrer">
			<Stack direction="row">
				<span>{getValue(dataItem, customCell.value)}</span>
				<Launch
					fontSize="small"
					sx={{ fontSize: "inherit", marginLeft: "1px" }}
				/>
			</Stack>
		</NavLink>
	);

	return createCell(gridCellProps, element);
};

const getDateTimeCell = (
	gridCellProps: GridCellProps,
	customCell: IDateTimeCell
) => {
	const dataItem = gridCellProps.dataItem;
	const originalDate = getValue(dataItem, customCell.date);
	const value = originalDate
		? moment(originalDate).format(customCell.format).toString()
		: "";

	const element = <span>{value}</span>;

	return createCell(gridCellProps, element);
};

const getOptionCell = (
	gridCellProps: GridCellProps,
	customCell: IOptionCell
) => {
	const dataItem = gridCellProps.dataItem;
	const originalValue = getValue(dataItem, customCell.value);
	const option = customCell.options.find((f) => f.value === originalValue);
	const value = option ? option.label : "";

	const element =
		customCell.badge !== "circle" ? (
			<span>{value}</span>
		) : (
			<Stack direction="row" spacing={0.5}>
				<Brightness1Icon sx={{ fontSize: "1rem", color: option?.color }} />
			</Stack>
		);

	return createCell(gridCellProps, element);
};

const getNumberCell = (
	gridCellProps: GridCellProps,
	customCell: INumberCell
) => {
	const dataItem = gridCellProps.dataItem;
	const value = getValue(dataItem, customCell.value);
	const formatted = value ? value.toFixed(customCell.fractionDigits) : "";

	const element = <span>{formatted}</span>;

	const style: CSSProperties = { textAlign: "right" };

	return createCell(gridCellProps, element, style);
};

const getUpdateCell = (
	gridCellProps: GridCellProps,
	customCell: IUpdateCell,
	itemsToBeUpdated: Array<string>,
	onChangeItemToBeUpdated: (
		item: string | Array<string>,
		checked: boolean
	) => any
) => {
	const dataItem = gridCellProps.dataItem;
	const key = getValue(dataItem, customCell.key);
	const checked = itemsToBeUpdated.some((s) => s === key);
	const element = (
		<Checkbox
			sx={{ p: 0, m: 0 }}
			checked={checked}
			color="secondary"
			onChange={(e) => onChangeItemToBeUpdated(key, e.target.checked)}
		/>
	);

	return createCell(gridCellProps, element);
};

const getBooleanCell = (
	gridCellProps: GridCellProps,
	customCell: IBooleanCell
) => {
	const dataItem = gridCellProps.dataItem;

	const value = getValue(dataItem, customCell.value);

	const element = value ? <CheckIcon fontSize="small" /> : "";

	const style: CSSProperties = { textAlign: "center" };

	return createCell(gridCellProps, element, style);
};
