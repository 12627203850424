import { TruckEditableProps } from "../../interfaces/Editable/TruckEditableDetails";
import {
	baseEditableModel,
	baseEditableModelBuilder,
} from "./BaseEditableModel";

export const truckEditableModel: TruckEditableProps = {
	...baseEditableModel,
	quotaDistrict: "",
	quotaRegion: "",
};

export function truckEditableModelBuilder(data: TruckEditableProps) {
	if (!data) return truckEditableModel;
	let model = baseEditableModelBuilder(data);
	return {
		...model,
		quotaDistrict: data.quotaDistrict ?? "",
		quotaRegion: data.quotaRegion ?? "",
	} as TruckEditableProps;
}
