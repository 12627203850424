import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import MonthPreFilter from "../components/MonthPreFilter";
import { MonthPreFilterProps } from "../Interfaces/MonthPreFilterProps";

const DeliveryProposalPreFilter: React.FC<MonthPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		label = "preFilters.deliveryProposal",
		id = "deliveryProposalPreFilter",
	} = props;
	return <MonthPreFilter {...props} label={t(label)} id={id} />;
};

export default observer(DeliveryProposalPreFilter);
