import moment from "moment";

export const textNotEmptyValidator = (value: string) => value && value.trim();

export const requiredDateValidator = (
	value: Date | null,
	minDate: Date,
	maxDate: Date
) =>
	value &&
	moment(value, "MM/yyyy", true).isValid() &&
	value.getFullYear() >= minDate.getFullYear() &&
	value.getFullYear() <= maxDate.getFullYear();

export const maxValueValidator = (value: number | undefined, max: number) =>
	!value || (value && value <= max);

export const hasMinValueRequiredValidator = (
	value: number | undefined,
	min: number
) => value && value >= min;
