import CtadmvenApi from "../../apis/CtadmvenApi";
import { FirmPeriodProps } from "../../pages/Quota/Edit/interfaces/FirmPeriodProps";

interface IFirmPeriodService {
	getFirmPeriod(line: string, year: number, market: string): Promise<any>;
	createOrUpdateFirmPeriod(data: FirmPeriodProps): Promise<any>;
}
const base = "/firmPeriod";

export class FirmPeriodService implements IFirmPeriodService {
	constructor(private api: CtadmvenApi) {}

	getFirmPeriod = async (
		line: string,
		year: number,
		market: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}?line=${line}&year=${year}&locale=${market}`
		);

	createOrUpdateFirmPeriod = async (data: FirmPeriodProps): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}`, data);
}
