import CtadmvenApi from "../../apis/CtadmvenApi";

interface IOrderInvoiceInstructionService {
	getByOrderId(id: string, menuKey: string): Promise<any>;
	getMinimalInformationByOrderId(id: string, menuKey: string): Promise<any>;
	getExportationByOrderId(id: string, menuKey: string): Promise<any>;
	getExportationMinimalInformationByOrderId(
		id: string,
		menuKey: string
	): Promise<any>;
}
const base = "/orderInvoiceInstruction";

export class OrderInvoiceInstructionService
	implements IOrderInvoiceInstructionService
{
	constructor(private api: CtadmvenApi) {}

	getByOrderId = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${menuKey}/${id}`);

	getMinimalInformationByOrderId = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/minimal/${menuKey}/${id}`);

	getExportationByOrderId = async (id: string, menuKey: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/exp/${menuKey}/${id}`);

	getExportationMinimalInformationByOrderId = async (
		id: string,
		menuKey: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/exp/minimal/${menuKey}/${id}`);
}
