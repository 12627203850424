import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { CARD_SIZE } from "../../shared/cardSize";

interface FirmPeriodWeekProps {
	firmPeriodWeek?: number;
}
const FirmPeriodWeek: React.FC<FirmPeriodWeekProps> = ({ firmPeriodWeek }) => {
	const { t } = useTranslation("quota");

	return (
		<>
			{firmPeriodWeek !== -1 && (
				<Card sx={{ width: CARD_SIZE, mt: 2 }}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Stack direction="row" spacing={1} alignItems="center">
									<Typography>
										{t("firmPeriod.weekPeriodTitle")} {firmPeriodWeek ?? " - "}
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			)}
		</>
	);
};

export default observer(FirmPeriodWeek);
