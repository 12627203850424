import { AuthGroupsType } from "../../../../constants/AuthGroups/authGroups";
import {
	groupsToUpdateFollowUpArg,
	groupsToUpdateFollowUpArgAccounting,
	groupsToUpdateFollowUpArgBusiness,
	groupsToUpdateFollowUpArgEngineering,
	groupsToUpdateFollowUpArgFinancial,
	groupsToUpdateFollowUpArgGeneralComment,
	groupsToUpdateFollowUpArgLogistic,
} from "../../../../constants/AuthGroups/followUpArgPage";
import {
	followUpArgAccountingEditableModel,
	followUpArgBusinessEditableModel,
	followUpArgEditableModel,
	followUpArgEngineeringEditableModel,
	followUpArgFinancialEditableModel,
	followUpArgGeneralCommentEditableModel,
	followUpArgLogisticEditableModel,
} from "../models/Editable/FollowUpArgEditableModel";

function getFollowUpArgEditableInterfaceObjectKeys(obj: any) {
	let keys = Object.keys(obj);
	if (obj.orderMCDelivery) keys.push(...Object.keys(obj.orderMCDelivery));
	if (obj.orderMCManagement) keys.push(...Object.keys(obj.orderMCManagement));
	return keys;
}

export function getUserUpdatePermission(
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean
) {
	if (isAuthorizedGroup(groupsToUpdateFollowUpArg)) return "UpdateFollowUpArg";

	if (isAuthorizedGroup(groupsToUpdateFollowUpArgGeneralComment))
		return "UpdateFollowUpArgGeneralComment";

	if (isAuthorizedGroup(groupsToUpdateFollowUpArgFinancial))
		return "UpdateFollowUpArgFinancial";

	if (isAuthorizedGroup(groupsToUpdateFollowUpArgLogistic))
		return "UpdateFollowUpArgLogistic";

	if (isAuthorizedGroup(groupsToUpdateFollowUpArgAccounting))
		return "UpdateFollowUpArgAccounting";

	if (isAuthorizedGroup(groupsToUpdateFollowUpArgBusiness))
		return "UpdateFollowUpArgBusiness";

	if (isAuthorizedGroup(groupsToUpdateFollowUpArgEngineering))
		return "UpdateFollowUpArgEngineering";

	return "";
}

export function getFieldsAvailableForEditionByUser(
	userUpdatePermission: string
) {
	switch (userUpdatePermission) {
		case "UpdateFollowUpArg":
			return getFollowUpArgEditableInterfaceObjectKeys(
				followUpArgEditableModel
			);
		case "UpdateFollowUpArgGeneralComment":
			return getFollowUpArgEditableInterfaceObjectKeys(
				followUpArgGeneralCommentEditableModel
			);
		case "UpdateFollowUpArgFinancial":
			return getFollowUpArgEditableInterfaceObjectKeys(
				followUpArgFinancialEditableModel
			);
		case "UpdateFollowUpArgLogistic":
			return getFollowUpArgEditableInterfaceObjectKeys(
				followUpArgLogisticEditableModel
			);
		case "UpdateFollowUpArgAccounting":
			return getFollowUpArgEditableInterfaceObjectKeys(
				followUpArgAccountingEditableModel
			);
		case "UpdateFollowUpArgBusiness":
			return getFollowUpArgEditableInterfaceObjectKeys(
				followUpArgBusinessEditableModel
			);
		case "UpdateFollowUpArgEngineering":
			return getFollowUpArgEditableInterfaceObjectKeys(
				followUpArgEngineeringEditableModel
			);

		default:
			return [];
	}
}

export function fieldIsEditable(
	fieldName: string,
	fieldsAvailableForEditionByUser: Array<string>
) {
	return fieldsAvailableForEditionByUser.includes(fieldName);
}

export function fieldIsVisibleForOwner(
	userIsOwner: boolean,
	fieldIsEditable: boolean
) {
	return userIsOwner || fieldIsEditable;
}
