import React from "react";
import {
	InputAdornment,
	TextField,
	StandardTextFieldProps,
	Tooltip,
} from "@mui/material";
import { Edit, Info } from "@mui/icons-material";

type TextFormProps = StandardTextFieldProps & {
	editable?: boolean;
	validator?: (value: string, name?: string) => string;
	tooltip?: string;
	onEnter?: () => void;
	onChange?: (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		hasErrors?: boolean,
		error?: string
	) => void;
	isCurrency?: boolean;
};

const TextForm: React.FC<TextFormProps> = ({ editable, ...props }) => {
	if (props.tooltip)
		props.label = (
			<>
				{props.label}{" "}
				<Tooltip title={props.tooltip}>
					<Info sx={{ paddingTop: 1 }} />
				</Tooltip>{" "}
			</>
		);

	return (
		<>{editable ? <EditableForm {...props} /> : <ReadOnlyForm {...props} />}</>
	);
};

const ReadOnlyForm: React.FC<TextFormProps> = ({
	validator,
	tooltip,
	onEnter,
	onChange,
	isCurrency,
	...props
}) => {
	return (
		<TextField
			InputProps={{
				readOnly: true,
				startAdornment: isCurrency ? (
					<InputAdornment position="start">$</InputAdornment>
				) : undefined,
			}}
			InputLabelProps={{
				shrink: true,
			}}
			variant="standard"
			fullWidth
			{...props}
		/>
	);
};

const EditableForm: React.FC<TextFormProps> = ({
	validator,
	tooltip,
	onEnter,
	onChange,
	isCurrency,
	...props
}) => {
	let errorMessage = validator
		? validator(props.value as string, props.name)
		: "";

	const onKeyEnter = (e: React.KeyboardEvent) => {
		if (onEnter && e.key === "Enter") onEnter();
	};

	const onChangeHandler = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		if (!onChange) return undefined;
		if (!validator) return onChange(e);
		const error = validator(e.target.value, props.name);
		errorMessage = error;
		return onChange(e, Boolean(error), error);
	};

	return (
		<TextField
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Edit />
					</InputAdornment>
				),
				startAdornment: isCurrency ? (
					<InputAdornment position="start">$</InputAdornment>
				) : undefined,
			}}
			InputLabelProps={{
				shrink: true,
			}}
			variant="standard"
			error={Boolean(errorMessage)}
			helperText={errorMessage}
			fullWidth
			onKeyUp={onEnter ? onKeyEnter : () => {}}
			onChange={(e) => onChangeHandler(e)}
			{...props}
		/>
	);
};

export default TextForm;
