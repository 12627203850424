import React from "react";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	MenuProps,
	Select,
	SelectProps,
} from "@mui/material";
import { Edit } from "@mui/icons-material";

type MenuItemProps = {
	label: string;
	value: any;
};

type SelectFormProps = SelectProps & {
	options: Array<MenuItemProps>;
	editable?: boolean;
	hasEmptyDefaultItem?: boolean;
	hasEditIcon?: boolean;
	validator?: (value: string) => string;
	defaultItem?: MenuItemProps;
	MenuProps?: Partial<MenuProps>;
};

const SelectForm: React.FC<SelectFormProps> = ({ editable, ...props }) => {
	return (
		<>{editable ? <EditableForm {...props} /> : <ReadOnlyForm {...props} />}</>
	);
};

const ReadOnlyForm: React.FC<SelectFormProps> = ({
	validator,
	defaultItem,
	options,
	hasEmptyDefaultItem,
	hasEditIcon,
	...props
}) => {
	return (
		<FormControl variant="standard" fullWidth>
			<InputLabel id={props.labelId} shrink>
				{props.label}
			</InputLabel>
			<Select inputProps={{ readOnly: true }} {...props}>
				{defaultItem && (
					<MenuItem value={defaultItem.value}>{defaultItem.label}</MenuItem>
				)}
				{options.map((option: MenuItemProps) => (
					<MenuItem value={option.value} key={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const EditableForm: React.FC<SelectFormProps> = ({
	validator,
	defaultItem,
	options,
	hasEmptyDefaultItem,
	hasEditIcon,
	...props
}) => {
	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			},
		},
	};
	const errorMessage =
		validator && props.value ? validator(props.value as string) : "";
	const hasError = Boolean(errorMessage);
	const emptyDefaultItem = hasEmptyDefaultItem
		? { label: "", value: "" }
		: defaultItem;
	const EditIcon = () => (
		<Edit sx={{ transform: "none", color: "rgba(0, 0, 0, 0.54)" }} />
	);
	return (
		<FormControl variant="standard" fullWidth error={hasError}>
			<InputLabel id={props.labelId} shrink>
				{props.label}
			</InputLabel>
			<Select
				IconComponent={hasEditIcon ? EditIcon : undefined}
				MenuProps={MenuProps}
				{...props}
			>
				{emptyDefaultItem && (
					<MenuItem value={emptyDefaultItem.value}>
						{emptyDefaultItem.label}
					</MenuItem>
				)}
				{options?.map((option: MenuItemProps) => (
					<MenuItem
						value={option.value}
						key={`item-${props.labelId}-${option.value}`}
					>
						{option.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{errorMessage}</FormHelperText>
		</FormControl>
	);
};

export default SelectForm;
