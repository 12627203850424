import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./HomePageGrid";
import { useStores } from "../stores/RootStore";
import { FOLLOWUP_MENU_ARG_BUS } from "../../../../constants/Menus/FollowUpMenuUtils";
import HomePageCsvExport from "./HomePageCsvExport";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import {
	groupsToBatchEditFollowUpArg,
	groupsToExportOrderCsvFollowUpArg,
} from "../../../../constants/AuthGroups/followUpArgPage";
import FollowUpArgBatchEditionModal from "./BatchEdition/FollowUpArgBatchEditionModal";
import SummaryBus from "./Summary/SummaryBus";
import PreFilters from "./PreFilters/PreFilters";
import { getFollowUpGridColumns } from "../../../../utils/ArgGuidUtils";
import { GetBreadCrumbs } from "../utils/BreadCrumbs";

const BusIndex: React.FC = () => {
	const { t } = useTranslation(["order", "menu"]);
	const { setMenu, menu, reset } = useStores().listStore;
	const { isAuthorizedGroup } = useAuthStore();
	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";
	const columns = getFollowUpGridColumns(t, language, menu);
	const isAllowedToExportCsv = isAuthorizedGroup(
		groupsToExportOrderCsvFollowUpArg
	);
	const userCanDoBatchEdition = isAuthorizedGroup(groupsToBatchEditFollowUpArg);
	const fetch = React.useCallback(async () => {
		reset();
		setMenu(FOLLOWUP_MENU_ARG_BUS);
	}, [reset, setMenu]);

	React.useEffect(() => {
		fetch();
	}, [fetch]);

	const breadcrumbs = GetBreadCrumbs(t, "bus");

	return (
		<Grid container spacing={2}>
			{menu === FOLLOWUP_MENU_ARG_BUS && (
				<>
					<Grid item xs={12}>
						<Box sx={{ display: "flex" }}>
							<TitleBox breadcrumbs={breadcrumbs} />
							{isAllowedToExportCsv && <HomePageCsvExport />}
						</Box>
					</Grid>
					{userCanDoBatchEdition && <FollowUpArgBatchEditionModal />}
					<PreFilters />
					<Grid item xs={12}>
						{columns && <HomePageGrid columns={columns} />}
					</Grid>
					<SummaryBus />
				</>
			)}
		</Grid>
	);
};

export default observer(BusIndex);
