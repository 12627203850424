import { observer } from "mobx-react";
import React from "react";
import { useVolvoDealerReadStore } from "../../stores/VolvoDealerReadStore";
import QuotaSharedReadGrid from "../../../shared/QuotaSharedReadGrid";

const VolvoDealerReadGrid: React.FC = () => {
	const {
		dealerQuotaProposal,
		dealerQuotaProposalTotal,
		hasFilterApplied,
		getQuotaProposalOrdersCsvByRegion,
	} = useVolvoDealerReadStore();

	return (
		<QuotaSharedReadGrid
			dealerQuotaProposal={dealerQuotaProposal}
			dealerQuotaProposalTotal={dealerQuotaProposalTotal}
			getQuotaProposal={getQuotaProposalOrdersCsvByRegion}
			hasFilterApplied={hasFilterApplied}
		></QuotaSharedReadGrid>
	);
};

export default observer(VolvoDealerReadGrid);
