import { GUID_EMPTY } from "../../../../../app/constants/config";
import { RegionsEditProps } from "../../interfaces/RegionsEditProps";
import markets from "./markets.json";

export const regionsEditModel: RegionsEditProps = {
	id: GUID_EMPTY,
	markets: markets[0],
	value: "",
	checked: false,
};
