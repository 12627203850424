import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { LocalizationInvoicesSectionProps } from "../../interfaces/Sections/LocalizationInvoicesSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { InvoiceLocalizationProps } from "../../interfaces/InvoiceLocalizationDetails";
import DateForm from "../../../../../components/FormInputs/DateForm";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";
import _ from "lodash";

const LocalizationInvoicesSection: React.FC<
	LocalizationInvoicesSectionProps
> = ({ readOnlyFields, loadLocalizationInvoices }) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);

	const fetchLocalizationInvoices = React.useCallback(async () => {
		await loadLocalizationInvoices(readOnlyFields.id).finally(() => setLoading(false));
	}, [loadLocalizationInvoices]);

	React.useEffect(() => {
		fetchLocalizationInvoices();
	}, [fetchLocalizationInvoices]);

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : readOnlyFields.localizationInvoices?.length > 0 ? (
				readOnlyFields.localizationInvoices.map(
					(invoice: InvoiceLocalizationProps, idx: number) => (
						<React.Fragment key={idx}>
							<Grid
								className="accordionSubTitle"
								item
								xs={12}
								key={`accordionSubTitle-${idx}`}
							>
								{t(
									`details.sections.localizationInvoice.${_.camelCase(invoice.invoiceOperationType)}Title`
								)}
							</Grid>
							<Grid key={`receiver-${idx}`} item xs={4}>
								<TextForm
									label={t("details.sections.localizationInvoice.receiver")}
									value={invoice.receiverName}
								/>
							</Grid>
							<Grid key={`exitNumber-${idx}`} item xs={4}>
								<TextForm
									label={t("details.sections.localizationInvoice.exitNumber")}
									value={invoice.exitNumber}
								/>
							</Grid>
							<Grid key={`exit-${idx}`} item xs={4}>
								<DateForm
									label={t("details.sections.localizationInvoice.exit")}
									value={invoice.exit}
								/>
							</Grid>
							<Grid key={`empty-${idx}`} item xs={4}></Grid>
							<Grid key={`returnNumber-${idx}`} item xs={4}>
								<TextForm
									label={t("details.sections.localizationInvoice.returnNumber")}
									value={invoice.returnNumber}
								/>
							</Grid>
							<Grid key={`return-${idx}`} item xs={4}>
								<DateForm
									label={t("details.sections.localizationInvoice.return")}
									value={invoice.return}
								/>
							</Grid>
						</React.Fragment>
					)
				)
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(LocalizationInvoicesSection);
