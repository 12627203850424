import axios from "axios";
import { BusService } from "../services/ctadmven/BusService";
import { DistrictService } from "../services/ctadmven/DistrictService";
import { LineService } from "../services/ctadmven/LineService";
import { OrderInvoiceInstructionService } from "../services/ctadmven/OrderInvoiceInstructionService";
import { OrderInvoiceService } from "../services/ctadmven/OrderInvoiceService";
import { OrderService } from "../services/ctadmven/OrderService";
import { RegionService } from "../services/ctadmven/RegionService";
import { ExportPartnerService } from "../services/ctadmven/ExportPartnerService";
import { IQuotaService, QuotaService } from "../services/ctadmven/QuotaService";
import { EuropeFactoryService } from "../services/ctadmven/EuropeFactoryService";
import { VolvoManagementService } from "../services/ctadmven/VolvoManagementService";
import { ParametersService } from "../services/ctadmven/ParametersService";
import { HomeService } from "../services/ctadmven/HomeService";
import { VariantService } from "../services/ctadmven/VariantService";
import { FirmPeriodService } from "../services/ctadmven/FirmPeriodService";
import { OrderUnitStatusService } from "../services/ctadmven/OrderUnitStatusService";
import { DealerService } from "../services/ctadmven/DealerService";
import { QuotationService } from "../services/ctadmven/QuotationService";
import { VehicleLocationService } from "../services/ctadmven/VehicleLocationService";
import { FollowUpArgService } from "../services/ctadmven/FollowUpArgService";
import { FollowUpChlService } from "../services/ctadmven/FollowUpChlService";
import { SalesmanService } from "../services/ctadmven/SalesmanService";
import { PaymentTermsService } from "../services/ctadmven/PaymentTermsService";
import { ClosedPeriodService } from "../services/ctadmven/ClosedPeriodService";
import { CustomerService } from "../services/ctadmven/CustomerService";
import { QuotationLogService } from "../services/ctadmven/QuotationLogService";
import {
	IPaqueteService,
	PaqueteService,
} from "../services/ctadmven/PaqueteService";
import {
	CsvQueueManagerService,
	ICsvQueueManagerService,
} from "../services/ctadmven/CsvQueueManagerService";

export default class CtadmvenApi {
	ctadmvenClient = axios.create({
		baseURL: process.env.REACT_APP_APIM_ENDPOINT,
		headers: { "Content-type": "application/json", Accept: "application/json" },
	});

	//TODO use interface for each service
	busService: BusService;
	csvQueueManagerService: ICsvQueueManagerService;
	districtService: DistrictService;
	lineService: LineService;
	orderService: OrderService;
	orderInvoiceService: OrderInvoiceService;
	orderInvoiceInstructionService: OrderInvoiceInstructionService;
	regionService: RegionService;
	exportPartnerService: ExportPartnerService;
	quotaService: IQuotaService;
	europeFactoryService: EuropeFactoryService;
	volvoManagementService: VolvoManagementService;
	parametersService: ParametersService;
	homeService: HomeService;
	variantService: VariantService;
	firmPeriodService: FirmPeriodService;
	orderUnitStatusService: OrderUnitStatusService;
	dealerService: DealerService;
	vehicleLocationService: VehicleLocationService;
	quotationService: QuotationService;
	followUpArgService: FollowUpArgService;
	followUpChlService: FollowUpChlService;
	salesmanService: SalesmanService;
	paymentTermsService: PaymentTermsService;
	closedPeriodService: ClosedPeriodService;
	customerService: CustomerService;
	quotationLogService: QuotationLogService;
	paqueteService: IPaqueteService;

	constructor() {
		this.ctadmvenClient.interceptors.request.use(
			async (cfg) => {
				if (!cfg || !cfg.headers) {
					throw new axios.Cancel("Operation canceled by the user.");
				}
				const token = localStorage.getItem("webApiAccessToken");

				cfg.headers["Authorization"] = `Bearer ${token}`;
				cfg.headers["Ocp-Apim-Subscription-Key"] =
					process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY ?? "";

				return cfg;
			},
			function (error) {
				return Promise.reject(error);
			}
		);

		this.busService = new BusService(this);
		this.csvQueueManagerService = new CsvQueueManagerService(this);
		this.districtService = new DistrictService(this);
		this.lineService = new LineService(this);
		this.orderService = new OrderService(this);
		this.orderInvoiceService = new OrderInvoiceService(this);
		this.orderInvoiceInstructionService = new OrderInvoiceInstructionService(
			this
		);
		this.regionService = new RegionService(this);
		this.exportPartnerService = new ExportPartnerService(this);
		this.quotaService = new QuotaService(this);
		this.europeFactoryService = new EuropeFactoryService(this);
		this.volvoManagementService = new VolvoManagementService(this);
		this.volvoManagementService = new VolvoManagementService(this);
		this.parametersService = new ParametersService(this);
		this.homeService = new HomeService(this);
		this.variantService = new VariantService(this);
		this.firmPeriodService = new FirmPeriodService(this);
		this.orderUnitStatusService = new OrderUnitStatusService(this);
		this.dealerService = new DealerService(this);
		this.vehicleLocationService = new VehicleLocationService(this);
		this.quotationService = new QuotationService(this);
		this.followUpArgService = new FollowUpArgService(this);
		this.followUpChlService = new FollowUpChlService(this);
		this.salesmanService = new SalesmanService(this);
		this.paymentTermsService = new PaymentTermsService(this);
		this.closedPeriodService = new ClosedPeriodService(this);
		this.customerService = new CustomerService(this);
		this.quotationLogService = new QuotationLogService(this);
		this.paqueteService = new PaqueteService(this);
	}
}
