import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import ClearRecordItem from "../../../../../components/BatchEdition/ClearRecordItem";
import { orderKeyLabels } from "../../utils/BatchEditionUtils";

const VolvoManagementGeneralCommentContent: React.FC = () => {
	const { t } = useTranslation("order");

	const { batchData, setVolvoManagementGeneralComment } =
		useStores().batchEditionStore;

	return (
		<Grid item xs={12}>
			<ClearRecordItem
				recordTitle={t(orderKeyLabels.generalComment.label)}
				checked={batchData.generalComment.clearValue}
				onClearRecord={setVolvoManagementGeneralComment}
			>
				<TextForm
					label={t(orderKeyLabels.generalComment.label)}
					editable
					value={batchData.generalComment.value}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500 }}
					onChange={(e) =>
						setVolvoManagementGeneralComment({
							value: e.target.value,
							clearValue: false,
						})
					}
					disabled={batchData.generalComment.clearValue}
					helperText={t("details.sections.generalComments.warningText")}
				/>
			</ClearRecordItem>
		</Grid>
	);
};

export default observer(VolvoManagementGeneralCommentContent);
