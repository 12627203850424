import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Typography } from "@mui/material";
import { GeneralCommentsSectionProps } from "../../interfaces/Sections/GeneralCommentsSection";
import TextForm from "../../../../../components/FormInputs/TextForm";

const GeneralCommentsSection: React.FC<GeneralCommentsSectionProps> = ({
	editable,
	setFieldByKeyOnChange,
	generalComment,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.generalComments.title")}
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.generalComments.title")}
					editable={editable}
					value={generalComment}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500 }}
					onChange={(e) =>
						setFieldByKeyOnChange("generalComment", e.target.value)
					}
				/>
			</Grid>
			{editable && (
				<Grid item xs={12}>
					<Typography variant="subtitle2" color="red">
						<small>{t("details.sections.generalComments.warningText")}</small>
					</Typography>
				</Grid>
			)}
		</>
	);
};

export default observer(GeneralCommentsSection);
