export class StoreUtils {
    static async LoadData<T>(
        serviceMethod: (...args: any[]) => Promise<{ data: T }>,
        setter: (data: T | null) => void,
        ...args: any[]
    ): Promise<void> {
        if (!this.areValidArgs(args)) {
            return;
        }

        await serviceMethod().then((response) => {
            setter(response.data ?? null);
        });
    };

    private static areValidArgs = (args: any[]): boolean =>
        args && !!args.length && !args.some(arg => arg == null || (typeof arg === 'string' && arg.trim() === '') || (typeof arg === 'number' && arg === 0))
}