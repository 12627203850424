import {
	CTADMVEN_CLOSED_PERIOD_ARG_TRUCK,
	CTADMVEN_CLOSED_PERIOD_ARG_BUS,
} from "../../constants/routes";
import { MenuRouteProps } from "../../utils/GenericInterfaces";
import { AuthGroupsType } from "../../constants/AuthGroups/authGroups";
import { AppRouteProps } from "../routes";
import _ from "lodash";
import { TFunction } from "i18next";
import TruckIndex from "./List/containers/TruckIndex";
import BusIndex from "./List/containers/BusIndex";
import ClosedPeriodBusDetailIndex from "./Details/containers/ClosedPeriodBusDetailIndex";
import ClosedPeriodTruckDetailIndex from "./Details/containers/ClosedPeriodTruckDetailIndex";
import { groupsAllowedToSeeClosedPeriodArg } from "../../constants/AuthGroups/closedPeriodArgPage";
import {
	getMenuAuthorized,
	getRouteListAndDetails,
} from "../../utils/RoutesUtil";

export const closedPeriodArgAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeClosedPeriodArg,
]);

const closedPeriodTruckRoute: MenuRouteProps = {
	path: CTADMVEN_CLOSED_PERIOD_ARG_TRUCK,
	claims: groupsAllowedToSeeClosedPeriodArg,
	element: <TruckIndex />,
	label: "truck",
	isCollapsable: true,
};

const closedPeriodBusRoute: MenuRouteProps = {
	path: CTADMVEN_CLOSED_PERIOD_ARG_BUS,
	claims: groupsAllowedToSeeClosedPeriodArg,
	element: <BusIndex />,
	label: "bus",
	isCollapsable: true,
};

export const closedPeriodArgRoutes: Array<AppRouteProps> = [
	...getRouteListAndDetails(
		closedPeriodTruckRoute,
		<ClosedPeriodTruckDetailIndex />
	),
	...getRouteListAndDetails(
		closedPeriodBusRoute,
		<ClosedPeriodBusDetailIndex />
	),
];

export function getClosedPeriodArgMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean
) {
	return (
		<>
			{getMenuAuthorized(closedPeriodTruckRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(closedPeriodBusRoute, t, isAuthorizedGroup)}
		</>
	);
}
