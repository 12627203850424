import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { getExportationStatusOptions } from "../../../../../../utils/SelectOptions";
import { ReservationExportSectionProps } from "../../../interfaces/Sections/Europe/ReservationExportSection";
import BaseReservationExportSection from "../BaseReservationExportSection";

const ReservationExportSection: React.FC<ReservationExportSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation(["order", "general"]);

	const exportationOptions = getExportationStatusOptions(t).filter(
		(x) => x.value !== "InvoicedMC"
	);

	return (
		<BaseReservationExportSection readOnlyFields={readOnlyFields} exportationOptions={exportationOptions} />
	);
};

export default observer(ReservationExportSection);
