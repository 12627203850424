import RootStore from "../../../stores/RootStore";
import { configure } from "mobx";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import BasePreFilterDateStore from "../../../../../../components/PreFilter/Stores/BasePreFilterDateStore";

configure({ enforceActions: "always" });

class PreFilterShippingDateStore extends BasePreFilterDateStore {
	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	getSpecificFilter(): any {
		return this.preFilter && this.preFilter.endDate
			? [
				getFilterObject(
					"managementDetail.shippingDate",
					this.preFilter?.startDate,
					"gte"
				),
				getFilterObject(
					"managementDetail.shippingDate",
					this.preFilter?.endDate,
					"lte"
				),
			]
			: this.preFilter && this.preFilter.startDate
				? [
					getFilterObject(
						"managementDetail.shippingDate",
						this.preFilter?.startDate,
						"eq"
					),
				]
				: [];
	}
}

export default PreFilterShippingDateStore;
