import React from "react";

import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextPreFilter from "../components/TextPreFilter";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";

const BCVEHICLE_PRE_FILTER_XS = 6;
const BCVEHICLE_PRE_FILTER_SM = 6;
const BCVEHICLE_PRE_FILTER_MD = 4;
const BCVEHICLE_PRE_FILTER_LG = 4;
const BCVEHICLE_PRE_FILTER_XL = 2;

const BusinessControlVehiclePreFilter: React.FC<TextPreFilterProps> = (
	props
) => {
	const { t } = useTranslation("order");
	const {
		id = "bc_vehiclePreFilter",
		label = "preFilters.bc_vehicle",
		maxLength = 51,
		xs = BCVEHICLE_PRE_FILTER_XS,
		sm = BCVEHICLE_PRE_FILTER_SM,
		md = BCVEHICLE_PRE_FILTER_MD,
		lg = BCVEHICLE_PRE_FILTER_LG,
		xl = BCVEHICLE_PRE_FILTER_XL,
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			maxLength={maxLength}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(BusinessControlVehiclePreFilter);
