import { ReservationExportProps } from "../interfaces/ReservationExportDetails";

export const reservationExportModel: ReservationExportProps = {
	exportationStatus: "Production",
	available: false,
	quotationNumber: "",
	availableDate: null,
	reserved: false,
	reservationCreationDate: null,
};
