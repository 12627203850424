import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useExportPartnerStore } from "../stores/ExportPartnerStore";
import { DialogActions, DialogContent, Grid } from "@mui/material";
import TextForm from "../../../components/FormInputs/TextForm";
import { GUID_EMPTY } from "../../../app/constants/config";
import { exportPartnerModel } from "../model/ExportPartnerModel";
import DateForm from "../../../components/FormInputs/DateForm";
import { Save } from "@mui/icons-material";
import DialogForm from "../../../components/Dialog/DialogForm";
import CancelButton from "../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../components/Dialog/Buttons/ApplyButton";

type ExportPartnerFormProps = {
	actionLabel: string;
};

const ExportPartnerForm: React.FC<ExportPartnerFormProps> = ({
	actionLabel,
}) => {
	const {
		allRecords,
		createOrUpdate,
		setLoading,
		setFormVisible,
		formVisible,
		dataItemChanged,
		setDataItemChangedFieldByKey,
		setDataItemChanged,
	} = useExportPartnerStore();
	const { t } = useTranslation(["general", "exportPartner"]);

	const toggleDialog = () => {
		setFormVisible(!formVisible);
	};

	const handleSubmit = () => {
		if (actionLabel === "addRecord") dataItemChanged.id = GUID_EMPTY;

		createOrUpdate(dataItemChanged)
			.then(() => {
				allRecords();
				toast.success(t(`${actionLabel}WithSuccess`).toString());
			})
			.catch((err) => toast.error(err.response.data))
			.finally(() => {
				cleanForm();
				setLoading(false);
			});
		setFormVisible(!formVisible);
	};

	const cleanForm = () => {
		setDataItemChanged(exportPartnerModel);
	};

	const handleClose = () => {
		cleanForm();
		toggleDialog();
	};

	return (
		<DialogForm
			id="dialog-exp-partner-form"
			open={formVisible}
			title={t(`${actionLabel}`, { name: t("exportPartner") })}
			onClose={handleClose}
		>
			<DialogContent>
				<Grid container spacing={2} overflow="none">
					<Grid item xs>
						<TextForm
							editable
							id={"customerCdb"}
							label={t("customerCDB")}
							value={dataItemChanged?.customerCDB}
							onChange={(e) =>
								setDataItemChangedFieldByKey("customerCDB", e.target.value)
							}
							type="number"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							editable
							id={"customerName"}
							label={t("customer")}
							value={dataItemChanged?.customerName}
							onChange={(e) =>
								setDataItemChangedFieldByKey("customerName", e.target.value)
							}
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							editable
							id={"exportPartnerCDB"}
							label={t("exportPartnerCDB")}
							value={dataItemChanged?.cdb}
							onChange={(e) =>
								setDataItemChangedFieldByKey("cdb", e.target.value)
							}
							type="number"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							editable
							id={"exportPartner"}
							label={t("exportPartner")}
							value={dataItemChanged?.name}
							onChange={(e) =>
								setDataItemChangedFieldByKey("name", e.target.value)
							}
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
					<Grid item xs>
						<DateForm
							editable
							label={t("startDate")}
							inputFormat="dd/MM/yyyy"
							value={dataItemChanged?.startDate}
							onChange={(e: any) =>
								setDataItemChangedFieldByKey("startDate", e)
							}
						/>
					</Grid>
					<Grid item xs>
						<DateForm
							editable
							label={t("endDate")}
							inputFormat="dd/MM/yyyy"
							value={dataItemChanged?.endDate}
							onChange={(e: any) => setDataItemChangedFieldByKey("endDate", e)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<CancelButton
					id="dialog-exp-partner-form"
					onCancel={handleClose}
					title={t("cancel")}
				/>
				<ApplyButton
					id="dialog-exp-partner-form"
					onSubmit={handleSubmit}
					title={t("save")}
					startIcon={<Save />}
				/>
			</DialogActions>
		</DialogForm>
	);
};

export default observer(ExportPartnerForm);
