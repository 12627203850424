import React from "react";
import { observer } from "mobx-react";
import { Dialog, DialogProps } from "@mui/material";
import DialogTitleClosable from "./DialogTitleClosable";

type DialogFormProps = DialogProps & {
	id: string;
	open: boolean;
	title: string;
	onClose: (() => void) | ((e: any) => void);
	onEnterSubmit?: () => void;
	children: React.ReactNode;
};

const DialogForm: React.FC<DialogFormProps> = ({
	id,
	open,
	title,
	onClose,
	onEnterSubmit,
	children,
	...props
}) => {
	const handleKeyEnter = (e: React.KeyboardEvent) => {
		if (!onEnterSubmit || e.key !== "Enter") return;
		onEnterSubmit();
	};

	return (
		<Dialog
			onClose={onClose}
			onKeyUp={handleKeyEnter}
			aria-labelledby={id}
			open={open}
			scroll="paper"
			sx={{
				alignItems: "flex-start",
			}}
			{...props}
		>
			<DialogTitleClosable id={id} onClose={onClose}>
				<strong>{title}</strong>
			</DialogTitleClosable>
			{children}
		</Dialog>
	);
};

export default observer(DialogForm);
