import React, { ReactNode } from "react";
import {
	IntlProvider,
	loadMessages,
	LocalizationProvider as KendoLocalizationProvider,
} from "@progress/kendo-react-intl";
import { load } from "@progress/kendo-intl";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import portuguese from "./kendoLocales/pt";
import spanish from "./kendoLocales/es";
//kendo others
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
//kendo numbers
import ptNumbers from "cldr-numbers-full/main/pt/numbers.json";
import ptCurrencies from "cldr-numbers-full/main/pt/currencies.json";
import esNumbers from "cldr-numbers-full/main/es/numbers.json";
import esCurrencies from "cldr-numbers-full/main/es/currencies.json";
//kendo dates
import ptCaGregorian from "cldr-dates-full/main/pt/ca-gregorian.json";
import ptDateFields from "cldr-dates-full/main/pt/dateFields.json";
import ptTimeZoneNames from "cldr-dates-full/main/pt/timeZoneNames.json";
import esCaGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import esDateFields from "cldr-dates-full/main/es/dateFields.json";
import esTimeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
//material
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider as MaterialLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR as ptBRLocale } from "date-fns/locale/pt-BR";
import { es as enESLocale } from "date-fns/locale/es";
import { enUS as enUSLocale } from "date-fns/locale/en-US";

load(
	likelySubtags,
	currencyData,
	weekData,
	ptNumbers,
	ptCurrencies,
	esNumbers,
	esCurrencies,
	ptCaGregorian,
	ptDateFields,
	ptTimeZoneNames,
	esCaGregorian,
	esDateFields,
	esTimeZoneNames
);

type OthersProviderProps = {
	children: ReactNode;
};

const getLocale = (language: string) => {
	const localeMap = [
		{
			localeName: "en-US",
			locale: enUSLocale,
		},
		{
			localeName: "pt-BR",
			locale: ptBRLocale,
		},
		{
			localeName: "es-ES",
			locale: enESLocale,
		},
	];
	return localeMap.find((f) => f.localeName === language)?.locale ?? enUSLocale;
};

const OthersProvider: React.FC<OthersProviderProps> = ({ children }) => {
	const { i18n } = useTranslation();

	const language = Boolean(i18n.language) ? i18n.language : "en-US";
	const locale = Boolean(i18n.language) ? i18n.language.substring(0, 2) : "en";

	loadMessages(portuguese["kendoMessages"], "pt-BR");
	loadMessages(spanish["kendoMessages"], "es-ES");

	return (
		<MaterialLocalizationProvider
			dateAdapter={AdapterDateFns}
			adapterLocale={getLocale(language)}
		>
			<KendoLocalizationProvider language={language}>
				<IntlProvider locale={locale}>{children}</IntlProvider>
			</KendoLocalizationProvider>
		</MaterialLocalizationProvider>
	);
};

export default observer(OthersProvider);
