import { ProductionWeekCalendarProps } from "../interfaces/ProductionWeekCalendarProps";

export const productionWeekCalendarModel: ProductionWeekCalendarProps = {
	endDate: new Date(),
	initialDate: new Date(),
	monthPeriod: 6,
	productionLine: "F",
	calendarWeeks: [],
	userEmail: "",
	logEvent: {},
};
