import React from "react";
import AccordionCard, {
	AccordionCardProps,
} from "../AccordionCard/AccordionCard";
import PreFilterEditionActions, { PreFilterEditionActionsProps } from "./PreFilterEditionActions";
import AppliedFiltersChips from "./AppliedFiltersChips";

interface PreFilterEditionProps
	extends AccordionCardProps,
		PreFilterEditionActionsProps {}

const PreFilter: React.FC<PreFilterEditionProps> = (props) => {
	const summaryBody = props.hasPreFiltersApplied 
		? <AppliedFiltersChips 
				filters={props.filters} 
				chassiType={props.chassiType} 
				removeFilter={props.removeFilter} 
				page={props.page}/> 
		: null;
		
	return (
		<AccordionCard
			{...props}
			summaryBody={summaryBody}
			actions={<PreFilterEditionActions {...props} />}
		/>
	);
};

export default PreFilter;
