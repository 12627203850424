import RootStore from "../stores/RootStore";

export function newRootStore(): RootStore {
	return {
		filterStore: {},
		listStore: {},
		batchEditionStore: {},
		preFilterChassiAOStore: {},
		preFilterLineStore: {},
		preFilterDeliveryProposalStore: {},
		preFilterMonthAvailabilityStore: {},
		preFilterBusApplicationStore: {},
		preFilterRegionStore: {},
		preFilterCountryStore: {},
		preFilterAsWeekStore: {},
		preFilterCommercialModelStore: {},
		preFilterCustomerStoreOM: {},
		preFilterCustomerStoreSAS: {},
		preFilterLineSequenceDateStore: {},
	};
}
