import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import DateRangePreFilter from "../components/DateRangePreFilter";
import { DateRangePreFilterProps } from "../Interfaces/DateRangePreFilterProps";

const ShippingDatePreFilter: React.FC<DateRangePreFilterProps> = ({
	startDate,
	endDate,
	preFilterStore,
	xs = 6,
	labelEnd = "preFilters.shippingDateEnd",
	label = "preFilters.shippingDate",
	id = "shippingDatePreFilter",
}) => {
	const { t } = useTranslation("order");

	return (
		<DateRangePreFilter
			startDate={startDate}
			endDate={endDate}
			preFilterStore={preFilterStore}
			xs={xs}
			labelEnd={t(labelEnd)}
			label={t(label)}
			id={id}
		/>
	);
};

export default observer(ShippingDatePreFilter);
