import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { fieldIsEditable } from "../../utils/EditableFieldsUtils";
import { EngineeringCommentsSectionProps } from "../../interfaces/Sections/EngineeringCommentsSection";

const EngineeringCommentsSection: React.FC<EngineeringCommentsSectionProps> = ({
	fieldsAvailableForEdition,
	setOrderMCManagementFieldByKeyOnChange,
	engineeringComments,
}) => {
	const { t } = useTranslation("order");
	const editable = fieldIsEditable(
		"engineeringComments",
		fieldsAvailableForEdition
	);
	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.engineeringComments")}
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.engineeringComments")}
					editable={editable}
					value={engineeringComments}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500, style: { color: "red" } }}
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"engineeringComments",
							e.target.value
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(EngineeringCommentsSection);
