import React from "react";
import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemText,
	MenuItem,
	MenuProps,
	Select,
	SelectChangeEvent,
	SelectProps,
} from "@mui/material";
import { Edit } from "@mui/icons-material";

type MenuItemProps = {
	label: string;
	value: string;
};

type MultiSelectFormProps = SelectProps & {
	options: Array<MenuItemProps>;
	editable?: boolean;
	hasEmptyDefaultItem?: boolean;
	hasEditIcon?: boolean;
	validator?: (value: Array<string>) => string;
	defaultItem?: MenuItemProps;
	MenuProps?: Partial<MenuProps>;
	value: Array<string>;
	onChange?: (newValues: Array<string>) => any;
};

const MultiSelectForm: React.FC<MultiSelectFormProps> = ({
	editable,
	...props
}) => {
	return (
		<>{editable ? <EditableForm {...props} /> : <ReadOnlyForm {...props} />}</>
	);
};

const ReadOnlyForm: React.FC<MultiSelectFormProps> = ({
	validator,
	defaultItem,
	options,
	hasEmptyDefaultItem,
	hasEditIcon,
	...props
}) => {
	return (
		<FormControl variant="standard" fullWidth>
			<InputLabel id={props.labelId} shrink>
				{props.label}
			</InputLabel>
			<Select inputProps={{ readOnly: true }} {...props}>
				{options.map((option: MenuItemProps) => (
					<MenuItem value={option.value} key={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const EditableForm: React.FC<MultiSelectFormProps> = ({
	validator,
	defaultItem,
	options,
	hasEmptyDefaultItem,
	hasEditIcon,
	value,
	...props
}) => {
	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			},
		},
	};

	const errorMessage = validator && value ? validator(value) : "";
	const hasError = Boolean(errorMessage);
	const emptyDefaultItem = hasEmptyDefaultItem
		? { label: "", value: options.map((m) => m.value).join(",") }
		: defaultItem;
	const EditIcon = () => (
		<Edit sx={{ transform: "none", color: "rgba(0, 0, 0, 0.54)" }} />
	);

	const handleChangeDefaultArray = (targetValue: any) => {
		const key = "defaultArray";
		if (targetValue && targetValue.length === 0) return targetValue;

		const defaultOption = targetValue.find((f: string) => f.includes(key));
		if (defaultOption && targetValue.length > options.length) return [];

		if (defaultOption)
			return defaultOption.substring(
				defaultOption.lastIndexOf(key) + key.length,
				defaultOption.length
			);
		return targetValue;
	};

	const handleChange = (event: SelectChangeEvent<unknown>, _: any) => {
		const targetValue = handleChangeDefaultArray(event.target.value);
		const newValue =
			typeof targetValue === "string" ? targetValue.split(",") : targetValue;

		if (props.onChange) props.onChange(newValue as Array<string>);
	};

	const handleRenderValue = (selected: any) =>
		options
			.filter((f) => selected.some((s: string) => s === f.value))
			.map((m) => m.label)
			.join(", ");

	return (
		<FormControl variant="standard" fullWidth error={hasError}>
			<InputLabel id={props.labelId} shrink>
				{props.label}
			</InputLabel>
			<Select
				IconComponent={hasEditIcon ? EditIcon : undefined}
				multiple
				value={value}
				renderValue={handleRenderValue}
				MenuProps={MenuProps}
				{...props}
				onChange={handleChange}
			>
				{emptyDefaultItem && (
					<MenuItem value={emptyDefaultItem.value}>
						<Checkbox
							checked={value.length === options.length}
							color="secondary"
						/>
						<ListItemText primary={emptyDefaultItem.label} />
					</MenuItem>
				)}
				{options?.map((option: MenuItemProps) => (
					<MenuItem
						value={option.value}
						key={`item-${props.labelId}-${option.value}`}
					>
						<Checkbox
							checked={value?.indexOf(option.value) > -1}
							color="secondary"
						/>
						<ListItemText primary={option.label} />
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{errorMessage}</FormHelperText>
		</FormControl>
	);
};

export default MultiSelectForm;
