import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { useStores } from "../../stores/RootStore";
import { orderKeyLabels } from "../../utils/BatchEditionUtils";

const QuotaContent: React.FC = () => {
	const { t } = useTranslation("order");

	const {
		districtsList,
		regionsList,
		loadDistrictsAndRegionsList,
		batchData,
		setQuotaDataByKey,
	} = useStores().batchEditionStore;

	const loadOptions = React.useCallback(() => {
		loadDistrictsAndRegionsList();
	}, [loadDistrictsAndRegionsList]);

	React.useEffect(() => {
		loadOptions();
	}, [loadOptions]);

	return (
		<>
			<Grid item xs={6}>
				<SelectForm
					label={t(orderKeyLabels.quotaDistrict.label)}
					labelId={`${orderKeyLabels.quotaDistrict.label}-label`}
					value={batchData.quotaData.quotaDistrict}
					options={districtsList}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setQuotaDataByKey(
							e.target.value as string,
							orderKeyLabels.quotaDistrict.key
						)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<SelectForm
					label={t(orderKeyLabels.quotaRegion.label)}
					labelId={`${orderKeyLabels.quotaRegion.label}-label`}
					value={batchData.quotaData.quotaRegion}
					options={regionsList}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setQuotaDataByKey(
							e.target.value as string,
							orderKeyLabels.quotaRegion.key
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(QuotaContent);
