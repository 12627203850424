import { observer } from "mobx-react";
import React from "react";
import { useExportReadStore } from "../stores/ExportReadStore";
import QuotaSharedReadGrid from "../../shared/QuotaSharedReadGrid";

const ExportReadGrid: React.FC = () => {
	const {
		dealerQuotaProposal,
		dealerQuotaProposalTotal,
		hasFilterApplied,
		getQuotaProposalOrdersCsvByRegion,
	} = useExportReadStore();

	return (
		<QuotaSharedReadGrid
			dealerQuotaProposal={dealerQuotaProposal}
			dealerQuotaProposalTotal={dealerQuotaProposalTotal}
			getQuotaProposal={getQuotaProposalOrdersCsvByRegion}
			hasFilterApplied={hasFilterApplied}
		></QuotaSharedReadGrid>
	);
};

export default observer(ExportReadGrid);
