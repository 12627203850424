import {
	CTADMVEN_QUOTA_DEALER_READ,
	CTADMVEN_QUOTA_VOLVO_READ,
	CTADMVEN_QUOTA_EDIT,
	CTADMVEN_QUOTA_VOLVO_DEALER_READ,
	CTADMVEN_QUOTA_EXPORT_READ,
} from "../../constants/routes";
import { AuthGroupsType } from "../../constants/AuthGroups/authGroups";
import { AppRouteProps } from "../routes";
import { TFunction } from "i18next";
import VolvoReadIndex from "./VolvoRead/containers/VolvoReadIndex";
import DealerReadIndex from "./DealerRead/containers/Dealer/DealerReadIndex";
import VolvoDealerReadIndex from "./DealerRead/containers/Volvo/VolvoDealerReadIndex";
import QuotaProposalEditPageIndex from "./Edit/container/EditIndex";
import {
	MenuCollapsableRouteProps,
	MenuRouteProps,
} from "../../utils/GenericInterfaces";
import {
	groupsAllowedToSeeDealerReadRoute,
	groupsAllowedToSeeEditQuotaMenu,
	groupsAllowedToSeeExportReadMenu,
	groupsAllowedToSeeVolvoDealerReadMenu,
	groupsAllowedToSeeVolvoReadMenu,
} from "../../constants/AuthGroups/quotaPages";
import _ from "lodash";
import { getMenuAuthorized, getMenu, getRoute } from "../../utils/RoutesUtil";
import ExportReadIndex from "./ExportRead/containers/ExportReadIndex";
import { TableChart } from "@mui/icons-material";

const quotaAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeVolvoReadMenu,
	...groupsAllowedToSeeEditQuotaMenu,
	...groupsAllowedToSeeDealerReadRoute,
	...groupsAllowedToSeeVolvoDealerReadMenu,
	...groupsAllowedToSeeExportReadMenu,
]);

//routes
export const quotaMenuCollapsableRoute: MenuCollapsableRouteProps = {
	claims: quotaAllClaims,
	label: "quota.title",
	icon: <TableChart />,
	getMenus: getQuotaMenus,
};

const volvoReadRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTA_VOLVO_READ,
	claims: groupsAllowedToSeeVolvoReadMenu,
	element: <VolvoReadIndex />,
	label: "quota.volvoRead",
	isCollapsable: true,
};

const editQuotaRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTA_EDIT,
	claims: groupsAllowedToSeeEditQuotaMenu,
	element: <QuotaProposalEditPageIndex />,
	label: "quota.edit",
	isCollapsable: true,
};

const dealerReadRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTA_DEALER_READ,
	claims: groupsAllowedToSeeDealerReadRoute,
	element: <DealerReadIndex />,
	label: "quota.dealerRead",
	isCollapsable: true,
};

const volvoDealerReadRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTA_VOLVO_DEALER_READ,
	claims: groupsAllowedToSeeVolvoDealerReadMenu,
	element: <VolvoDealerReadIndex />,
	label: "quota.dealerRead",
	isCollapsable: true,
};

const exportDealerReadRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTA_EXPORT_READ,
	claims: groupsAllowedToSeeExportReadMenu,
	element: <ExportReadIndex />,
	label: "quota.exportRead",
	isCollapsable: true,
};
export const quotaRoutes: Array<AppRouteProps> = [
	getRoute(volvoReadRoute),
	getRoute(editQuotaRoute),
	getRoute(dealerReadRoute),
	getRoute(volvoDealerReadRoute),
	getRoute(exportDealerReadRoute),
];

function getQuotaDealerMenuHigherRole(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean
) {
	if (isAuthorizedGroup(volvoDealerReadRoute.claims)) {
		return getMenu(volvoDealerReadRoute, t);
	}
	if (isAuthorizedGroup(dealerReadRoute.claims)) {
		return getMenu(dealerReadRoute, t);
	}
	return <></>;
}

function getQuotaMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean
) {
	return (
		<>
			{getMenuAuthorized(volvoReadRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(editQuotaRoute, t, isAuthorizedGroup)}
			{getQuotaDealerMenuHigherRole(t, isAuthorizedGroup)}
			{getMenuAuthorized(exportDealerReadRoute, t, isAuthorizedGroup)}
		</>
	);
}
