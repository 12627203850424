import CtadmvenApi from "../../apis/CtadmvenApi";

interface IOrderUnitStatusService {
	getLocalizationByOrderId(id: string): Promise<any>;
}
const base = "/JITIntegration";

export class OrderUnitStatusService implements IOrderUnitStatusService {
	constructor(private api: CtadmvenApi) {}

	getLocalizationByOrderId = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/localization/${id}`);
}
