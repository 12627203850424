import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { ModelSectionProps } from "../../../interfaces/Sections/Bus/ModelSection";
import { useAuthStore } from "../../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg } from "../../../../../../constants/AuthGroups/closedPeriodArgPage";

const ModelSection: React.FC<ModelSectionProps> = ({ readOnlyFields }) => {
	const { t } = useTranslation("order");

	const { isAuthorizedGroup } = useAuthStore();
	const shouldSeeAll = isAuthorizedGroup(
		groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg
	);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.model.title")}
			</Grid>
			{shouldSeeAll && (
				<Grid item xs={4}>
					<TextForm
						label={t("details.sections.model.productionModel")}
						value={readOnlyFields.productionModel}
					/>
				</Grid>
			)}
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.factoryYear")}
					value={readOnlyFields.factoryYear}
					type="number"
				/>
			</Grid>
			{shouldSeeAll && (
				<Grid item xs={4}>
					<TextForm
						label={t("details.sections.model.lineCode")}
						value={readOnlyFields.lineCode}
					/>
				</Grid>
			)}
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialModel")}
					value={readOnlyFields.commercialModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.modelYear")}
					value={readOnlyFields.modelYear}
					type="number"
				/>
			</Grid>
			{shouldSeeAll && (
				<Grid item xs={4}>
					<TextForm
						label={t("details.sections.model.class")}
						value={readOnlyFields.class}
					/>
				</Grid>
			)}
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.busApplication")}
					value={readOnlyFields.busApplication}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSection);
