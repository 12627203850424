import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";
import { OrderMCManagementEditableProps } from "../../pages/ClosedPeriodArg/Details/interfaces/Editable/OrderMCManagementEditable";
interface IClosedPeriodService {
	listPaginated(dataState: State): Promise<any>;
	listBusPaginated(dataState: State): Promise<any>;
	getDetails(id: string): Promise<any>;
	getDetailsBus(id: string): Promise<any>;
	getDetailsVariants(id: string): Promise<any>;
	getDetailsVariantsBus(id: string): Promise<any>;
	getDetailsMCDelivery(id: string): Promise<any>;
	getDetailsMCManagement(id: string): Promise<any>;
	openClosedPeriod(id: string): Promise<any>;
	csvExport(dataState: State, csvOptions: CsvOptionsProps): Promise<any>;
	csvExportBus(dataState: State, csvOptions: CsvOptionsProps): Promise<any>;
	update(data: OrderMCManagementEditableProps): Promise<any>;
}

const base = "/ClosedPeriod";

export class ClosedPeriodService implements IClosedPeriodService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list?${toDataSourceRequestString(dataState)}`
		);

	listBusPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/listBus?${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/details/${id}`);

	getDetailsBus = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/detailsBus/${id}`);

	getDetailsVariants = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variants/${id}`);

	getDetailsVariantsBus = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variantsBus/${id}`);

	getDetailsMCDelivery = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/mcDelivery/${id}`);

	getDetailsMCManagement = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/mcManagement/${id}`);

	openClosedPeriod = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/cancel/${id}`);

	csvExport = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	csvExportBus = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csvBus?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	update = async (data: OrderMCManagementEditableProps): Promise<any> =>
		this.api.ctadmvenClient.post(`${base}`, JSON.stringify(data));
}
