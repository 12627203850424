import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { useEditDistrictStore } from "../stores/EditDistrictStore";
import EditGrid from "./EditGrid";

const DistrictEditIndex: React.FC = () => {
	const { t } = useTranslation("general");
	const { loading, cleanData, districtEdit, fetchData } =
		useEditDistrictStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("district.edit", { ns: "menu" })}`,
			main: true,
		},
	];

	useEffect(() => {
		fetchData().catch((err) => toast.error(err.response.data));
	}, []);

	useEffect(() => {
		return () => {
			cleanData();
		};
	}, []);

	return (
		<>
			<LoadingPage loading={loading} />
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs} />
			</Grid>
			<Grid item lg={12}>
				{districtEdit && districtEdit.length > 0 && <EditGrid />}
			</Grid>
		</>
	);
};

export default observer(DistrictEditIndex);
