import { ExportLocalizationProps } from "../interfaces/ExportLocalizationDetails";

const exportLocalizationModel: ExportLocalizationProps = {
	thirdPartnerName: "",
	documentDate: null,
	facility: "",
};

export function exportLocalizationModelBuilder(data: ExportLocalizationProps) {
	if (!data) return exportLocalizationModel;
	return {
		...exportLocalizationModel,
		...data,
		thirdPartnerName: data.thirdPartnerName ?? "",
		documentDate: data.documentDate ?? null,
		facility: data.facility ?? "",
	} as ExportLocalizationProps;
}
