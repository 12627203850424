import * as React from "react";
import { Button } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";

interface DeleteCommandCellProps {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const DeleteCommandCell = (props: DeleteCommandCellProps) => {
	return (
		<td className="k-command-cell">
			<Button onClick={props.onClick} color={"secondary"}>
				<DeleteForever fontSize="small" />
			</Button>
		</td>
	);
};
