const DEALER_PROPOSAL_TYPE = "DEALER";
const VOLVO_PROPOSAL_TYPE = "VOLVO";
const BR_PROPOSAL_TYPE = "BR";
const EXP_PROPOSAL_TYPE = "EXP";
const ALL_PROPOSAL_TYPE = "ALL";

const AllProposalTypes = [
	DEALER_PROPOSAL_TYPE,
	VOLVO_PROPOSAL_TYPE,
	BR_PROPOSAL_TYPE,
	EXP_PROPOSAL_TYPE,
	ALL_PROPOSAL_TYPE,
];

export const ProposalTypesConsiderBR = [
	DEALER_PROPOSAL_TYPE,
	VOLVO_PROPOSAL_TYPE,
];

export const ProposalTypesConsiderALL = [
	DEALER_PROPOSAL_TYPE,
	VOLVO_PROPOSAL_TYPE,
	EXP_PROPOSAL_TYPE,
];

export const LINE_F_PROPOSAL_TYPES = AllProposalTypes;

export const LINE_VM_PROPOSAL_TYPES = AllProposalTypes;
