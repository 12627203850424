import { GUID_EMPTY } from "../../../../app/constants/config";
import { GlosaTextEditableProps } from "../interfaces/GlosaTextEditableProps";

export const glosaTextEditableModel: GlosaTextEditableProps = {
	id: GUID_EMPTY,
	glosa: "",
};

export function glosaTextEditableModelBuilder(
	data: any,
	id: string
): GlosaTextEditableProps {
	if (!data) return glosaTextEditableModel;

	return {
		id: id ?? GUID_EMPTY,
		glosa: data.glosa ?? "",
	};
}
