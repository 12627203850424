import { Stack, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";

interface IconTextProps {
	text: string | React.ReactNode;
	title: string;
	icon: React.ReactNode;
}

const IconText: React.FC<IconTextProps> = (props) => {
	return (
		<Tooltip title={props.title}>
			<Stack direction="row" spacing={1}>
				{props.icon}
				<Typography>{props.text}</Typography>
			</Stack>
		</Tooltip>
	);
};

export default observer(IconText);
