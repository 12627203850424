import { Grid, GridProps, Skeleton } from "@mui/material";
import React from "react";
import DraggableCard, {
	DraggableCardProps,
} from "../DraggableCard/DraggableCard";

export interface SummaryGridProps extends GridProps {
	value: string | number;
}

interface SummaryDraggableCardProps extends DraggableCardProps {
	headers: SummaryGridProps[];
	data: SummaryGridProps[];
	footerHeader: string;
	footerData: number | number[];
	loading: boolean;
	title?: string;
}

const SummaryDraggableCard: React.FC<SummaryDraggableCardProps> = ({
	id,
	width,
	headers,
	data,
	footerHeader,
	footerData,
	loading,
}) => {
	const GRID_XS = 12 / headers.length;
	const footerIsArray = Array.isArray(footerData);
	return (
		<DraggableCard id={id} width={width}>
			{loading ? (
				<Skeleton variant="rounded" width="100%" height={60} />
			) : (
				<Grid container spacing={1}>
					{headers.map((header, idx) => (
						<Grid item xs={GRID_XS} key={`header-${idx + GRID_XS}`} {...header}>
							<b>{header.value}</b>
						</Grid>
					))}
					{data.map((value, idx) => {
						return (
							<Grid item xs={GRID_XS} key={`value-${idx + GRID_XS}`} {...value}>
								{value.value}
							</Grid>
						);
					})}
					<Grid item xs={footerIsArray ? GRID_XS : 6}>
						<b>{footerHeader}</b>
					</Grid>
					{footerIsArray ? (
						footerData.map((value, idx) => (
							<Grid item xs={GRID_XS} key={`footer-${idx + GRID_XS}`}>
								{value}
							</Grid>
						))
					) : (
						<Grid item xs={6}>
							{footerData}
						</Grid>
					)}
				</Grid>
			)}
		</DraggableCard>
	);
};

export default SummaryDraggableCard;
