import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import { DateRangeFormProps } from "../../../../../../utils/GenericInterfaces";

configure({ enforceActions: "always" });

class PreFilterMonthAvailabilityStore {
	@observable rootStore;
	@observable
	preFilter?: DateRangeFormProps = undefined;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = undefined;
	};

	@action clean = () => (this.preFilter = undefined);

	@action get = () =>
		this.preFilter
			? [
					getFilterObject(
						"order.availabilityDate",
						this.preFilter?.startDate,
						"gte"
					),
					getFilterObject(
						"order.availabilityDate",
						this.preFilter?.endDate,
						"lte"
					),
				]
			: [];

	@action set = (monthAvailabilityFilter: DateRangeFormProps | undefined) =>
		(this.preFilter = monthAvailabilityFilter);

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const monthAvailabilityFilters = this.get();

		if (monthAvailabilityFilters)
			andFilters = andFilters.concat(monthAvailabilityFilters);

		return andFilters;
	}
}

export default PreFilterMonthAvailabilityStore;
