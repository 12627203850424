import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { useLineSpeedStore } from "../store/LineSpeedStore";
import { lineSpeedModel } from "../model/LineSpeedModel";
import { GUID_EMPTY } from "../../../../app/constants/config";
import { DialogActions, DialogContent, Grid } from "@mui/material";
import TextForm from "../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../components/FormInputs/SelectForm";
import { toJS } from "mobx";
import ApplyButton from "../../../../components/Dialog/Buttons/ApplyButton";
import CancelButton from "../../../../components/Dialog/Buttons/CancelButton";
import { Save } from "@mui/icons-material";
import DialogForm from "../../../../components/Dialog/DialogForm";

type LineSpeedFormProps = {
	actionLabel: string;
};

const LineSpeedForm: React.FC<LineSpeedFormProps> = ({ actionLabel }) => {
	const MIN_VALUE = -1;
	const MAX_VALUE = 100;
	const MAX_VALUE_LCD = 99;
	const MIN_VALUE_LCD = 1;
	const BUS_HDV_LCD_REQUIRED = "Bus HDV";

	const {
		allRecords,
		createOrUpdate,
		setLoading,
		setFormVisible,
		setDataItemChangedFieldByKey,
		setDataItemChanged,
		formVisible,
		dataItemChanged,
		data,
		lineOptions,
		isValidObject,
	} = useLineSpeedStore();

	const { t } = useTranslation("general");
	const [lcdIsRequired, setLcdIsRequired] = React.useState(false);

	React.useEffect(() => {
		if (formVisible) {
			if (
				dataItemChanged !== undefined &&
				dataItemChanged?.line?.name === "HDV" &&
				dataItemChanged?.line?.vehicleType === "Bus"
			) {
				setLcdIsRequired(true);
				return;
			}

			setLcdIsRequired(false);
		}
	}, [formVisible, dataItemChanged]);

	const toggleDialog = () => {
		setFormVisible(!formVisible);
	};

	const cleanForm = () => {
		setDataItemChanged(lineSpeedModel);
	};

	const handleClose = () => {
		cleanForm();
		toggleDialog();
	};

	const handleSubmit = (evt: any) => {
		if (actionLabel === "addRecord") {
			dataItemChanged.id = GUID_EMPTY;
			dataItemChanged.line = null;
		}

		createOrUpdate(dataItemChanged)
			.then(() => {
				allRecords();
				if (actionLabel === "addRecord")
					toast.success(t(`addRecordWithSuccess`).toString());
				else toast.success(t(`updatedRecordWithSuccess`).toString());
			})
			.catch((err) => toast.error(err.response.data))
			.finally(() => {
				cleanForm();
				setLoading(false);
			});
		setFormVisible(!formVisible);
	};

	const requiredValidator = (value: any) =>
		value ? "" : t("validator.fieldRequired");

	const speedValidator = function (value: any) {
		return !(value > MIN_VALUE && value < MAX_VALUE)
			? t("validator.middleNumber", {
					max: MAX_VALUE,
					min: MIN_VALUE,
				})
			: "";
	};

	const lcdValidator = (value: any) =>
		!(value >= MIN_VALUE_LCD && value <= MAX_VALUE_LCD)
			? t("validator.middleNumber", {
					max: MAX_VALUE_LCD,
					min: MIN_VALUE_LCD,
				})
			: "";

	const codeValidator = (value: any) =>
		!(value > MIN_VALUE && value < MAX_VALUE)
			? t("validator.middleNumber", {
					max: MAX_VALUE,
					min: MIN_VALUE,
				})
			: dataItemChanged &&
				  _.some(data, (s) => s.code === value && s.id !== dataItemChanged.id)
				? t("validator.duplicated-value")
				: !dataItemChanged && _.some(data, (s) => s.code === value)
					? t("validator.duplicated-value")
					: "";

	function handleOperatorChange(evt: any) {
		setDataItemChangedFieldByKey("lineId", evt.target.value);

		if (
			evt.target.value !== undefined &&
			lineOptions.filter((x: any) => x.value === evt.target.value)[0].label ===
				BUS_HDV_LCD_REQUIRED
		) {
			setLcdIsRequired(true);
			return;
		}

		setLcdIsRequired(false);
	}

	return (
		<DialogForm
			id="dialog-linespeed-form"
			open={formVisible}
			title={t(`${actionLabel}`, { name: "Speed" })}
			onClose={handleClose}
		>
			<DialogContent>
				<Grid container spacing={2} overflow="none">
					<Grid item xs={6}>
						<TextForm
							editable
							id={"code"}
							label={t("lineSpeed.fields.code")}
							name={"code"}
							value={dataItemChanged.code}
							onChange={(e) =>
								setDataItemChangedFieldByKey("code", e.target.value)
							}
							type="number"
							inputProps={{ maxLength: 99, min: 0 }}
							validator={codeValidator}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextForm
							editable
							id={"speed"}
							label={t("lineSpeed.fields.speed")}
							name={"speed"}
							value={dataItemChanged?.speed}
							onChange={(e) =>
								setDataItemChangedFieldByKey("speed", e.target.value)
							}
							type="number"
							inputProps={{ maxLength: 99, min: 0 }}
							validator={speedValidator}
						/>
					</Grid>
					<Grid item xs={6}>
						<SelectForm
							label={t("lineSpeed.fields.line")}
							labelId={t("lineSpeed.fields.line")}
							name={"line"}
							value={dataItemChanged?.lineId}
							options={toJS(lineOptions)}
							editable={true}
							hasEmptyDefaultItem
							onChange={(evt) => handleOperatorChange(evt)}
							validator={requiredValidator}
							disabled={actionLabel === "editRecord"}
						/>
					</Grid>
					<Grid item xs={6}>
						{lcdIsRequired && (
							<TextForm
								editable
								id={t("lineSpeed.fields.lcd")}
								label={t("lineSpeed.fields.lcd")}
								name={"lcd"}
								value={dataItemChanged?.lcd}
								onChange={(e) =>
									setDataItemChangedFieldByKey("lcd", e.target.value)
								}
								type="number"
								inputProps={{ maxLength: 99, min: 1 }}
								validator={lcdValidator}
							/>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<CancelButton
					id="dialog-linespeed-form"
					onCancel={handleClose}
					title={t("cancel")}
				/>
				<ApplyButton
					id="dialog-linespeed-form"
					onSubmit={handleSubmit}
					title={t("save")}
					startIcon={<Save />}
					disabled={!isValidObject}
				/>
			</DialogActions>
		</DialogForm>
	);
};

export default observer(LineSpeedForm);
