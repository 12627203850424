import { GridColumnProps } from "@progress/kendo-react-grid";
import { TFunction } from "i18next";
import { QuotaProposalItemProps } from "../interfaces/QuotaProposalItemProps";
import HeaderCell from "../containers/Grid/HeaderCell";
import FooterCell from "../containers/Grid/FooterCell";
import { QuotaProposalProps } from "../interfaces/QuotaProposalProps";
import { GENERAL_COLUMNS } from "../constants/gridColumns";

const getGeneralColumn = (
	props: GridColumnProps,
	field: string,
	total: QuotaProposalItemProps | undefined,
	t: TFunction<"quota", undefined>
) => {
	return {
		...props,
		field: field,
		headerCell: () => (
			<HeaderCell
				title={t(`gridColumns.${field}`)}
				tooltip={t(`tooltips.${field}`)}
			/>
		),
		footerCell: !total
			? undefined
			: props.footerCell
				? props.footerCell
				: () => (
						<FooterCell value={total[field as keyof QuotaProposalItemProps]} />
					),
	} as GridColumnProps;
};

export const getColumns = (
	quota: QuotaProposalProps,
	t: TFunction<"quota", undefined>
) => {
	let cols = GENERAL_COLUMNS;

	return [
		getGeneralColumn(
			{ footerCell: () => <FooterCell value={"TOTAL"} /> },
			"region",
			quota.total,
			t
		),
		...cols.map((m) => {
			return getGeneralColumn({}, m, quota.total, t);
		}),
	] as Array<GridColumnProps>;
};
