import { VariantGetProps } from "../interfaces/VariantGetProps";
import {
	variantAdminPutPropsModel,
	variantAdminPutPropsModelBuilder,
} from "./VariantAdminPutPropsModel";

export const variantGetPropsModel: VariantGetProps = {
	...variantAdminPutPropsModel,
	family: "",
};

function variantGetPropsModelBuilder(data: any) {
	if (!data) {
		return variantGetPropsModel;
	}
	return {
		...variantAdminPutPropsModelBuilder(data),
		family: data.family ?? "",
	} as VariantGetProps;
}

export function variantGetPropsModelArrayBuilder(data: Array<any>) {
	if (!data) {
		return [];
	}
	return data.map((item) => variantGetPropsModelBuilder(item));
}
