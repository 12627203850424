import { TFunction } from "i18next";
import { RadioGroupOptionProps } from "./GenericInterfaces";

export function getDeliveryProbabilityOptions(
	t: TFunction<"order", undefined>
): Array<RadioGroupOptionProps> {
	return [
		{
			label: t("deliveryProbability.none"),
			value: "None",
			color: "#BF2012",
		},
		{
			label: t("deliveryProbability.probable"),
			value: "Probable",
			color: "#FFA000",
		},
		{
			label: t("deliveryProbability.firm"),
			value: "Firm",
			color: "#1ABC31",
		},
	];
}
