import React from "react";
import { observer } from "mobx-react";
import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
import DateForm from "../../FormInputs/DateForm";
import { handleDateRangeChange } from "../../../utils/DateUtils";
import { MonthPreFilterProps } from "../Interfaces/MonthPreFilterProps";
import { MMyyyy } from "../../../constants/dateFormats";
const MonthPreFilter: React.FC<MonthPreFilterProps> = ({
	startDate = moment().startOf("month").month(0).year(2020).toDate(),
	endDate = moment().startOf("month").month(0).year(2030).toDate(),
	preFilterStore,
	label = "",
	xs = 6,
	sm = 6,
	md = 2,
	lg = 2,
	xl = 2,
}) => {
	const { preFilter, set } = preFilterStore;

	return (
		<Tooltip title={label} placement="top">
			<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
				<DateForm
					editable
					label={label}
					inputFormat={MMyyyy}
					value={preFilter?.startDate}
					onChange={(e: any) =>
						handleDateRangeChange(e, MMyyyy, set, startDate, endDate)
					}
					size="small"
					minDate={startDate}
					maxDate={endDate}
				/>
			</Grid>
		</Tooltip>
	);
};

export default observer(MonthPreFilter);
