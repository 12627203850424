import { dateFormModel } from "../../../../../utils/GenericModels";
import { OrderMCDeliveryBatchEditProps } from "../../interfaces/BatchEdition/OrderMCDeliveryBatchEditProps";

export const orderMCDelivery: OrderMCDeliveryBatchEditProps = {
	deliveryProbability: "",
	firmBilling: dateFormModel,
	financialDelivery: dateFormModel,
	physicalDelivery: dateFormModel,
	finalDelivery: dateFormModel,
};
