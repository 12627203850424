import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import { observer } from "mobx-react";

const AdelantoChip: React.FC = () => {
	const { t } = useTranslation("quotation");
	return (
		<Box display="flex" justifyContent="flex-end" width={"75%"}>
			<Chip
				variant="filled"
				color="warning"
				size="small"
				label={t("details.sections.adelanto.title")}
			/>
		</Box>
	);
};

export default observer(AdelantoChip);
