import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { EXTERNAL_BRAZIL } from "../../../../../constants/Menus/OrderMenuUtils";
import { useTranslation } from "react-i18next";
import { getGridColumnsByMenu } from "../../utils/GridUtils";
import OrderBookPage from "../../components/HomePage/OrderBookPage";
import { OrderbookEnum } from "../../../../../utils/enums/OrderbookEnum";

const ExternalBrazil: React.FC = () => {
	const { t } = useTranslation("order");
	const { setMenu, menu, reset } = useStores().listStore;
	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	React.useEffect(() => {
		reset();
		setMenu(EXTERNAL_BRAZIL);
		const gridColumns = getGridColumnsByMenu(t, EXTERNAL_BRAZIL);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{menu === EXTERNAL_BRAZIL && (
				<OrderBookPage
					orderbookTitleSource="orderBookTrucks.orderBook"
					orderbookSubTitleSource="orderBookTrucks.externalBrazil"
					orderbookPageTitleSource="orderBookTrucks.pageTitle"
					columns={columns}
					type={OrderbookEnum.Truck}
				/>
			)}
		</>
	);
};

export default observer(ExternalBrazil);
