import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import DownloadCard from "../components/DownloadCard";
import { useCsvQueueManagerStore } from "../stores/CsvQueueManagerStore";

const DownloadsGrid: React.FC = () => {
	const { t } = useTranslation("downloadCenter");
	const store = useCsvQueueManagerStore();

	useEffect(() => {
		store.getAvailableDownloads();
	}, [store]);

	return (
		<>
			<LoadingPage loading={store.loading} />
			{store.files.length === 0 && !store.loading && (
				<>{t("grid.empty-grid")}</>
			)}
			{store.files.length > 0 &&
				store.files.map((file, i) => {
					return <DownloadCard key={file.rowKey} file={file} i={i} />;
				})}
		</>
	);
};

export default observer(DownloadsGrid);
