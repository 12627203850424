import React from "react";
import { useAuthStore } from "../../auth/store/AuthStore";
import { AuthGroupsType } from "../../constants/AuthGroups/authGroups";
import UnauthorizedError from "../msal/UnauthorizedError";

type ProtectedRouteProps = {
	element: React.ReactElement;
	claims: Array<AuthGroupsType>;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, claims }) => {
	const { isAuthorizedGroup } = useAuthStore();
	const isAuthorized = isAuthorizedGroup(claims);

	return isAuthorized ? element : <UnauthorizedError />;
};

export default ProtectedRoute;
