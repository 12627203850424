import { GlosaTextEditableProps } from "../interfaces/GlosaTextEditableProps";
import { QuotationEditableDetails } from "../interfaces/QuotationEditableDetails";
import { SendRejectedWorkflowProps } from "../interfaces/SendPriceAndPaymentProps";
import { getUpdateAllQuotationPaymentTermsModel } from "./UpdateAllQuotationPaymentTermsModel";

export function sendPriceAndPaymentModelBuilder(
	data: QuotationEditableDetails,
	glosaText: GlosaTextEditableProps
): SendRejectedWorkflowProps {
	return {
		discount: data.priceAndPayment.discount,
		totalPrice: data.priceAndPayment.totalPrice,
		id: data.priceAndPayment.id,
		allPaymentTerms: getUpdateAllQuotationPaymentTermsModel(
			data.paymentTerms,
			data.paymentTermRetoma
		),
		reservations: data.reservations,
		glosaText: glosaText,
	};
}
