import { GlosaReadOnlyDetails } from "../interfaces/GlosaReadOnlyDetails";

export const glosaReadOnlyDetailsModel: GlosaReadOnlyDetails = {
	commercialModel: "",
	traction: "",
	color: "",
	axelDisposition: "",
	power: "",
	fuel: "DIESEL",
	isWorkflowStatusPermitedToEditGlosa: false,
};

export function glosaReadOnlyDetailsModelBuilder(
	data: any
): GlosaReadOnlyDetails {
	if (!data) return glosaReadOnlyDetailsModel;

	return {
		commercialModel: data.commercialModel ?? "",
		traction: data.traction ?? "",
		color: data.color ?? "",
		axelDisposition: data.axelDisposition ?? "",
		power: data.power ?? "",
		fuel: "DIESEL",
		vehicleWeightPer1000: data.vehicleWeight
			? data.vehicleWeight / 10000
			: undefined,
		isWorkflowStatusPermitedToEditGlosa:
			data.isWorkflowStatusPermitedToEditGlosa,
	};
}
