import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";
import SelectPreFilter from "../components/SelectPreFilter";

const QUOTATIONSTATUSSAS_PRE_FILTER_XS = 4;
const QUOTATIONSTATUSSAS_PRE_FILTER_SM = 3;
const QUOTATIONSTATUSSAS_PRE_FILTER_MD_LG_XL = 1;
const QuotationStatusSASPreFilter: React.FC<SelectPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "quotationStatusSASreFilter",
		label = "preFilters.statusSAS",
		xs = QUOTATIONSTATUSSAS_PRE_FILTER_XS,
		sm = QUOTATIONSTATUSSAS_PRE_FILTER_SM,
		md = QUOTATIONSTATUSSAS_PRE_FILTER_MD_LG_XL,
		lg = QUOTATIONSTATUSSAS_PRE_FILTER_MD_LG_XL,
		xl = QUOTATIONSTATUSSAS_PRE_FILTER_MD_LG_XL,
	} = props;
	return (
		<SelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(QuotationStatusSASPreFilter);
