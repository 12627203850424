import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import React from "react";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { LineType, MarketType } from "../../../../utils/GenericTypes";
import { LogEventProps } from "../../../../utils/LogEventProps";
import { FilterProps } from "../interfaces/FilterProps";
import { FirmPeriodProps } from "../interfaces/FirmPeriodProps";
import { QuotaProposalEditInsertProps } from "../interfaces/QuotaProposalEditInsertProps";
import { QuotaProposalEditProps } from "../interfaces/QuotaProposalEditProps";
import { filterModel } from "../models/FilterModel";
import {
	firmPeriodModel,
	firmPeriodModelBuilder,
} from "../models/FirmPeriodModel";

class EditQuotaStore {
	@observable filter: FilterProps = filterModel;
	@observable filterIsValid: boolean = true;
	@observable lastUpdateDate: Date = new Date();
	@observable line: LineType = "F";
	@observable market: MarketType = "BR";
	@observable year: number = moment().startOf("year").year();
	@observable proposalLineTypes: Array<string> = [];
	@observable quotaProposalEdit: Array<QuotaProposalEditProps> = [];
	@observable loading: boolean = false;
	@observable loadingFirmPeriod: boolean = false;
	@observable logEvent: Partial<LogEventProps> = {};
	@observable firmPeriod: FirmPeriodProps = firmPeriodModel;
	@observable firmPeriodInitial: FirmPeriodProps = firmPeriodModel;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action loadData = async () => {
		await this.fetchQuotaProposalEdit();
		await this.fetchFirmPeriod();
	};

	@action fetchQuotaProposalEdit = async () => {
		this.loading = true;

		this.quotaProposalEdit = [];

		await this.api.quotaService
			.getEditList(this.filter.market, this.filter.line, this.filter.year)
			.then((rs) => {
				this.quotaProposalEdit = rs?.data?.quotaProposalList;
				this.logEvent = rs?.data?.logEvent;
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action setEditProposals = async (
		data: Array<QuotaProposalEditProps>,
		userEmail: string
	) => {
		this.loading = true;
		this.quotaProposalEdit = data;

		const insertData = {
			market: this.filter.market,
			line: this.filter.line,
			year: this.filter.year,
			quotas: data,
			userEmail,
		} as QuotaProposalEditInsertProps;

		await this.api.quotaService
			.insertEditQuotaProposal(insertData)
			.then(() => {
				this.fetchQuotaProposalEdit();
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action cleanData = () => {
		this.quotaProposalEdit = [];
		this.logEvent = {};
		this.setFirmPeriodInitialAndNew(firmPeriodModel);
	};

	@action setFilter = () => {
		this.line = this.filter.line;
		this.market = this.filter.market;
		this.year = this.filter.year;
	};

	@action setFilterByKeyOnChange = <K extends keyof FilterProps>(
		fieldKey: K,
		newValue: FilterProps[K],
		isValid: boolean
	) => {
		const _filter: FilterProps = Object.assign({}, this.filter);
		_filter[fieldKey] = newValue;
		this.filter = _filter;
		this.filterIsValid = isValid;
	};

	//firm period setters
	@action setLoadingFirmPeriod = (loading: boolean) =>
		(this.loadingFirmPeriod = loading);

	@action setFirmPeriod = (firmPeriod: FirmPeriodProps) =>
		(this.firmPeriod = firmPeriod);

	@action setFirmPeriodInitial = (firmPeriod: FirmPeriodProps) =>
		(this.firmPeriodInitial = firmPeriod);

	@action setFirmPeriodInitialAndNew = (firmPeriod: FirmPeriodProps) => {
		this.setFirmPeriodInitial(firmPeriod);
		this.setFirmPeriod(firmPeriod);
	};

	@action setFirmPeriodByKeyOnChange = <K extends keyof FirmPeriodProps>(
		fieldKey: K,
		newValue: FirmPeriodProps[K]
	) => {
		const _firmPeriod: FirmPeriodProps = Object.assign({}, this.firmPeriod);
		_firmPeriod[fieldKey] = newValue;
		this.firmPeriod = _firmPeriod;
	};

	// firm period get
	@action fetchFirmPeriod = async () => {
		if (this.market !== "BR") return;
		this.setLoadingFirmPeriod(true);
		this.setFirmPeriodInitialAndNew(firmPeriodModel);

		await this.api.firmPeriodService
			.getFirmPeriod(
				this.filter.line,
				this.filter.year,
				this.getMarket(this.filter.market)
			)
			.then((rs) => {
				this.setFirmPeriodInitialAndNew(firmPeriodModelBuilder(rs.data));
			})
			.finally(() => this.setLoadingFirmPeriod(false));
	};

	//firm period put

	@action putFirmPeriod = async () => {
		this.setLoadingFirmPeriod(true);
		await this.api.firmPeriodService
			.createOrUpdateFirmPeriod({
				...this.firmPeriod,
				locale: this.getMarket(this.firmPeriod.locale),
			})
			.then(() => {
				this.fetchFirmPeriod();
			})
			.finally(() => this.setLoadingFirmPeriod(false));
	};

	getMarket = (market: string) => {
		return market === "EXP" ? "EX" : market;
	};
}

const EditQuotaStoreContext = React.createContext(new EditQuotaStore());

export const useEditQuotaStore = () => React.useContext(EditQuotaStoreContext);
