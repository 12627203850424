import CtadmvenApi from "../../apis/CtadmvenApi";

interface ISalesmanService {
	getSalesmanOptions(): Promise<any>;
}
const base = "/Salesman";

export class SalesmanService implements ISalesmanService {
	constructor(private api: CtadmvenApi) {}

	getSalesmanOptions = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/ComboList`);
}
