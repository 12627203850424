import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCustomerStoreSAS {
	@observable rootStore;
	@observable preFilter: string = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}
	@action reset = () => {
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");

	@action get = () => {
		if (this.preFilter === "") return [];

		var operation = this.preFilter.length === 100 ? "eq" : "contains";

		return [getFilterObject("order.finalCustomer", this.preFilter, operation)];
	};

	@action set = (value: string) => (this.preFilter = value);

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const customerFilters = this.get();
		if (customerFilters) orFiltersGroups.push(customerFilters);
	}
}

export default PreFilterCustomerStoreSAS;
