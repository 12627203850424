import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHomeVolvoStore } from "../stores/HomeVolvoStore";
import { Button, Grid, TextField, Stack } from "@mui/material";
import { Search } from "@mui/icons-material";

const VehicleKeyFilter: React.FC = () => {
	const { t } = useTranslation("order");
	const { vehicleKeyFilter, setVehicleKeyFilter, getOrders } =
		useHomeVolvoStore();

	const onEnter = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") getOrders();
	};
	return (
		<Grid item xs={12} md={4}>
			<Stack direction={"row"}>
				<TextField
					variant="outlined"
					value={vehicleKeyFilter}
					onChange={(e) => setVehicleKeyFilter(e.target.value)}
					id={"searchVehicleKey"}
					label={t(`preFilters.searchVehicleKey`)}
					size="small"
					fullWidth
					inputProps={{ maxLength: 14 }}
					onKeyDown={onEnter}
				/>
				<Button
					variant="outlined"
					disableElevation
					color="secondary"
					sx={{ minWidth: 42 }}
					onClick={getOrders}
				>
					<Search />
				</Button>
			</Stack>
		</Grid>
	);
};

export default observer(VehicleKeyFilter);
