import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import { toast } from "react-toastify";
import OrderContent from "./OrderContent";
import BatchEdition from "../../../../../components/BatchEdition/BatchEdition";

const FollowUpChlBatchEditionModal: React.FC = () => {
	const { t } = useTranslation("general");
	const { listTotalResult } = useStores().listStore;

	const { resetData, updateBatchData, formHasChanges, setCustomerOptions } =
		useStores().batchEditionStore;

	const validListSize = listTotalResult > 0 && listTotalResult <= 80;

	const initialFetch = React.useCallback(() => {
		setCustomerOptions();
		resetData();
	}, [setCustomerOptions, resetData]);

	React.useEffect(() => {
		initialFetch();
	}, [initialFetch]);

	const handleSubmit = () => {
		updateBatchData()
			.then(() => toast.success(t("editRecordsWithSuccess").toString()))
			.catch((err: any) => toast.error(err.response.data));
	};

	return (
		<BatchEdition
			id="follow-up-chl-batch-edition"
			title={t("batchEdition", { ns: "general" })}
			onUndo={resetData}
			onSubmit={handleSubmit}
			hasChanges={formHasChanges}
			hasItemsSelected={validListSize}
		>
			<OrderContent />
		</BatchEdition>
	);
};

export default observer(FollowUpChlBatchEditionModal);
