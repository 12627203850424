import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { GeneralSectionProps } from "../../../interfaces/Sections/Bus/GeneralSection";
import { useAuthStore } from "../../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg } from "../../../../../../constants/AuthGroups/closedPeriodArgPage";

const GeneralSection: React.FC<GeneralSectionProps> = ({ readOnlyFields }) => {
	const { t } = useTranslation("order");

	const { isAuthorizedGroup } = useAuthStore();
	const shouldSeeAll = isAuthorizedGroup(
		groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg
	);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.general.busTitle", {
					order: readOnlyFields.factoryOrder,
				})}
			</Grid>
			<Grid item xs={2}>
				<TextForm
					label={t("details.sections.general.foDealerRegion")}
					value={readOnlyFields.foDealerRegion}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.chassi")}
					value={readOnlyFields.chassi}
				/>
			</Grid>
			{shouldSeeAll && (
				<>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.general.foDistrict")}
							value={readOnlyFields.factoryOrderDistrict}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextForm
							label={t("details.sections.general.productionPeriod")}
							value={readOnlyFields.productionPeriod}
							type="number"
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(GeneralSection);
