export enum RoleType {
	None = 100,
	GeneralSalesSupport = 101,
	ArgentinaSalesSupport = 102,
	ChileSalesSupport = 103,
	Bra_Dealer_Asp = 104,
	Bra_Dealer_Aut = 105,
	Bra_Dealer_Dic = 106,
	Bra_Dealer_Dip = 107,
	Bra_Dealer_Got = 108,
	Bra_Dealer_Lap = 109,
	Bra_Dealer_Luv = 110,
	Bra_Dealer_Nor = 111,
	Bra_Dealer_Riv = 112,
	Bra_Dealer_Sue = 113,
	Bra_Dealer_Tne = 114,
	Bra_Dealer_Tno = 115,
	Bra_Dealer_Tne_Tno = 116,
	Bra_Dealer_Trv = 117,
	Arg_Dealer_Agecer = 118,
	Arg_Dealer_Caminoa = 119,
	Arg_Dealer_Gerencia = 120,
	Arg_Dealer_Gloker = 121,
	Arg_Dealer_Gotland = 122,
	Arg_Dealer_Latinacam = 123,
	Arg_Dealer_Libertadores = 124,
	Arg_Dealer_Rutasur = 125,
	Arg_Dealer_Scevola = 126,
	Arg_Dealer_Sudamericana = 127,
	Arg_Dealer_Sueca = 128,
	Col_Market_Company = 129,
	Mex_Sales_Support = 130,
	Meac_Sales_Support = 131,
	Arg_Market_Company = 132,
	Per_Market_Company = 133,
	Chl_Market_Company = 134,
	Pi_Sales_Support = 135,
	ChileGeneralSalesSupport = 136,
}
