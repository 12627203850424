import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { FactoryOptionalSectionProps } from "../../interfaces/Sections/FactoryOptionalSection";
import { getLanguage } from "../../../../../utils/Language";
import { VariantComponent } from "./Component/VariantComponent";
import { QuotationVariantProps } from "../../interfaces/QuotationVariantProps";

const FactoryOptionalSection: React.FC<FactoryOptionalSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation(["quotation"]);
	const { i18n } = useTranslation();
	const language = getLanguage(i18n);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.factoryOptional.title")}
			</Grid>
			<VariantComponent
				variants={readOnlyFields.variants.filter(
					(variant: QuotationVariantProps) => variant.isOptional
				)}
				language={language}
				t={t}
			/>
		</>
	);
};

export default observer(FactoryOptionalSection);
