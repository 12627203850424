import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { VolvoManagementEditableProps } from "../interfaces/Editable/VolvoManagementEditableDetails";
import {
	volvoManagementEditableModel,
	volvoManagementEditableModelBuilder,
} from "../models/Editable/VolvoManagementEditableModel";
import _ from "lodash";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import { VolvoManagementCommentProps } from "../interfaces/VolvoManagementCommentDetails";

configure({ enforceActions: "always" });

/* Store start */
class VolvoManagementStore {
	@observable hasChanges: boolean = false;
	@observable
	initialOrderVolvoManagementComments: Array<VolvoManagementCommentProps> = [];
	@observable orderVolvoManagementComments: Array<VolvoManagementCommentProps> =
		[];
	@observable editableDetails: VolvoManagementEditableProps =
		volvoManagementEditableModel;
	@observable initialEditableDetails: VolvoManagementEditableProps =
		volvoManagementEditableModel;
	@observable newCommercialComment: VolvoManagementCommentProps | null = null;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action setStore = () => {
		this.hasChanges = false;
		this.editableDetails = volvoManagementEditableModel;
		this.initialEditableDetails = volvoManagementEditableModel;
		this.orderVolvoManagementComments = [];
		this.initialOrderVolvoManagementComments = [];
		this.newCommercialComment = null;
	};

	@action setEditableFieldByKeyOnChange = <
		K extends keyof VolvoManagementEditableProps,
	>(
		fieldKey: K,
		newValue: VolvoManagementEditableProps[K]
	) => {
		const _editableDetails: VolvoManagementEditableProps = Object.assign(
			{},
			this.editableDetails
		);
		_editableDetails[fieldKey] = newValue;
		this.editableDetails = _editableDetails;
		this.checkChanges();
	};

	@action private checkChanges = () => {
		this.hasChanges =
			!this.isEditableDetailsEqual() ||
			this.hasNewComment() ||
			this.hasChangesVolvoManagementComments();
		this.rootStore.commonStore.setHasChanges();
	};

	@action load = async () => {
		this.rootStore.commonStore.validateBaseRequest();
		await this.api.volvoManagementService
			.getByOrderId(
				this.rootStore.commonStore.orderId,
				this.rootStore.commonStore.menuKey
			)
			.then(async (response) => {
				this.setEditableDetails(response.data);
				await this.loadCommercialComments();
			});
	};

	@action private addNewComment = () => {
		if (this.newCommercialComment)
			this.orderVolvoManagementComments.push(this.newCommercialComment);
	};

	@action setNewComment = (newComment: VolvoManagementCommentProps) => {
		this.newCommercialComment = newComment;
		this.checkChanges();
	};

	@action private setEditableDetails = (data: VolvoManagementEditableProps) => {
		this.initialEditableDetails = volvoManagementEditableModelBuilder(
			data,
			this.rootStore.commonStore.orderId
		);

		this.editableDetails = volvoManagementEditableModelBuilder(
			data,
			this.rootStore.commonStore.orderId
		);
	};

	@action update = async () => {
		if (!this.hasChanges) return;
		this.rootStore.commonStore.validateBaseRequest();
		this.addNewComment();

		if (!this.isEditableDetailsEqual()) {
			await this.api.volvoManagementService.update(
				this.editableDetails,
				this.rootStore.commonStore.menuKey
			);
		}

		if (this.hasChangesVolvoManagementComments())
			await this.api.volvoManagementService.updateCommercialComments(
				this.orderVolvoManagementComments,
				this.rootStore.commonStore.menuKey,
				this.rootStore.commonStore.orderId
			);
	};

	@action deleteVolvoManagementComments = (
		item: VolvoManagementCommentProps
	) => {
		let index = this.orderVolvoManagementComments.findIndex(
			(record) => record.id === item.id
		);

		this.orderVolvoManagementComments.splice(index, 1);

		this.checkChanges();
	};

	private loadCommercialComments = async () => {
		this.api.volvoManagementService
			.getCommercialCommentsByOrderId(
				this.rootStore.commonStore.orderId,
				this.rootStore.commonStore.menuKey
			)
			.then((response) => {
				this.initialOrderVolvoManagementComments = response.data || [];
				this.orderVolvoManagementComments = response.data || [];
			});
	};

	private isEditableDetailsEqual = () =>
		_.isEqual(this.initialEditableDetails, this.editableDetails);

	private hasNewComment = () =>
		this.newCommercialComment !== null &&
		this.newCommercialComment.comment !== "";

	private hasChangesVolvoManagementComments = () =>
		!_.isEqual(
			this.initialOrderVolvoManagementComments,
			this.orderVolvoManagementComments
		);
}
/* Store end */
export default VolvoManagementStore;
