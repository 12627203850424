import { TFunction } from "i18next";

export function getBreadcrumbs(t: TFunction<"menu"[], undefined>) {
	return [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: t("followup.title", { ns: "menu" }),
			href: "#/followUpChl",
			main: false,
		},
		{
			title: t("followup.details", { ns: "menu" }),
			main: true,
		},
	];
}
