import {
	CTADMVEN_FOLLOWUP_ARG_TRUCK,
	CTADMVEN_FOLLOWUP_ARG_BUS,
} from "../../constants/routes";
import { MenuRouteProps } from "../../utils/GenericInterfaces";
import { AuthGroupsType } from "../../constants/AuthGroups/authGroups";
import { AppRouteProps } from "../routes";
import _ from "lodash";
import { groupsAllowedToSeeFollowUpArgentinaMenu } from "../../constants/AuthGroups/followUpArgPage";
import { TFunction } from "i18next";
import TruckIndex from "./List/containers/TruckIndex";
import BusIndex from "./List/containers/BusIndex";
import FollowUpTruckDetailIndex from "./Details/containers/FollowUpTruckDetailIndex";
import FollowUpBusDetailIndex from "./Details/containers/FollowUpBusDetailIndex";
import {
	getMenuAuthorized,
	getRouteListAndDetails,
} from "../../utils/RoutesUtil";
import MenuListSubHeader from "../../components/header/MenuListSubHeader";

export const followUpArgentinaAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeFollowUpArgentinaMenu,
]);

const followUpTruckRoute: MenuRouteProps = {
	path: CTADMVEN_FOLLOWUP_ARG_TRUCK,
	claims: groupsAllowedToSeeFollowUpArgentinaMenu,
	element: <TruckIndex />,
	label: "truck",
	isCollapsable: true,
};

const followUpBusRoute: MenuRouteProps = {
	path: CTADMVEN_FOLLOWUP_ARG_BUS,
	claims: groupsAllowedToSeeFollowUpArgentinaMenu,
	element: <BusIndex />,
	label: "bus",
	isCollapsable: true,
};

export const followUpArgentinaRoutes: Array<AppRouteProps> = [
	...getRouteListAndDetails(followUpTruckRoute, <FollowUpTruckDetailIndex />),
	...getRouteListAndDetails(followUpBusRoute, <FollowUpBusDetailIndex />),
];

export function getFollowUpArgentinaMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean
) {
	if (!isAuthorizedGroup(followUpArgentinaAllClaims)) {
		return <></>;
	}

	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("argentina")} />
			{getMenuAuthorized(followUpTruckRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(followUpBusRoute, t, isAuthorizedGroup)}
		</>
	);
}
