import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import SelectForm from "../FormInputs/SelectForm";
import { getVehicleLineOptions } from "../../utils/SelectOptions";
import { textNotEmptyValidator } from "../../utils/FormValidators";
import { LineType } from "../../utils/GenericTypes";

interface LineFilterProps {
	value: LineType;
	set: (value: any) => void;
}

const LineFilter: React.FC<LineFilterProps> = ({ set, value = "F" }) => {
	const { t } = useTranslation("general");
	const [line, setLine] = useState<string>(value);
	const lineOptions = getVehicleLineOptions();

	const validator = (value: string) => {
		if (!textNotEmptyValidator(value)) return "required";
		return "";
	};

	const handleChange = (event: any) => {
		const value = event.target.value;
		setLine(value);
		if (!Boolean(validator(value))) set({ value: value, isValid: true });
		else set({ value: value, isValid: false });
	};

	return (
		<SelectForm
			editable
			label={t("line")}
			labelId={"filter-line-label"}
			value={line}
			options={lineOptions}
			onChange={handleChange}
			validator={validator}
		/>
	);
};

export default observer(LineFilter);
