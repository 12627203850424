import { CTADMVEN_DOWNLOAD_CENTER } from "../../constants/routes";
import { MenuRouteProps } from "../../utils/GenericInterfaces";
import { AppRouteProps } from "../routes";
import { getRoute } from "../../utils/RoutesUtil";
import { Download } from "@mui/icons-material";
import { groupsAllowedToSeeDownloadCenterMenu } from "../../constants/AuthGroups/downloadCenterPage";
import Index from "./List/containers/Index";

//routes
export const downloadCenterMenuRoute: MenuRouteProps = {
	path: CTADMVEN_DOWNLOAD_CENTER,
	claims: groupsAllowedToSeeDownloadCenterMenu,
	element: <Index />,
	label: "download-center.title",
	icon: <Download />,
	isCollapsable: false,
};

export const downloadCenterRoutes: Array<AppRouteProps> = [
	getRoute(downloadCenterMenuRoute),
];
