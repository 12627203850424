import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextPreFilter from "../components/TextPreFilter";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";

const ASWEEK_PRE_FILTER_XS = 4;
export const ASWEEK_PRE_FILTER_SM = 3;
export const ASWEEK_PRE_FILTER_MD_LG = 2;
export const ASWEEK_PRE_FILTER_XL = 1;

const AsWeekPreFilter: React.FC<TextPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "asWeekPreFilter",
		maxLength = 5,
		label = "preFilters.asWeek",
		xs = ASWEEK_PRE_FILTER_XS,
		sm = ASWEEK_PRE_FILTER_SM,
		md = ASWEEK_PRE_FILTER_MD_LG,
		lg = ASWEEK_PRE_FILTER_MD_LG,
		xl = ASWEEK_PRE_FILTER_XL,
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			maxLength={maxLength}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};
export default observer(AsWeekPreFilter);
