import CtadmvenApi from "../../apis/CtadmvenApi";
import { RegionGroupProps } from "../../pages/Parameters/Regions/Group/interfaces/RegionGroupProps";
import { RegionsEditProps } from "../../pages/Parameters/Regions/interfaces/RegionsEditProps";

interface IRegionService {
	getList(): Promise<any>;
	getOrderBookFilterOptions(menu: string): Promise<any>;
	getRegions(): Promise<any>;
	updateRegions(regions: Array<RegionsEditProps>): Promise<any>;
	listRegionGroups(): Promise<any>;
	updateRegionGroupRegions(regionsGroup: Array<RegionGroupProps>): Promise<any>;
}
const base = "/region";

export class RegionService implements IRegionService {
	constructor(private api: CtadmvenApi) {}

	getList = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/listValues`);

	getOrderBookFilterOptions = async (menu: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/filter/${menu}`);

	getRegions = async (): Promise<any> => this.api.ctadmvenClient.get(base);

	updateRegions = async (regions: Array<RegionsEditProps>): Promise<any> =>
		this.api.ctadmvenClient.post(base, regions);

	listRegionGroups = async () =>
		this.api.ctadmvenClient.get(`${base}/regionGroups`);

	updateRegionGroupRegions = async (
		regionsGroup: Array<RegionGroupProps>
	): Promise<any> =>
		this.api.ctadmvenClient.post(`${base}/regionGroups`, regionsGroup);
}
