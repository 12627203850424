import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import DetailsTimeline from "./Utils/TimeLine";
import SaveBar from "../../../../components/SaveBar/SaveBar";
import VehicleAccordion from "./Accordions/Bus/VehicleAccordion";
import ProductionAccordion from "./Accordions/ProductionAccordion";
import EolRulesInvoicingDeliveryAccordion from "./Accordions/EolRulesInvoicingDeliveryAccordion";
import LocalizationAccordion from "./Accordions/LocalizationAccordion";
import {
	getMenuBusByRoute,
	getMenuTitleByMenuKey,
} from "../../../../constants/Menus/OrderMenuUtils";
import NoContentResult from "../../../../components/msal/NoContentResult";
import { ModelStateProps } from "../interfaces/Utils/Validation";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import AvailabilityInvoicingDeliveryAccordion from "./Accordions/AvailabilityInvoicingDeliveryAccordion";
import CommercialAccordion from "./Accordions/CommercialAccordion";
import { useStores } from "../stores/RootStore";
import {
	groupsAllowedToEditCommercialCommentsVolvoManagement,
	groupsAllowedToEditVolvoManagementDetails,
} from "../../../../constants/AuthGroups/orderDetails";
import GeneralAccordion from "./Accordions/Bus/GeneralAccordion";

const BusIndex: React.FC = () => {
	const { t } = useTranslation(["ctadmven", "menu", "general"]);
	const { isAuthorizedGroup, isDealerOrMKTCompanyOwner, graphData } =
		useAuthStore();

	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = React.useState(true);

	const { busStore, commonStore } = useStores();
	const {
		readOnlyDetails,
		editableDetails,
		setEditableDetailsFieldByKeyOnChange,
		loadDetails,
		loadVariants,
		loadVariantsRespec,
		loadLocalizationInvoices,
		loadInvoiceInstruction,
		loadInvoiceInstructionExportation,
		loadSaleInvoice,
		loadInvoiceExportation,
		loadInvoicingDeliveryMarketCompany,
		update,
		loadReservationAvailability,
	} = busStore;
	const { hasChanges } = commonStore;

	const userIsOwner = isDealerOrMKTCompanyOwner(readOnlyDetails.foDealerRegion);

	const editVolvoManagementDetailsEnabled = isAuthorizedGroup(
		groupsAllowedToEditVolvoManagementDetails
	);

	const editCommercialCommentsVolvoManagementEnabled = isAuthorizedGroup(
		groupsAllowedToEditCommercialCommentsVolvoManagement
	);

	const editGeneralCommentsEnabled =
		editVolvoManagementDetailsEnabled || userIsOwner;

	const splittedHash = window.location.hash.split("/");
	const menuRoute = splittedHash[splittedHash.length - 2] ?? "";
	const menuKey = getMenuBusByRoute(menuRoute);

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		await loadDetails(id ?? "", menuKey, graphData?.displayName).finally(() =>
			setLoading(false)
		);
	}, [graphData?.displayName, id, loadDetails, menuKey]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const handleErrors = (data: string | ModelStateProps) => {
		if (typeof data === "string") toast.error(data);
		else {
			toast.error(data.title);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		await update()
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => handleErrors(err.message))
			.finally(() => fetchDetails());
	};

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t(getMenuTitleByMenuKey(menuKey), { ns: "menu" })}`,
			href: `#/${menuRoute}`,
			main: false,
		},
		{
			title: t("orderBookBus.details", { ns: "menu" }),
			main: true,
		},
	];

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && readOnlyDetails.id && (
				<>
					<TitleBox
						breadcrumbs={breadcrumbs}
						pageTitle={t("orderBookBus.pageTitle", {
							ns: "menu",
							ob: t("orderBookBus.details", { ns: "menu" }),
						})}
					/>
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs>
								<Grid container spacing={2}>
									<GeneralAccordion
										lastUpdateDetails={{ ...readOnlyDetails }}
										generalDetails={{ readOnlyFields: { ...readOnlyDetails } }}
									/>
									<CommercialAccordion
										customerDetails={{
											readOnlyFields: { ...readOnlyDetails },
											editableFields: { ...editableDetails },
											setFieldByKeyOnChange:
												setEditableDetailsFieldByKeyOnChange,
											userIsOwner,
										}}
										generalComment={{
											editable: editGeneralCommentsEnabled,
											generalComment: editableDetails.generalComment,
											setFieldByKeyOnChange:
												setEditableDetailsFieldByKeyOnChange,
										}}
										editVolvoManagementDetailsEnabled={
											editVolvoManagementDetailsEnabled
										}
										editCommercialCommentsVolvoManagementEnabled={
											editCommercialCommentsVolvoManagementEnabled
										}
									/>
									<VehicleAccordion
										modelDetails={{ readOnlyFields: { ...readOnlyDetails } }}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadVariants: loadVariants,
										}}
									/>
									<ProductionAccordion
										productionDatesDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										respecificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsRespecificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadVariantsRespec: loadVariantsRespec,
										}}
									/>
									<LocalizationAccordion
										locationStatus={readOnlyDetails.locationStatus}
										localizationInvoicesDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadLocalizationInvoices: loadLocalizationInvoices,
										}}
									/>
									{readOnlyDetails.isDistrictBrazil ? (
										<EolRulesInvoicingDeliveryAccordion
											reservationAvailabilityDetails={{
												readOnlyFields: {
													id: readOnlyDetails.id,
													...readOnlyDetails.reservationAvailability,
												},
												loadReservationAvailability:
													loadReservationAvailability,
											}}
											invoiceInstructionDetails={{
												readOnlyFields: { ...readOnlyDetails },
												loadInvoiceInstruction: loadInvoiceInstruction,
												userIsOwner: userIsOwner,
											}}
											invoicingDeliveryDetails={{
												readOnlyFields: { ...readOnlyDetails },
												loadSaleInvoice: loadSaleInvoice,
											}}
											userIsOwner={userIsOwner}
										/>
									) : (
										<AvailabilityInvoicingDeliveryAccordion
											invoiceInstructionExportationDetails={{
												readOnlyFields: { ...readOnlyDetails },
												loadInvoiceInstructionExportation:
													loadInvoiceInstructionExportation,
												userIsOwner: userIsOwner,
											}}
											invoicingDeliveryDetails={{
												readOnlyFields: { ...readOnlyDetails },
												loadSaleInvoice: loadSaleInvoice,
											}}
											invoiceExportationDetails={{
												readOnlyFields: { ...readOnlyDetails },
												loadInvoiceExportation: loadInvoiceExportation,
											}}
											invoicingDeliveryMarketCompanyDetails={{
												readOnlyFields: {
													id: readOnlyDetails.id,
													...readOnlyDetails.invoicingDeliveryMarketCompany,
												},
												loadInvoicingDeliveryMarketCompany:
													loadInvoicingDeliveryMarketCompany,
											}}
											userIsOwner={userIsOwner}
											menuKey={menuKey}
											foDealerRegion={readOnlyDetails.foDealerRegion}
										/>
									)}
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasChanges}
								/>
							</Grid>
							<DetailsTimeline />
						</Grid>
					</Box>
				</>
			)}
			{!loading && !readOnlyDetails.id && <NoContentResult />}
		</>
	);
};

export default observer(BusIndex);
