import CtadmvenApi from "../../apis/CtadmvenApi";
import { CountryType } from "../../utils/GenericTypes";

interface ICustomerService {
	getCustomerByCountry(country: CountryType): Promise<any>;
	getFinancialCustomerByCountry(country: CountryType): Promise<any>;
}
const base = "/Customer";

export class CustomerService implements ICustomerService {
	constructor(private api: CtadmvenApi) {}

	getCustomerByCountry = async (country: CountryType): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/bycountry/${country}`);

	getFinancialCustomerByCountry = async (country: CountryType): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/financialBycountry/${country}`);
}
