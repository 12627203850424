import React from "react";
import { observer } from "mobx-react";
import { Grid, Tooltip } from "@mui/material";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";
import SelectForm from "../../FormInputs/SelectForm";
import { useTranslation } from "react-i18next";
import { getDefaultStringOption } from "../../../utils/SelectOptions";
const SelectPreFilter: React.FC<SelectPreFilterProps> = ({
	preFilterStore,
	id,
	label = "",
	xs = 12,
	sm,
	md,
	lg,
	xl,
	isBooleanOptions = false,
}) => {
	const { t } = useTranslation("general");
	const { preFilter, set, preFilterOptions } = preFilterStore;
	const defaultItem = getDefaultStringOption(t);
	let actualXs = xs;
	let actualSm = sm;
	let actualMd = md;
	let actualLg = lg;
	let actualXl = xl;

	if (isBooleanOptions) {
		actualXs = 2;
		actualSm = 1;
		actualMd = 1;
		actualLg = 1;
		actualXl = 1;
	}

	return (
		<Tooltip title={label} placement="top">
			<Grid
				item
				xs={actualXs}
				sm={actualSm}
				md={actualMd}
				lg={actualLg}
				xl={actualXl}
			>
				<SelectForm
					label={label}
					labelId={`pre-filter-${id}-label`}
					value={preFilter}
					options={preFilterOptions}
					size="small"
					editable
					defaultItem={defaultItem}
					onChange={(e) => set(e.target.value)}
					id={id}
				/>
			</Grid>
		</Tooltip>
	);
};

export default observer(SelectPreFilter);
