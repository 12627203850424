import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import { CommercialCommentsSectionProps } from "../../interfaces/Sections/CommercialCommentsSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateTimeString from "../../../../../utils/DateTimeToString";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import moment from "moment";
import { VolvoManagementCommentProps } from "../../interfaces/VolvoManagementCommentDetails";
import { DeleteCommandCell } from "../../../../../components/kendo/DataGridApi/DeleteCommandCell";

const CommercialCommentsSection: React.FC<CommercialCommentsSectionProps> = ({
	editable,
	setNewComment,
	deleteVolvoManagementComments,
	orderVolvoManagementComments,
	newCommercialComment,
}) => {
	const { t } = useTranslation("order");
	const { graphData } = useAuthStore();

	const dateCell = (props: any) => (
		<td>
			{DateTimeString(
				props.dataItem.creationDate,
				"DD/MM/YYYY HH:mm:ss",
				"-",
				true
			)}
		</td>
	);

	const setNewVolvoManagementComment = (comment: string) => {
		const userEmail = graphData?.mail ?? "";
		const newOrderVolvoManagementComment: VolvoManagementCommentProps = {
			...newCommercialComment,
			userEmail: userEmail,
			creationDate: moment().toDate(),
			comment: comment,
		};

		setNewComment(newOrderVolvoManagementComment);
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.commercialComments.title")}
			</Grid>
			{orderVolvoManagementComments.length > 0 && (
				<Grid item xs={12}>
					<KendoGrid data={orderVolvoManagementComments}>
						<GridColumn
							field="creationDate"
							title={t("details.sections.commercialComments.creationDate")}
							cell={dateCell}
						/>
						<GridColumn
							field="userEmail"
							title={t("details.sections.commercialComments.userEmail")}
						/>
						<GridColumn
							field="comment"
							title={t("details.sections.commercialComments.comment")}
						/>
						{editable && (
							<GridColumn
								cell={(gridCell) => (
									<DeleteCommandCell
										onClick={() =>
											deleteVolvoManagementComments(gridCell.dataItem)
										}
									/>
								)}
								width="200px"
							/>
						)}
					</KendoGrid>
				</Grid>
			)}
			{editable && (
				<Grid item xs={12}>
					<TextForm
						label={t("details.sections.commercialComments.title")}
						editable={editable}
						value={newCommercialComment?.comment}
						multiline
						maxRows={4}
						inputProps={{ maxLength: 500 }}
						onChange={(e) => setNewVolvoManagementComment(e.target.value)}
					/>
				</Grid>
			)}
		</>
	);
};

export default observer(CommercialCommentsSection);
