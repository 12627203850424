import RootStore from "../../../stores/RootStore";
import { action, configure } from "mobx";
import {
	getFilterObjectArrayOfOrderKeyBySize,
} from "../../../../../../utils/FilterOperations";
import BasePreFilterChassiAOStore from "../../../../../../components/PreFilter/Stores/BasePreFilterChassiAOStore";
import { FilterDescriptor } from "@progress/kendo-data-query";

configure({ enforceActions: "always" });

class PreFilterChassiAOStore extends BasePreFilterChassiAOStore {

	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	@action getFilterBySize = (value: string) => {
		return getFilterObjectArrayOfOrderKeyBySize(
			"chassi",
			"assemblyOrder",
			"factoryOrder",
			"order.",
			value
		);
	};

	@action set = (value: string) => this.setBase(value, this.getFilterBySize);

	@action getFilterDescriptor = (andFilters: Array<FilterDescriptor>) => this.getFilterDescriptorBase(andFilters, this.getFilterBySize)

	@action getFilterDescriptorBySize = (
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>
	) => this.getFilterDescriptorBySizeBase(andFilters, orFiltersGroups, this.getFilterBySize);
}

export default PreFilterChassiAOStore;
