import { Box, Typography } from "@mui/material";
import React from "react";

interface OverlineTextCenteredProps {
	children: React.ReactNode;
}

const OverlineTextCentered: React.FC<OverlineTextCenteredProps> = ({
	children,
}) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
		>
			<Typography variant="overline">{children}</Typography>
		</Box>
	);
};

export default OverlineTextCentered;
