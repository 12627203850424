export function parseNumberToDecimal(value: number, decimalPlaces: number) {
	return Number(
		Number(
			Math.round(parseFloat(value + "e" + decimalPlaces)) + "e-" + decimalPlaces
		).toFixed(decimalPlaces)
	);
}

export function sumObjectArrayByKey(
	objArray: Array<any>,
	keyValue: string
): number {
	return parseNumberToDecimal(
		objArray.reduce((partialSum, item) => partialSum + item[keyValue], 0),
		2
	);
}
