import { Box, Card, CardContent, Paper } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";

export interface DraggableCardProps {
	id: string;
	width: number;
	children?: React.ReactNode;
}

const ID_PREFIX = "draggable-dialog-card";

const DraggableCard: React.FC<DraggableCardProps> = ({
	id,
	width,
	children,
}) => {
	return (
		<Draggable handle={`#${ID_PREFIX}${id}`}>
			<Box id={`${ID_PREFIX}${id}`} zIndex={100} width={width}>
				<Paper
					role="dialog"
					aria-modal="false"
					variant="outlined"
					sx={{ cursor: "move" }}
				>
					<Card>
						<CardContent>{children}</CardContent>
					</Card>
				</Paper>
			</Box>
		</Draggable>
	);
};

export default DraggableCard;
