import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { getExportationStatusOptions } from "../../../../../utils/SelectOptions";
import { ReservationExportSectionProps } from "../../interfaces/Sections/ReservationExportSection";
import BaseReservationExportSection from "./BaseReservationExportSection";

const ReservationExportSection: React.FC<ReservationExportSectionProps> = ({
	readOnlyFields,
	loadReservationExport,
}) => {
	const { t } = useTranslation(["order", "general"]);

	const fetchReservationExport = React.useCallback(async () => {
		await loadReservationExport();
	}, [loadReservationExport]);

	React.useEffect(() => {
		fetchReservationExport();
	}, [fetchReservationExport]);

	const exportationOptions = getExportationStatusOptions(t);

	return (
		<BaseReservationExportSection readOnlyFields={readOnlyFields} exportationOptions={exportationOptions} />
	);
};

export default observer(ReservationExportSection);
