import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import TextPreFilter from "../components/TextPreFilter";

const DESTINATION_PRE_FILTER_XS = 12;
const DESTINATION_PRE_FILTER_SM = 6;
const DESTINATION_PRE_FILTER_MD = 4;
const DESTINATION_PRE_FILTER_LG = 3;
const DESTINATION_PRE_FILTER_XL = 3;

const DestinationPreFilter: React.FC<TextPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "destinationPreFilter",
		label = "preFilters.destination",
		maxLength = 150,
		xs = DESTINATION_PRE_FILTER_XS,
		sm = DESTINATION_PRE_FILTER_SM,
		md = DESTINATION_PRE_FILTER_MD,
		lg = DESTINATION_PRE_FILTER_LG,
		xl = DESTINATION_PRE_FILTER_XL,
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			maxLength={maxLength}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(DestinationPreFilter);
