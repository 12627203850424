import CtadmvenApi from "../../apis/CtadmvenApi";

export interface ICsvQueueManagerService {
	list(): Promise<any>;
	download(rowKey: string): Promise<any>;
}

const base = "/csvQueueManager";

export class CsvQueueManagerService implements ICsvQueueManagerService {
	constructor(private api: CtadmvenApi) {}

	list(): Promise<any> {
		return this.api.ctadmvenClient.get(`${base}/GetCsvQueueMessages/`);
	}

	download(rowKey: string) {
		return this.api.ctadmvenClient.get(`${base}/DownloadCsv/${rowKey}`);
	}
}
