import {
	dateFormModel,
	stringFormModel,
	numberFormModel,
} from "../../../../../utils/GenericModels";
import { OrderMCManagementDetailBatchEditProps } from "../../interfaces/BatchEdition/OrderMCManagementDetailBatchEditProps";

export const orderMCManagementDetail: OrderMCManagementDetailBatchEditProps = {
	billingForecast: dateFormModel,
	dealerId: stringFormModel,
	destination: stringFormModel,
	currency: stringFormModel,
	paymentTermsId: stringFormModel,
	shippingDate: dateFormModel,
	certificateDate: dateFormModel,
	vehicleLocationId: stringFormModel,
	paqueteId: stringFormModel,
	logisticComments: stringFormModel,
	commercialComments: stringFormModel,
	engineeringComments: stringFormModel,
	salesSupportComments: stringFormModel,
	financialComments: stringFormModel,
	comission: numberFormModel,
};
