import { observer } from "mobx-react";
import React from "react";
import { useVolvoDealerReadStore } from "../../stores/VolvoDealerReadStore";
import { BRA_DEALER_TNE_TNO } from "../../../../../constants/AuthGroups/authGroups";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import QuotaReadFilters from "../../../../../components/Sections/QuotaReadFilters";
import DealerBrazilFilter from "../../../../../components/Filters/DealerBrazilFilter";

const VolvoDealerReadFilters: React.FC = () => {
	const {
		setFilterByKeyOnChange,
		setFilter,
		setIsTracbelUser,
		filter,
		filterIsValid,
	} = useVolvoDealerReadStore();

	const { isAuthorizedGroup } = useAuthStore();
	setIsTracbelUser(isAuthorizedGroup([BRA_DEALER_TNE_TNO]));

	return (
		<QuotaReadFilters
			setFilterByKeyOnChange={setFilterByKeyOnChange}
			setFilter={setFilter}
			filter={filter}
			filterIsValid={filterIsValid} >
			<DealerBrazilFilter
				set={(e) =>
					setFilterByKeyOnChange("dealerRegion", e.value, e.isValid)
				}
				value={filter.dealerRegion}
			/>
		</QuotaReadFilters>
	);
};

export default observer(VolvoDealerReadFilters);
