import { action, makeObservable, observable } from "mobx";
import React from "react";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import { RegionsEditProps } from "../../interfaces/RegionsEditProps";
import { regionsEditModel } from "../models/RegionsEditPropsModel";

class EditRegionStore {
	@observable regionsInitial: Array<RegionsEditProps> = [];
	@observable regionsEdit: Array<RegionsEditProps> = [];
	@observable loading: boolean = false;
	@observable isFormVisible: boolean = false;
	@observable actionLabel: string = "";
	@observable dataItemChanged: RegionsEditProps = regionsEditModel;
	@observable oldDataItemChanged: RegionsEditProps = regionsEditModel;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action cleanData = () => {
		this.regionsInitial = [];
		this.regionsEdit = [];
	};

	@action fetchData = async () => {
		this.loading = true;

		await this.api.regionService
			.getRegions()
			.then((rs) => {
				this.regionsInitial = rs?.data;
				this.regionsEdit = rs?.data;
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action setFormVisible = (isFormVisible: boolean) => {
		this.isFormVisible = isFormVisible;
	};

	@action setActionLabel = (actionLabel: string) => {
		this.actionLabel = actionLabel;
	};

	@action updateRegions = async (regionsEditProps: RegionsEditProps[]) => {
		this.loading = true;

		await this.api.regionService
			.updateRegions(regionsEditProps)
			.then(async () => {
				await this.fetchData();
			})
			.finally(() => {
				this.loading = false;
			});
	};

	@action createOrUpdate = async (regionsEditProps: RegionsEditProps) => {
		let region = this.regionsEdit.filter((x) => x.id === regionsEditProps.id);

		if (region.length === 0) {
			this.regionsEdit.push(regionsEditProps);
		} else {
			let index = this.regionsEdit
				.map((x) => x.id)
				.indexOf(regionsEditProps.id);
			this.regionsEdit[index] = regionsEditProps;
		}

		await this.updateRegions(this.regionsEdit);
	};

	@action setDeleteElement = (regionsEditProps: RegionsEditProps) => {
		this.regionsEdit = this.regionsEdit.filter(
			(x) => x.value !== regionsEditProps.value
		);
	};

	@action setRegionsEdit = (regionsEditProps: RegionsEditProps[]) => {
		this.regionsEdit = regionsEditProps;
	};

	@action setDataItemChanged = (dataItemChanged: RegionsEditProps) => {
		this.dataItemChanged = dataItemChanged;
	};

	@action setOldDataItemChanged = (oldDataItemChanged: RegionsEditProps) => {
		this.oldDataItemChanged = oldDataItemChanged;
	};
}

const EditRegionStoreContext = React.createContext(new EditRegionStore());

export const useEditRegionStore = () =>
	React.useContext(EditRegionStoreContext);
