import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { useQuotationDetailStore } from "../../store/QuotationDetailStore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { PaymentTermRetomaSectionProps } from "../../interfaces/Sections/PaymentTermRetomaSection";
import { PaymentTermContainerComponent } from "./Component/PaymentTermContainerComponent";
import { PaymentRetoma } from "../../interfaces/QuotationPaymentTermRetoma";
import { hasMinValueRequiredValidator } from "../../../../../utils/FormValidators";

const PaymentTermRetomaSection: React.FC<PaymentTermRetomaSectionProps> = ({
	editable,
}) => {
	const { t } = useTranslation(["quotation", "general"]);

	const {
		editableDetails,
		setPaymentTermRetoma,
		addNewPaymentTermRetoma,
		removePaymentTermRetoma,
		setRetomaTotalProvision,
	} = useQuotationDetailStore();

	const { paymentTermRetoma } = editableDetails;

	const handleChangePaymentTermRetoma = <K extends keyof PaymentRetoma>(
		fieldKey: K,
		paymentTermIndex: number,
		newValue: any,
		error?: string
	) => {
		if (fieldKey === "plate" && newValue.length > 8) {
			newValue =
				editableDetails.paymentTermRetoma.paymentRetomaList[paymentTermIndex]
					.plate;
		}

		setPaymentTermRetoma(fieldKey, paymentTermIndex, newValue, error ?? "");
	};

	const plateFormValidator = (value: string) => {
		if (!value || value.length === 0 || /\s/g.test(value))
			return t("validator.fieldRequired", { ns: "general" });

		if (
			editableDetails.paymentTermRetoma.paymentRetomaList.filter(
				(x) => x.plate === value
			).length > 1
		)
			return t("validator.duplicatedValue", { ns: "general" });

		return "";
	};

	const valueInClpValidator = (value?: number) =>
		!hasMinValueRequiredValidator(value, 1)
			? t("validator.minNumber", { min: 1, ns: "general" })
			: "";

	const formValidators = (value: any, key?: string) => {
		if (key === "valueInClp") return valueInClpValidator(value);
		if (key === "plate") return plateFormValidator(value);
		return "";
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.retoma.title")}
			</Grid>
			{paymentTermRetoma &&
				paymentTermRetoma.paymentRetomaList.map((retoma, i) => (
					<PaymentTermContainerComponent key={i}>
						<Grid item justifyContent="end" alignItems="end">
							{editable && (
								<Tooltip
									title={`${t("delete", { ns: "general" })}`}
									arrow
									placement="left"
								>
									<IconButton
										color="error"
										size="large"
										onClick={() => removePaymentTermRetoma(retoma)}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							)}
						</Grid>
						<Grid item xs={4}>
							<TextForm
								label={t("details.sections.retoma.plate")}
								value={retoma.plate}
								onChange={(
									e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
									_?: boolean,
									error?: string
								) => {
									handleChangePaymentTermRetoma(
										"plate",
										i,
										e.target.value,
										error
									);
								}}
								name="plate"
								editable={editable}
								validator={(value, key) => formValidators(value, key)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextForm
								label={t("details.sections.retoma.valueInClp")}
								value={retoma.value}
								onChange={(
									e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
									_?: boolean,
									error?: string
								) =>
									handleChangePaymentTermRetoma(
										"value",
										i,
										e.target.value,
										error
									)
								}
								name="valueInClp"
								type="number"
								isCurrency
								inputProps={{ min: 1 }}
								editable={editable}
								validator={(value, key) => formValidators(value, key)}
							/>
						</Grid>
					</PaymentTermContainerComponent>
				))}
			<Grid container direction="row" justifyContent="end" alignItems="end">
				<Button
					variant="contained"
					color="secondary"
					size="small"
					disabled={!editable}
					onClick={addNewPaymentTermRetoma}
				>
					<AddIcon /> {t("addRegister", { ns: "general" })}
				</Button>
			</Grid>
			{paymentTermRetoma?.paymentRetomaList?.length > 0 && (
				<>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.retoma.totalProvision")}
							value={editableDetails.paymentTermRetoma.retomaTotalProvision}
							onChange={(e) => setRetomaTotalProvision(e.target.value)}
							type="number"
							isCurrency
							inputProps={{ min: 0 }}
							editable={editable}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(PaymentTermRetomaSection);
