import CtadmvenApi from "../../apis/CtadmvenApi";
import { LineSpeedProps } from "../../pages/Parameters/LineSpeed/interfaces/LineSpeedProps";

interface ILineService {
	getPreFilterLineOptions(): Promise<any>;
	getList(): Promise<[LineSpeedProps]>;
	listLines(): Promise<any>;
	createOrUpdate(record: any): Promise<any>;
	delete(routingKey: string): Promise<any>;
}
const base = "/lineSpeed";

export class LineService implements ILineService {
	constructor(private api: CtadmvenApi) {}

	getPreFilterLineOptions = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/filter`);

	getList = async (): Promise<[LineSpeedProps]> =>
		this.api.ctadmvenClient.get(`${base}`);

	listLines = async () => this.api.ctadmvenClient.get(`${base}/line`);

	createOrUpdate = async (record: any) =>
		this.api.ctadmvenClient.post(`${base}`, record);

	delete = async (lineSpeedId: string) =>
		this.api.ctadmvenClient.delete(`${base}?lineSpeedId=${lineSpeedId}`);
}
