import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeRegionPreFilter: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_DEALER_TNE_TNO,
		AuthGroups.PI_SALES_SUPPORT,
	];

export const groupsAllowedToSeeExternalBrazilMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		...AuthGroups.BRA_DEALERS,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
	];

export const groupsAllowedToSeeAvailabilityMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		...AuthGroups.BRA_DEALERS,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
	];

export const groupsAllowedToSeeMktCompanyMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
	];

export const groupsAllowedToSeeInternalVdbMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
	];

export const groupsAllowedToSeeTotalMenu: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.BRA_SALES_SUPPORT_VDB,
	AuthGroups.GENERAL_VDB_VIEWER,
];

export const groupsAllowedToSeeEuropeFactoryMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
		AuthGroups.PI_SALES_SUPPORT,
		AuthGroups.GENERAL_VDB_VIEWER,
	];

export const groupsAllowedToSeeFactoryDeliveriesMktCompanyMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
	];

export const groupsAllowedToSeeFactoryDeliveriesEuropeMktCompanyMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
	];

export const groupsAllowedToSeePrivateImportersMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.COL_MARKET_COMPANY,
		AuthGroups.PI_SALES_SUPPORT,
	];

export const groupsAllowedToSeeVCIMenu: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY,
	AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
	AuthGroups.ARG_MARKET_COMPANY,
	AuthGroups.PER_MARKET_COMPANY,
];
