import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import {
	baseReadOnlyModel,
	baseReadOnlyModelBuilder,
} from "./BaseReadOnlyModel";

export const truckReadOnlyModel: TruckReadOnlyProps = {
	...baseReadOnlyModel,
	assemblyOrder: "",
	deliveryProposal: null,
	commercialTrimmingPackages: "",
	availabilityDate: null,
	pickingDate: null,
	pickingDistrict: "",
	pickingRegion: "",
	greenOkDistrict: "",
	greenOkRegion: "",
};

export function truckReadOnlyModelBuilder(data: TruckReadOnlyProps) {
	if (!data) return truckReadOnlyModel;
	let model = baseReadOnlyModelBuilder(data);
	return {
		...truckReadOnlyModel,
		...data,
		...model,
		assemblyOrder: data.assemblyOrder ?? "",
		commercialTrimmingPackages: data.commercialTrimmingPackages ?? "",
	} as TruckReadOnlyProps;
}
