import { Save, Undo } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
	Grid,
	GridCellProps,
	GridColumn,
	GridToolbar,
} from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useEditRegionStore } from "../stores/EditRegionStore";
import { getColumns } from "../utils/GridUtils";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import EditForm from "./EditForm";
import { toast } from "react-toastify";

const EditGrid: React.FC = () => {
	const { t } = useTranslation("general");
	const {
		regionsEdit,
		regionsInitial,
		setFormVisible,
		setActionLabel,
		updateRegions,
		setRegionsEdit,
		setDataItemChanged,
		setOldDataItemChanged,
	} = useEditRegionStore();

	const onDelete = (e: GridCellProps) => {
		var newData = regionsEdit.filter((x) => x.value !== e.dataItem.value);

		setRegionsEdit(newData);
	};

	const handleAddClick = () => {
		setFormVisible(true);
		setActionLabel("addRecord");
	};

	const handleSubmit = async () => {
		await updateRegions(regionsEdit)
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err) => toast.error(err.response.data));
	};

	const onUpdate = (e: GridCellProps) => {
		setFormVisible(true);
		setActionLabel("editRecord");
		setDataItemChanged(e.dataItem);
		setOldDataItemChanged(e.dataItem);
	};

	return (
		<>
			<EditForm />
			<Grid data={regionsEdit} scrollable="none" editField="inEdit">
				<GridToolbar>
					<div>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => handleAddClick()}
							disabled={!_.isEqual(regionsInitial, regionsEdit)}
						>
							<AddIcon /> {t("addRegister")}
						</Button>
					</div>
					<div className="right-buttons-header">
						<Button
							color="secondary"
							onClick={() => setRegionsEdit(regionsInitial)}
							disabled={_.isEqual(regionsInitial, regionsEdit)}
						>
							<Undo fontSize="small" />
							{t("clear")}
						</Button>
						<Button
							color="secondary"
							type={"submit"}
							onClick={handleSubmit}
							className="ms-2 btn-sm"
							disabled={_.isEqual(regionsInitial, regionsEdit)}
						>
							<Save fontSize="small" />
							{t("save")}
						</Button>
					</div>
				</GridToolbar>
				{getColumns(
					t,
					onDelete,
					onUpdate,
					!_.isEqual(regionsInitial, regionsEdit)
				).map((col: any, i: number) => {
					return <GridColumn key={i} {...col} />;
				})}
			</Grid>
		</>
	);
};

export default observer(EditGrid);
