import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";
import { DateRangeFormProps } from "../../../utils/GenericInterfaces";

configure({ enforceActions: "always" });

class BasePreFilterDateStore {
	@observable rootStore;
	@observable preFilter?: DateRangeFormProps = undefined;

	constructor(rootStore: any) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = undefined
	};

	@action clean = () => (this.preFilter = undefined);

	getSpecificFilter(): any {
		// Implement in subclasses
	}

	@action setStart = (startDate: Date | undefined) => {
		this.preFilter = { ...this.preFilter, startDate };
	};

	@action setEnd = (endDate: Date | undefined) => {
		if (this.preFilter && this.preFilter.startDate) {
			this.preFilter.endDate = endDate;
		}
	};

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const certificateDateFilters = this.getSpecificFilter();

		if (certificateDateFilters) {
			andFilters = andFilters.concat(certificateDateFilters);
		}

		return andFilters;
	}
}

export default BasePreFilterDateStore;
