import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { QuotaInformationSectionProps } from "../../interfaces/Sections/QuotaInformationSection";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import TextForm from "../../../../../components/FormInputs/TextForm";

const QuotaInformationSection: React.FC<QuotaInformationSectionProps> = ({
	readOnlyFields,
	editableFields,
	setFieldByKeyOnChange,
	districtsList,
	regionsList,
	userCanEdit,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.quotaInformation.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.quotaInformation.pickingDistrict")}
					value={readOnlyFields.pickingDistrict}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.quotaInformation.pickingRegion")}
					value={readOnlyFields.pickingRegion}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.quotaInformation.pickingDate")}
					value={readOnlyFields.pickingDate}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.quotaInformation.quotaDistrict")}
					labelId={"sections.quotaInformation-quotaDistrict-label"}
					value={editableFields.quotaDistrict}
					options={districtsList}
					editable={
						userCanEdit &&
						!!readOnlyFields.pickingDistrict &&
						!!readOnlyFields.pickingRegion
					}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setFieldByKeyOnChange("quotaDistrict", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.quotaInformation.quotaRegion")}
					labelId={"sections.quotaInformation-quotaRegion-label"}
					value={editableFields.quotaRegion}
					options={regionsList}
					editable={
						userCanEdit &&
						!!readOnlyFields.pickingDistrict &&
						!!readOnlyFields.pickingRegion
					}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) => setFieldByKeyOnChange("quotaRegion", e.target.value)}
				/>
			</Grid>
			<Grid item xs={4}></Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.quotaInformation.greenOkDistrict")}
					value={readOnlyFields.greenOkDistrict}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.quotaInformation.greenOkRegion")}
					value={readOnlyFields.greenOkRegion}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.quotaInformation.greenOkDate")}
					value={readOnlyFields.greenOk}
				/>
			</Grid>
		</>
	);
};

export default observer(QuotaInformationSection);
