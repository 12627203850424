import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import ProductionWeekFilters from "./ProductionWeekFilters";
import { useProductionWeekStore } from "../stores/ProductionWeekStore";
import TableGrid from "./Grid/TableGrid";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import _ from "lodash";
import DateTimeToString from "../../../../utils/DateTimeToString";

const ProductionWeekIndex: React.FC = () => {
	const { t } = useTranslation("general");
	const { isLoading, gridDataTable, resetStore, logEvent } =
		useProductionWeekStore();

	const cleanData = React.useCallback(() => {
		resetStore();
	}, [resetStore]);

	React.useEffect(() => {
		cleanData();
	}, [cleanData]);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("parameters.productionWeek", { ns: "menu" })}`,
			main: true,
		},
	];

	const dateTime = DateTimeToString(
		logEvent?.updatedAt ?? new Date(),
		"DD/MM/YYYY HH:mm",
		"-",
		true
	);

	return (
		<>
			<LoadingPage loading={isLoading} />
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs}>
					{!_.isEmpty(logEvent) && (
						<div className="fs14" style={{ marginTop: "1px" }}>
							{logEvent.wasManualLastUpdated && (
								<span>
									{t("updatedManuallyAt", {
										reference: logEvent.reference,
										dateTime,
									})}
								</span>
							)}
							{!logEvent.wasManualLastUpdated && (
								<span>
									{t("syncronizedAt", {
										reference: logEvent.reference,
										dateTime,
									})}
								</span>
							)}
						</div>
					)}
				</TitleBox>
				<ProductionWeekFilters />
				{gridDataTable && gridDataTable.length > 0 && <TableGrid />}
			</Grid>
		</>
	);
};

export default observer(ProductionWeekIndex);
