import React from "react";
import { observer } from "mobx-react";
import { Grid, Tooltip } from "@mui/material";
import MultiSelectForm from "../../FormInputs/MultiSelectForm";
import { getDefaultArrayStringOption } from "../../../utils/SelectOptions";
import { MultiSelectPreFilterProps } from "../Interfaces/MultiSelectPreFilterProps";
import { useTranslation } from "react-i18next";
const MultiSelectPreFilter: React.FC<MultiSelectPreFilterProps> = ({
	preFilterStore,
	id,
	label = "",
	xs = 12,
	sm,
	md,
	lg,
	xl,
}) => {
	const { t } = useTranslation("general");

	const { preFilter, set, preFilterOptions } = preFilterStore;
	const defaultItem = getDefaultArrayStringOption(t, preFilterOptions);

	return (
		<Tooltip title={label} placement="top">
			<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
				<MultiSelectForm
					label={label}
					labelId={`pre-filter-${id}-label`}
					value={preFilter}
					options={preFilterOptions}
					size="small"
					editable
					defaultItem={defaultItem}
					onChange={(e) => set(e)}
					id={id}
				/>
			</Grid>
		</Tooltip>
	);
};

export default observer(MultiSelectPreFilter);
