import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { CustomerSectionProps } from "../../interfaces/Sections/CustomerSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { fieldIsEditable } from "../../utils/EditableFieldsUtils";

const CustomerSection: React.FC<CustomerSectionProps> = ({
	fieldsAvailableForEdition,
	readOnlyFields,
	editableFields,
	setFieldByKeyOnChange,
	setOrderMCManagementFieldByKeyOnChange,
	dealersOptions,
}) => {
	const { t } = useTranslation(["order", "general"]);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.customer.title")}
				{readOnlyFields.saleStatus && " - " + readOnlyFields.saleStatus}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.originalCustomer")}
					value={readOnlyFields.foOriginalCustomerOM}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.sasCustomer")}
					value={editableFields.finalCustomer}
					editable={fieldIsEditable("finalCustomer", fieldsAvailableForEdition)}
					name="FinalCustomer"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setFieldByKeyOnChange("finalCustomer", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.bodybuilder")}
					value={editableFields.bodybuilder}
					editable={fieldIsEditable("bodybuilder", fieldsAvailableForEdition)}
					name="Bodybuilder"
					inputProps={{ maxLength: 50 }}
					onChange={(e) => setFieldByKeyOnChange("bodybuilder", e.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.salesman")}
					value={editableFields.salesman}
					editable={fieldIsEditable("salesman", fieldsAvailableForEdition)}
					name="Salesman"
					inputProps={{ maxLength: 100 }}
					onChange={(e) => setFieldByKeyOnChange("salesman", e.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.branchoffice")}
					value={editableFields.branchOffice}
					editable={fieldIsEditable("branchOffice", fieldsAvailableForEdition)}
					name="BranchOffice"
					inputProps={{ maxLength: 50 }}
					onChange={(e) =>
						setFieldByKeyOnChange("branchOffice", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<SelectForm
					label={t("details.sections.customer.dealer")}
					labelId={"sections.customer-dealer-label"}
					value={editableFields.dealerId}
					options={dealersOptions}
					editable={fieldIsEditable("dealerId", fieldsAvailableForEdition)}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementFieldByKeyOnChange(
							"dealerId",
							e.target.value as string
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(CustomerSection);
