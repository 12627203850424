import { BusEditableProps } from "../../interfaces/Editable/BusEditableDetails";
import {
	baseEditableModel,
	baseEditableModelBuilder,
} from "./BaseEditableModel";

export const busEditableModel: BusEditableProps = {
	...baseEditableModel,
	finalCustomer: "",
};

export function busEditableModelBuilder(data: BusEditableProps) {
	if (!data) return busEditableModel;
	let model = baseEditableModelBuilder(data);
	return {
		...model,
		finalCustomer: data.finalCustomer ?? "",
	} as BusEditableProps;
}
