import moment from "moment";
import { GUID_EMPTY } from "../../../../app/constants/config";
import { OrderMCDeliveryReadOnlyProps } from "./OrderMCDeliveryReadOnly";

export const orderMCDeliveryReadOnlyModel: OrderMCDeliveryReadOnlyProps = {
	orderId: GUID_EMPTY,
	deliveryProbability: "None",
	firmBilling: null,
	financialDelivery: null,
	physicalDelivery: null,
	finalDelivery: null,
	financialDeliveryUser: "",
	finalDeliveryUser: "",
	firmBillingChecked: false,
	financialDeliveryChecked: false,
	physicalDeliveryChecked: false,
	finalDeliveryChecked: false,
	firmBillingUser: "",
	physicalDeliveryUser: "",
};

export function orderMCDeliveryReadOnlyModelBuilder(
	data: any
): OrderMCDeliveryReadOnlyProps {
	if (!data) return { ...orderMCDeliveryReadOnlyModel };
	return {
		...orderMCDeliveryReadOnlyModel,
		deliveryProbability: data.deliveryProbability ?? "None",
		firmBilling: getStartDate(data.firmBilling),
		financialDelivery: getStartDate(data.financialDelivery),
		physicalDelivery: getStartDate(data.physicalDelivery),
		finalDelivery: getStartDate(data.finalDelivery),
		financialDeliveryUser: data.financialDeliveryUser ?? "",
		finalDeliveryUser: data.finalDeliveryUser ?? "",
		firmBillingChecked: Boolean(data.firmBilling),
		financialDeliveryChecked: Boolean(data.financialDelivery),
		physicalDeliveryChecked: Boolean(data.physicalDelivery),
		finalDeliveryChecked: Boolean(data.finalDelivery),
		firmBillingUser: data.firmBillingUser ?? "",
		physicalDeliveryUser: data.physicalDeliveryUser ?? "",
	};
}

function getStartDate(date: Date | null): Date | null {
	return date ? moment(date).startOf("day").toDate() : null;
}
