import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../components/AccordionCard/AccordionCard";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import DateTimeToString from "../../../../utils/DateTimeToString";
import { useVolvoReadStore } from "../stores/VolvoReadStore";
import VolvoReadGrid from "./Grid/VolvoReadGrid";
import VolvoReadFilters from "./VolvoReadFilters";

const VolvoReadIndex: React.FC = () => {
	const { t } = useTranslation("quota");
	const { lastUpdateDate, proposalLineTypes, resetStore } = useVolvoReadStore();

	const reset = React.useCallback(() => {
		resetStore();
	}, [resetStore]);

	React.useEffect(() => {
		reset();
	}, [reset]);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("quota.volvoRead", { ns: "menu" })} - ${DateTimeToString(
				lastUpdateDate,
				"LLL"
			)}`,
			main: true,
		},
	];

	return (
		<>
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs} />
				<VolvoReadFilters />
			</Grid>
			<Grid item lg={12}>
				{proposalLineTypes &&
					proposalLineTypes.map((proposalType) => (
						<AccordionCard
							title={t(`typeTitle.${proposalType.toLowerCase()}`)}
							id={`accordion-${proposalType}`}
							key={`accordion-${proposalType}`}
							defaultExpanded={proposalType === "DEALER"}
							overflow="auto"
						>
							<VolvoReadGrid type={proposalType} />
						</AccordionCard>
					))}
			</Grid>
		</>
	);
};

export default observer(VolvoReadIndex);
