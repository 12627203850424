import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { LocalizationAccordionProps } from "../../interfaces/Accordions/LocalizationAccordion";
import LocalizationInvoicesSection from "../Sections/LocalizationInvoicesSection";

const LocalizationAccordion: React.FC<LocalizationAccordionProps> = (
	details
) => {
	const { t } = useTranslation("order");
	const locationStatusLabel =
		(details.locationStatus || 0) === 0
			? t("details.locationStatus.internal")
			: t("details.locationStatus.external");

	return (
		<AccordionCard
			id="localizationAccordion"
			title={`${t("details.accordions.localization")} - ${locationStatusLabel}`}
		>
			<LocalizationInvoicesSection {...details.localizationInvoicesDetails} />
		</AccordionCard>
	);
};

export default observer(LocalizationAccordion);
