import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextPreFilter from "../components/TextPreFilter";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import { GRID_MAX_SIZE } from "../../../constants/gridConst";

const QUOTATIONID_PRE_FILTER_XS = GRID_MAX_SIZE;
const QUOTATIONID_PRE_FILTER_SM = GRID_MAX_SIZE;
const QUOTATIONID_PRE_FILTER_MD = 6;
const QUOTATIONID_PRE_FILTER_LG_XL = 4;

const QuotationIDPreFilter: React.FC<TextPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "quotationIdPreFilter",
		label = "preFilters.quotationId",
		xs = QUOTATIONID_PRE_FILTER_XS,
		sm = QUOTATIONID_PRE_FILTER_SM,
		md = QUOTATIONID_PRE_FILTER_MD,
		lg = QUOTATIONID_PRE_FILTER_LG_XL,
		xl = QUOTATIONID_PRE_FILTER_LG_XL,
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			tooltip={t("tooltips.multipleSearch")}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(QuotationIDPreFilter);
