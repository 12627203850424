import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../FormInputs/TextForm";
import DateForm from "../FormInputs/DateForm";
import OverlineTextCentered from "../OverlineTextCentered/OverlineTextCentered";

const BaseInvoicingDeliveryMarketCompanySection: React.FC<any> = ({ readOnlyFields }) => {
	const { t } = useTranslation(["order", "general"]);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.invoicingDeliveryMarketCompany.title")}
			</Grid>
			{readOnlyFields.invoicedNumber || readOnlyFields.deliveredNumber ? (
				<>
					{readOnlyFields.invoicedNumber && (
						<>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentNumber"
									)}
									value={readOnlyFields.invoicedNumber}
								/>
							</Grid>
							<Grid item xs={4}>
								<DateForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentDate"
									)}
									value={readOnlyFields.invoicedDate}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.label"
									)}
									value={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.invoiced"
									)}
								/>
							</Grid>
						</>
					)}
					{readOnlyFields.deliveredNumber && (
						<>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentNumber"
									)}
									value={readOnlyFields.deliveredNumber}
								/>
							</Grid>
							<Grid item xs={4}>
								<DateForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentDate"
									)}
									value={readOnlyFields.deliveredDate}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.label"
									)}
									value={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.delivered"
									)}
								/>
							</Grid>
						</>
					)}
				</>
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(BaseInvoicingDeliveryMarketCompanySection);
