import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import SelectPreFilter from "../components/SelectPreFilter";
import { SelectPreFilterProps } from "../Interfaces/SelectPreFilterProps";

const BUSAPP_PRE_FILTER_XS = 4;
export const BUSAPP_PRE_FILTER_SM = 3;
const BUSAPP_PRE_FILTER_MD_LG = 2;
const BUSAPP_PRE_FILTER_XL = 1;

const BusApplicationPreFilter: React.FC<SelectPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "busApplicationPreFilter",
		label = "preFilters.busApplication",
		xs = BUSAPP_PRE_FILTER_XS,
		sm = BUSAPP_PRE_FILTER_SM,
		md = BUSAPP_PRE_FILTER_MD_LG,
		lg = BUSAPP_PRE_FILTER_MD_LG,
		xl = BUSAPP_PRE_FILTER_XL,
	} = props;
	return (
		<SelectPreFilter
			{...props}
			id={id}
			label={t(label)}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};
export default observer(BusApplicationPreFilter);
