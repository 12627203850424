import React from "react";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useIsAuthenticated,
	useMsal,
} from "@azure/msal-react";
import DashboardContent from "./DashboardContent";
import UnauthenticatedError from "../components/msal/UnauthenticatedError";
import "@progress/kendo-theme-material/dist/all.css";
import AppRoutes from "../pages/routes";
import { useAuthStore } from "../auth/store/AuthStore";
import { LoadingPage } from "../components/LoadingPage/LoadingPage";
import "./style/style.css";
import "react-toastify/dist/ReactToastify.css";
import TelemetryProvider from "../components/AppInsights/TelemetryProvider";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const { handleLogin, signingIn, setSigningIn } = useAuthStore();

	React.useEffect(() => {
		if (!isAuthenticated) {
			setSigningIn(true);
			handleLogin("redirect", instance);
		} else {
			setSigningIn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App">
			<LoadingPage loading={signingIn} />
			{!signingIn && (
				<>
					<AuthenticatedTemplate>
						<TelemetryProvider>
							<DashboardContent>
								<AppRoutes />
							</DashboardContent>
						</TelemetryProvider>
					</AuthenticatedTemplate>

					<UnauthenticatedTemplate>
						<UnauthenticatedError />
					</UnauthenticatedTemplate>

					<ToastContainer />
				</>
			)}
		</div>
	);
};

export default App;
