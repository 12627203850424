export interface OrderMCDeliveryEditableProps {
	orderId: string;
	deliveryProbability: string;
	firmBilling: Date | null;
	financialDelivery: Date | null;
	physicalDelivery: Date | null;
	finalDelivery: Date | null;
	financialDeliveryUser: string;
	finalDeliveryUser: string;
	physicalDeliveryUser: string;
	firmBillingUser: string;
}

export interface OrderMCDeliveryFinancialEditableProps {
	orderId: string;
	financialDelivery: Date | null;
	financialDeliveryUser: string;
}

const CURRENT_DATE_USER_FIELDS = [
	"financialDelivery",
	"finalDelivery",
	"firmBilling",
	"physicalDelivery",
] as const;
type OrderMCDeliveryCurrentDateUserTypeTuple = typeof CURRENT_DATE_USER_FIELDS;

export type OrderMCDeliveryCurrentDateUserType =
	OrderMCDeliveryCurrentDateUserTypeTuple[number];

export function isOrderMCDeliveryCurrentDateUserType(
	value: string
): value is OrderMCDeliveryCurrentDateUserType {
	return CURRENT_DATE_USER_FIELDS.includes(
		value as OrderMCDeliveryCurrentDateUserType
	);
}
