export enum CsvRequestOrigin {
	ClosedPeriodArgTrucks,
	ClosedPeriodArgBuses,
	FollowUpArgTrucks,
	FollowUpArgBuses,
	FollowUpChlTrucks,
	OrdersTrucks,
	OrdersBus,
	Europe,
}
