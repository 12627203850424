import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { ForecastBillingSectionProps } from "../../interfaces/Sections/ForecastBillingSection";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg,
	groupsAllowedToUpdateForecastBillingClosedPeriodOrdersArg,
} from "../../../../../constants/AuthGroups/closedPeriodArgPage";

const ForecastBillingSection: React.FC<ForecastBillingSectionProps> = ({
	readOnlyFieldsProps,
	editableFields,
	setOrderMCManagementFieldByKeyOnChange,
}) => {
	const { t } = useTranslation("order");

	const { isAuthorizedGroup } = useAuthStore();

	const shouldSeeAll = isAuthorizedGroup(
		groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg
	);

	const canUpdate = isAuthorizedGroup(
		groupsAllowedToUpdateForecastBillingClosedPeriodOrdersArg
	);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.forecastBilling.title")}
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.shippingDate")}
					value={readOnlyFieldsProps.shippingDate}
				/>
			</Grid>
			{shouldSeeAll && (
				<Grid item xs={4}>
					<TextForm
						label={t("details.sections.forecastBilling.shippingNumber")}
						value={editableFields.shippingNumber}
						name="shippingNumber"
						inputProps={{ maxLength: 100 }}
						editable={canUpdate}
						onChange={(e) =>
							setOrderMCManagementFieldByKeyOnChange(
								"shippingNumber",
								e.target.value
							)
						}
					/>
				</Grid>
			)}
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.certificateDate")}
					value={editableFields.certificateDate}
					editable={canUpdate}
					onChange={(dataItem: any) =>
						setOrderMCManagementFieldByKeyOnChange("certificateDate", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.billingForecast")}
					inputFormat="MMMM/yy"
					value={readOnlyFieldsProps.billingForecast}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.forecastBilling.shippingCompany")}
					value={readOnlyFieldsProps.shippingCompany}
					name="shippingCompany"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.forecastBilling.destination")}
					value={readOnlyFieldsProps.destination}
					name="destination"
				/>
			</Grid>
		</>
	);
};

export default observer(ForecastBillingSection);
