import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import _ from "lodash";
import PopoverOverlay from "../../../../../components/PopoverOverlay/PopoverOverlay";
import InfoIcon from "@mui/icons-material/Info";
import { useGroupDistrictStore } from "../stores/GroupDistrictStore";
import { toast } from "react-toastify";
import SaveBar from "../../../../../components/SaveBar/SaveBar";

const DistrictGroupGrid: React.FC = () => {
	const { t } = useTranslation("general");

	const {
		resetDistrictGroupEdit,
		districtGroupEdit,
		districtGroupInitial,
		setDistrictIntoDistrictGroup,
		updateDistrictGroupDistricts,
	} = useGroupDistrictStore();

	const handleSubmit = async () => {
		await updateDistrictGroupDistricts()
			.then(() => toast.success(t(`updatedRecordWithSuccess`).toString()))
			.catch((err) => toast.error(err.response.data));
	};

	return (
		<>
			<Grid item lg={12}>
				<Grid
					container
					rowSpacing={1}
					columnSpacing={1}
					style={{ margin: "0 -4px" }}
				>
					{districtGroupEdit.map((districtGroup, i) => (
						<Grid key={i} item lg={6}>
							<AccordionCard
								id="accordion"
								title={districtGroup.name}
								gridNumber={12}
								key={i}
								preTitle={
									<PopoverOverlay
										header={districtGroup.name}
										body={districtGroup.description}
									>
										<span>
											<InfoIcon fontSize="small" style={{ fontSize: 16 }} />
										</span>
									</PopoverOverlay>
								}
							>
								<div style={{ paddingLeft: 25 }}>
									{districtGroup.districts?.map((district, i) => (
										<FormControlLabel
											control={
												<Checkbox
													checked={district?.isAssociated}
													color="secondary"
													onChange={() =>
														setDistrictIntoDistrictGroup(
															district,
															districtGroup
														)
													}
													style={{ padding: 6 }}
												/>
											}
											label={`[${district?.countryName}] ${district?.value}`}
											key={i}
										/>
									))}
								</div>
							</AccordionCard>
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid item lg={12} mt={4}>
				<SaveBar
					hasFormChanges={!_.isEqual(districtGroupEdit, districtGroupInitial)}
					onResetClick={resetDistrictGroupEdit}
					resetLabel={t("clear")}
					onSaveClick={handleSubmit}
					saveLabel={t("save")}
				/>
			</Grid>
		</>
	);
};

export default observer(DistrictGroupGrid);
