import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../apis/CtadmvenApi";
import { IPreFilterCommonOption } from "../Interfaces/IPreFilter";
import { getLabel } from "../../../utils/PaymentTermsUtil";
import { getFilterObject } from "../../../utils/FilterOperations";
import BasePreFilterStringArrayStore from "./BasePreFilterStringArrayStore";

configure({ enforceActions: "always" });

class PreFilterPaymentFormStore extends BasePreFilterStringArrayStore {
	constructor(
		rootStore: any,
		private api: CtadmvenApi
	) {
		super(rootStore, "managementDetail.paymentTermsId");
		makeObservable(this);
	}

	@action private setPaymentFormOptions = (data: any, language: string) => {
		this.preFilterOptions = [];

		if (!data) {
			return;
		}

		this.preFilterOptions = toJS(data).map(
			(m: any) =>
				({
					label: getLabel(m, language),
					value: m.id,
				}) as IPreFilterCommonOption
		);
	};

	@action getOptions = async () => 
		this.baseGetOptions(this.setPaymentFormOptions, this.api.paymentTermsService.getPaymentTerms);
}

export default PreFilterPaymentFormStore;
