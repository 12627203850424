import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { VolvoManagementSectionProps } from "../../interfaces/Sections/VolvoManagementSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getBooleanOptions } from "../../../../../utils/SelectOptions";
import _ from "lodash";

const VolvoManagementSection: React.FC<VolvoManagementSectionProps> = ({
	editable,
	editableFields,
	setEditableFieldByKeyOnChange,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const booleanOptions = getBooleanOptions(t);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.volvoManagement.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t(
						"details.sections.volvoManagement.commercialAreaClassification"
					)}
					value={editableFields.commercialAreaClassification}
					editable={editable}
					name="commercialAreaClassification"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setEditableFieldByKeyOnChange(
							"commercialAreaClassification",
							e.target.value
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.volvoManagement.location")}
					value={editableFields.location}
					editable={editable}
					name="location"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setEditableFieldByKeyOnChange("location", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.volvoManagement.followUp")}
					value={editableFields.followUp}
					editable={editable}
					onChange={(dataItem: any) =>
						setEditableFieldByKeyOnChange("followUp", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.volvoManagement.responsible")}
					value={editableFields.responsible}
					editable={editable}
					name="responsible"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setEditableFieldByKeyOnChange("responsible", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.volvoManagement.costCenter")}
					value={editableFields.costCenter}
					editable={editable}
					name="costCenter"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setEditableFieldByKeyOnChange("costCenter", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.volvoManagement.builded")}
					value={editableFields.builded}
					editable={editable}
					hasEditIcon
					name="builded"
					onChange={(e) =>
						setEditableFieldByKeyOnChange("builded", Boolean(e.target.value))
					}
					options={booleanOptions}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.volvoManagement.purpose")}
					value={editableFields.purpose}
					editable={editable}
					name="purpose"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setEditableFieldByKeyOnChange("purpose", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.volvoManagement.freeDate")}
					value={editableFields.freeDate}
					editable={editable}
					onChange={(dataItem: any) =>
						setEditableFieldByKeyOnChange("freeDate", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.volvoManagement.expectedKM")}
					value={editableFields.expectedKM}
					editable={editable}
					name="expectedKM"
					onChange={(e) =>
						setEditableFieldByKeyOnChange(
							"expectedKM",
							_.parseInt(e.target.value)
						)
					}
					type="number"
				/>
			</Grid>
		</>
	);
};

export default observer(VolvoManagementSection);
