import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import { AutoCompleteOptionProps } from "../../../../../../utils/GenericInterfaces";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterSalesmanStore {
	@observable rootStore;
	@observable loading = false;
	@observable preFilter = "";
	@observable preFilterOptions: Array<AutoCompleteOptionProps> = [];

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = "");

	@action get = () =>
		!this.preFilter || this.preFilter === ""
			? []
			: this.preFilter
				.split(",")
				.map((d: string) => getFilterObject("salesman.id", d, "eq"));

	@action set = (value: string) => {
		this.preFilter = value;
	};

	@action getSalesmanOptions = async () => {
		if (this.preFilterOptions.length > 0) {
			return;
		}

		this.loading = true;
		await this.api.salesmanService
			.getSalesmanOptions()
			.then((rs: any) => {
				if (rs.data) {
					this.preFilterOptions = toJS(rs.data).map(
						(m: any) =>
							({
								label: m.displayName,
								value: m.id,
								searchValue: `${m.displayName}/${m.email}/${m.tbCode}`,
							}) as AutoCompleteOptionProps
					);
				}
			})
			.finally(() => (this.loading = false));
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const salesmanFilters = this.get();
		if (salesmanFilters) {
			orFiltersGroups.push(salesmanFilters);
		}
	}

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterSalesmanStore;
