import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { TruckEditableProps } from "../../pages/FollowUpChl/Details/interfaces/Editable/TruckEditableDetails";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";
import { EuropeEditableProps } from "../../pages/FollowUpChl/Details/interfaces/Editable/EuropeEditableDetails";
import { BatchDataProps } from "../../pages/FollowUpChl/List/interfaces/BatchEdition/BatchDataProps";

interface IFollowUpChlService {
	listPaginated(dataState: State): Promise<any>;
	getDetails(id: string): Promise<any>;
	getEuropeDetails(id: string): Promise<any>;
	getDetailsVariants(id: string): Promise<any>;
	getDetailsVariantsRespecList(id: string): Promise<any>;
	getInvoiceExportationByOrderId(id: string): Promise<any>;
	getInvoicingDeliveryMarketCompanyByOrderId(id: string): Promise<any>;
	update(orderDetails: TruckEditableProps): Promise<any>;
	updateEurope(europeDetails: EuropeEditableProps): Promise<any>;
	getReservationExportDetails(id: string): Promise<any>;
	csvExport(dataState: State, csvOptions: CsvOptionsProps): Promise<any>;
	updateBatch(dataState: State, orderDetails: BatchDataProps): Promise<any>;
}

const base = "/followUpChl";

export class FollowUpChlService implements IFollowUpChlService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list?${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/details/${id}`);

	getEuropeDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/europeDetails/${id}`);

	getDetailsVariants = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variants/${id}`);

	getDetailsVariantsRespecList = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/respecVariant/${id}`);

	getInvoiceExportationByOrderId = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/orderInvoiceExp/${id}`);

	getInvoicingDeliveryMarketCompanyByOrderId = async (
		id: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/orderInvoiceMarketcompany/${id}`);

	update = async (orderDetails: TruckEditableProps): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/`, JSON.stringify(orderDetails));

	updateEurope = async (europeDetails: EuropeEditableProps): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/europe`,
			JSON.stringify(europeDetails)
		);

	getReservationExportDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/reservationExport/${id}`);

	csvExport = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	updateBatch = async (
		dataState: State,
		orderDetails: BatchDataProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batch?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(orderDetails)
		);
}
