import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { MktCompanyDeliverySectionProps } from "../../interfaces/Sections/MktCompanyDeliverySection";
import RadioGroupForm from "../../../../../components/FormInputs/RadioGroupForm";
import { getDeliveryProbabilityOptions } from "../../../../../utils/RadioGroupOptions";

const MktCompanyDeliverySection: React.FC<MktCompanyDeliverySectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation("order");

	const deliveryProbabilityOptions = getDeliveryProbabilityOptions(t);

	const getDateAndUserFields = (
		name:
			| "firmBilling"
			| "financialDelivery"
			| "physicalDelivery"
			| "finalDelivery"
	) => {
		const userField = (name + "User") as
			| "firmBillingUser"
			| "financialDeliveryUser"
			| "physicalDeliveryUser"
			| "finalDeliveryUser";

		return (
			<>
				<Grid item xs={4}>
					<DateForm
						label={t(`details.sections.mktCompanyDelivery.${name}Date`)}
						value={readOnlyFields[name]}
					/>
				</Grid>
				<Grid item xs={8}>
					<TextForm
						label={t(`details.sections.mktCompanyDelivery.${userField}`)}
						value={readOnlyFields[userField]}
					/>
				</Grid>
			</>
		);
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.mktCompanyDelivery.title")}
			</Grid>
			<Grid item xs={3}>
				<RadioGroupForm
					label={t("details.sections.mktCompanyDelivery.deliveryProbability")}
					labelId={"details.sections.mktCompanyDelivery.deliveryProbability"}
					items={deliveryProbabilityOptions}
					value={readOnlyFields.deliveryProbability}
				/>
			</Grid>
			<Grid container item xs={9} spacing={1}>
				{getDateAndUserFields("firmBilling")}
				{getDateAndUserFields("financialDelivery")}
				{getDateAndUserFields("physicalDelivery")}
				{getDateAndUserFields("finalDelivery")}
			</Grid>
		</>
	);
};

export default observer(MktCompanyDeliverySection);
