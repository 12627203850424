import {
	Grid,
	GridColumn,
	GridItemChangeEvent,
	GridToolbar,
} from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { QuotaProposalEditProps } from "../interfaces/QuotaProposalEditProps";
import months from "../models/months.json";
import { useEditQuotaStore } from "../stores/EditQuotaStore";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import _ from "lodash";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Save, Undo } from "@mui/icons-material";
import "../style/edit.css";
import { getColumns, setAllInEdit } from "../utils/GridUtils";
import { useAuthStore } from "../../../../auth/store/AuthStore";

const MAX_VALUE = 999;
const MIN_VALUE = 0;

const EditGrid: React.FC = () => {
	const { t } = useTranslation("general");

	const { quotaProposalEdit, setEditProposals } = useEditQuotaStore();

	const { graphData } = useAuthStore();

	const userEmail = graphData?.mail ?? "";

	const _export = React.useRef<ExcelExport | null>(null);

	const allInEdit: Array<QuotaProposalEditProps> =
		setAllInEdit(quotaProposalEdit);

	const [data, setData] =
		React.useState<Array<QuotaProposalEditProps>>(allInEdit);

	const handleSubmit = async (data: Array<QuotaProposalEditProps>) => {
		await setEditProposals(data, userEmail)
			.then(() => {
				toast.success(t("editRecordWithSuccess").toString());
			})
			.catch((err) => toast.error(err.response.data));
	};

	const handlePaste = (e: any) => {
		if (e.target.tagName && e.target.tagName.match(/(input|textarea)/i)) {
			// Do not handle past when an input element is currently focused
			return;
		}

		// Get clipboard data as text
		const data = e.clipboardData.getData("text");

		const hasNumber = /\d/;

		// Simplified parsing of the TSV data with hard-coded columns
		const rows = data.split("\n");
		const result = rows
			.filter(
				(row: any) =>
					row.split("\t").length === 13 &&
					hasNumber.test(row) &&
					row.split("\t")[0] !== ""
			)
			.map((row: any) => {
				var line = {};
				const cells = row.split("\t");

				line = {
					region: cells[0],
					inEdit: true,
				};

				months.forEach((m, idx) => {
					let proposal = cells[idx + 1].replace(/\D/g, "");
					proposal = proposal === "" ? 0 : parseInt(proposal);
					line = { ...line, [m]: proposal };
				});

				return line;
			});

		setData(result);
	};

	const excelExport = () => {
		if (_export.current !== null) {
			_export.current.save();
		}
	};

	const itemChange = (e: GridItemChangeEvent) => {
		if (e.value > MAX_VALUE) {
			return;
		}

		if (e.value < MIN_VALUE) {
			return;
		}

		let newData = data.map((item: any) => {
			if (item.region === e.dataItem.region) {
				item[e.field || ""] = e.value;
			}

			return item;
		});

		setData(newData);
	};

	return (
		<div onPaste={handlePaste}>
			<ExcelExport data={data} ref={_export}>
				<Grid
					data={data}
					scrollable="none"
					editField="inEdit"
					onItemChange={itemChange}
				>
					<GridToolbar>
						<div>
							<Button onClick={excelExport} sx={{ color: "black" }}>
								<FileDownloadIcon fontSize="small" />
								{t("exportToExcel")}
							</Button>
						</div>
						<div className="right-buttons-header">
							<Button
								color="secondary"
								onClick={() => setData(allInEdit)}
								disabled={_.isEqual(allInEdit, data)}
							>
								<Undo fontSize="small" />
								{t("clear")}
							</Button>
							<Button
								color="secondary"
								type={"submit"}
								onClick={() => handleSubmit(data)}
								className="ms-2 btn-sm"
								disabled={_.isEqual(allInEdit, data)}
							>
								<Save fontSize="small" />
								{t("save")}
							</Button>
						</div>
					</GridToolbar>
					{getColumns(t, data).map((col: any, i: number) => {
						return <GridColumn key={i} {...col} />;
					})}
				</Grid>
			</ExcelExport>
		</div>
	);
};

export default observer(EditGrid);
