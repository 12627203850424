import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import {
	Stack,
	ListItemButton,
	ListItemText,
	Divider,
	ClickAwayListener,
	ListItemIcon,
	List,
	Button,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { AccountCircle } from "@mui/icons-material";
import RolesDocumentationAction from "./RolesDocumentationAction";

interface UserMenuProps {
	userName?: string;
	userJobTitle?: string;
	signOutEvent?: React.MouseEventHandler<HTMLDivElement>;
	isOwnerUser?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = ({
	userName = "Not Found",
	userJobTitle = "Not Found",
	signOutEvent,
	isOwnerUser,
}) => {
	const handleClickAway = () => {
		setOpen(false);
	};
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	return (
		<Box pr={2}>
			<Button
				variant="text"
				color="secondary"
				aria-label="open user profile"
				aria-controls="user-profile-popper"
				ref={anchorRef}
				aria-haspopup="true"
				onClick={handleToggle}
				startIcon={<AccountCircle />}
			>
				{userName}
			</Button>
			<Popper
				id="user-profile-popper"
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Paper>
						<ClickAwayListener onClickAway={handleClickAway}>
							<Fade {...TransitionProps}>
								<Box sx={{ p: 1 }}>
									<Stack>
										<Typography variant="body2" sx={{ fontWeight: "bold" }}>
											{userName}
										</Typography>
										<Typography variant="subtitle2">{userJobTitle}</Typography>
									</Stack>
									<Divider />
									<Box>
										<List dense>
											{isOwnerUser && <RolesDocumentationAction />}
											<ListItemButton onClick={signOutEvent}>
												<ListItemIcon>
													<LogoutIcon fontSize="small" />
												</ListItemIcon>
												<ListItemText primary="Sign Out" />
											</ListItemButton>
										</List>
									</Box>
								</Box>
							</Fade>
						</ClickAwayListener>
					</Paper>
				)}
			</Popper>
		</Box>
	);
};

export default UserMenu;
