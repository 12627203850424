import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import GeneralCommentsForm from "./Form/GeneralCommentsForm";

const GeneralUserContent: React.FC = () => {
	return (
		<Grid item xs={12}>
			<GeneralCommentsForm />
		</Grid>
	);
};

export default observer(GeneralUserContent);
