export const orderKeyLabels = {
	salesman: {
		key: "salesman",
		label: "details.sections.customer.salesman",
	},
	branchOffice: {
		key: "branchOffice",
		label: "details.sections.customer.branchoffice",
	},
	sasCustomer: {
		label: "details.sections.customer.sasCustomer",
	},
	quotaDistrict: {
		key: "quotaDistrict",
		label: "details.sections.quotaInformation.quotaDistrict",
	},
	quotaRegion: {
		key: "quotaRegion",
		label: "details.sections.quotaInformation.quotaRegion",
	},
	extraQuota: {
		key: "extraQuota",
		label: "details.sections.commercial.extraQuota",
	},
	priority: {
		key: "priority",
		label: "details.sections.commercial.priority",
	},
	commercialComment: {
		key: "commercialComment",
		label: "details.sections.commercialComments.title",
	},
	generalComment: {
		label: "details.sections.generalComments.title",
	},
};

export const getUserCanEditFinalCustomerContent = (
	dealerCanEdit: boolean,
	isAuthorized: boolean
) => dealerCanEdit || isAuthorized;

export const getUserCanEditVolvoManagementGeneralCommentContent = (
	dealerCanEdit: boolean,
	isAuthorized: boolean
) => dealerCanEdit || isAuthorized;

export const getUserCanEditCustomerContent = (
	dealerCanEdit: boolean,
	isAdmin: boolean
) => dealerCanEdit || isAdmin;

export const getUserCanDoBatchEdition = (
	userCanEditQuotaContent: boolean,
	userCanEditVolvoManagementContent: boolean,
	userCanEditVolvoManagementGeneralCommentContent: boolean,
	userCanEditCustomerContent: boolean
) =>
	userCanEditQuotaContent ||
	userCanEditVolvoManagementContent ||
	userCanEditVolvoManagementGeneralCommentContent ||
	userCanEditCustomerContent;

export const getIsValidListSize = (listTotalResult: number) =>
	listTotalResult > 0 && listTotalResult <= 80;
