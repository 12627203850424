import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import { StoreUtils } from "../../../../utils/StoreUtils";

configure({ enforceActions: "always" });

/* Store start */
class CommonStore {
	@observable orderId: string = "";
	@observable menuKey: string = "";
	@observable menuType: string = "";
	@observable userDisplayName: string = "";
	@observable hasChanges: boolean = false;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action validateBaseRequest = () => {
		if (!this.orderId || !this.menuKey)
			throw console.log("Invalid Id or Menu Key");
	};

	@action resetAllStores = (id: string, menuKey: string) => {
		this.setStore(id, menuKey);
		this.rootStore.busStore.resetStore(menuKey);
		this.rootStore.truckStore.resetStore(menuKey);
		this.rootStore.volvoManagementStore.setStore();
	};

	@action setStore = (id: string, menuKey: string) => {
		this.orderId = id;
		this.menuKey = menuKey;
		this.menuType = "";
		this.userDisplayName = "";
		this.hasChanges = false;
	};

	@action setBaseFields = (userDisplayName: string) => {
		this.userDisplayName = userDisplayName;
	};

	@action setHasChanges = () => {
		this.hasChanges =
			this.rootStore.busStore.hasChanges ||
			this.rootStore.truckStore.hasChanges ||
			this.rootStore.volvoManagementStore.hasChanges;
	};

	@action update = async () => {
		await this.rootStore.volvoManagementStore.update();
	};

	@action loadVariantsRespec = async (id: string, setVariantsReespec: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderService.getDetailsVariantsRespecList(id, this.menuKey),
			setVariantsReespec,
			id,
			this.menuKey
		);
	};

	@action loadSaleInvoice = async (id: string, setSaleInvoice: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceService.getSaleInvoiceByOrderId(id, this.menuKey),
			setSaleInvoice,
			id,
			this.menuKey
		);
	};

	@action loadLocalizationInvoices = async (id: string, setLocalizationInvoices: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceService.getLocalizationInvoicesByOrderId(id, this.menuKey),
			setLocalizationInvoices,
			id,
			this.menuKey
		);
	};

	@action loadReservationAvailability = async (id: string, setReservationAvailability: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderService.getReservationAvailabilityDetails(id, this.menuKey),
			setReservationAvailability,
			id,
			this.menuKey
		);
	};

	@action loadInvoiceExportation = async (id: string, setInvoiceExportation: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceService.getInvoiceExportationByOrderId(id, this.menuKey),
			setInvoiceExportation,
			id,
			this.menuKey
		);
	};

	@action loadInvoicingDeliveryMarketCompany = async (id: string, setInvoicingDeliveryMarketCompany: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceService.getInvoicingDeliveryMarketCompanyByOrderId(id, this.menuKey),
			setInvoicingDeliveryMarketCompany,
			id,
			this.menuKey
		);
	};

	@action loadAllInvoiceInstruction = async (id: string, setAllInvoiceInstruction: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceInstructionService.getByOrderId(id, this.menuKey),
			setAllInvoiceInstruction,
			id,
			this.menuKey
		);
	};

	@action loadMinimalInvoiceInstruction = async (id: string, setMinimalInvoiceInstruction: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceInstructionService.getMinimalInformationByOrderId(id, this.menuKey),
			setMinimalInvoiceInstruction,
			id,
			this.menuKey
		);
	};

	@action loadAllInvoiceInstructionExportation = async (id: string, setAllInvoiceInstructionExportation: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceInstructionService.getExportationByOrderId(id, this.menuKey),
			setAllInvoiceInstructionExportation,
			id,
			this.menuKey
		);
	};

	@action loadMinimalInvoiceInstructionExportation = async (id: string, setMinimalInvoiceInstructionExportation: any) => {
		await StoreUtils.LoadData(
			() => this.api.orderInvoiceInstructionService.getExportationMinimalInformationByOrderId(id, this.menuKey),
			setMinimalInvoiceInstructionExportation,
			id,
			this.menuKey

		);
	};

	@action loadInvoiceInstruction = async (id: string, userCanSeeAllInfo: boolean, loadAllInvoiceInstruction: any, loadMinimalInvoiceInstruction: any) => {
		if (!id || !this.menuKey) {
			return;
		}
		if (userCanSeeAllInfo) {
			await loadAllInvoiceInstruction(id);
		}
		else {
			await loadMinimalInvoiceInstruction(id);
		}
	};

	@action loadInvoiceInstructionExportation = async (id: string, userCanSeeAllInfo: boolean, loadAllInvoiceInstructionExportation: any, loadMinimalInvoiceInstructionExportation: any) => {
		if (!id || !this.menuKey) {
			return;
		}
		if (userCanSeeAllInfo) {
			await loadAllInvoiceInstructionExportation(id);
		}
		else {
			await loadMinimalInvoiceInstructionExportation(id);
		}
	};
}
/* Store end */
export default CommonStore;
