import { Grid } from "@mui/material";
import PopoverOverlay from "../../../../../components/PopoverOverlay/PopoverOverlay";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { observer } from "mobx-react";
import { ProductionDatesSectionProps } from "../../interfaces/Sections/ProductionDatesSection";

const ProductionDatesSection: React.FC<ProductionDatesSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.productionDates.title")}
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.lcd")}
					body={t("tooltips.lcd")}
				>
					<DateForm
						label={t("details.sections.productionDates.lcd")}
						value={readOnlyFields.lcd}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.cdd")}
					body={t("tooltips.cdd")}
				>
					<DateForm
						label={t("details.sections.productionDates.cdd")}
						value={readOnlyFields.cdd}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.cddEol")}
					body={t("tooltips.cddEol")}
				>
					<DateForm
						label={t("details.sections.productionDates.cddEol")}
						value={readOnlyFields.cddeol}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.initialAsweek")}
					body={t("tooltips.initialAsweek")}
				>
					<DateForm
						label={t("details.sections.productionDates.initialAsweek")}
						value={readOnlyFields.asWeekDateInitial}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.asWeekDate")}
					body={t("tooltips.asWeekDate")}
				>
					<DateForm
						label={t("details.sections.productionDates.asWeekDate")}
						value={readOnlyFields.asWeekDate}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.asweekDelay")}
					body={t("tooltips.asweekDelay")}
				>
					<TextForm
						label={t("details.sections.productionDates.asweekDelay")}
						value={readOnlyFields.asWeekDelay}
						type="number"
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.online")}
					body={t("tooltips.online")}
				>
					<DateForm
						label={t("details.sections.productionDates.online")}
						value={readOnlyFields.online}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.offline")}
					body={t("tooltips.offline")}
				>
					<DateForm
						label={t("details.sections.productionDates.offline")}
						value={readOnlyFields.offline}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.onlineDelay")}
					body={t("tooltips.onlineDelay")}
				>
					<TextForm
						label={t("details.sections.productionDates.onlineDelay")}
						value={readOnlyFields.onlineDelay}
						type="number"
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.greenOk")}
					body={t("tooltips.greenOk")}
				>
					<DateForm
						label={t("details.sections.productionDates.greenOk")}
						value={readOnlyFields.greenOk}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.lineSequenceDate")}
					body={t("tooltips.lineSequenceDate")}
				>
					<DateForm
						label={t("details.sections.productionDates.lineSequenceDate")}
						value={readOnlyFields.lineSequenceDate}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.lineSequence")}
					body={t("tooltips.lineSequence")}
				>
					<TextForm
						label={t("details.sections.productionDates.lineSequence")}
						value={readOnlyFields.lineSequence}
					/>
				</PopoverOverlay>
			</Grid>
		</>
	);
};

export default observer(ProductionDatesSection);
