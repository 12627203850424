import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeVolvoReadMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_QUOTA,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
	];

export const groupsAllowedToSeeEditQuotaMenu: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.BRA_SALES_SUPPORT_QUOTA];

export const groupsAllowedToSeeDealerReadRoute: Array<AuthGroups.AuthGroupsType> =
	[...AuthGroups.BRA_DEALERS];

export const groupsAllowedToSeeVolvoDealerReadMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_QUOTA,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_DEALER_TNE_TNO,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
	];

export const groupsAllowedToSeeExportReadMenu: Array<AuthGroups.AuthGroupsType> =
	[
		AuthGroups.ADMIN,
		AuthGroups.BRA_SALES_SUPPORT_QUOTA,
		AuthGroups.BRA_SALES_SUPPORT_VDB,
		AuthGroups.BRA_SALES_SUPPORT_EXPORT,
		AuthGroups.BRA_SALES_SUPPORT_MANAGEMENT_TRUCK,
		AuthGroups.CHL_MARKET_COMPANY,
		AuthGroups.PER_MARKET_COMPANY,
		AuthGroups.ARG_MARKET_COMPANY,
		AuthGroups.PI_SALES_SUPPORT,
		AuthGroups.ARG_MARKET_COMPANY_ENGINEERING
	];
