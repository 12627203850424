import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import GeneralAccordion from "./Accordions/GeneralAccordion";
import DetailsTimeline from "./Utils/TimeLine";
import SaveBar from "../../../../components/SaveBar/SaveBar";
import VehicleAccordion from "./Accordions/VehicleAccordion";
import ProductionAccordion from "./Accordions/ProductionAccordion";
import NoContentResult from "../../../../components/msal/NoContentResult";
import AvailabilityInvoicingDeliveryAccordion from "./Accordions/AvailabilityInvoicingDeliveryAccordion";
import CommercialAccordion from "./Accordions/CommercialAccordion";
import { getBreadcrumbs } from "./Utils/BreadcrumbsUtils";
import { ModelStateProps } from "../interfaces/Utils/Validation";
import { useFollowUpChlStore } from "../stores/FollowUpChlStore";
import LocalizationExportAccordion from "./Accordions/LocalizationExportAccordion";

const TruckIndex: React.FC = () => {
	const { t } = useTranslation(["ctadmven", "menu", "general"]);
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = React.useState(true);

	const {
		readOnlyDetails,
		editableDetails,
		setEditableDetailsFieldByKeyOnChange,
		loadDetails,
		loadVariants,
		loadVariantsRespec,
		loadInvoiceExportation,
		loadInvoicingDeliveryMarketCompany,
		loadLocalizationExport,
		update,
		loadReservationExport,
		hasChanges,
	} = useFollowUpChlStore();

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		const orderId = id ?? "";

		await loadDetails(orderId).finally(() => setLoading(false));
	}, [id, loadDetails]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const handleErrors = (data: string | ModelStateProps) => {
		if (typeof data === "string") toast.error(data);
		else {
			toast.error(data.title);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		await update()
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => handleErrors(err.message))
			.finally(() => fetchDetails());
	};

	const breadcrumbs = getBreadcrumbs(t);

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && readOnlyDetails.id && (
				<>
					<TitleBox breadcrumbs={breadcrumbs} />
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs>
								<Grid container spacing={2}>
									<GeneralAccordion
										lastUpdateDetails={{ ...readOnlyDetails }}
										generalDetails={{ readOnlyFields: { ...readOnlyDetails } }}
									/>
									<CommercialAccordion
										customerDetails={{
											readOnlyFields: { ...readOnlyDetails },
											editableFields: { ...editableDetails },
											setFieldByKeyOnChange:
												setEditableDetailsFieldByKeyOnChange,
										}}
										generalComment={{
											generalComment: editableDetails.generalComment,
											setFieldByKeyOnChange:
												setEditableDetailsFieldByKeyOnChange,
										}}
									/>
									<VehicleAccordion
										modelDetails={{ readOnlyFields: { ...readOnlyDetails } }}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadVariants: loadVariants,
										}}
									/>
									<ProductionAccordion
										productionDatesDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										respecificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsRespecificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadVariantsRespec: loadVariantsRespec,
										}}
									/>
									<LocalizationExportAccordion
										localizationExportDetails={{
											readOnlyFields: {
												...readOnlyDetails.exportLocalization,
											},
											loadLocalizationExport: loadLocalizationExport,
										}}
									/>
									<AvailabilityInvoicingDeliveryAccordion
										reservationExportDetails={{
											readOnlyFields: {
												...readOnlyDetails.reservationExport,
											},
											loadReservationExport: loadReservationExport,
										}}
										invoiceExportationDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadInvoiceExportation: loadInvoiceExportation,
										}}
										invoicingDeliveryMarketCompanyDetails={{
											readOnlyFields: {
												...readOnlyDetails.invoicingDeliveryMarketCompany,
											},
											loadInvoicingDeliveryMarketCompany:
												loadInvoicingDeliveryMarketCompany,
										}}
									/>
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasChanges}
								/>
							</Grid>
							<DetailsTimeline />
						</Grid>
					</Box>
				</>
			)}
			{!loading && !readOnlyDetails.id && <NoContentResult />}
		</>
	);
};

export default observer(TruckIndex);
