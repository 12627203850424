import * as React from "react";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import MuiDrawer from "@mui/material/Drawer";
import { List } from "@mui/material";

interface MenuDrawerProps {
	open: boolean;
	menuList: React.ReactNode;
	openDrawer: () => void;
	closeDrawer: () => void;
}

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	height: "100vh",
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: 240,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			overflowY: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(6),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(8),
			},
		}),
	},
}));

const MenuDrawer: React.FC<MenuDrawerProps> = ({
	open,
	menuList,
	openDrawer,
	closeDrawer,
}) => {
	return (
		<Drawer
			variant="permanent"
			open={open}
			onMouseOver={openDrawer}
			onMouseLeave={closeDrawer}
		>
			<Toolbar />
			<List component="nav">{menuList}</List>
		</Drawer>
	);
};

export default MenuDrawer;
