import { action, configure, makeObservable, observable, toJS } from "mobx";
import {
	CompositeFilterDescriptor,
	normalizeFilters,
} from "@progress/kendo-data-query";
import { LineProps } from "../interfaces/LineProps";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import React from "react";
import { LineSpeedProps } from "../interfaces/LineSpeedProps";
import { lineSpeedModel } from "../model/LineSpeedModel";
import { GUID_EMPTY } from "../../../../app/constants/config";
import { SelectOptionProps } from "../../../../utils/GenericInterfaces";

configure({ enforceActions: "always" });

class LineSpeedStore {
	@observable filters: CompositeFilterDescriptor | undefined = normalizeFilters(
		{
			operator: "and",
			filters: [],
		}
	);

	@observable loading: boolean = false;
	@observable data: Array<LineSpeedProps> = [];
	@observable lines: Array<LineProps> = [];
	@observable lineOptions: Array<SelectOptionProps> = [];
	@observable alertMessage: string = "";
	@observable formVisible: boolean = false;
	@observable actionLabel: string = "";
	@observable refresh: number = 0;
	@observable dataItemChanged: LineSpeedProps = lineSpeedModel;
	@observable model: LineSpeedProps = lineSpeedModel;
	@observable isValidObject: boolean = false;

	api: CtadmvenApi;

	constructor() {
		this.model = lineSpeedModel;
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action setLoading = (value: boolean) => {
		this.loading = value;
	};

	@action setFormVisible = (value: boolean) => (this.formVisible = value);

	@action setActionLabel = (value: string) => (this.actionLabel = value);

	@action setDataItemChanged = (value: LineSpeedProps) =>
		(this.dataItemChanged = value);

	@action setIsValidObject = (value: boolean) => (this.isValidObject = value);

	@action setDataItemChangedFieldByKey = <K extends keyof LineSpeedProps>(
		fieldKey: K,
		newValue: any
	): void => {
		this.dataItemChanged[fieldKey] = newValue;

		if (
			this.dataItemChanged.code &&
			this.dataItemChanged.speed &&
			this.dataItemChanged.lineId !== GUID_EMPTY &&
			this.dataItemChanged.lineId !== ""
		) {
			var lineName = toJS(
				this.lineOptions.find((x) => x.value === this.dataItemChanged.lineId)
					?.label
			);

			if (
				(lineName !== "Bus HDV" && !this.dataItemChanged.lcd) ||
				(lineName === "Bus HDV" && this.dataItemChanged.lcd)
			) {
				this.setIsValidObject(true);
				return;
			}
		}

		this.setIsValidObject(false);
	};

	@action allRecords = async () => {
		this.setLoading(true);

		return await this.api.lineService
			.getList()
			.then((rs) => (this.data = rs))
			.finally(() => this.setLoading(false));
	};

	@action listLines = async () => {
		this.setLoading(true);

		this.api.lineService.listLines().then((rs) => {
			this.lines = rs.data;
			this.getLines();
			this.setLoading(false);
		});
	};

	@action getLines = async () => {
		this.lineOptions = this.lines.map((m) => ({
			label: m.vehicleType + " " + m.name,
			value: m.id,
		}));
	};

	@action createOrUpdate = async (record: any) => {
		this.setLoading(true);
		if (!record) return;

		return this.api.lineService
			.createOrUpdate(record)
			.finally(() => this.refresh++);
	};

	@action deleteLineSpeed = async (lineSpeedId: string) => {
		this.setLoading(true);
		this.setDataItemChanged(lineSpeedModel);
		return await this.api.lineService
			.delete(lineSpeedId)
			.finally(() => this.refresh++);
	};
}

const LineSpeedStoreContext = React.createContext(new LineSpeedStore());

export const useLineSpeedStore = () => React.useContext(LineSpeedStoreContext);
