import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import SelectForm from "../FormInputs/SelectForm";
import { getMarketOptions } from "../../utils/SelectOptions";
import { textNotEmptyValidator } from "../../utils/FormValidators";
import { MarketType } from "../../utils/GenericTypes";

interface MarketFilterProps {
	value: MarketType;
	set: (value: any) => void;
}

const MarketFilter: React.FC<MarketFilterProps> = ({ set, value = "BR" }) => {
	const { t } = useTranslation("general");
	const [market, setMarket] = useState<string>(value);
	const marketOptions = getMarketOptions();

	const validator = (value: string) => {
		if (!textNotEmptyValidator(value)) return "required";
		return "";
	};

	const handleChange = (event: any) => {
		const value = event.target.value;
		setMarket(value);
		if (!Boolean(validator(value))) set({ value: value, isValid: true });
		else set({ value: value, isValid: false });
	};

	return (
		<SelectForm
			editable
			label={t("market")}
			labelId={"filter-market-label"}
			value={market}
			options={marketOptions}
			onChange={handleChange}
			validator={validator}
			sx={{ width: "60px" }}
		/>
	);
};

export default observer(MarketFilter);
