import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import TextPreFilter from "../components/TextPreFilter";
import { GRID_MAX_SIZE } from "../../../constants/gridConst";

const SALESMAN_PRE_FILTER_XS_SM = GRID_MAX_SIZE;
const SALESMAN_PRE_FILTER_MD_LG = 6;
const SALESMAN_PRE_FILTER_XL = 4;

const SalesmanPreFilter: React.FC<TextPreFilterProps> = (props) => {
	const { t } = useTranslation("order");
	const {
		id = "salesmanPreFilter",
		maxLength = 100,
		label = "preFilters.salesman",
		xs = SALESMAN_PRE_FILTER_XS_SM,
		sm = SALESMAN_PRE_FILTER_XS_SM,
		md = SALESMAN_PRE_FILTER_MD_LG,
		lg = SALESMAN_PRE_FILTER_MD_LG,
		xl = SALESMAN_PRE_FILTER_XL,
	} = props;
	return (
		<TextPreFilter
			{...props}
			id={id}
			label={t(label)}
			maxLength={maxLength}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		/>
	);
};

export default observer(SalesmanPreFilter);
