import React from "react";
import { observer } from "mobx-react";

interface FooterCellProps {
	value: string | number;
}

const FooterCell: React.FC<FooterCellProps> = ({ value }) => {
	return <td>{value}</td>;
};

export default observer(FooterCell);
