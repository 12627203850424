import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/RootStore";
import { observer } from "mobx-react";
import ClearRecordItem from "../../../../../../components/BatchEdition/ClearRecordItem";
import { followUpArgKeyLabels } from "../../../utils/BatchEditionUtils";
import TextForm from "../../../../../../components/FormInputs/TextForm";

const DestinationForm: React.FC = () => {
	const { t } = useTranslation("order");

	const { batchData, setMCManagementDetailDestination } =
		useStores().batchEditionStore;

	return (
		<ClearRecordItem
			recordTitle={t(followUpArgKeyLabels.destination.label)}
			checked={batchData.orderMCManagementDetail.destination.clearValue}
			onClearRecord={setMCManagementDetailDestination}
		>
			<TextForm
				label={t(followUpArgKeyLabels.destination.label)}
				editable
				value={batchData.orderMCManagementDetail.destination.value}
				slotProps={{ htmlInput: { maxLength: 150 } }}
				onChange={(e) =>
					setMCManagementDetailDestination({
						value: e.target.value,
						clearValue: false,
					})
				}
				disabled={batchData.orderMCManagementDetail.destination.clearValue}
			/>
		</ClearRecordItem>
	);
};

export default observer(DestinationForm);
