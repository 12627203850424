import { configure } from "mobx";
import { FilterDescriptor, } from "@progress/kendo-data-query";
import RootStore from "./RootStore";
import BaseFilterStore from "../../../../components/PreFilter/Stores/BaseFilterStore";

configure({ enforceActions: "always" });

class FilterStore extends BaseFilterStore {
	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	cleanSpecificPreFilters = () => {
		this.rootStore.preFilterChassiAOStore.clean();
		this.rootStore.preFilterLineSequenceDateStore.clean();
		this.rootStore.preFilterDeliveryProposalStore.clean();
		this.rootStore.preFilterMonthAvailabilityStore.clean();
		this.rootStore.preFilterLineStore.clean();
		this.rootStore.preFilterZoneStore.clean();
		this.rootStore.preFilterAsWeekStore.clean();
		this.rootStore.preFilterCommercialModelStore.clean();
		this.rootStore.preFilterSalesmanStore.clean();
		this.rootStore.preFilterQuotationCustomerStore.clean();
		this.rootStore.preFilterQuotationSalesmanStore.clean();
		this.rootStore.preFilterCustomerSASStore.clean();
		this.rootStore.preFilterCustomerOMStore.clean();
	};

	applySpecificFilters(
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>): any {
		andFilters =
			this.rootStore.preFilterChassiAOStore.getFilterDescriptorBySize(
				andFilters,
				orFiltersGroups
			);

		andFilters =
			this.rootStore.preFilterDeliveryProposalStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterMonthAvailabilityStore.getFilterDescriptor(
				andFilters
			);

		this.rootStore.preFilterLineStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterZoneStore.getFilterDescriptor(orFiltersGroups);

		andFilters =
			this.rootStore.preFilterAsWeekStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterCommercialModelStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterLineSequenceDateStore.getFilterDescriptor(
				andFilters
			);

		this.rootStore.preFilterCustomerSASStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterCustomerOMStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterSalesmanStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterQuotationCustomerStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterQuotationSalesmanStore.getFilterDescriptor(
			orFiltersGroups
		);

		return { andFilters, orFiltersGroups };
	};
}

export default FilterStore;
