import RootStore from "../../../stores/RootStore";
import { configure, makeObservable } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import BasePreFilterDealerStore from "../../../../../../components/PreFilter/Stores/BasePreFilterDealerStore";

configure({ enforceActions: "always" });

class PreFilterDealerStore extends BasePreFilterDealerStore{
	constructor(
		rootStore: RootStore,
		api: CtadmvenApi
	) {
		super(rootStore, api);
		makeObservable(this);
	}
}

export default PreFilterDealerStore;
