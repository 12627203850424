import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import _ from "lodash";
import RootStore from "./RootStore";
import { AutoCompleteOptionProps } from "../../../../utils/GenericInterfaces";
import { batchDataModel } from "../models/BatchEdition/BatchDataModel";
import { BatchDataProps } from "../interfaces/BatchEdition/BatchDataProps";
import { newRootStore } from "../utils/StoreUtils";

configure({ enforceActions: "always" });

class BatchEditionStore {
	@observable batchData: BatchDataProps = batchDataModel;
	@observable formHasChanges: boolean = false;
	@observable customerOptions: Array<AutoCompleteOptionProps> = [];
	@observable loading: boolean = false;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private readonly api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action setLoading = (value: boolean) => {
		this.rootStore.listStore.setLoading(value);
	};

	@action resetData = () => {
		this.resetBatchData();
		this.setFormHasChanges(false);
	};

	@action setCustomerOptions = async () => {
		if (this.customerOptions.length > 0) return;

		this.loading = true;
		await this.api.customerService
			.getCustomerByCountry("CHL")
			.then((rs: any) => this.setCustomer(toJS(rs.data)))
			.finally(() => (this.loading = false));
	};

	@action private readonly setCustomer = (data: any) => {
		this.customerOptions = [];

		if (!data) {
			return;
		}

		this.customerOptions = data.map(
			(m: any) =>
				({
					label: m.name ?? m.code,
					value: m.code,
					searchValue: `${m.name}/${m.code}`,
				}) as AutoCompleteOptionProps
		);
	};

	@action private resetBatchData = () => (this.batchData = batchDataModel);

	@action setFormHasChanges = (formHasChanges: boolean) =>
		(this.formHasChanges = formHasChanges);

	@action checkAndSetFormHasChanges = () => {
		const batchDataHasChanges = !_.isEqual(batchDataModel, this.batchData);
		this.setFormHasChanges(batchDataHasChanges);
	};

	@action setBatchDataByKey = <K extends keyof BatchDataProps>(
		newValue: BatchDataProps[K],
		fieldKey: K
	) => {
		const _batchDataProps: BatchDataProps = Object.assign({}, this.batchData);
		_batchDataProps[fieldKey] = newValue;
		this.batchData = _batchDataProps;
		this.checkAndSetFormHasChanges();
	};

	@action updateBatchData = async () => {
		this.setLoading(true);
		const batchData = toJS(this.batchData);

		return await this.api.followUpChlService
			.updateBatch(this.rootStore.listStore.lastDataState, batchData)
			.then(() => this.resetData())
			.finally(() => {
				this.rootStore.listStore.setRefresh();
			});
	};
}

export default BatchEditionStore;
