import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import { observer } from "mobx-react";
import { CTADMVEN_FOLLOWUP_CHL } from "../../constants/routes";
import { CHASSI_FO_OM_AO } from "./PreFilterUtils";

interface AppliedFiltersChipsProps {
	filters?: any;
	chassiType: string;
	removeFilter: any;
	page?: string;
}

interface ElementProps {
	field: string;
}

const extractFields = (filters: any): ElementProps[] => {
	let result: ElementProps[] = [];

	filters?.filters?.forEach((filter: any) => {
		result = processFilter(filter, result);
	});

	return result;
};

const processFilter = (filter: any, result: ElementProps[]): ElementProps[] => {
	if (hasAlreadyChassiOrFOOrOMOrAO(result, filter?.field)) {
		return result;
	}

	if (canPushIntoArray(result, filter)) {
		result.push({ field: filter.field });
	} else if (filter?.filters) {
		filter.filters.forEach((subFilter: any) => {
			result = processFilter(subFilter, result);
		});
	}
	return result;
};

function hasAlreadyChassiOrFOOrOMOrAO(result: ElementProps[], field: string): boolean {
	return CHASSI_FO_OM_AO.includes(field) && result.some(item => CHASSI_FO_OM_AO.includes(item.field));
}

const canPushIntoArray = (result: ElementProps[], filter: any) => filter?.field && !result.some(item => item.field === filter.field)

const getFieldLabel = (element: ElementProps, t: any, chassiType: string, page?: string): string => {
	if (CHASSI_FO_OM_AO.includes(element.field)) {
		return t("preFilters." + chassiType);
	}

	if (element.field === "customer" && page === CTADMVEN_FOLLOWUP_CHL) {
		return t("preFilters.customerSAS");
	}

	if (element.field === "reservations.factoryOrder") {
		return t("preFilters.quotationFO");
	}

	return t("preFilters." + element.field.split(".").at(-1))
}

const AppliedFiltersChips: React.FC<AppliedFiltersChipsProps> = ({ filters, chassiType, removeFilter, page }) => {
	const { t } = useTranslation("order");

	const extractedFilters = extractFields(filters);

	return (
		<Box display="flex" width={"85%"} gap={"10px"} flexWrap={"wrap"} paddingLeft={"20px"}>
			{extractedFilters.map((filter, i) =>
				<Chip
					key={i}
					variant="outlined"
					color="info"
					size="small"
					label={getFieldLabel(filter, t, chassiType, page)}
					onDelete={() => { removeFilter(filter) }}
				/>
			)}
		</Box>
	);
};

export default observer(AppliedFiltersChips);
