import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ModelSectionProps } from "../../../interfaces/Sections/Europe/ModelSection";
import TextForm from "../../../../../../components/FormInputs/TextForm";

const ModelSection: React.FC<ModelSectionProps> = ({ readOnlyFields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.model.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.productionModel")}
					value={readOnlyFields.industrialModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialModel")}
					value={readOnlyFields.commercialModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.factoryYear")}
					value={readOnlyFields.factoryYear}
					type="number"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.traction")}
					value={readOnlyFields.traction}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.tractionType")}
					value={readOnlyFields.tractionType}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.weigth")}
					value={readOnlyFields.weigth}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.power")}
					value={readOnlyFields.power}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSection);
