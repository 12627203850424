import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { IPreFilterDeliveryProposal } from "../interfaces/IPreFilter";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterDeliveryProposalStore {
	@observable rootStore;
	@observable preFilter?: IPreFilterDeliveryProposal = undefined;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = undefined;
	};

	@action clean = () => (this.preFilter = undefined);

	@action get = () =>
		this.preFilter
			? [
					getFilterObject("deliveryProposal", this.preFilter?.startDate, "gte"),
					getFilterObject("deliveryProposal", this.preFilter?.endDate, "lte"),
				]
			: [];

	@action set = (
		deliveryProposalFilter: IPreFilterDeliveryProposal | undefined
	) => {
		if (deliveryProposalFilter !== null)
			this.rootStore.filterStore.hasPreFiltersApplied = true;

		this.preFilter = deliveryProposalFilter;
	};

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const deliveryProposalFilters = this.get();

		if (deliveryProposalFilters)
			andFilters = andFilters.concat(deliveryProposalFilters);

		return andFilters;
	}
}

export default PreFilterDeliveryProposalStore;
