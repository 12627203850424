import RootStore from "../../../stores/RootStore";
import { action, configure, observable } from "mobx";
import {
	OrderBookMenuType,
	TOTAL,
} from "../../../../../../constants/Menus/OrderMenuUtils";
import {
	getFilterObject,
} from "../../../../../../utils/FilterOperations";
import BasePreFilterChassiAOStore from "../../../../../../components/PreFilter/Stores/BasePreFilterChassiAOStore";
import { FilterDescriptor } from "@progress/kendo-data-query";

configure({ enforceActions: "always" });

class PreFilterChassiAOStore extends BasePreFilterChassiAOStore {
	@observable menu: OrderBookMenuType = TOTAL;

	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	@action setMenu = (value: OrderBookMenuType) => (this.menu = value);

	@action getFilterBySize = (value: string) => {
		value = value.trim();

		if (value === "") {
			return [];
		}

		var operation = "eq";
		var field = "";
		var chassi = "chassi";
		var aOOMNumber = "aOOMNumber";

		switch (value.length) {
			case 6:
				field = chassi;
				break;

			case 7:
				field = chassi;
				value = value.substring(1);
				break;

			case 8:
				field = aOOMNumber;
				break;

			case 11:
				field = aOOMNumber;
				value = value.substring(2).replace(" ", "");
				break;

			default:
				break;
		}

		return [getFilterObject(field, value, operation)];
	};

	@action set = (value: string) => this.setBase(value, this.getFilterBySize);

	@action getFilterDescriptor = (andFilters: Array<FilterDescriptor>) => this.getFilterDescriptorBase(andFilters, this.getFilterBySize)

	@action getFilterDescriptorBySize = (
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>
	) => this.getFilterDescriptorBySizeBase(andFilters, orFiltersGroups, this.getFilterBySize);
}

export default PreFilterChassiAOStore;
