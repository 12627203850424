import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { Login } from "@mui/icons-material";
import { useAuthStore } from "../../auth/store/AuthStore";

const SignInButton: React.FC = () => {
	const { instance } = useMsal();
	const { handleLogin } = useAuthStore();

	return (
		<Button
			variant="outlined"
			onClick={() => handleLogin("redirect", instance)}
			startIcon={<Login />}
		>
			Sign In
		</Button>
	);
};

export default SignInButton;
